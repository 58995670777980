import { Region } from '@getpopsure/public-models';
import { ValidVerticalUrlParams } from 'features/paymentScreen/utils/verticalId.utils';
import { useSafeTranslation } from 'shared/i18n';

import { ClaimSubmittedView } from './ClaimSubmitted.view';

interface Props {
  children?: React.ReactNode;
  vertical: ValidVerticalUrlParams | 'private';
  region: Region;
}

export const ClaimSubmitted = ({ children, vertical, region }: Props) => {
  const { t } = useSafeTranslation();
  return (
    <ClaimSubmittedView t={t} vertical={vertical} region={region}>
      {children}
    </ClaimSubmittedView>
  );
};
