import SectionHeader from 'components/sectionHeader';
import { LiabilityCoverageDetails } from 'features/liability/components/LiabilityCoverageDetails/LiabilityCoverageDetails';
import { CardInfo } from 'models/liability';

import CoverageCard from './components/coverageCard';
import styles from './style.module.scss';

interface Props {
  cardInfo: CardInfo[];
  title?: string;
  showCoverageDetails?: boolean;
}

const CoverageSection = ({ cardInfo, title, showCoverageDetails }: Props) => (
  <>
    <div
      className={`d-flex ai-center jc-between gap8 ${styles.titleContainer}`}
    >
      <div className={`d-flex fd-column ${styles.sectionHeaderContainer}`}>
        {title && <SectionHeader title={title} />}
      </div>
      {showCoverageDetails && (
        <LiabilityCoverageDetails useAlternateDefaultCaption />
      )}
    </div>
    <div className={`mt8 ${styles['coverage-card-container']}`}>
      {cardInfo.map(({ title: cardTitle, description, icon }, index) => (
        <CoverageCard
          key={String(`${cardTitle}${index}`)}
          className="wmx6 mt40"
          description={description}
          title={cardTitle}
          icon={icon}
        />
      ))}
    </div>
  </>
);

export default CoverageSection;
