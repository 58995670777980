import { email } from '@getpopsure/private-constants';
import { Button } from '@popsure/dirty-swan';
import { useMutation } from '@tanstack/react-query';
// eslint-disable-next-line import/no-cycle
import { fetchPolicyDetail, mergePolicyAttr } from 'actions/policies';
import { HealthCardDispatch } from 'models/healthCard';
import { useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { createPortal } from 'react-dom';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import endpoint from 'shared/api';

import { ConfirmReceivedHealthCardModal } from '../ConfirmReceivedHealthCardModal';
import { useMarkCardAsReceived } from '../hooks/useMarkCardAsReceived';
import { healthCardMutationErrorHandler } from '../utils/healthCardMutationErrorHandler';

export const RequestHealthCard = () => {
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const { policyId } = useParams<{ policyId: string }>();
  const dispatch = useDispatch<HealthCardDispatch>();

  const {
    mutate: createHealthCardRecord,
    isLoading: requestCardLoading,
    error: requestCardError,
  } = useMutation({
    mutationFn: (id: string) => {
      return endpoint.createHealthCardRecord(id);
    },
  });

  const {
    mutate: markHealthCardAsReceived,
    isLoading: markAsReceivedLoading,
    error: markAsReceivedError,
  } = useMarkCardAsReceived();

  const refreshPolicy = async () => dispatch(fetchPolicyDetail(policyId));

  const handleRequestHealthCard = async () => {
    createHealthCardRecord(policyId, {
      onSuccess: async () => {
        await refreshPolicy();
      },
      onError: (error, id) =>
        healthCardMutationErrorHandler(error, { policyId: id }),
    });
  };

  const openConfirmationModal = () => setShowConfirmModal(true);

  const handlCancel = () => setShowConfirmModal(false);

  const handleMarkAsReceived = async () => {
    markHealthCardAsReceived(policyId, {
      onSuccess: async () => {
        await refreshPolicy();
        dispatch(
          mergePolicyAttr(policyId, { showHealthCardConfirmation: true })
        );
      },
    });
  };

  return (
    <>
      <div className="d-flex fd-column">
        <div className="d-flex ai-center">
          <Button
            data-testid="request-health-card-button"
            data-cy="request-health-card-button"
            className="w100 wmx3"
            onClick={handleRequestHealthCard}
            loading={requestCardLoading}
          >
            Request Health Card
          </Button>
          <Button
            data-testid="mark-health-card-as-received"
            data-cy="mark-health-card-as-received"
            className="ml16 w100 wmx3"
            variant="filledGray"
            onClick={openConfirmationModal}
          >
            I already have it
          </Button>
        </div>
        <AnimateHeight
          duration={150}
          height={requestCardError || markAsReceivedError ? 'auto' : 0}
        >
          <div className="p-notice p-notice--danger mt16 p-p">
            {`Sorry, something didn’t work as it should. Please try again or contact us at ${email.help}`}
          </div>
        </AnimateHeight>
      </div>
      {showConfirmModal &&
        createPortal(
          <ConfirmReceivedHealthCardModal
            loading={markAsReceivedLoading}
            onMarkAsReceived={handleMarkAsReceived}
            onCancel={handlCancel}
            error={markAsReceivedError}
          />,
          document.body
        )}
    </>
  );
};
