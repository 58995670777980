import { Address } from '@getpopsure/public-models';
import { getPaymentMethods } from 'features/paymentMethods/paymentMethods.selectors';
import { PaymentOption } from 'features/paymentMethodSelector/paymentMethodSelector.models';
import { useQueryParamValue } from 'hooks/useQueryParamValue';
import { PaymentMethod } from 'models/paymentMethods';
import type { Dispatch, FC, SetStateAction } from 'react';
import { createContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

interface ICheckoutContext {
  addNewPaymentMethod: boolean;
  setAddNewPaymentMethod: Dispatch<SetStateAction<boolean>>;
  paymentOption: PaymentOption;
  currentUserPaymentMethods: PaymentMethod[];
  setPaymentOption: Dispatch<SetStateAction<PaymentOption>>;
  paymentTypeSelected: string | undefined;
  onStripePaymentMethodTypeChange: (type: string) => void;
  currentSelectedPaymentMethod?: PaymentMethod;
  setCurrentSelectedPaymentMethod: Dispatch<
    SetStateAction<PaymentMethod | undefined>
  >;
  isSubmittingNewPaymentMethod: boolean;
  setIsSubmittingNewPaymentMethod: Dispatch<SetStateAction<boolean>>;
  isProcessingPayment: boolean;
  setIsProcessingPayment: Dispatch<SetStateAction<boolean>>;
  address: Address | undefined;
  setAddress: Dispatch<SetStateAction<Address | undefined>>;
  newDefaultOption: boolean;
  setNewDefaultOption: Dispatch<SetStateAction<boolean>>;
}

/**
 * This hook is intended to pick a payment method from query params to
 * support a redirect from Stripe Element when setting up i.e. PayPal
 */
export const usePaymentOptionFromQuery = (
  paymentOptions: PaymentMethod[]
): PaymentMethod | undefined => {
  const paymentMethodId = useQueryParamValue('paymentMethodId');
  if (!paymentMethodId) return;

  return paymentOptions.find(({ id }) => id === paymentMethodId);
};

export const CheckoutContext = createContext<ICheckoutContext>({
  addNewPaymentMethod: false,
  setAddNewPaymentMethod: () => {},
  currentUserPaymentMethods: [],
  paymentOption: {
    type: 'NOT_STARTED',
  },
  setPaymentOption: () => {},
  paymentTypeSelected: '',
  onStripePaymentMethodTypeChange: () => {},
  currentSelectedPaymentMethod: undefined,
  setCurrentSelectedPaymentMethod: () => {},
  isSubmittingNewPaymentMethod: false,
  setIsSubmittingNewPaymentMethod: () => {},
  isProcessingPayment: false,
  setIsProcessingPayment: () => {},
  address: undefined,
  setAddress: () => {},
  newDefaultOption: false,
  setNewDefaultOption: () => {},
});

export const CheckoutProvider: FC = ({ children }) => {
  const currentUserPaymentMethods = useSelector(getPaymentMethods);
  const [paymentOption, setPaymentOption] = useState<PaymentOption>({
    type: 'NOT_STARTED',
  });
  const [addNewPaymentMethod, setAddNewPaymentMethod] =
    useState<boolean>(false);

  const [setDefaultOption, setSetDefaultOption] = useState<boolean>(false);

  const [paymentTypeSelected, setPaymentTypeSelected] = useState<
    string | undefined
  >(currentUserPaymentMethods.find((method) => method.isDefault)?.type);

  const [currentSelectedPaymentMethod, setCurrentSelectedPaymentMethod] =
    useState<PaymentMethod | undefined>(
      currentUserPaymentMethods.find((method) => method.isDefault)
    );

  const [isSubmittingNewPaymentMethod, setIsSubmittingNewPaymentMethod] =
    useState<boolean>(false);
  const [address, setAddress] = useState<Address | undefined>(undefined);

  const [isProcessingPayment, setIsProcessingPayment] =
    useState<boolean>(false);

  const onStripePaymentMethodTypeChange = (type: string) => {
    setPaymentTypeSelected(type);
  };
  const overridePaymentOption = usePaymentOptionFromQuery(
    currentUserPaymentMethods
  );

  useEffect(() => {
    setCurrentSelectedPaymentMethod(
      overridePaymentOption ||
        currentUserPaymentMethods.find((method) => method.isDefault)
    );
  }, [currentUserPaymentMethods, overridePaymentOption]);

  return (
    <CheckoutContext.Provider
      value={{
        addNewPaymentMethod,
        setAddNewPaymentMethod,
        paymentOption,
        setPaymentOption,
        paymentTypeSelected,
        onStripePaymentMethodTypeChange,
        currentUserPaymentMethods,
        currentSelectedPaymentMethod,
        setCurrentSelectedPaymentMethod,
        isSubmittingNewPaymentMethod,
        setIsSubmittingNewPaymentMethod,
        isProcessingPayment,
        setIsProcessingPayment,
        address,
        setAddress,
        newDefaultOption: setDefaultOption,
        setNewDefaultOption: setSetDefaultOption,
      }}
    >
      {children}
    </CheckoutContext.Provider>
  );
};
