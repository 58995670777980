import * as Sentry from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import { ValidVerticalUrlParams } from 'features/paymentScreen/utils/verticalId.utils';
import { useQueryParamValue } from 'hooks/useQueryParamValue';
import { InsuranceTypes } from 'models/insurances/types';

import { getPolicyRecommendations } from './api/policyRecommendations.api';
import { PetHealthCard } from './components/PetHealth';

const mapToVertical: Partial<
  Record<ValidVerticalUrlParams | InsuranceTypes, InsuranceTypes>
> = {
  // The signupSuccessParam for the CMS-signup is DOG_LIABILITY. `dog` should be removed once we clean up the feature flag app_cms_dog_liability
  dog: 'DOG_LIABILITY',
  DOG_LIABILITY: 'DOG_LIABILITY',
};

const verticalCards: Partial<Record<InsuranceTypes, React.ElementType>> = {
  PET_HEALTH: PetHealthCard,
};

/**
 * PolicyRecommendations is a self contained component that can be dropped into
 * any part of the application and will return policy recommendations based on a provided
 * vertical id.
 *
 * The component knows how to interact with the API to fetch and handle the response.
 * Based on the response the component might show one or more recommendations
 * or render null if no recommendations are applicable to a vertical.
 * The recommendations themselves are calculated based on the provided vertical id and the policies the current user has.
 *
 * The current implementation only covers dog-liability/pet-health cross recommendations but will be extended further in the future.
 *
 */
export const PolicyRecommendations = () => {
  const signupSuccessParam = useQueryParamValue(
    'signupSuccess'
  ) as ValidVerticalUrlParams;

  const insuranceType = mapToVertical[signupSuccessParam];

  const {
    data: recommendedPolicies,
    isError,
    error,
  } = useQuery(
    ['recommendedPolicies', insuranceType],
    () => getPolicyRecommendations(insuranceType),
    {
      enabled: Boolean(insuranceType),
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      initialData: [],
    }
  );

  if (isError) {
    Sentry.captureException(error, {
      tags: {
        feature: 'POLICY_RECOMMENDATION',
      },
      extra: {
        description: `Error happened while fetching policy recommentations for vertical: ${insuranceType}`,
      },
    });
  }

  if (recommendedPolicies && recommendedPolicies.length > 0) {
    return (
      <>
        {recommendedPolicies.map((type) => {
          const Component = verticalCards[type];
          if (Component) {
            return <Component key={type} />;
          }
          return null;
        })}
      </>
    );
  }

  return null;
};
