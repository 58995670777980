import { quotes as previewQuotes } from 'components/QuotePage/mocks';
import { QuoteCustomization } from 'components/QuotePage/Quote.models';
import { QuotePageView } from 'components/QuotePage/Quote.view';
import { useState } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const PreviewQuote = ({ ...props }: any) => {
  const [quoteCustomization, setQuoteCustomization] =
    useState<QuoteCustomization>({
      selectedPlan: previewQuotes[0].planId ?? '',
      selectedAddons: previewQuotes[0].addons ?? [],
      selectedQuoteOptions: props?.value?.quoteOptions ?? {},
    });

  const selectedQuote = previewQuotes.find(
    (quote) => quote.planId === quoteCustomization.selectedPlan
  );

  const { selectedAddons, selectedQuoteOptions, selectedPlan } =
    quoteCustomization;

  return (
    <QuotePageView
      isInitialLoading={false}
      hasMultiplePlans={Boolean(props.table)}
      quotes={props.table ? [...previewQuotes] : previewQuotes[0]}
      selectedQuote={selectedQuote}
      selectedPlan={selectedPlan}
      selectedQuoteOptions={selectedQuoteOptions}
      selectedAddons={selectedAddons}
      setQuoteCustomization={setQuoteCustomization}
      {...props}
    />
  );
};
