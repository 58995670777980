import {
  QuestionnaireFormProps,
  QuestionnaireQuestions,
} from '@getpopsure/qnr-framework';
import { ErrorWithAction } from 'components/ErrorWithAction';
import routes from 'constants/routes';
import { useEffect, useState } from 'react';
import { generatePath, useHistory, useParams } from 'react-router';
import { useSafeTranslation } from 'shared/i18n';
import { ProcessingView } from 'shared/pages/Processing/Processing.view';
import { ProcessingProps } from 'SignupQuestionnaire/models';

export const RequestProcessing = <
  Questionnaire extends QuestionnaireQuestions,
  TResponseData
>({
  onSubmitValue,
  requestFn,
  textList,
  description,
}: ProcessingProps<TResponseData> &
  QuestionnaireFormProps<Questionnaire> & {
    requestFn: () => Promise<TResponseData>;
  }) => {
  const { t } = useSafeTranslation();
  const { policyId } = useParams<{ policyId: string }>();
  const history = useHistory();

  const [processingFailed, setProcessingFailed] = useState(false);

  const TIMEOUT_TIME = 3_000;
  const elementIndex = 0;
  const fadeInAnimation = false;

  const list: string[] = textList
    ? [textList[0]]
    : [t('generic.qnr.processing.loadingText', 'Processing...')];

  useEffect(() => {
    const timeoutId = setTimeout(async () => {
      try {
        await requestFn();
        onSubmitValue(true);
      } catch {
        setProcessingFailed(true);
      }
    }, TIMEOUT_TIME);

    return () => clearTimeout(timeoutId);
  }, [onSubmitValue, requestFn]);

  const handlePolicyError = () => {
    const policyPage = generatePath(routes.me.policies.detail.path, {
      policyId,
    });
    history.push(policyPage);
  };

  if (processingFailed) {
    return (
      <ErrorWithAction
        title={t(
          'generic.qnr.processing.request.submissionError.title',
          'Something went wrong'
        )}
        description={t(
          'generic.qnr.processing.request.submissionError.description',
          'Sorry, something didn’t work as it should. Starting over should address this issue.'
        )}
        cta={{
          title: t(
            'generic.qnr.processing.request.submissionError.cta',
            'Start over'
          ),
          onClick: handlePolicyError,
        }}
      />
    );
  }

  return (
    <ProcessingView
      elementIndex={elementIndex}
      fadeInAnimation={fadeInAnimation}
      text={list}
      description={description}
    />
  );
};
