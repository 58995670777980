import {
  getRoutes,
  QuestionnaireQuestions,
  RemoveAnswerType,
  stateManagerHelper,
} from '@getpopsure/qnr-framework';
import { useFlag } from '@unleash/proxy-client-react';
import {
  removeGenericQuestionnaireAnswer,
  storeGenericQuestionnaireAnswer,
} from 'actions/genericQuestionnaire';
import routes from 'constants/routes';
import { UserWithBrokerMandate } from 'models/user';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { AppState } from 'reducers';
import { getAccountInfo } from 'selectors/user';
import { useSafeTranslation } from 'shared/i18n';
import { SignupQuestionnaire } from 'SignupQuestionnaire';

import { DisabilityV2 } from './models';
import { DisabilityV2Components } from './questionnaire';
import { getTranslatedQuestionnaire } from './questionnaire/questionnaire';
import { rules } from './rules';

const removeReview: RemoveAnswerType<DisabilityV2> = {
  op: 'always',
  questions: ['review'],
};

const removeAnswersLogic: Partial<
  Record<keyof DisabilityV2, RemoveAnswerType<DisabilityV2>>
> = {
  quote: removeReview,
  name: removeReview,
  email: removeReview,
  address: removeReview,
  review: {
    op: 'always',
    questions: ['hasDownloadedDocs'],
  },
};

const prefillNameAndBirthDate = <
  Questionnaire extends QuestionnaireQuestions,
  Key extends keyof Questionnaire
>(
  questionnaireAnswers: Partial<Questionnaire>,
  questionId: Key,
  answer: Questionnaire[Key],
  account?: UserWithBrokerMandate
) => {
  if (
    questionId === 'email' &&
    account &&
    account.email === answer &&
    questionnaireAnswers.email !== answer
  ) {
    let toUpdate: Partial<DisabilityV2> = {};
    const { firstName, lastName } = account;

    if (firstName && lastName) {
      toUpdate = { ...toUpdate, name: { firstName, lastName } };
    }
    return toUpdate;
  }

  return null;
};

export const SignupPage = () => {
  const questionnaireAnswers =
    useSelector((state: AppState) => state.genericQuestionnaire.disabilityV2) ||
    {};
  const dispatch = useDispatch();
  const account = useSelector(getAccountInfo);
  const useNewCheckout = useFlag('app_checkout_revamp_disability_v2');

  const onAnswer = <QuestionId extends keyof DisabilityV2>(
    questionId: QuestionId,
    answer: DisabilityV2[QuestionId]
  ) => {
    dispatch(
      storeGenericQuestionnaireAnswer('disabilityV2', { [questionId]: answer })
    );

    const answersToRemove = stateManagerHelper(
      removeAnswersLogic,
      questionnaire.components,
      questionnaireAnswers,
      questionnaire.rules
    ).getAnswersToRemove(questionId, answer);

    dispatch(removeGenericQuestionnaireAnswer('disabilityV2', answersToRemove));

    const toUpdate = prefillNameAndBirthDate(
      questionnaireAnswers,
      questionId,
      answer,
      account
    );
    if (toUpdate) {
      dispatch(storeGenericQuestionnaireAnswer('disabilityV2', toUpdate));
    }
  };

  const { t } = useSafeTranslation();
  const questions = getTranslatedQuestionnaire(t, useNewCheckout);

  const questionnaire = {
    components: questions,
    routes: getRoutes(questions, routes.policies.disabilityV2.path),
    rules,
  };

  return (
    <Switch>
      <Route path={routes.policies.disabilityV2.questionnaire.path}>
        <SignupQuestionnaire
          questionnaireAnswers={questionnaireAnswers}
          questionnaire={questionnaire}
          onAnswer={onAnswer}
          configuration={{
            components: DisabilityV2Components,
          }}
          basePath={routes.policies.disabilityV2.path}
          questionId="intro"
          featureName="DisabilityV2"
          share={{
            verticalId: 'disabilityV2',
          }}
        />
      </Route>
    </Switch>
  );
};
