import { faq } from '@getpopsure/private-constants';
import classNames from 'classnames';
import { IconTile } from 'components/IconTile';
import { ImprovementBox } from 'components/ImprovementBox/ImprovementBox';
import routes from 'constants/routes';
import { Link } from 'react-router-dom';
import { useSafeTranslation } from 'shared/i18n';
import { isMobileApp } from 'shared/util/isMobileApp';

import checkIcon from './icons/check.svg';
import confirmationIcon1 from './icons/confirmation1.svg';
import confirmationIcon2 from './icons/confirmation2.svg';
import confirmationIcon3 from './icons/confirmation3.svg';
import styles from './style.module.scss';

export const ClaimSubmitted = () => {
  const { t } = useSafeTranslation();
  return (
    <div
      className={classNames(
        `d-flex fd-column ai-center jc-center ml-auto mr-auto`,
        styles.container,
        {
          [styles.containerMobileApp]: isMobileApp,
        }
      )}
    >
      <img src={checkIcon} alt="circle with checkmark" />
      <h2 className="p-h2 mt32">
        {t('expat.qnr.claims.claimSubmitted.title', 'Claim submitted!')}
      </h2>
      <h4 className="p-h4 mt32">
        {t('expat.qnr.claims.claimSubmitted.subtitle', 'What happens next?')}{' '}
      </h4>
      <div className={`d-grid gap16 mt16 w100 ${styles.grid}`}>
        <IconTile
          title={t(
            'expat.qnr.claims.claimSubmitted.review.title',
            'Claim review'
          )}
          paragraphClassName="p-p--small"
          iconSrc={confirmationIcon1}
          iconAlt="checkmark on a sheet of paper"
        >
          {t(
            'expat.qnr.claims.claimSubmitted..review.description',
            'It will take 3 to 6 weeks to review and process your claim.'
          )}
        </IconTile>
        <IconTile
          title={t(
            'expat.qnr.claims.claimSubmitted.getInTouch.title',
            'We get in touch'
          )}
          paragraphClassName="p-p--small"
          iconSrc={confirmationIcon2}
          iconAlt="opened envelope with message inside"
        >
          {t(
            'expat.qnr.claims.claimSubmitted.getInTouch.description',
            'We will reach out by email when your claim status changes or if we require more details.'
          )}
        </IconTile>
        <IconTile
          title={t('expat.qnr.claims.claimSubmitted.payout.title', 'Payout')}
          paragraphClassName="p-p--small"
          iconSrc={confirmationIcon3}
          iconAlt="euro coins"
        >
          {t(
            'expat.qnr.claims.claimSubmitted.payout.description',
            `If approved, you’ll receive your reimbursement within a week. [Learn more]({{reimbursementFaq}}) about the most common reasons for partial payouts.`,
            { reimbursementFaq: faq.expatClaimPartialReimbursement }
          )}
        </IconTile>
      </div>
      <Link
        to={routes.claims.path}
        className={`p-btn p-btn--primary mt32 wmn4 ${styles.button}`}
      >
        {t('expat.qnr.claims.claimSubmitted.button.text', 'View all claims')}
      </Link>
      <ImprovementBox vertical="expat" region="de" />
    </div>
  );
};
