import { email } from '@getpopsure/private-constants';
import { Button } from '@popsure/dirty-swan';
import { useMutation } from '@tanstack/react-query';
import { fetchHealthCard } from 'actions/healthCard';
import { StepProps } from 'features/studentChecklist/models';
import { HealthCardDispatch } from 'models/healthCard';
import AnimateHeight from 'react-animate-height';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
// eslint-disable-next-line
import { getHealthCardId } from 'selectors/healthCard';
import endpoint from 'shared/api';

import { healthCardMutationErrorHandler } from '../utils/healthCardMutationErrorHandler';

const SendHealthCard = ({ completed }: StepProps) => {
  const dispatch = useDispatch<HealthCardDispatch>();
  const { policyId } = useParams<{ policyId: string }>();
  const healthCardId = useSelector(getHealthCardId(policyId));

  const {
    mutate: finalizeHealthCard,
    isLoading,
    isError,
  } = useMutation({
    mutationFn: (cardId: string) => {
      return endpoint.finalizeHealthCard(cardId);
    },
  });

  if (!healthCardId)
    throw new Error(
      '[HEALTH_CARD_CHECKLIST] trying to send a photo to a non existing health card'
    );

  const handleSendPhoto = async () => {
    finalizeHealthCard(healthCardId, {
      onSuccess: async () => {
        await dispatch(fetchHealthCard(healthCardId));
      },
      onError: (error, cardId) =>
        healthCardMutationErrorHandler(error, { healthCardId: cardId }),
    });
  };

  if (completed) return null;

  return (
    <div className="mt8">
      <Button
        data-testid="submit-health-card"
        data-cy="submit-health-card"
        className="w100 wmx3"
        onClick={handleSendPhoto}
        loading={isLoading}
      >
        Send health card
      </Button>
      <AnimateHeight duration={150} height={isError ? 'auto' : 0}>
        <div className="p-notice p-notice--danger mt8 p-p">
          {`An error occurred while submitting your health card details. Please try
            again or contact us at ${email.help}`}
        </div>
      </AnimateHeight>
    </div>
  );
};

export default SendHealthCard;
