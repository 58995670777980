import { trackPrivateHealthBookVideoDoctorClicked } from '@getpopsure/analytics';
import {
  BottomOrRegularModal,
  Button,
  Card,
  CheckIcon,
  CopyIcon,
  toast,
  VideoIcon,
} from '@popsure/dirty-swan';
import { PolicySingleGenericSectionProps } from 'features/policySingle/PolicySingle.models';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSafeTranslation } from 'shared/i18n';
import { copyToClipboard } from 'shared/util/copyToClipboard';

import { getUserId } from '../../../../../selectors/user';
import styles from './VideoDoctorAppointment.module.scss';

export const videoDoctorAppointmentURL =
  'https://legolas.md-medicus.net/Medicus/Demand/hkvdemanf.nsf/DocVideoPageEN?OpenPage';

export const VideoDoctorAppointment = ({
  policyData,
}: PolicySingleGenericSectionProps) => {
  const userId = useSelector(getUserId);
  const { policyNumber } = policyData.attributes;
  const { t } = useSafeTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hasCopied, setHasCopied] = useState(false);
  const ref = useRef<HTMLSpanElement>(null);
  const truncatedPolicyNumber = policyNumber?.endsWith('_d')
    ? policyNumber?.replace('_d', '')
    : policyNumber;

  const handleOnCopyPolicyNumber = () => {
    if (!ref.current) {
      return;
    }

    copyToClipboard(ref.current);
    setHasCopied(true);
    toast(
      t(
        'myPolicies.sections.videoDoctorAppointment.copiedPolicyNumberToClipboard',
        'Policy number copied to clipboard'
      ),
      { type: 'success' }
    );
  };

  const handleOnContinue = () => {
    trackPrivateHealthBookVideoDoctorClicked({ user_id: userId ?? null });
    window.open(videoDoctorAppointmentURL, '_blank');
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setHasCopied(false);
    }, 3000);

    return () => clearTimeout(timeout);
  }, [hasCopied]);

  return (
    <div className="p-container">
      <Card
        title={t(
          'myPolicies.sections.videoDoctorAppointment.title',
          'Video doctor'
        )}
        description={t(
          'myPolicies.sections.videoDoctorAppointment.description',
          'Get a doctor consultation from the comfort of your home'
        )}
        onClick={() => setIsModalOpen(true)}
        classNames={{ buttonWrapper: styles.card }}
        icon={<VideoIcon color="grey-700" size={32} />}
      />

      <BottomOrRegularModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={t(
          'myPolicies.sections.videoDoctorAppointment.modalTitle',
          'Book a video doctor'
        )}
      >
        <div className="p24 pt0">
          <p className="p-p mb16">
            {t(
              'myPolicies.sections.videoDoctorAppointment.modalContent',
              'Take note of your policy number below; you’ll need it in the next step to book the video call via our service partner.'
            )}
          </p>

          <p className="p-p fw-bold mb8" id="policy-number-label">
            {t(
              'myPolicies.sections.videoDoctorAppointment.modalPolicyNumberLabel',
              'Your policy number'
            )}
          </p>

          <div
            aria-describedby="policy-number-label"
            className="d-flex ai-center jc-between p-h3 bg-grey-200 br8 p8 pl16 mb16"
          >
            <span data-testid="copy-policy-number" ref={ref}>
              {truncatedPolicyNumber}
            </span>

            <Button
              leftIcon={hasCopied ? <CheckIcon /> : <CopyIcon />}
              hideLabel
              onClick={handleOnCopyPolicyNumber}
              variant="textColor"
            >
              {t(
                'myPolicies.sections.videoDoctorAppointment.modalCopyButton',
                'Copy policy number'
              )}
            </Button>
          </div>

          <Button
            className="p-btn p-btn--primary wmn3"
            onClick={handleOnContinue}
          >
            {t(
              'myPolicies.sections.videoDoctorAppointment.modalButton',
              'Continue'
            )}
          </Button>
        </div>
      </BottomOrRegularModal>
    </div>
  );
};
