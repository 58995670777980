import { website } from '@getpopsure/private-constants';
import { Button } from '@popsure/dirty-swan';
import shieldIcon from 'features/liability/icons/shield.svg';
import { useSafeTranslation } from 'shared/i18n';

import styles from './style.module.scss';

const PreviousCancelationBlocker = () => {
  const { t } = useSafeTranslation();
  return (
    <div className="p-body">
      <div className={`ta-center w100 wmx8 ${styles.container}`}>
        <img src={shieldIcon} alt="" />
        <header className="p-h1 mt8">
          {t(
            'page.liability.questionnaire.previousCancelationBlocker.title',
            'Unfortunately, we can’t sign you up'
          )}
        </header>

        <p className="p-p mt16">
          {t(
            'page.liability.questionnaire.previousCancelationBlocker.description.1',
            'We’re working hard to make our policies cover all use cases, but still have certain limitations.'
          )}
        </p>

        <Button
          as="a"
          className="mt24 wmn3"
          href={website.base}
          target="_blank"
          variant="filledWhite"
        >
          {t(
            'page.liability.questionnaire.previousCancelationBlocker.buttonCaption',
            'Back to homepage'
          )}
        </Button>
      </div>
    </div>
  );
};

export default PreviousCancelationBlocker;
