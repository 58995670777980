import { TFunction } from '@getpopsure/i18n-react';
import { Address, Gender } from '@getpopsure/public-models';
import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import dayjs from 'dayjs';
import { CheckoutInfo } from 'features/paymentScreen/paymentScreen.models';
import {
  PartialQuestionnaire,
  RoutesObject,
} from 'models/questionnaireFramework';
import { QuestionReviewValue } from 'models/review';
import { Name, UserWithBrokerMandate } from 'models/user';
import {
  ZAddressSchema,
  ZDateStringSchema,
  ZEmailSchema,
  ZFutureDateString,
  ZGenderSchema,
  ZNameSchema,
  ZUserWithBrokerMandateSchema,
} from 'shared/models/validations';
import { z } from 'zod';

export type Household = {
  address: Address;

  livingSpace: {
    propertyInSqMeters: number;
    propertyInSqFeet: number;
    selectedUnitSize: SpaceUnit;
  };

  securityDevices: {
    hasSecurityDevice: boolean;
    ownedSecurityDevices: SecurityDevice[];
  };

  insuredSum: number;

  addonOptions: {
    selectedAddons: HouseholdAddon[];
    bikeInsuredSum: number;
  };

  overview: boolean;

  propertyOwnership: PropertyOwnership;

  homeType: HomeType;

  isPrimaryResidence: boolean;

  coveringEveryHouseholdMember: boolean;

  numberOfPreviousClaims: NumberOfPreviousClaims;

  isClaimsAmountMoreThan1500: boolean;

  blocker: boolean;

  account: UserWithBrokerMandate | null;

  email: string;

  name: Name;

  dateOfBirth: string;

  ageBlocker: boolean;

  gender: Gender;

  startDate: string;

  review: boolean;

  quote: {
    questionnaireId: string;
    quoteId: string;
    totalPrice: number;
    priceDetails?: HouseholdPriceDetails;
  };

  checkoutInfo: CheckoutInfo;

  reviewCheckout: boolean;
};

export const NUMBER_OF_PREVIOUS_CLAIMS = [
  'NONE',
  'ONE',
  'TWO',
  'THREE_OR_MORE',
] as const;
export type NumberOfPreviousClaims = typeof NUMBER_OF_PREVIOUS_CLAIMS[number];

export const HOME_TYPES = ['SINGLE_HOME', 'APARTMENT', 'NOT_SURE'] as const;
export type HomeType = typeof HOME_TYPES[number];

export const PROPERTY_OWNERSHIP_TYPES = ['RENT', 'OWNED'] as const;
export type PropertyOwnership = typeof PROPERTY_OWNERSHIP_TYPES[number];

export const SPACE_UNITS = ['SQUARE_METERS', 'SQUARE_FEET'] as const;
export type SpaceUnit = typeof SPACE_UNITS[number];

export type HouseholdGroupId = 'signup';

export type HouseholdQuestionnaire = PartialQuestionnaire<
  Household,
  HouseholdGroupId
>;

export type HouseholdQuestionnaireRoutes = RoutesObject<HouseholdQuestionnaire>;

export type HouseholdQuestionId = keyof Household;

export const HOUSEHOLD_ADDONS = [
  'EXTENDED_COVERAGE',
  'BROKEN_GLASS_COVERAGE',
  'ADDITIONAL_BIKE_COVERAGE',
] as const;
export type HouseholdAddon = typeof HOUSEHOLD_ADDONS[number];

export const SECURITY_DEVICES = [
  'SMOKE_DETECTOR',
  'SECURITY_SYSTEM',
  'SECURITY_CAMERA',
  'NONE',
] as const;
export type SecurityDevice = typeof SECURITY_DEVICES[number];

export type HouseholdInfoBackendSchema = {
  livingSpace: {
    meters: number;
    feet: number;
    selectedUnit: SpaceUnit;
  };
  securityDevices: SecurityDevice[];
  hasSecurityDevice: boolean;
  homeType: HomeType;
};

export type PersonalInfoBackendSchema = {
  addOns: HouseholdAddon[];
  address: Address;
  bikeInsuredSum: number;
  coveringEveryHouseholdMember: boolean;
  dateOfBirth: string;
  email: string;
  gender: Gender;
  insuredSum: number;
  isClaimsAmountMoreThan1500: boolean;
  isPrimaryResidence: boolean;
  numberOfPreviousClaims: NumberOfPreviousClaims;
  name: Name;
  propertyOwnership: PropertyOwnership;
  startDate: string;
};

type SpecialPersonalInfoTypes = Pick<
  PersonalInfoBackendSchema,
  'addOns' | 'gender' | 'numberOfPreviousClaims' | 'propertyOwnership'
>;

type SpecialHouseholdInfoTypes = Pick<
  HouseholdInfoBackendSchema,
  'securityDevices' | 'homeType'
>;

export type HouseholdAnswersBackendSchema = {
  householdInfo: HouseholdInfoBackendSchema;
  personalInfo: PersonalInfoBackendSchema;
};

export type HouseholdQuestionnaireLabels = {
  labels: {
    title: {
      personalInfo: Record<keyof PersonalInfoBackendSchema, string>;
      householdInfo: Record<keyof HouseholdInfoBackendSchema, string>;
    };
    answers: {
      personalInfo: Record<
        keyof SpecialPersonalInfoTypes,
        Record<string, string>
      >;
      householdInfo: Record<
        keyof SpecialHouseholdInfoTypes,
        Record<string, string>
      >;
    };
  };
  questionOrder: {
    personalInfo: (keyof PersonalInfoBackendSchema)[];
    householdInfo: (keyof HouseholdInfoBackendSchema)[];
  };
};

export type HouseholdQuotesDto = {
  postcode: string;
  propertyInSqMeters: number;
  insuredSum: number;
  bikeInsuredSum: number;
  hasSecurityDevice: boolean;
  addOns: HouseholdAddon[];
};

export type HouseholdQuotesResponse = {
  id: string;
  planId?: string;
  price: number;
  attributes: {
    addOns: HouseholdAddon[];
    priceDetails: HouseholdPriceDetails;
  };
};

export type HouseholdPriceDetails = {
  basicPremium: number;
  extendedPremium: number;
  glassPrice: number;
  bikePrice: number;
  recommendedInsuredSum: number;
};

export type HouseholdPolicyDetails = {
  insuredSum?: number;
  address?: Address;
  homeSecurityDevice?: boolean;
  provider: string;
  bikeInsuredSum: number;
  addOns: HouseholdAddon[];
  priceDetails?: HouseholdPriceDetails;
  price: number;
};

const ZAddonOptionsSchema = z.object({
  bikeInsuredSum: z.number(),
  selectedAddons: z.array(z.enum(HOUSEHOLD_ADDONS)),
});

const ZLivingSpaceSchema = z.object({
  propertyInSqMeters: z.number(),
  propertyInSqFeet: z.number(),
  selectedUnitSize: z.enum(SPACE_UNITS),
});

const ZSecurityDeviceSchema = z.object({
  ownedSecurityDevices: z.array(z.enum(SECURITY_DEVICES)),
  hasSecurityDevice: z.boolean(),
});

export const ZHouseholdAnswersRequiredForQuoteSchema = z
  .object({
    addonOptions: ZAddonOptionsSchema,
    address: ZAddressSchema,
    dateOfBirth: ZDateStringSchema,
    email: ZEmailSchema,
    gender: ZGenderSchema,
    homeType: z.enum(HOME_TYPES),
    insuredSum: z.number(),
    name: ZNameSchema,
    numberOfPreviousClaims: z.enum(NUMBER_OF_PREVIOUS_CLAIMS),
    propertyOwnership: z.enum(PROPERTY_OWNERSHIP_TYPES),
    livingSpace: ZLivingSpaceSchema,
    securityDevices: ZSecurityDeviceSchema,
    startDate: ZFutureDateString,
    account: ZUserWithBrokerMandateSchema.nullable(),
    coveringEveryHouseholdMember: z.boolean(),
    isClaimsAmountMoreThan1500: z.boolean(),
    isPrimaryResidence: z.boolean(),
  })
  .transform(
    ({
      account,
      addonOptions: { selectedAddons: addOns, bikeInsuredSum },
      address,
      coveringEveryHouseholdMember,
      dateOfBirth,
      email,
      gender,
      insuredSum,
      isClaimsAmountMoreThan1500,
      isPrimaryResidence,
      homeType,
      livingSpace: {
        propertyInSqMeters: meters,
        propertyInSqFeet: feet,
        selectedUnitSize: selectedUnit,
      },
      name,
      numberOfPreviousClaims,
      propertyOwnership,
      securityDevices: {
        ownedSecurityDevices: securityDevices,
        hasSecurityDevice,
      },
      startDate,
    }) => ({
      account,
      householdAnswersTransformedForBackendSubmission: {
        personalInfo: {
          addOns,
          address,
          bikeInsuredSum,
          coveringEveryHouseholdMember,
          dateOfBirth,
          email,
          gender,
          insuredSum,
          isClaimsAmountMoreThan1500,
          isPrimaryResidence,
          name,
          numberOfPreviousClaims,
          propertyOwnership,
          startDate,
        },
        householdInfo: {
          livingSpace: {
            meters,
            feet,
            selectedUnit,
          },
          securityDevices,
          hasSecurityDevice,
          homeType,
        },
      },
      householdQuotesDto: {
        postcode: address.postcode,
        propertyInSqMeters: meters,
        insuredSum,
        bikeInsuredSum,
        hasSecurityDevice,
        addOns,
      },
    })
  );

export const answerValueToStringLookup = (
  t: TFunction
): Pick<
  Record<keyof Household, Record<string, string>>,
  | 'propertyOwnership'
  | 'homeType'
  | 'isPrimaryResidence'
  | 'numberOfPreviousClaims'
  | 'isClaimsAmountMoreThan1500'
  | 'coveringEveryHouseholdMember'
  | 'gender'
> => ({
  propertyOwnership: {
    RENT: t(
      'page.household.questionnaire.review.propertyownership.renting',
      'I am renting'
    ),
    OWNED: t(
      'page.household.questionnaire.review.propertyownership.owning',
      'I am a home owner'
    ),
  },
  homeType: {
    SINGLE_HOME: t(
      'page.household.questionnaire.review.hometype.singlehome',
      'House'
    ),
    APARTMENT: t(
      'page.household.questionnaire.review.hometype.apartment',
      'Apartment'
    ),
    NOT_SURE: t(
      'page.household.questionnaire.review.hometype.notsure',
      'Not sure'
    ),
  },
  isPrimaryResidence: {
    true: t(
      'page.household.questionnaire.review.isprimaryresidence.true',
      'Primary'
    ),
    false: t(
      'page.household.questionnaire.review.isprimaryresidence.false',
      'Secondary'
    ),
  },
  numberOfPreviousClaims: {
    NONE: t(
      'page.household.questionnaire.review.numberofpreviousclaims.none',
      'None'
    ),
    ONE: t(
      'page.household.questionnaire.review.numberofpreviousclaims.1claim',
      '1 claim'
    ),
    TWO: t(
      'page.household.questionnaire.review.numberofpreviousclaims.2claims',
      '2 claims'
    ),
    THREE_OR_MORE: t(
      'page.household.questionnaire.review.numberofpreviousclaims.3+claims',
      '3 or more'
    ),
  },
  isClaimsAmountMoreThan1500: {
    true: t(
      'page.household.questionnaire.review.isclaimsamountmorethanlimit.true',
      {
        defaultValue: 'Over {{limit}}',
        limit: englishFormattedEuroCurrency(1500, true),
      }
    ),
    false: t(
      'page.household.questionnaire.review.isclaimsamountmorethanlimit.false',
      {
        defaultValue: 'Up to {{limit}}',
        limit: englishFormattedEuroCurrency(1500, true),
      }
    ),
  },
  coveringEveryHouseholdMember: {
    true: t(
      'page.household.questionnaire.review.coveringeveryhouseholdmember.true',
      'Yes'
    ),
    false: t(
      'page.household.questionnaire.review.coveringeveryhouseholdmember.false',
      'No'
    ),
  },
  gender: {
    MALE: t('page.household.questionnaire.review.gender.male', 'Male'),
    FEMALE: t('page.household.questionnaire.review.gender.female', 'Female'),
    OTHER: t(
      'page.household.questionnaire.review.gender.nonconforming',
      'Gender Nonconforming'
    ),
  },
});

export const addonTypeLookup = (
  t: TFunction
): Record<HouseholdAddon, string> => ({
  EXTENDED_COVERAGE: t(
    'page.household.questionnaire.addons.extendedcoverage',
    'Extended coverage'
  ),
  BROKEN_GLASS_COVERAGE: t(
    'page.household.questionnaire.addons.brokenglasscoverage',
    'Broken glass coverage'
  ),
  ADDITIONAL_BIKE_COVERAGE: t(
    'page.household.questionnaire.addons.additionalbikecoverage',
    'Comprehensive bike theft coverage'
  ),
});

export type QuestionTextData = {
  question: string;
  description?: string;
  placeholder?: string;
  additionalInfo?: {
    title: string;
    description: string;
  };
  mapValue?: Record<string, string>;
};

export const questionPropLookup = (
  t: TFunction
): Record<keyof Household | 'addOns' | 'bikeInsuredSum', QuestionTextData> => ({
  address: {
    question: t(
      'page.household.questionnaire.address.question',
      "What's your home address?"
    ),
  },

  addOns: {
    question: t('page.household.questionnaire.addons.question', 'Add-ons'),
  },

  bikeInsuredSum: {
    question: t(
      'page.household.questionnaire.bikeinsuredsum.question',
      'Bike insured sum'
    ),
  },

  insuredSum: {
    question: t(
      'page.household.questionnaire.insuredsum.question',
      'Insured sum'
    ),
  },

  livingSpace: {
    question: t(
      'page.household.questionnaire.livingspace.question',
      'What’s the total living space of your home?'
    ),
    description: t(
      'page.household.questionnaire.livingspace.description',
      'The total living space should be indicated in your rental or purchase contract.'
    ),
    placeholder: t(
      'page.household.questionnaire.livingspace.placeholder',
      'Living space'
    ),
  },

  securityDevices: {
    question: t(
      'page.household.questionnaire.securitydevices.question',
      'Do you have any home security devices at home?'
    ),
    mapValue: {
      SMOKE_DETECTOR: t(
        'page.household.questionnaire.securitydevices.smokedetector',
        'Smoke detector'
      ),
      SECURITY_SYSTEM: t(
        'page.household.questionnaire.securitydevices.securitysystem',
        'Security system'
      ),
      SECURITY_CAMERA: t(
        'page.household.questionnaire.securitydevices.securitycamera',
        'Security camera'
      ),
      NONE: t(
        'page.household.questionnaire.securitydevices.none',
        'None of the above'
      ),
    },
    additionalInfo: {
      title: t(
        'page.household.questionnaire.securitydevices.additionalinfo.title',
        'Why does it matter?'
      ),
      description: t(
        'page.household.questionnaire.securitydevices.additionalinfo.description',
        'If you have any protective devices installed at home, you will benefit from a 5% discount on your insurance monthly premium.'
      ),
    },
  },

  addonOptions: { question: '' },

  overview: { question: '' },

  propertyOwnership: {
    question: t(
      'page.household.questionnaire.propertyownership.question',
      'Do you rent or own your home?'
    ),
    mapValue: {
      RENT: t(
        'page.household.questionnaire.propertyownership.rent',
        'I am renting'
      ),
      OWNED: t(
        'page.household.questionnaire.propertyownership.owned',
        'I am a homeowner'
      ),
    },
  },

  homeType: {
    question: t(
      'page.household.questionnaire.hometype.question',
      'What type of building is your household located in?'
    ),
    mapValue: {
      SINGLE_HOME: t(
        'page.household.questionnaire.hometype.singlehome',
        'Single home (detached house)'
      ),
      APARTMENT: t(
        'page.household.questionnaire.hometype.apartment',
        'Apartment building'
      ),
      NOT_SURE: t(
        'page.household.questionnaire.hometype.notsure',
        'I’m not sure'
      ),
    },
  },

  isPrimaryResidence: {
    question: t(
      'page.household.questionnaire.isprimaryresidence.question',
      'Is this your primary residence?'
    ),
  },

  coveringEveryHouseholdMember: {
    question: t(
      'page.household.questionnaire.coveringeveryhouseholdmember.question',
      'Will everyone in your household be covered under this plan?'
    ),
    description: t(
      'page.household.questionnaire.coveringeveryhouseholdmember.description',
      'If applicable, the insurance will be shared between other household members'
    ),
  },

  numberOfPreviousClaims: {
    question: t(
      'page.household.questionnaire.numberofpreviousclaims.question',
      'How many household contents insurance claims did you make in Germany over the past 3 years?'
    ),
    mapValue: {
      NONE: t(
        'page.household.questionnaire.numberofpreviousclaims.none',
        'None'
      ),
      ONE: t(
        'page.household.questionnaire.numberofpreviousclaims.1claim',
        '1 claim'
      ),
      TWO: t(
        'page.household.questionnaire.numberofpreviousclaims.2claims',
        '2 claims'
      ),
      THREE_OR_MORE: t(
        'page.household.questionnaire.numberofpreviousclaims.3+claims',
        '3 or more claims'
      ),
    },
  },

  isClaimsAmountMoreThan1500: {
    question: t(
      'page.household.questionnaire.isclaimsamountmorethanlimit.question',
      {
        defaultValue:
          'Was the total amount for the claims you made over the past 3 years more than {{limit}}?',
        limit: englishFormattedEuroCurrency(1500, true),
      }
    ),
  },

  blocker: { question: '' },

  account: { question: '' },

  email: {
    question: "What's your email address?",
  },

  name: {
    question: t(
      'page.household.questionnaire.name.question',
      'What’s your name?'
    ),
  },

  dateOfBirth: {
    question: t(
      'page.household.questionnaire.dateofbirth.question',
      'When were you born?'
    ),
  },

  ageBlocker: { question: '' },

  gender: {
    question: t(
      'page.household.questionnaire.gender.question',
      'Which gender do you identify with?'
    ),
    mapValue: {
      MALE: t('page.household.questionnaire.gender.male', 'Male'),
      FEMALE: t('page.household.questionnaire.gender.female', 'Female'),
      OTHER: t(
        'page.household.questionnaire.gender.nonconforming',
        'Gender Nonconforming'
      ),
    },
  },

  startDate: {
    question: t(
      'page.household.questionnaire.startdate.question',
      'When would you like your coverage to start?'
    ),
  },

  review: { question: '' },

  quote: { question: '' },

  checkoutInfo: { question: '' },

  reviewCheckout: { question: '' },
});

export const answersToReviewValues = (
  t: TFunction,
  answers: Partial<Household>,
  routes: HouseholdQuestionnaireRoutes
): QuestionReviewValue[] => [
  {
    title: t('page.household.questionnaire.review.postcode', 'Postcode'),
    value: String(answers.address?.postcode),
    path: routes.address?.path || '',
  },
  {
    title: t('page.household.questionnaire.review.livingspace', 'Living space'),
    value:
      answers.livingSpace?.selectedUnitSize === 'SQUARE_FEET'
        ? `${String(answers.livingSpace?.propertyInSqFeet)} sq. ft`
        : `${String(answers.livingSpace?.propertyInSqMeters)} m²`,
    path: routes.livingSpace?.path || '',
  },
  {
    title: t(
      'page.household.questionnaire.review.securitydevices',
      'Security devices'
    ),
    value:
      answers.securityDevices?.ownedSecurityDevices
        .map((sd) => questionPropLookup(t).securityDevices?.mapValue?.[sd])
        .join(', ') || '',
    path: routes.securityDevices?.path || '',
  },
  {
    title: t('page.household.questionnaire.review.insuredsum', 'Insured sum'),
    value: englishFormattedEuroCurrency(answers.insuredSum || 0).replace(
      /\.00$/,
      ''
    ),
    path: routes.insuredSum?.path || '',
  },
  {
    title: t('page.household.questionnaire.review.addons', 'Add-ons'),
    value:
      answers.addonOptions?.selectedAddons
        .map((sd) => {
          if (
            sd === 'ADDITIONAL_BIKE_COVERAGE' &&
            !answers.addonOptions?.selectedAddons.includes('EXTENDED_COVERAGE')
          ) {
            return null;
          }
          return addonTypeLookup(t)[sd];
        })
        .filter(Boolean)
        .join(', ') ||
      t('page.household.questionnaire.review.addons.none', 'None'),
    path: routes.addonOptions?.path || '',
  },
  ...(answers.addonOptions?.selectedAddons.includes('EXTENDED_COVERAGE') &&
  answers.addonOptions?.selectedAddons.includes('ADDITIONAL_BIKE_COVERAGE')
    ? [
        {
          title: t(
            'page.household.questionnaire.review.bikeinsuredsum',
            'Bike insured sum'
          ),
          value: englishFormattedEuroCurrency(
            answers.addonOptions.bikeInsuredSum || 0
          ).replace(/\.00$/, ''),
          path: routes.addonOptions?.path || '',
        },
      ]
    : []),
  {
    title: t(
      'page.household.questionnaire.review.propertyownership',
      'Renting or owning?'
    ),
    value:
      answerValueToStringLookup(t).propertyOwnership[
        String(answers.propertyOwnership)
      ],
    path: routes.propertyOwnership?.path || '',
  },
  {
    title: t('page.household.questionnaire.review.hometype', 'Building type'),
    value: answerValueToStringLookup(t).homeType[String(answers.homeType)],
    path: routes.homeType?.path || '',
  },
  {
    title: t(
      'page.household.questionnaire.review.primaryresidence',
      'Residence'
    ),
    value:
      answerValueToStringLookup(t).isPrimaryResidence[
        String(answers.isPrimaryResidence)
      ],
    path: routes.isPrimaryResidence?.path || '',
  },
  {
    title: t(
      'page.household.questionnaire.review.numberofpreviousclaims',
      'Claims during the past 3 years'
    ),
    value:
      answerValueToStringLookup(t).numberOfPreviousClaims[
        String(answers.numberOfPreviousClaims)
      ],
    path: routes.numberOfPreviousClaims?.path || '',
  },
  ...(answers.numberOfPreviousClaims !== 'NONE'
    ? [
        {
          title: t(
            'page.household.questionnaire.review.isclaimsamountmorethanlimit',
            'Total amount claimed in the past 3 years'
          ),
          value:
            answerValueToStringLookup(t).isClaimsAmountMoreThan1500[
              String(answers.isClaimsAmountMoreThan1500)
            ],
          path: routes.isClaimsAmountMoreThan1500?.path || '',
        },
      ]
    : []),
  {
    title: t(
      'page.household.questionnaire.review.coveringeveryhouseholdmember',
      'Every household member covered under this plan?'
    ),
    value:
      answerValueToStringLookup(t).coveringEveryHouseholdMember[
        String(answers.coveringEveryHouseholdMember)
      ],
    path: routes.coveringEveryHouseholdMember?.path || '',
  },
  answers.account
    ? {
        title: t(
          'page.household.questionnaire.review.useraccount',
          'User account'
        ),
        value: `${answers.name?.firstName} ${answers.name?.lastName}`,
        path: routes.account?.path || '',
      }
    : {
        title: t(
          'page.household.questionnaire.review.useraccount',
          'User account'
        ),
        value: t('page.household.questionnaire.review.useraccount.new', 'New'),
        path: routes.account?.path || '',
      },
  {
    title: t('page.household.questionnaire.review.name', 'Policy holder'),
    value: `${answers.name?.firstName} ${answers.name?.lastName}`,
    path: routes.name?.path || '',
  },
  {
    title: t('page.household.questionnaire.review.email', 'Email address'),
    value: answers.email || '',
    path: routes.email?.path || '',
  },
  {
    title: t(
      'page.household.questionnaire.review.dateofbirth',
      'Date of birth'
    ),
    value: dayjs(answers.dateOfBirth || '').format('DD MMM YYYY'),
    path: routes.dateOfBirth?.path || '',
  },
  {
    title: t('page.household.questionnaire.review.gender', 'Gender'),
    value: answerValueToStringLookup(t).gender[String(answers.gender)],
    path: routes.gender?.path || '',
  },
  {
    title: t(
      'page.household.questionnaire.review.startdate',
      'Coverage start date'
    ),
    value: dayjs(answers.startDate || '').format('DD MMM YYYY'),
    path: routes.startDate?.path || '',
  },
];
