import * as Sentry from '@sentry/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import endpoint from 'shared/api';
import { z } from 'zod';

export const PayoutDetailsSchema = z.object({
  claimId: z.string(),
  payload: z.object({
    amount: z
      .string()
      .transform((value) => parseInt(value.replaceAll(' ', ''), 10))
      .pipe(z.number())
      .optional(),
    iban: z.string().optional(),
  }),
});

export type PayoutDetailsType = z.infer<typeof PayoutDetailsSchema>;

export const useUpdateClaimPayout = () => {
  const queryClient = useQueryClient();

  const onUpdateClaimPayout = (data: PayoutDetailsType) => {
    const { claimId, payload } = data;
    return endpoint.updateClaimPayout(claimId, payload);
  };

  const mutation = useMutation({
    mutationFn: onUpdateClaimPayout,
    onSuccess: (variables) => {
      queryClient.invalidateQueries({ queryKey: ['claim', variables.data.id] });
    },
    onError: (error, variables) => {
      const { claimId } = variables;
      Sentry.captureException(error, {
        tags: {
          feature: 'CLAIMS',
        },
        extra: {
          claimId,
          description: 'Error happened while updating claim payout details',
        },
      });
    },
  });

  return mutation;
};
