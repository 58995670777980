import { TFunction } from '@getpopsure/i18n-react';
import { email } from '@getpopsure/private-constants';
import * as Sentry from '@sentry/react';
import { useMutation } from '@tanstack/react-query';
import endpoint from 'shared/api';

export const useMarkPolicyDroppedOut = (t: TFunction) => {
  const {
    mutate: markPolicyDroppedOut,
    isLoading,
    isError,
  } = useMutation({
    mutationFn: ({ policyId }: { policyId: string }) =>
      endpoint.markPolicyDroppedOut(policyId),
    onError: (error, variables) => {
      Sentry.captureException(error, {
        tags: {
          feature: 'POLICY_SINGLE',
        },
        extra: {
          policyId: variables.policyId,
          description: 'Error happened while marking policy as dropped out',
        },
      });
    },
  });

  const errorMessage = isError
    ? t(
        'myPolicies.markAsDroppedOut.errorMessage',
        'An error happening while canceling your application. Please try again or contact us at {{supportEmail}}',
        { supportEmail: email.help }
      )
    : undefined;

  return {
    markPolicyDroppedOut,
    isLoading,
    errorMessage,
  };
};
