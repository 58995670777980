import { Region } from '@getpopsure/public-models';
import PageNotFound from 'components/pageNotFound';
import { RegionSelector } from 'components/RegionSelector';
import { ReactNode, useState } from 'react';
import {
  generatePath,
  Redirect,
  useHistory,
  useParams,
} from 'react-router-dom';
import { useSafeTranslation } from 'shared/i18n';

import { QuestionnaireMeta } from '../../../model';

const regionToNameMapping: Record<Region, string> = {
  nl: 'Netherlands',
  se: 'Sweden',
  be: 'Belgium',
  it: 'Italy',
  at: 'Austria',
  mt: 'Malta',
  fi: 'Finland',
  cy: 'Cyprus',
  gr: 'Greece',
  ee: 'Estonia',
  pt: 'Portugal',
  lt: 'Lithuania',
  hr: 'Croatia',
  lv: 'Latvia',
  mc: 'Monaco',
  de: 'Germany',
  es: 'Spain',
  fr: 'France',
  eu: 'Europe',
};

export const formatRegions = (
  regions: Region[]
): Partial<Record<Region, string>> => {
  if (!regions) {
    return {};
  }

  return regions.reduce((acc, region) => {
    acc[region] = regionToNameMapping[region];
    return acc;
  }, {} as Record<Region, string>);
};

interface RegionManagerProps {
  children: ReactNode;
  noRegionPath: string;
  questionnairePath: string;
  questionnaireMeta: QuestionnaireMeta;
}

export const RegionManager = ({
  children,
  noRegionPath,
  questionnairePath,
  questionnaireMeta,
}: RegionManagerProps) => {
  const { regionId, verticalId } = useParams<{
    regionId: Region;
    verticalId: string;
  }>();

  const { regions = [], type } = questionnaireMeta;
  const { t } = useSafeTranslation();
  const [regionError, setRegionError] = useState('');
  const history = useHistory();
  const isMultiRegion = type === 'multiRegion';

  const onRegionSubmit = (selectedRegion: string) => {
    setRegionError('');

    const region = Object.entries(regionToNameMapping).find(([_, name]) => {
      return name === selectedRegion;
    });

    if (!region) {
      setRegionError(
        t(
          'generalPolicy.region.errorMessage',
          'This country is not available, please try again.'
        )
      );

      return;
    }

    history.push(
      generatePath(questionnairePath, {
        regionId: region[0],
        verticalId,
      })
    );
  };

  if (!regions.length) {
    return <PageNotFound />;
  }

  if (!isMultiRegion) {
    if (!regionId || regionId !== regions[0]) {
      return (
        <Redirect
          to={generatePath(questionnairePath, {
            regionId: regions[0],
            verticalId,
          })}
        />
      );
    }

    return <>{children}</>;
  }

  if (!regionId) {
    return (
      <RegionSelector
        regions={formatRegions(regions)}
        errorMessage={regionError}
        onSubmit={onRegionSubmit}
      />
    );
  }

  if (!regions.includes(regionId as Region)) {
    return (
      <Redirect
        to={generatePath(noRegionPath, {
          verticalId,
        })}
      />
    );
  }

  return <>{children}</>;
};
