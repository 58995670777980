import { isMobileApp } from 'shared/util/isMobileApp';

export const mobileAppLogEvent = (
  eventName: string,
  eventParams: Record<string, unknown>
) => {
  if (!isMobileApp) {
    return;
  }
  window?.ReactNativeWebView?.postMessage(
    JSON.stringify({
      logAnalyticsEvent: {
        eventName,
        eventParams,
      },
    })
  );
};
