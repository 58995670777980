import { ClaimsAction } from 'constants/actions';

import { PrivateHealthClaims } from '../models/PrivateHealthClaims.model';

export const storePrivateHealthClaimsAnswers = (
  answer: Partial<PrivateHealthClaims>
): ClaimsAction => ({
  type: 'STORE_PRIVATE_HEALTH_CLAIM',
  privateHealth: answer,
});

export const flushPrivateHealthClaimsAnswers = (): ClaimsAction => ({
  type: 'FLUSH_PRIVATE_HEALTH_CLAIM',
});
