import { TFunction } from '@getpopsure/i18n-react';
import { calendly, insurance } from '@getpopsure/private-constants';
import { imageTypeMapping } from 'models/insurances/types/mapping';

import { DisabilityV2Questionnaire } from '.';
import { getExtremeSportsQuestionnaire } from './extremeSports';
import { getInsuranceHistoryQuestionnaire } from './insuranceHistory';
import { getMedicalHistoryQuestionnaire } from './medicalHistory';
import { getPersonalInfoQuestionnaire } from './personalInfo';
import { getPreQuoteQuestionnaire } from './preQuote';

export const getTranslatedQuestionnaire = (
  t: TFunction,
  useNewCheckout?: boolean
): DisabilityV2Questionnaire => [
  ...getPreQuoteQuestionnaire(t),
  {
    id: 'quote',
    required: true,
    type: 'QUOTE',
    groupId: 'signup',
    props: {},
    screen: {
      layout: 'Standalone',
    },
  },
  {
    id: 'tallyForm',
    type: 'TALLY_FORM',
    groupId: 'signup',
    props: {},
    screen: {
      layout: 'Standalone',
    },
  },
  ...getPersonalInfoQuestionnaire(t),
  ...getInsuranceHistoryQuestionnaire(t),
  ...getMedicalHistoryQuestionnaire(t),
  ...getExtremeSportsQuestionnaire(t),
  {
    id: 'hasEligibilityCheck',
    type: 'ELIGIBILITY_CHECK',
    groupId: 'signup',
    props: {},
    screen: {
      layout: 'Standalone',
    },
  },
  {
    id: 'postSignupBlocker',
    type: 'BLOCKER',
    groupId: 'signup',
    props: {
      title: t('disabilityV2.qnr.postQuoteBlocker.title', 'Unfortunately...'),
      description: t(
        'disabilityV2.qnr.postQuoteBlocker.description',
        "You aren't eligible for occupational disability insurance. We are working hard to make sure everyone can be covered."
      ),
      buttonProps: [
        {
          type: 'href',
          href: insurance.pension.landingPage,
          caption: t(
            'disabilityV2.qnr.postQuoteBlocker.moreInformation.caption',
            'Get more information'
          ),
        },
        {
          type: 'calendly',
          trackingCategory: 'DISABILITY_INSURANCE',
          caption: t(
            'disabilityV2.qnr.postQuoteBlocker.bookACallButton.caption',
            'Book a call with our experts'
          ),

          calendlyLink: `${calendly.base}/pension-insurance-consultation`,
        },
      ],
      iconType: 'SHIELD',
    },
    screen: {
      layout: 'Standalone',
    },
  },
  {
    id: 'review',
    required: true,
    type: 'PROCESSING_SCREEN',
    groupId: 'signup',
    props: {},
    screen: {
      layout: 'Standalone',
    },
  },
  ...(!useNewCheckout
    ? [
        {
          id: 'reviewCheckout' as const,
          required: true,
          type: 'GENERIC_CHECKOUT' as const,
          props: {
            type: 'DISABILITY_V2' as const,
            icon: imageTypeMapping.DISABILITY_V2,
            policyInfoKind: 'DisabilityPolicy',
            region: 'de' as const,
            hasCheckoutDocuments: true,
            policyDetailsConfiguration: {
              policyDetails: [
                {
                  title: t(
                    'disabilityv1.qnr.checkout.policyDetails.startDate',
                    'Start date'
                  ),
                  questionId: 'startDate',
                },
              ],
            },
          },
          screen: {
            layout: 'Standalone' as const,
          },
          groupId: 'signup' as const,
        },
      ]
    : [
        {
          id: 'reviewCheckout' as const,
          required: true,
          type: 'GENERIC_CHECKOUT_V2' as const,
          props: {
            type: 'DISABILITY_V2' as const,
            policyInfoKind: 'DisabilityPolicy',
            region: 'de' as const,
            hasCheckoutDocuments: true,
            productName: t(
              'disability.qnr.checkout.productName',
              'Disability insurance'
            ),
            policyDetailsConfiguration: {
              productCardDetails: [
                {
                  id: 'startDate',
                  label: t(
                    'disabilityv1.qnr.checkout.policyDetails.startDate',
                    'Start date'
                  ),
                  questionId: 'startDate',
                },
                {
                  id: 'monthlyPayout',
                  label: t(
                    'disabilityv1.qnr.checkout.policyDetails.monthlyPayout',
                    'Monthly payout'
                  ),
                  questionId: 'monthlyPayout',
                  dataType: 'CURRENCY' as const,
                },
              ],
              policyDetails: [
                {
                  id: 'name',
                  label: t(
                    'disabilityv1.qnr.checkout.policyDetails.name',
                    'Name'
                  ),
                  questionId: 'name',
                },
                {
                  id: 'dateOfBirth',
                  label: t(
                    'disabilityv1.qnr.checkout.policyDetails.dateOfBirth',
                    'Date of birth'
                  ),
                  questionId: 'dateOfBirth',
                },
                {
                  id: 'email',
                  label: t(
                    'disabilityv1.qnr.checkout.policyDetails.email',
                    'Account email'
                  ),
                  questionId: 'email',
                },
              ],
            },
          },
          screen: {
            layout: 'Standalone' as const,
          },
          groupId: 'signup' as const,
        },
      ]),
];
