import { Region } from '@getpopsure/public-models';
import { ValidVerticalUrlParams } from 'features/paymentScreen/utils/verticalId.utils';
import { useSelector } from 'react-redux';
import { getAccountInfo } from 'selectors/user';
import { useSafeTranslation } from 'shared/i18n';
import { isMobileApp } from 'shared/util/isMobileApp';

type ImprovementBoxProps = {
  vertical: ValidVerticalUrlParams | 'private';
  region?: Region;
};

export const ImprovementBox = ({ vertical, region }: ImprovementBoxProps) => {
  const { t } = useSafeTranslation();
  const user = useSelector(getAccountInfo);

  if (!user) {
    return null;
  }

  return (
    <a
      className="mt24 mb24 p-a c-pointer fw-bold tc-primary-500"
      href={
        isMobileApp
          ? `https://tally.so/r/wbxYE1?userId=${user.id}&vertical=${vertical}&country=${region}`
          : `https://tally.so/r/mO5bXY?userId=${user.id}&vertical=${vertical}&country=${region}`
      }
    >
      {t('components.improvementBox.title', 'Anything we can improve?')}
    </a>
  );
};
