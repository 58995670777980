import { flushRequestError } from 'actions/request';
import View from 'components/signupAuthentication/email/components/loginModal/view';
import { useRequestLoginCode } from 'features/accountV2/hooks/useRequestLoginCode';
import { useRequestStatus } from 'hooks/useRequestStatus';
import { useDispatch } from 'react-redux';
import { GenericQuestionnaireState } from 'reducers/genericQuestionnaire';

import { signinAccount } from '../../actions/account';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onLogin: () => void;
  email: string;
  verticalId: keyof GenericQuestionnaireState;
};

const LoginModal = ({ email, isOpen, onClose, verticalId, onLogin }: Props) => {
  const dispatch = useDispatch();
  const { loading: signInLoading, error: signinError } = useRequestStatus(
    'SIGN_IN_WITH_LOGIN_CODE'
  );
  const {
    mutate: requestLoginCode,
    isLoading: codeRequestLoading,
    error: codeRequestError,
    reset: resetCodeRequestMutation,
  } = useRequestLoginCode();

  const resetErrors = () => {
    dispatch(flushRequestError('SIGN_IN_WITH_LOGIN_CODE'));
    resetCodeRequestMutation();
  };

  const handleClose = () => {
    resetErrors();
    onClose();
  };

  const handleSendNewCode = () => {
    resetErrors();
    requestLoginCode({ email });
  };

  const handleSignin = (loginCode: string) => {
    resetErrors();
    dispatch(signinAccount(loginCode, email, verticalId, onLogin));
  };

  return (
    <View
      email={email}
      isOpen={isOpen}
      handleClose={handleClose}
      loading={signInLoading || codeRequestLoading}
      inputError={signinError}
      codeRequestError={codeRequestError}
      handleSignin={handleSignin}
      handleSendNewCode={handleSendNewCode}
    />
  );
};

export default LoginModal;
