import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import { fetchPolicyDetail } from '../../actions/policies';
import { useRequestStatus } from '../../hooks/useRequestStatus';
import { AppState } from '../../reducers';
import { getPolicyDetail } from '../../selectors/policies';
import type { ModalType } from './components/modals';
import { PolicySingleView } from './PolicySingle.view';

export const PolicySingle = () => {
  const { policyId } = useParams<{
    policyId: string;
  }>();
  const dispatch = useDispatch();
  const { loading, error } = useRequestStatus('LOAD_MY_POLICY_DETAIL');
  const policy = useSelector((state: AppState) =>
    getPolicyDetail(state, policyId)
  );
  const [showModal, setShowModal] = useState<ModalType | null>(null);
  const { hash } = useLocation();

  useEffect(() => {
    dispatch(fetchPolicyDetail(policyId));
  }, [dispatch, policyId]);

  useEffect(() => {
    return () => {
      setShowModal(null);
    };
  }, []);

  // Allow scrolling to specific sections when coming from other routes
  useEffect(() => {
    if (hash) {
      const hashWithoutHashSymbol = hash.replace('#', '');
      const element = document.getElementById(hashWithoutHashSymbol);
      if (element) {
        element.scrollIntoView({ behavior: 'auto' });
      }
    }
  }, [hash]);

  const handleOpenModal = (modalType: ModalType) => setShowModal(modalType);
  const handleCloseModal = () => setShowModal(null);

  return (
    <PolicySingleView
      policy={policy}
      showModal={showModal}
      handleOpenModal={handleOpenModal}
      handleCloseModal={handleCloseModal}
      loading={loading}
      error={error}
    />
  );
};
