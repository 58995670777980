import { TFunction } from '@getpopsure/i18n-react';
import { email } from '@getpopsure/private-constants';
import * as Sentry from '@sentry/react';
import { useMutation } from '@tanstack/react-query';
import { PolicyCancellationReason } from 'models/policies';
import endpoint from 'shared/api';

export const useCancelPolicy = (t: TFunction) => {
  const cancelPolicyFn = ({
    policyId,
    reason,
    additionalInfo,
  }: {
    policyId: string;
    reason?: PolicyCancellationReason;
    additionalInfo?: string;
  }) => endpoint.cancelPolicy(policyId, reason, additionalInfo);

  const {
    mutate: cancelPolicy,
    isLoading: isCancelling,
    isError,
  } = useMutation({
    mutationFn: cancelPolicyFn,
    onError: (error, variables) => {
      Sentry.captureException(error, {
        tags: {
          feature: 'POLICY_SINGLE',
        },
        extra: {
          policyId: variables.policyId,
          description: 'Policy cancellation request failed',
        },
      });
    },
  });

  const cancellingError = isError
    ? t(
        'myPolicies.cancelModal.errorMessage',
        'An error happened while canceling your policy. Please try again or contact us at {{supportEmail}}',
        { supportEmail: email.help }
      )
    : undefined;

  return {
    cancelPolicy,
    isCancelling,
    cancellingError,
  };
};
