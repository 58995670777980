import {
  GettingStartedCard,
  Props as GettingStartedCardProps,
} from 'components/GettingStartedCard/GettingStartedCard';
import TimedLoadSpinner from 'components/timedLoadSpinner';
import routes from 'constants/routes';
import { useGetPolicy } from 'features/policySingle/hooks/useGetPolicy';
import { useEffect } from 'react';
import { generatePath, useHistory, useParams } from 'react-router';
import endpoint from 'shared/api';
import { TFunction, Trans, useSafeTranslation } from 'shared/i18n';

import fourIcon from './icons/four.svg';
import oneIcon from './icons/one.svg';
import phoneIcon from './icons/phone.svg';
import threeIcon from './icons/three.svg';
import twoIcon from './icons/two.svg';
import styles from './styles.module.scss';

const cardData = (
  t: TFunction,
  policyNumber?: string
): (GettingStartedCardProps & { id: string })[] => [
  {
    id: 'cardOne',
    title: (
      <Trans i18nKey="legal.qnr.claims.phoneConsultation.cardinfo.1.title">
        {/* TODO: [KONG] Should be a link on mobile */}
        <p>
          Call <span className="tc-purple-500">+49 221 8277 4411</span> to book
          a consultation.
        </p>
      </Trans>
    ),
    description: t(
      'legal.qnr.claims.phoneConsultation.cardinfo.1.description',
      'Available Monday to Friday from 8:00 AM to 6:00 PM.'
    ),
    icon: {
      src: oneIcon,
      alt: t(
        'legal.qnr.claims.phoneConsultation.cardinfo.1.iconalt',
        'number one'
      ),
    },
  },
  {
    id: 'cardTwo',
    title: (
      <>
        {t(
          'legal.qnr.claims.phoneConsultation.cardinfo.2.title',
          'Provide your policy number:'
        )}{' '}
        <span className="tc-purple-500">
          {policyNumber ??
            t(
              'legal.qnr.claims.phoneConsultation.cardinfo.2.title.noPolicyNumber',
              'Policy number not found'
            )}
        </span>
      </>
    ),
    icon: {
      src: twoIcon,
      alt: t(
        'legal.qnr.claims.phoneConsultation.cardinfo.2.iconalt',
        'number two'
      ),
    },
  },
  {
    id: 'cardThree',
    title: t(
      'legal.qnr.claims.phoneConsultation.cardinfo.3.title',
      'Discuss the legal matter'
    ),
    description: t(
      'legal.qnr.claims.phoneConsultation.cardinfo.3.description',
      "During the call, the lawyer can't confirm if your case will be covered or review documents, but they can help you understand if you might have a valid case and recommend what to do next."
    ),
    icon: {
      src: threeIcon,
      alt: t(
        'legal.qnr.claims.phoneConsultation.cardinfo.3.iconalt',
        'number three'
      ),
    },
  },
  {
    id: 'cardFour',
    title: t(
      'legal.qnr.claims.phoneConsultation.cardinfo.4.title',
      'Submit a claim to confirm the coverage'
    ),
    description: t(
      'legal.qnr.claims.phoneConsultation.cardinfo.4.description',
      'If the lawyer advises you to seek further legal support, you can submit a claim in your account to confirm that your legal case will be covered.'
    ),
    icon: {
      src: fourIcon,
      alt: t(
        'legal.qnr.claims.phoneConsultation.cardinfo.4.iconalt',
        'number four'
      ),
    },
  },
];

export const PhoneConsultation = () => {
  const { t } = useSafeTranslation();
  const history = useHistory();
  const { policyId }: { policyId: string } = useParams();

  /**
   * TODO: [KONG] Should be moved to policy details page
   * Policy number should be retrieved from cache
   */
  const { policy, isLoading } = useGetPolicy(policyId);

  useEffect(() => {
    if (!policyId) return;

    endpoint.triggerLegalPhoneConsultationRequestEvent(policyId);
  }, [policyId]);

  const redirectToPolicy = () => {
    history.push(
      generatePath(routes.me.policies.detail.path, {
        policyId,
      })
    );
  };

  if (isLoading) {
    return <TimedLoadSpinner />;
  }

  return (
    <div className={`my80 ${styles.container}`}>
      <div className="p-body">
        <img src={phoneIcon} alt="purple phone" />
        <h1 className="p-h1 mt16">
          {t('legal.qnr.claims.phoneConsultation.title', 'Phone consultation')}
        </h1>
        {cardData(t, policy?.attributes.policyNumber).map(
          ({ id, title, description, icon }) => (
            <GettingStartedCard
              className="mt16"
              title={title}
              icon={icon}
              description={description}
              key={id}
            />
          )
        )}
        <button
          type="button"
          onClick={redirectToPolicy}
          className={`p-btn--primary mt32 wmn3 ${styles.button}`}
        >
          {t('legal.qnr.claims.phoneConsultation.cta', 'Back to policy')}
        </button>
      </div>
    </div>
  );
};
