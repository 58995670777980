import { useFlag, useFlagsStatus } from '@unleash/proxy-client-react';

import PageNotFound from '../../components/pageNotFound';
import { APIResponseError } from '../../models/error';
import { Policy } from '../../models/policies';
import { getCurrentLocale, useSafeTranslation } from '../../shared/i18n';
import { Header } from './components/Header';
import { LoadingSkeleton } from './components/LoadingSkeleton/LoadingSkeleton';
import { ModalType } from './components/modals';
import { getPolicyConfig } from './getPolicyConfig';
import { PolicyAPVs } from './sections/PolicyAPVs/PolicyAPVs';
import { PolicyChecklists } from './sections/PolicyChecklists/PolicyChecklists';
import { PolicyClaims } from './sections/PolicyClaims/PolicyClaims';
import { PolicyCoverage } from './sections/PolicyCoverage/PolicyCoverage';
import { PolicyDetails } from './sections/PolicyDetails/PolicyDetails';
import { PolicyDocuments } from './sections/PolicyDocuments/PolicyDocuments';
import { PolicyTaskEngine } from './sections/PolicyTaskEngine/PolicyTaskEngine';

interface PolicySingleViewProps {
  loading?: boolean;
  error?: APIResponseError;
  policy?: Policy;
  showModal: ModalType | null;

  handleOpenModal(modalType: ModalType): void;

  handleCloseModal(): void;
}

export const PolicySingleView = (props: PolicySingleViewProps) => {
  const {
    policy,
    showModal,
    handleOpenModal,
    handleCloseModal,
    loading,
    error,
  } = props;
  const locale = getCurrentLocale();
  const { t } = useSafeTranslation();
  const { flagsReady } = useFlagsStatus();
  const isCommonClaims = useFlag('app_common_claims');

  if (loading && !policy) return <LoadingSkeleton />;

  if (error || !policy) return <PageNotFound />;

  const { header, sections, modals } = getPolicyConfig(policy, t, {
    isCommonClaims,
    flagsReady,
  });

  const Modal = modals?.find((modal) => modal.id === showModal)?.component;

  return (
    <div lang={locale}>
      <Header {...header} />

      <PolicyTaskEngine policyId={policy.id} />

      {sections.map((section) => {
        switch (section.id) {
          case 'POLICY_CHECKLISTS':
            return <PolicyChecklists key={section.id} />;
          case 'POLICY_DETAILS':
            return (
              <PolicyDetails
                {...section.props}
                key={section.id}
                policyData={policy}
                onOpenModal={handleOpenModal}
              />
            );
          case 'POLICY_COVERAGE':
            return <PolicyCoverage {...section.props} key={section.id} />;
          case 'POLICY_CLAIMS':
            return <PolicyClaims {...section.props} key={section.id} />;
          case 'POLICY_APVS':
            return <PolicyAPVs {...section.props} key={section.id} />;
          case 'POLICY_DOCUMENTS':
            return <PolicyDocuments {...section.props} key={section.id} />;
          case 'POLICY_GENERIC_SECTION':
            return (
              <section.component
                key={section.id}
                policyData={policy}
                onOpenModal={handleOpenModal}
              />
            );
          default:
            return null;
        }
      })}

      {showModal && Modal && (
        <Modal policyData={policy} onClose={handleCloseModal} />
      )}
    </div>
  );
};
