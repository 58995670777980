import * as Sentry from '@sentry/react';
import { useMutation } from '@tanstack/react-query';
import { ClaimSubmitInfo } from 'models/claims';
import endpoint from 'shared/api';

import { triggerPushNotifications } from '../../../shared/pushNotifications';
import { LegalSubmitClaims, ZSubmitClaimInfo } from '../models';

export const useSubmitLegalClaim = () => {
  const onSubmitClaim = ({
    questionnaire,
    policyId,
  }: {
    questionnaire: Partial<LegalSubmitClaims> | undefined;
    policyId: string;
  }) => {
    if (!policyId) {
      throw new Error(
        '[Legal Submit Claim] Policy ID is missing for submitting claim'
      );
    }

    const validationResult = ZSubmitClaimInfo.parse(questionnaire);

    if (!validationResult) {
      throw new Error(
        '[LEGAL - Submit Claim] Questionnaire answer validation errors'
      );
    }

    const validatedData = validationResult;

    const claimInfo: ClaimSubmitInfo = {
      claimTypes: [{ claimType: 'LEGAL_CLAIM' }],
      userPolicyId: policyId,
      description: validatedData.description,
      phoneConsultation: validatedData.phoneConsultation,
      phoneNumber: validatedData.phoneNumber,
    };

    return endpoint.submitClaim({ type: 'LEGAL', ...claimInfo });
  };

  const mutation = useMutation({
    mutationFn: onSubmitClaim,
    onError: (error, variables) => {
      const { policyId, questionnaire } = variables;
      Sentry.captureException(error, {
        tags: {
          feature: 'CLAIMS',
          vertical: 'LEGAL_V2',
        },
        extra: {
          policyId,
          questionnaire,
          description: 'Error happened while submitting Legal claim',
        },
      });
    },
    onSuccess: () => {
      triggerPushNotifications('LEGAL_V2');
    },
  });

  return mutation;
};
