import { InitializeProps } from '@getpopsure/analytics';
import { ConsentObject, cookieType } from '@getpopsure/cookie-consent';

const nonce = (document.querySelector('[data-id="csp-nonce"]') as HTMLElement)
  ?.nonce;

/**
 * GTM environments are meant to be used only in development/staging environments.
 * Therefore, check the current env and don't add the params in production
 */
const isProduction = process.env.REACT_APP_ENVIRONMENT === 'production';
const googleTagManagerOptions: InitializeProps['googleTagManager'] = {
  containerId: process.env.REACT_APP_GTM_ID ?? '',
  nonce,
  ...(isProduction
    ? {}
    : {
        auth: process.env.REACT_APP_GTM_AUTH,
        preview: process.env.REACT_APP_GTM_PREVIEW,
      }),
};

export const initializeTrackers = async (consent: ConsentObject) => {
  let trackerOptions: InitializeProps = {
    snowplow: {
      collector: process.env.REACT_APP_COLLECTOR_URL ?? '',
      appId: process.env.REACT_APP_COLLECTOR_APP_ID ?? '',
    },
  };

  if (consent[cookieType.analytics_storage] === 'granted') {
    trackerOptions = {
      ...trackerOptions,
      googleAnalytics: process.env.REACT_APP_GA_TRACKING
        ? {
            trackingCode: process.env.REACT_APP_GA_TRACKING,
          }
        : undefined,
    };
  }

  if (
    consent[cookieType.ad_storage] === 'granted' ||
    consent[cookieType.analytics_storage] === 'granted' ||
    consent[cookieType.ad_personalization] === 'granted' ||
    consent[cookieType.ad_user_data] === 'granted'
  ) {
    trackerOptions = {
      ...trackerOptions,
      googleTagManager: googleTagManagerOptions,
    };
  }

  const { initialize } = await import('@getpopsure/analytics');
  initialize(trackerOptions);
};
