import {
  BottomOrRegularModal,
  Button,
  CurrencyInput,
} from '@popsure/dirty-swan';
import { type FormEvent } from 'react';
import { useSafeTranslation } from 'shared/i18n';

interface Props {
  payoutAmount?: number;
  isOpen: boolean;
  isLoading?: boolean;
  onClose: () => void;
  onSubmit?(value: FormEvent<HTMLFormElement>): void;
}

export const ClaimAmountUpdateModal = ({
  isOpen,
  onClose,
  payoutAmount,
  isLoading,
  onSubmit,
}: Props) => {
  const { t } = useSafeTranslation();
  return (
    <BottomOrRegularModal
      isOpen={isOpen}
      onClose={onClose}
      title={t(
        'claims.claimDetail.claimAmountModal.title',
        'Edit claim amount'
      )}
    >
      <form className="px24 pb24 p-p wmx6" onSubmit={onSubmit}>
        <p className="mb16">
          {t(
            'claims.claimDetail.claimAmountModal.description',
            'Please enter the new claim amount.'
          )}
        </p>
        <CurrencyInput
          name="amount"
          placeholder={t(
            'claims.claimDetail.claimAmountModal.input.placeholder',
            'Claim amount'
          )}
          prefix="€"
          value={payoutAmount}
        />

        <Button type="submit" className="mt16" loading={isLoading}>
          {t('claims.claimDetail.claimAmountModal.button', 'Submit')}
        </Button>
      </form>
    </BottomOrRegularModal>
  );
};
