import { Trans } from '@getpopsure/i18n-react';
import { Button, Checkbox, InformationBox } from '@popsure/dirty-swan';
import { CheckoutContext } from 'features/paymentScreenV2/checkout.context';
import { useContext } from 'react';
import AnimateHeight from 'react-animate-height';
import { useSafeTranslation } from 'shared/i18n';

import { AddNewPaymentOptionModal } from '../AddNewPaymentOptionModal/AddNewPaymentOptionModal';
import { PaymentMethodSelector } from '../PaymentMethodSelector/PaymentMethodSelector';

export const PayComponent = ({
  checkboxValues,
  setCheckboxValues,
  isSubmitButtonEnabled,
  startDateError,
  purchasePolicy,
  updatePaymentMethods,
  changeStartDatePath,
  hasCheckoutDocuments,
}: {
  checkboxValues: string[];
  setCheckboxValues: (values: string[]) => void;
  isSubmitButtonEnabled: boolean;
  startDateError: boolean;
  purchasePolicy: () => void;
  updatePaymentMethods: () => void;
  changeStartDatePath?: string;
  hasCheckoutDocuments: boolean;
}) => {
  const { t } = useSafeTranslation();
  const {
    isProcessingPayment,
    currentUserPaymentMethods,
    setAddNewPaymentMethod,
  } = useContext(CheckoutContext);

  const hasNoPaymentMethods = currentUserPaymentMethods.length === 0;

  return (
    <div>
      <div className="mt16">
        {!hasNoPaymentMethods && (
          <>
            <h3 className="p-h3">
              {t('paymentScreenV2.paymentMethod.text', 'Payment method')}
            </h3>
            <PaymentMethodSelector
              updatePaymentMethods={updatePaymentMethods}
            />
          </>
        )}
      </div>

      <AnimateHeight duration={300} height={startDateError ? 'auto' : 0}>
        <InformationBox variant="error" className="mb16 mt16 wmx8">
          {changeStartDatePath ? (
            <Trans i18nKey="paymentScreenV2.startDateError.changeStartDate.text">
              <p>
                The start date you selected is in the past.{' '}
                <a href={changeStartDatePath}>Go back</a> and update it to
                continue.
              </p>
            </Trans>
          ) : (
            <p>
              {t(
                'paymentScreenV2.startDateError.text',
                'The start date you selected is in the past. Please navigate back to update it before continuing.'
              )}
            </p>
          )}
        </InformationBox>
      </AnimateHeight>

      {hasCheckoutDocuments && (
        <Checkbox
          bordered={false}
          classNames={{ container: 'mt24', label: 'wmx8' }}
          onChange={setCheckboxValues}
          wide
          options={{
            hasReviewedDocuments: t(
              'paymentScreenV2.hasReviewedDocuments.checkbox.text',
              'I reviewed the insurance conditions/IPID, advice record, and licensing information.'
            ),
          }}
          value={checkboxValues}
        />
      )}

      {!hasNoPaymentMethods ? (
        <Button
          className="w100 mt24"
          disabled={!isSubmitButtonEnabled || startDateError}
          onClick={purchasePolicy}
          data-cy="button-checkout"
          type="button"
          loading={isProcessingPayment}
        >
          {t('paymentScreenV2.button.caption', 'Buy policy')}
        </Button>
      ) : (
        <>
          <Button
            className="w100 mt24"
            onClick={() => setAddNewPaymentMethod(true)}
            data-cy="button-add-payment-method"
          >
            {t(
              'paymentScreen.newPaymentMethod.button.title',
              'Add payment method'
            )}
          </Button>
          <AddNewPaymentOptionModal
            updatePaymentMethods={updatePaymentMethods}
          />
        </>
      )}
      <p className="p-p--small ta-center wmx8 mt24 tc-grey-600">
        {t(
          'paymentScreenV2.disclaimer.text',
          'First payments are charged immediately, even if the policy start date is in the future. Coverage is valid only after a confirmation email is received.'
        )}
      </p>
    </div>
  );
};
