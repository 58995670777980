import { useMutation } from '@tanstack/react-query';
import endpoint from 'shared/api';

import { healthCardMutationErrorHandler } from '../utils/healthCardMutationErrorHandler';

export const useFetchPhotoUploadToken = () => {
  const fetchPhotoUploadToken = async (cardId: string) => {
    const response = await endpoint.fetchPhotoUploadToken(cardId);
    if ('data' in response) {
      const { token } = response.data;
      return token;
    }
  };

  return useMutation({
    mutationFn: fetchPhotoUploadToken,
    onError: (error, cardId) =>
      healthCardMutationErrorHandler(error, { cardId }),
  });
};
