import classNames from 'classnames';
import { useSafeTranslation } from 'shared/i18n';

import { featherIcon as logo } from '../../../../icons/index';
import Buttons from '../Buttons';
import styles from './style.module.scss';

const DesktopView = () => {
  const { t } = useSafeTranslation();

  return (
    <div className="root-container">
      <div className={classNames('wmx12 w100 px16 py0', styles.innerContainer)}>
        <img src={logo} alt="logo" className={classNames('mx8', styles.logo)} />
        <h1 className="p-h1 mt16 p-h1--xl p--serif tc-grey-900">
          {t('main.welcomeScreen.title', 'Honest, simple insurance.')}
        </h1>
        <p className="p-p mt16 tc-grey-600">
          {t(
            'main.welcomeScreen.subtitle',
            'Welcome to your insurance companion'
          )}
        </p>
        <Buttons className={classNames('mt24 gap8', styles.btnContDesktop)} />
      </div>
      <Buttons
        className={classNames(
          'my0 mx-auto pt0 py0 px16 w100 gap8',
          styles.btnContMobile
        )}
      />
    </div>
  );
};

export default DesktopView;
