import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import dayjs from 'dayjs';
import { Policy } from 'models/policies';
import { TFunction } from 'shared/i18n';
import { capitalizeName } from 'shared/util/capitalizeName';

import { DATE_FORMAT } from '../../constants';
import { PolicyDetailsInfo } from '../../sections/PolicyDetails/PolicyDetails.models';

type IdTypeKeys = 'FOREIGN_IDENTITY_NUMBER' | 'NATIONAL_ID' | 'NO_SPANISH_ID';

export const idTypes = (t: TFunction) => ({
  FOREIGN_IDENTITY_NUMBER: t(
    'myPolicies.policyDetails.dentalSpain.idType.foreignIdentityNumber',
    'NIE (Foreigner identity number)'
  ),
  NATIONAL_ID: t(
    'myPolicies.policyDetails.dentalSpain.idType.nationalId',
    'DNI (Spanish nation ID)'
  ),
  NO_SPANISH_ID: t(
    'myPolicies.policyDetails.dentalSpain.idType.noSpanishId',
    "I don't have any"
  ),
});

export const getDentalSpainInfo = (policy: Policy, t: TFunction) => {
  const { insuredPerson, policyNumber, startDate, price, idType, idNumber } =
    policy.attributes;
  const infoArray: Array<PolicyDetailsInfo> = [];

  if (policyNumber) {
    infoArray.push({
      id: 'policyNumber',
      title: t('myPolicies.policyDetails.policyNumber', 'Policy number'),
      value: policyNumber,
    });
  }

  if (insuredPerson) {
    infoArray.push({
      title: t('myPolicies.policyDetails.insuredPerson', 'Insured person'),
      value: capitalizeName(insuredPerson.name),
    });
  }

  if (startDate) {
    infoArray.push({
      id: 'startDate',
      title: t('myPolicies.policyDetails.startDate', 'Start date'),
      value: dayjs(startDate).format(DATE_FORMAT),
    });
  }

  infoArray.push({
    id: 'purchaseDate',
    title: t('myPolicies.policyDetails.purchaseDate', 'Purchase date'),
    value: dayjs(policy.createdAt).format(DATE_FORMAT),
  });

  if (price) {
    infoArray.push({
      id: 'monthlyPremium',
      title: t('myPolicies.policyDetails.monthlyPremium', 'Monthly premium'),
      value: englishFormattedEuroCurrency(Number(price)),
    });
  }

  infoArray.push({
    title: t('myPolicies.policyDetails.dentalSpain.idType', 'ID document'),
    value: idType ? idTypes(t)?.[idType as IdTypeKeys] ?? '' : '',
  });

  infoArray.push({
    id: 'idNumber',
    title: t('myPolicies.policyDetails.dentalSpain.idNumber', 'ID number'),
    value: idNumber,
  });

  return infoArray;
};
