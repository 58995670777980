import { TFunction } from '@getpopsure/i18n-react';
import { InsuranceTypes } from 'models/insurances/types';

export type CheckoutDocumentStatus = 'FAILED' | 'READY' | 'SCHEDULED';

export type CheckoutDocumentCategory =
  | 'FEATHER_INTRODUCTORY_INFORMATION'
  | 'ADVICE_RECORD'
  | 'INSURANCE_CONDITIONS';

export interface CheckoutDocument {
  id: string;
  category: CheckoutDocumentCategory;
  status: CheckoutDocumentStatus;
  url: string;
  blobName: string;
}

export const checkoutDocumentCategoryDisplayName = (
  t: TFunction
): Partial<Record<CheckoutDocumentCategory, string>> => ({
  FEATHER_INTRODUCTORY_INFORMATION: t(
    'checkoutDocuments.licensingInformation.label',
    'Licensing information'
  ),
  ADVICE_RECORD: t('checkoutDocuments.adviceRecord.label', 'Advice record'),
  INSURANCE_CONDITIONS: t(
    'checkoutDocuments.insuranceConditions.label',
    'Insurance conditions/IPID'
  ),
});

export interface UseCheckoutDocumentsReturnValues {
  documentsLoading: boolean;
  documentsError: boolean;
  startPollingDocuments: () => void;
}

export type PollCheckoutDocumentsDetailsResponse =
  | { status: 'FETCHING'; payload: null }
  | { status: 'COMPLETED'; payload: CheckoutDocument[] }
  | { status: 'ERROR'; payload: null };

export const verticalWithCheckoutDocs: Partial<
  Record<InsuranceTypes, boolean>
> = {
  BIKE: false,
  LIABILITY: true,
  HOUSEHOLD: true,
  LEGAL_V2: true,
  EXPAT_V2: true,
  DOG_LIABILITY: true,
  DENTAL_V2: true,
  INCOMING_ES: true,
  LIFE_V2: true,
  INCOMING_EU: true,
  INCOMING_LT: true,
  BIKE_V2: true,
  DISABILITY_V2: true,
  TRAVEL: true,
};

export const isVerticalWithCheckoutDocuments = (
  insuranceType: InsuranceTypes
): boolean => {
  return verticalWithCheckoutDocs[insuranceType] ?? false;
};
