import { Policy } from 'models/policies';

import { ContactUsButton } from '../../components/buttons';
import { PolicyDetailsButton } from '../../sections/PolicyDetails/PolicyDetails.models';

export const getDentalSpainButtons = (
  _policy: Policy
): PolicyDetailsButton[] => {
  const buttonArray: PolicyDetailsButton[] = [
    { id: 'CONTACT_US_BUTTON', component: ContactUsButton },
  ];

  return buttonArray;
};
