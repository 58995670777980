import { TFunction } from '@getpopsure/i18n-react';

export const faqs = (
  t: TFunction
): {
  id: string;
  question: string;
  answer: string;
}[] => [
  {
    id: '1',
    question: t(
      'legal.qnr.quote.faqs.1.question',
      'What matters or disputes can legal insurance help resolve?'
    ),
    answer: t(
      'legal.qnr.quote.faqs.1.answer',
      'Legal insurance can help with a huge amount of different situations that may arise in your personal or professional life. Whether it’s a problem with your employer, issues with your housing situation, or a dispute over a contract with another party, having legal insurance can cover expensive legal costs.\n\n\n[Read more](https://faq.feather-insurance.com/en/articles/212982-what-are-common-situations-where-legal-insurance-can-help-me) about some of the most common uses for legal insurance in Germany.'
    ),
  },
  {
    id: '2',
    question: t(
      'legal.qnr.quote.faqs.2.question',
      'Who is eligible for legal insurance?'
    ),
    answer: t(
      'legal.qnr.quote.faqs.2.answer',
      'The main eligibility factors are that you live in Germany and are at least 18 years old. However, if you have had your legal insurance canceled by your previous insurance provider, or you have had more than 3 claims in the past 5 years, you are not eligible to sign up.'
    ),
  },
  {
    id: '3',
    question: t(
      'legal.qnr.quote.faqs.3.question',
      'Are my partner and family members also covered?'
    ),
    answer: t(
      'legal.qnr.quote.faqs.3.answer',
      'You can add your family during the sign-up process as long as you both are registered and live at the same address in Germany. Adding your partner will cost around €10 - much more cost effective than having separate policies.\n\nChildren under 18 and any unmarried adult children still in school are also included automatically under your policy.'
    ),
  },
  {
    id: '4',
    question: t(
      'legal.qnr.quote.faqs.4.question',
      'What are the waiting periods for legal insurance?'
    ),
    answer: t(
      'legal.qnr.quote.faqs.4.answer',
      'The waiting periods depend which insurance plan you have and what type of claim you need to make. [Learn more here](https://faq.feather-insurance.com/en/articles/77453-waiting-periods-in-legal-insurance).'
    ),
  },
  {
    id: '5',
    question: t(
      'legal.qnr.quote.faqs.5.question',
      'How does the legal phone consultation service work?'
    ),
    answer: t(
      'legal.qnr.quote.faqs.5.answer',
      'Included with the costs of your legal insurance policy is the ability to [request a phone call](https://faq.feather-insurance.com/en/articles/28679-request-a-phone-consultation-for-legal-insurance) with an English speaking lawyer. These phone consultations are intended to help you when you need quick legal advice about a matter or dispute in your life,. While these phone consultations cannot replace hiring a lawyer for more comprehensive support, they can help determine if hiring a lawyer is necessary, or if there are other options for you.\n\nThe phone consultation service is [completely separate from the claims process](https://faq.feather-insurance.com/en/articles/92347-legal-insurance-understanding-the-difference-between-a-phone-consultation-and-submitting-a-claim), and has no impact on your coverage, deductibles, or claims history. There is also no waiting period to be able to use it - you can request phone consultation as soon as you have an active policy.'
    ),
  },
  {
    id: '6',
    question: t(
      'legal.qnr.quote.faqs.6.question',
      'What is the legal claims process?'
    ),
    answer: t(
      'legal.qnr.quote.faqs.6.answer',
      '[Submitting a legal insurance claim](https://faq.feather-insurance.com/en/articles/28675-submit-a-legal-insurance-claim) can be done as soon as a situation arises where you think hiring legal services may be necessary. The claim can then be checked before you ever speak to a lawyer, so you know whether your policy will help cover the legal costs or not up front.\n\nClaims can also be submitted after speaking to a lawyer, which is helpful when you have an urgent matter and must see a lawyer immediately.\n\nIf the claim is approved in either instance, billing can be done directly between your lawyer and the insurance provider. Your lawyer can often even submit additional documents or other information for approval on your behalf to the claims department.'
    ),
  },
  {
    id: '7',
    question: t(
      'legal.qnr.quote.faqs.7.question',
      'What costs are covered by legal insurance?'
    ),
    answer: t(
      'legal.qnr.quote.faqs.7.answer',
      'The primary use for legal insurance is to cover the costs charged by your lawyer. Additionally, it can also cover court fees and pay to hire expert witnesses. '
    ),
  },
  {
    id: '8',
    question: t(
      'legal.qnr.quote.faqs.8.question',
      'Is there a preferred network of lawyers in Germany to use?'
    ),
    answer: t(
      'legal.qnr.quote.faqs.8.answer',
      'You are able to go to any lawyer in Germany - there is no preferred network that you must follow. During the claim process you can either ask for a recommendation if needed or find your own. The only requirement is that the lawyer must bill for services following the Lawyer Compensation Act (RVG) pricing framework, which lawyers should be able to provide.'
    ),
  },
  {
    id: '9',
    question: t(
      'legal.qnr.quote.faqs.9.question',
      'What is the cancellation policy?'
    ),
    answer: t(
      'legal.qnr.quote.faqs.9.answer',
      'There is no minimum contract period you must commit to when purchasing legal insurance. You are able to cancel at any time - just a one month notice is required. To cancel your policy, simply log in to your Feather account, go to your legal policy, and click the Cancel button.'
    ),
  },
];
