import {
  capitalizeName,
  englishFormattedEuroCurrency,
} from '@getpopsure/public-utility';
import dayjs from 'dayjs';
import { getPolicyStatus } from 'features/policiesDashboard/utils/getPolicyStatus';
import {
  bannerColorMapping,
  getTitleMapping,
} from 'models/insurances/types/mapping';

import { CancelPolicyButton, ContactUsButton } from '../../components/buttons';
import type { PolicySingleModal } from '../../components/modals';
import {
  CancelPolicyModal,
  DelinquencyBlockerCancelPolicyModal,
} from '../../components/modals';
import { DATE_FORMAT } from '../../constants';
import {
  BillingType,
  GetPolicyConfigArgs,
  PolicyConfig,
  PolicySingleSection,
} from '../../PolicySingle.models';
import { PolicyDetailsInfo } from '../../sections/PolicyDetails/PolicyDetails.models';
import { getPolicyDocuments } from '../../sections/PolicyDocuments/utils/getPolicyDocuments';

export const getLifeEuConfig = (args: GetPolicyConfigArgs): PolicyConfig => {
  const [policy, t] = args;
  const { insuredPerson, startDate, policyNumber, insuredSum, endDate } =
    policy.attributes;

  const policyStatus = getPolicyStatus(policy, true);
  const title = getTitleMapping(t).LIFE_EU;
  const capitalizedName = insuredPerson?.name
    ? capitalizeName(insuredPerson.name)
    : '';
  const subtitle = capitalizedName || '';

  const header: PolicyConfig['header'] = {
    title,
    subtitle,
    backgroundColor: bannerColorMapping.LIFE_EU,
    insuranceType: policy.type,
    policyStatus,
    startDate,
  };

  if (policy.attributes.price) {
    header.price = {
      amount: parseFloat(policy.attributes.price),
      billingType: policy.attributes.billingType as BillingType,
    };
  }

  let beneficiaries: PolicyDetailsInfo[] = [];

  if (policy.attributes.beneficiaries) {
    beneficiaries = [
      {
        title: t('myPolicies.policyDetails.beneficiaries', 'Beneficiaries'),
        value: policy.attributes.beneficiaries.map((beneficiary) => {
          if (!policy.attributes.coverage || !beneficiary.payoutPercentage) {
            return { ...beneficiary, coverage: undefined };
          }

          const coverage =
            (beneficiary.payoutPercentage * policy.attributes.coverage) / 100;

          return {
            ...beneficiary,
            coverage,
          };
        }),
        category: 'BENEFICIARIES',
      },
    ];
  }

  const sections: PolicySingleSection[] = [
    {
      id: 'POLICY_DETAILS',
      props: {
        policyType: policy.type,
        info: [
          {
            title: t('myPolicies.policyDetails.policyNumber', 'Policy number'),
            value: policyNumber,
          },
          {
            title: t(
              'myPolicies.policyDetails.insuredPerson',
              'Insured person'
            ),
            value: insuredPerson?.name && capitalizeName(insuredPerson.name),
          },
          {
            title: t('myPolicies.policyDetails.coverage', 'Insured sum'),
            value: insuredSum && englishFormattedEuroCurrency(insuredSum, true),
          },
          {
            title: t('myPolicies.policyDetails.startDate', 'Start date'),
            value: startDate && dayjs(startDate).format(DATE_FORMAT),
          },
          {
            title: t('myPolicies.policyDetails.endDate', 'End date'),
            value: endDate && dayjs(endDate).format(DATE_FORMAT),
          },
          ...beneficiaries,
        ],
        buttons: [
          {
            id: 'CONTACT_US',
            component: ContactUsButton,
          },
          ...(policy.status !== 'CANCELED'
            ? [
                {
                  id: 'CANCEL_POLICY',
                  component: CancelPolicyButton,
                },
              ]
            : []),
        ],
      },
    },
    {
      id: 'POLICY_DOCUMENTS',
      props: {
        documents: getPolicyDocuments(policy),
      },
    },
  ];

  const modals: PolicySingleModal[] = [
    {
      id: 'CANCEL_POLICY',
      component: CancelPolicyModal,
    },
    {
      id: 'DELINQUENT_BLOCKER_CANCEL_POLICY',
      component: DelinquencyBlockerCancelPolicyModal,
    },
  ];

  return { header, modals, sections };
};
