import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Policy } from 'models/policies';
import endpoint from 'shared/api';

export const getPolicy = async (policyId: string): Promise<Policy> => {
  const { data } = await endpoint.getPolicyDetail(policyId);
  return data;
};

export const useGetPolicy = (
  policyId: string,
  options?: UseQueryOptions<Policy, AxiosError>
) => {
  const {
    isLoading,
    data: policy,
    error,
  } = useQuery<Policy, AxiosError>({
    queryKey: ['getPolicy'],
    queryFn: () => getPolicy(policyId),
    enabled: true,
    ...options,
  });

  return {
    error,
    isLoading,
    policy,
  };
};
