import { BottomOrRegularModal, Button } from '@popsure/dirty-swan';
import routes from 'constants/routes';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getAccountInfo } from 'selectors/user';
import { useSafeTranslation } from 'shared/i18n';
import { formatCardLastDigits } from 'shared/util/cardFormatters';
import englishFormattedEuroCurrency from 'shared/util/priceFormatter';

import { useDraftInvoicePayAttempt } from '../hooks';
import { DisputeInvoice } from '../models';
import {
  getPaymentMethodIcon,
  mapBillingType,
  mapUserFriendlyInsuranceName,
} from '../utils';
import styles from './TransactionDetailsModal/styles.module.scss';

dayjs.extend(isSameOrAfter);

export const DisputeInvoiceDetailsModal = ({
  onClose,
  disputeInvoice,
}: {
  onClose: () => void;
  disputeInvoice: DisputeInvoice;
}) => {
  const { t } = useSafeTranslation();
  const { attemptingPayment, onPayClick } =
    useDraftInvoicePayAttempt(disputeInvoice);

  const account = useSelector(getAccountInfo);

  const retryCutOffDate =
    account?.region === 'es'
      ? dayjs(disputeInvoice.createdAt).endOf('month')
      : dayjs(disputeInvoice.createdAt).add(21, 'day');

  return (
    <BottomOrRegularModal
      isOpen
      onClose={onClose}
      title={mapBillingType[disputeInvoice.paymentType]}
    >
      <div className={`px24 pb24 ws6 ${styles.container}`}>
        <div
          className={`py24 d-flex fd-column ai-center jc-center bg-grey-100 br8 w100 ${styles.paymentAmountTime}`}
        >
          <h2 className="p-h2">
            {englishFormattedEuroCurrency(disputeInvoice.totalAmount)}
          </h2>
          <p className="p-p tc-grey-500">
            {dayjs(disputeInvoice.createdAt).format('DD MMM YYYY, HH:MM')}
          </p>
        </div>

        <div className={`w100 bg-grey-200 mt24 ${styles.divider}`} />
        <div className="mt24">
          {disputeInvoice.lines.map(
            ({ amount: latestChargeAmount, insuranceType }) => (
              <div
                className="d-flex ai-start jc-between mt16"
                key={`${insuranceType}-${latestChargeAmount}`}
              >
                <div className="p-p">
                  <p>{mapUserFriendlyInsuranceName(t)[insuranceType]}</p>
                </div>
                <p className="p-p">
                  {englishFormattedEuroCurrency(latestChargeAmount)}
                </p>
              </div>
            )
          )}
        </div>

        <div className={`mt24 p16 br8 ${styles.paymentDetails}`}>
          <div className={`${styles.paymentStatus}`}>
            <div className="d-flex ai-center">
              <img
                className={`mr16 bg-grey-200 ${styles.brandIcon}`}
                src={getPaymentMethodIcon(disputeInvoice)}
                alt="payment provider logo"
              />

              <p className={`p-p ${styles.paypalEmail}`}>
                {disputeInvoice.paymentMethodDetails.type === 'paypal'
                  ? disputeInvoice.paymentMethodDetails.identifier
                  : formatCardLastDigits(
                      disputeInvoice.paymentMethodDetails.lastDigits
                    )}
              </p>
            </div>
          </div>
        </div>

        <Button
          className="p-btn--primary mt24 w100"
          loading={attemptingPayment}
          onClick={onPayClick}
          disabled={dayjs().isSameOrAfter(retryCutOffDate)}
        >
          {t('billingHistory.billingModal.disputeInvoice.cta.pay.title', 'Pay')}
        </Button>
        <Link
          className="mt24 w100 p-btn--secondary bg-white"
          to={routes.me.paymentMethods.path}
        >
          {t(
            'billingHistory.billingModal.cta.paymentMethod.title',
            'Change payment method'
          )}
        </Link>
      </div>
    </BottomOrRegularModal>
  );
};
