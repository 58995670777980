import * as Sentry from '@sentry/react';
import { useMutation } from '@tanstack/react-query';
import endpoint from 'shared/api';
import { PayoutDetails } from 'shared/models/types';

import { triggerPushNotifications } from '../../../shared/pushNotifications';
import {
  DentalClaims,
  SubmitInfo,
  ZDentalClaimsSubmissionSchema,
} from '../models/DentalClaims.model';

export const useSubmitDentalClaim = () => {
  const onSubmitClaim = ({
    questionnaire,
    policyId,
    payoutDetails,
  }: {
    questionnaire: Partial<DentalClaims> | undefined;
    policyId: string;
    payoutDetails: PayoutDetails;
  }) => {
    const validation = ZDentalClaimsSubmissionSchema.parse({
      ...questionnaire,
      payoutDetails,
    });

    const { claimTypes, uploadedDocumentTokens, otherClaimType } = validation;

    const submittableClaimTypes = claimTypes.map((type) => ({
      claimType: type,
    }));
    const tokens = uploadedDocumentTokens.flatMap(({ token }) =>
      token !== undefined ? [token] : []
    );

    const submittableInfo: SubmitInfo = {
      type: 'DENTAL',
      userPolicyId: policyId,
      uploadedDocumentTokens: tokens,
      claimTypes: submittableClaimTypes,
      ...payoutDetails,
      ...(otherClaimType ? { otherClaimType } : {}),
    };

    return endpoint.submitClaim(submittableInfo);
  };

  const mutation = useMutation({
    mutationFn: onSubmitClaim,
    onError: (error, variables) => {
      const { policyId, questionnaire } = variables;
      Sentry.captureException(error, {
        tags: {
          feature: 'CLAIMS',
          vertical: 'DENTAL',
        },
        extra: {
          policyId,
          questionnaire,
          description: 'Error happened while submitting Dental claims',
        },
      });
    },
    onSuccess: () => {
      triggerPushNotifications('DENTAL');
    },
  });

  return mutation;
};
