import { toast } from '@popsure/dirty-swan';
import { useCancelPolicy } from 'features/policySingle/hooks/useCancelPolicy';
import { PolicyCancellationReason } from 'models/policies';
import { useHistory } from 'react-router-dom';
import { useSafeTranslation } from 'shared/i18n';

import CancelModalByVertical from '../legacy/modals/cancel';
import { PolicySingleModalProps } from './PolicySingleModal.models';

export const CancelPolicyModal = (props: PolicySingleModalProps) => {
  const history = useHistory();
  const { t } = useSafeTranslation();
  const {
    onClose,
    policyData: { id: policyId, type, createdAt, attributes },
  } = props;

  const { cancelPolicy, isCancelling, cancellingError } = useCancelPolicy(t);

  const onCancelPolicy = (
    reason?: PolicyCancellationReason,
    additionalInfo?: string,
    onSuccessMessage?: string
  ) => {
    cancelPolicy(
      { reason, additionalInfo, policyId },
      {
        onSuccess: () => {
          history.goBack();
          toast(
            onSuccessMessage ??
              t(
                'myPolicies.cancelModal.successMessage',
                'Your policy was successfully cancelled'
              ),
            {
              type: 'success',
            }
          );
        },
      }
    );
  };

  return (
    <CancelModalByVertical
      isOpen
      onCloseModal={onClose}
      id={policyId}
      type={type}
      activeUntil={attributes?.activeUntil}
      policyNumber={attributes?.policyNumber}
      startDate={attributes?.startDate}
      createdAt={createdAt}
      isCancelling={isCancelling}
      cancellingError={cancellingError}
      onCancelPolicy={onCancelPolicy}
    />
  );
};
