import { BottomOrRegularModal, Input } from '@popsure/dirty-swan';
import { fetchAllClaims } from 'actions/claims';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { ExpatEuCancellationReasonsId } from 'features/expatEu/models';
import { Fragment, useEffect, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { useDispatch, useSelector } from 'react-redux';
import { getClaims } from 'selectors/claims';
import { TFunction, Trans, useSafeTranslation } from 'shared/i18n';

import styles from '../style.module.scss';
import { ViewProps } from '../types';
import { isInRightOfWithdrawalPeriod } from '../utils';

type CancellationReason = {
  id: ExpatEuCancellationReasonsId;
  title: string;
};

const getExpatEuCancellationReasons = (
  t: TFunction,
  showRightOfWithdrawlReason?: boolean
): CancellationReason[] => {
  const reasons: CancellationReason[] = [
    {
      id: 'FOUND_A_JOB',
      title: t(
        'myPolicies.cancelModal.expatEu.reason.foundAJob.title',
        'I found a job'
      ),
    },
    {
      id: 'FOUND_ANOTHER_INSURANCE',
      title: t(
        'myPolicies.cancelModal.expatEu.reason.foundAnotherInsurance.title',
        'I got another health insurance'
      ),
    },
    {
      id: 'LEFT_COUNTRY',
      title: t(
        'myPolicies.cancelModal.expatEu.reason.leftCountry.title',
        'I will leave the country'
      ),
    },
  ];

  if (showRightOfWithdrawlReason) {
    return [
      {
        id: 'RIGHT_OF_WITHDRAWAL',
        title: t(
          'myPolicies.cancelModal.expatEu.reason.rightOfWithdrawal.title',
          'I changed my mind and need a full refund'
        ),
      },
      ...reasons,
    ];
  }

  return reasons;
};

export const canCancel = (
  cancelationReason?: ExpatEuCancellationReasonsId,
  additionalInfo?: string
): boolean => {
  if (cancelationReason === 'FOUND_ANOTHER_INSURANCE') {
    return additionalInfo !== undefined && additionalInfo.length > 0;
  }

  return cancelationReason !== undefined;
};

const ClaimsFetcher = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllClaims());
  }, [dispatch]);

  return null;
};

export const CancelExpatEuModal = ({
  activeUntil,
  onCloseModal,
  onCancelPolicy,
  isCancelling,
  cancellingError,
  isOpen,
  id: policyId,
  createdAt,
}: ViewProps) => {
  const { t } = useSafeTranslation();

  const [selectedExpatEuCancellationReason, setExpatEuCancellationReason] =
    useState<ExpatEuCancellationReasonsId | undefined>();
  const [newInsuranceProvider, setNewInsuranceProvider] = useState<
    string | undefined
  >();

  const claims = useSelector(getClaims) ?? null;

  const policyHasClaims = claims?.some(
    (claim) => claim.userPolicyId === policyId
  );

  const nowDate = Date.now();

  const showRightOfWithdrawlReason =
    isInRightOfWithdrawalPeriod({
      nowDate,
      startDateOfRoWPeriod: createdAt,
    }) && !policyHasClaims;

  const getAdditionalInfo = (): string | undefined => {
    if (selectedExpatEuCancellationReason === 'FOUND_ANOTHER_INSURANCE') {
      return newInsuranceProvider;
    }

    return undefined;
  };

  const additionalInfo = getAdditionalInfo();

  const valid = canCancel(selectedExpatEuCancellationReason, additionalInfo);

  const handleCancelPolicy = () => {
    const successMessage = t(
      'myPolicies.cancelModal.expatEu.reason.successMessage',
      'Your policy was successfully cancelled'
    );

    onCancelPolicy(
      selectedExpatEuCancellationReason,
      additionalInfo,
      successMessage
    );
  };

  return (
    <BottomOrRegularModal
      title={t('myPolicies.cancelModal.expatEu.title', 'Cancel policy')}
      isOpen={isOpen}
      onClose={onCloseModal}
    >
      {isOpen && <ClaimsFetcher />}
      <div
        className={`mt24 ${styles.container}`}
        data-testid="cancel-modal-expat-eu"
      >
        <>
          <form
            className="mt16"
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            {getExpatEuCancellationReasons(t, showRightOfWithdrawlReason).map(
              ({ id, title: reasonTitle }) => (
                <Fragment key={id}>
                  <label className="p-p mt8 d-block" htmlFor={id}>
                    <input
                      id={id}
                      value={id}
                      type="radio"
                      name="cancellationReason"
                      onChange={() => {
                        setExpatEuCancellationReason(id);
                      }}
                      checked={id === selectedExpatEuCancellationReason}
                    />
                    &nbsp;{reasonTitle}
                  </label>
                  {id === 'FOUND_ANOTHER_INSURANCE' && (
                    <AnimateHeight
                      duration={300}
                      height={
                        selectedExpatEuCancellationReason ===
                        'FOUND_ANOTHER_INSURANCE'
                          ? 'auto'
                          : 0
                      }
                    >
                      <Input
                        className="mt8 wmx5"
                        placeholder={t(
                          'myPolicies.cancelModal.expatEu.reason.foundAnotherInsurance.input.placeholder',
                          'Insurance provider'
                        )}
                        value={newInsuranceProvider ?? ''}
                        onChange={(e) => {
                          setNewInsuranceProvider(e.target.value);
                        }}
                      />
                    </AnimateHeight>
                  )}
                </Fragment>
              )
            )}
          </form>
        </>
        {activeUntil && !showRightOfWithdrawlReason && (
          <Trans i18nKey="myPolicies.cancelModal.expatEu.activeUntil.description">
            <p className="p-notice--warning mt24 p-p">
              The cancellation will be effective at the end of your next billing
              period on{' '}
              <b className="fw-bold">
                {{ endDate: dayjs(activeUntil).format('DD MMM YYYY') }}
              </b>
              .
            </p>
          </Trans>
        )}
        {showRightOfWithdrawlReason && (
          <Trans i18nKey="myPolicies.cancelModal.expatEu.activeUntil.rightOfWithdrawal.description">
            <p className="p-notice--warning mt24 p-p">
              Your policy will be canceled immediately.
            </p>
          </Trans>
        )}
        <div className={`d-flex f-wrap mt24 ${styles['button-container']}`}>
          <button
            className={classNames(`p-btn--primary wmn3`, {
              'p-btn--loading': isCancelling,
              invalid: !valid,
            })}
            disabled={!valid || isCancelling}
            onClick={handleCancelPolicy}
            type="button"
          >
            {t(
              'myPolicies.cancelModal.expatEu.confirmButton.caption',
              'Cancel policy'
            )}
          </button>
          <button
            className={`p-btn--secondary wmn2 ${styles['secondary-button']}`}
            onClick={() => onCloseModal()}
            type="button"
          >
            {t('myPolicies.cancelModal.expatEu.closeButton.caption', 'Close')}
          </button>
        </div>
        {cancellingError && (
          <p className="p-notice--danger p-p mt24">{cancellingError}</p>
        )}
      </div>
    </BottomOrRegularModal>
  );
};
