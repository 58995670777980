import { useMutation } from '@tanstack/react-query';
import { UploadHealthCardPhotoPayload } from 'models/healthCard';
import endpoint from 'shared/api';

import { healthCardMutationErrorHandler } from '../utils/healthCardMutationErrorHandler';

export const useUploadHealthCardPhoto = () => {
  const uploadHealthCardPhoto = async (
    payload: UploadHealthCardPhotoPayload
  ): Promise<string> => {
    const { file, fileExtension, token } = payload;
    const {
      data: { photoId, uploadUrl },
    } = await endpoint.createHealthCardPhotoUpload(payload);
    await endpoint.uploadFile(file, fileExtension, uploadUrl);
    const {
      data: { downloadUrl },
    } = await endpoint.markHealthCardPhotoUploaded(photoId, token);
    return downloadUrl;
  };

  return useMutation({
    mutationFn: uploadHealthCardPhoto,
    onError: (error, variables) =>
      healthCardMutationErrorHandler(error, { ...variables }),
  });
};
