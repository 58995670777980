import { hasAutomatedCancelProcess } from 'models/insurances/types/mapping';

import { CancelAutomatedModal } from './cancelAutomatedModal';
import { CancelDentalModal } from './cancelDentalModal';
import { CancelExpatEuModal } from './cancelExpatEuModal';
import { CancelExpatHealthModal } from './cancelExpatHealthModal';
import { CancelExpatLongTermModal } from './cancelExpatLongTermModal';
import { CancelExpatSpainModal } from './cancelExpatSpainModal';
import { CancelManuallyModal } from './cancelManuallyModal';
import { ViewProps } from './types';

const CancelModal = (props: ViewProps) => {
  const { type, activeUntil, onCloseModal, isOpen, policyNumber } = props;

  if (hasAutomatedCancelProcess[type]) {
    // Expat health
    if (type === 'INCOMING') {
      return <CancelExpatHealthModal {...props} />;
    }

    if (type === 'DENTAL') {
      return <CancelDentalModal {...props} />;
    }

    if (type === 'INCOMING_ES') {
      return <CancelExpatSpainModal {...props} />;
    }

    if (type === 'INCOMING_EU') {
      return <CancelExpatEuModal {...props} />;
    }

    if (type === 'INCOMING_LT') {
      return <CancelExpatLongTermModal {...props} />;
    }

    // Any other policy with automated cancelation
    return <CancelAutomatedModal {...props} />;
  }

  // Any other policy that has to be manually canceled
  return (
    <CancelManuallyModal
      activeUntil={activeUntil}
      onCloseModal={onCloseModal}
      isOpen={isOpen}
      policyNumber={policyNumber}
    />
  );
};

export default CancelModal;
