import { email } from '@getpopsure/private-constants';
import { AxiosError } from 'axios';
import { TFunction } from 'shared/i18n';

export const getLoginModalErrorMessage = (t: TFunction) => {
  const codeRequestErrorMessage = t(
    'components.loginmodal.errors.requestCodeFailed',
    'An error occurred. Check the email address you provided is correct and try again, or contact us at {{supportEmail}}.',
    { supportEmail: email.help }
  );

  const getInputErrorMessage = (inputError: unknown) => {
    if (!inputError) return;

    if (
      inputError instanceof AxiosError &&
      inputError?.response?.status === 422
    ) {
      const errorMessage = inputError?.response?.data;

      if (
        typeof errorMessage === 'string' &&
        errorMessage.includes('Code is invalid')
      ) {
        return t(
          'components.loginmodal.errors.invalidlogincode',
          'The code you entered was not recognized. Please try again or receive a new code'
        );
      }
    }

    return t(
      'components.loginmodal.errors.generic',
      'An error occurred. Please try again or contact us at {{supportEmail}}.',
      { supportEmail: email.help }
    );
  };

  return { codeRequestErrorMessage, getInputErrorMessage };
};
