import { ChevronUpIcon } from '@popsure/dirty-swan';
import classNames from 'classnames';
import { useState } from 'react';
import AnimateHeight from 'react-animate-height';

import styles from './CollapsibleSection.module.scss';

export const CollapsibleSection = ({
  title,
  children,
  initiallyOpen,
  icon,
}: {
  icon?: JSX.Element;
  children: React.ReactNode;
  title: string;
  initiallyOpen?: boolean;
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(!!initiallyOpen);
  return (
    <div className="ds-card ds-card--no-dropshadow mt24 p24 gap24">
      <button
        type="button"
        className="bg-white c-pointer d-flex df-row ai-start w100"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="d-flex gap16 w100 ai-center">
          {!!icon && <>{icon}</>}
          <span className={classNames('p-h3 w100 jc-between', styles.row)}>
            {title}
            <ChevronUpIcon
              className={isOpen ? styles.chevronOpen : styles.chevronClosed}
              color="primary-500"
              size={24}
              noMargin
            />
          </span>
        </div>
      </button>
      <div className="d-flex fd-column w100">
        <AnimateHeight duration={300} height={isOpen ? 'auto' : 0}>
          {children}
        </AnimateHeight>
      </div>
    </div>
  );
};
