import { TFunction } from '@getpopsure/i18n-react';
import { APIResponseError } from 'models/error';

export const getApiErrors = (error: APIResponseError) => {
  if (Array.isArray(error.response?.data.message)) {
    return error.response?.data.message.join(', ');
  }

  return error.response?.data.message;
};

export const getErrorMessageTranslation = (
  t: TFunction,
  error: APIResponseError
) => {
  const userFriendlyErrorMessages: Record<string, string> = {
    NO_ACTIVE_POLICIES: t(
      'referralengine.generator.modal.error.noActivePolicies',
      'Error generating promo code: must have active insurance policy'
    ),
    SOMETHING_WENT_WRONG: t(
      'referralengine.generator.modal.error.somethingWentWrongTryAgainLater',
      'Error generating promo code: something went wrong - please try again later.'
    ),
  };

  if (error.response && error.response.data && error.response.data.message) {
    const errorMessage = error.response.data.message;

    return (
      userFriendlyErrorMessages?.[errorMessage] ||
      userFriendlyErrorMessages.SOMETHING_WENT_WRONG
    );
  }

  return userFriendlyErrorMessages.SOMETHING_WENT_WRONG;
};
