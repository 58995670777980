import { BottomOrRegularModal, Button } from '@popsure/dirty-swan';
import { fetchAccountInfo } from 'actions/user';
import { fetchPaymentMethods } from 'features/paymentMethods/paymentMethods.actions';
import { StripeElementSetupData } from 'features/paymentMethodSelector/paymentMethodSelector.models';
import { PaymentMethodSelectorThunkDispatch } from 'features/paymentMethodSelector/paymentMethodSelector.thunks';
import { isReadyForPayment } from 'features/paymentMethodSelector/paymentMethodSelector.utils';
import { CheckoutContext } from 'features/paymentScreenV2/checkout.context';
import { StripeElementForm } from 'features/paymentScreenV2/StripeElementForm/StripeElementForm';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'reducers';
import { getRequestInProcess } from 'selectors/requests';
import { getAccountInfo } from 'selectors/user';
import { useSafeTranslation } from 'shared/i18n';

export const AddNewPaymentOptionModal = ({
  updatePaymentMethods,
}: {
  updatePaymentMethods: () => void;
}) => {
  const { t } = useSafeTranslation();
  const {
    addNewPaymentMethod,
    setAddNewPaymentMethod,
    currentUserPaymentMethods: paymentOptions,
    onStripePaymentMethodTypeChange,
    isSubmittingNewPaymentMethod,
    currentSelectedPaymentMethod,
    paymentOption,
    setPaymentOption,
    address,
  } = useContext(CheckoutContext);

  const dispatch = useDispatch<PaymentMethodSelectorThunkDispatch>();
  const [isFirstFetch, setIsFirstFetch] = useState<boolean>(true);
  const isLoadingPaymentMethods = useSelector((state: AppState) =>
    getRequestInProcess(state, 'GET_PAYMENT_METHODS')
  );
  /**
   * Account data is required to fetch current user's email to configure
   * the SepaSetupData.accountHolderEmail property required by Stripe.
   *
   * This is a SEPA/Stripe requirement, and is rather an implementation detail,
   * that's why selector has been moved to this component. In the future we might want
   * to make this editable by the customer.
   */
  const account = useSelector(getAccountInfo);

  const isLoading = !account || isFirstFetch || isLoadingPaymentMethods;

  const [stripeSetupData, onStripeSetupDataChange] =
    useState<StripeElementSetupData | null>(null);
  const accountHolderEmail = account?.email;
  const accountHolderAddress = address;

  const forceMakeDefault = paymentOptions.length === 0;

  useEffect(() => {
    if (isFirstFetch) {
      setIsFirstFetch(false);
      dispatch(fetchAccountInfo());
      dispatch(fetchPaymentMethods());
    }
  }, [dispatch, isFirstFetch]);

  useEffect(() => {
    if (addNewPaymentMethod) {
      if (stripeSetupData) {
        setPaymentOption({
          type: 'READY_FOR_SETUP_WITH_REDIRECT',
          setupData: stripeSetupData,
        });
      } else {
        setPaymentOption({
          type: 'NOT_READY_FOR_SETUP',
        });
      }
    } else if (currentSelectedPaymentMethod) {
      setPaymentOption({
        type: 'EXISTING_PAYMENT_METHOD',
        paymentMethod: currentSelectedPaymentMethod,
      });
    }
  }, [
    addNewPaymentMethod,
    stripeSetupData,
    currentSelectedPaymentMethod,
    setPaymentOption,
  ]);

  if (!accountHolderEmail || isLoading) {
    return <div className="ds-spinner ds-spinner__m mx-auto my24" />;
  }

  return (
    <BottomOrRegularModal
      title={t('paymentScreen.paymentMethod.modal.title', 'Payment method')}
      onClose={() => setAddNewPaymentMethod(false)}
      dismissible={true}
      isOpen={addNewPaymentMethod}
    >
      <div className="px24 py24">
        <p className="tc-grey-600 p-p">
          {t(
            'paymentScreen.paymentMethod.modal.description',
            'Add a new payment method or select one you’ve saved before. You can update your payment methods later in account settings.'
          )}
        </p>
        <StripeElementForm
          forceMakeDefault={forceMakeDefault}
          accountHolderEmail={accountHolderEmail}
          accountHolderAddress={accountHolderAddress}
          onSetupDataChange={onStripeSetupDataChange}
          onPaymentMethodTypeChange={onStripePaymentMethodTypeChange}
          hideStripePaymentElements={false}
        />
        <Button
          className="w100"
          onClick={updatePaymentMethods}
          disabled={
            isSubmittingNewPaymentMethod || !isReadyForPayment(paymentOption)
          }
          loading={isSubmittingNewPaymentMethod}
          data-cy="button-continue"
        >
          {t(
            'paymentMethodSelector.stripeElementFormV2.button.title',
            'Continue'
          )}
        </Button>
      </div>
    </BottomOrRegularModal>
  );
};
