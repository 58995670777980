import {
  flushGenericQuestionnaire,
  storeGenericQuestionnaireAnswer,
} from 'actions/genericQuestionnaire';
import {
  setRequestErrored,
  setRequestInProcess,
  setRequestSkipped,
} from 'actions/request';
import { RequestAction } from 'constants/actions';
import { submitPrivatePreSignupQuestionnaire } from 'features/privateHealthPreSignup/api';
import {
  PrivatePreSignup,
  ZPrivatePreSignupRequiredAnswersForQuestionnaireSubmissionSchema,
} from 'features/privateHealthPreSignup/models';
import hashSum from 'hash-sum';
import { APIResponseError } from 'models/error';
import { AppState } from 'reducers';
import { GenericQuestionnaireAction } from 'reducers/genericQuestionnaire';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import endpoint from 'shared/api';
import { ActionResponse } from 'shared/models/types';

export type PrivatePreSignupDispatch = ThunkDispatch<
  AppState,
  Record<string, unknown>,
  GenericQuestionnaireAction<'privateHealthPreSignup'> | RequestAction
>;

export type SubmitQuestionnaireReturnType = {
  status: ActionResponse;
  questionnaireId?: string;
  hashOfQuestionnaire?: string;
  error?: unknown;
};

export const persistPrivatePreSignupQuestionnaire =
  ({
    answers,
    shortTermPrice,
    basicPrice,
    plusPrice,
    premiumPrice,
    email,
    name,
    sendEmail,
    hashOfLastSubmittedQuestionnaire,
  }: {
    answers: Partial<PrivatePreSignup>;
    shortTermPrice?: number;
    basicPrice?: number;
    plusPrice?: number;
    premiumPrice?: number;
    email?: string;
    name?: string;
    sendEmail?: boolean;
    hashOfLastSubmittedQuestionnaire?: string;
  }): ThunkAction<
    Promise<SubmitQuestionnaireReturnType>,
    AppState,
    Record<string, unknown>,
    GenericQuestionnaireAction<'privateHealthPreSignup'> | RequestAction
  > =>
  async (dispatch) => {
    const requestType = 'SUBMIT_QUESTIONNAIRE';

    dispatch(setRequestInProcess(true, requestType));

    try {
      const validationResult =
        ZPrivatePreSignupRequiredAnswersForQuestionnaireSubmissionSchema.safeParse(
          {
            ...answers,
            email,
          }
        );

      if (!validationResult.success) {
        throw new Error(
          `[Private Pre-Signup] Questionnaire answer validation errors: ${validationResult.error.toString()}`
        );
      }

      const validatedAnswers = validationResult.data;

      const hashOfQuestionnaire = hashSum(validatedAnswers);

      if (
        hashOfLastSubmittedQuestionnaire &&
        hashOfLastSubmittedQuestionnaire === hashOfQuestionnaire
      ) {
        dispatch(setRequestSkipped(requestType));

        return {
          status: 'SUCCESS',
          hashOfQuestionnaire,
          questionnaireId: answers.questionnaireId,
        };
      }

      const {
        data: { id: questionnaireId },
      } = await submitPrivatePreSignupQuestionnaire(endpoint.network, {
        answers: validationResult.data,
        shortTermPrice,
        basicPrice,
        plusPrice,
        premiumPrice,
        email,
        name,
        sendEmail,
      });

      dispatch(
        storeGenericQuestionnaireAnswer('privateHealthPreSignup', {
          questionnaireId,
          hashOfQuestionnaire,
        })
      );

      dispatch(setRequestInProcess(false, requestType));

      return {
        status: 'SUCCESS',
        hashOfQuestionnaire,
        questionnaireId,
      };
    } catch (error) {
      dispatch(setRequestErrored(error as APIResponseError, requestType));
      // eslint-disable-next-line no-console
      console.error(error);
      return { status: 'ERROR', error };
    }
  };

export const flushPrivatePreSignupAnswers = (
  dispatch: PrivatePreSignupDispatch
) => {
  dispatch(flushGenericQuestionnaire('privateHealthPreSignup'));
};
