import { ErrorWithAction } from 'components/ErrorWithAction';
import LoadingSpinner from 'components/loadingSpinner';
import routes from 'constants/routes';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useFetchQuestionnaire, useRubiksDebugger } from '../hooks';

export const QuestionnairePreview = () => {
  const { verticalId } = useParams<{
    verticalId: string;
  }>();
  const history = useHistory();

  const { json, loading } = useFetchQuestionnaire(verticalId);

  useRubiksDebugger();

  useEffect(() => {
    if (json) {
      const questionnaireType = json?.meta.type;

      if (
        questionnaireType === 'singleRegion' ||
        questionnaireType === 'multiRegion'
      ) {
        const path = routes.birdhouse.preview.policy.noRegion.path.replace(
          ':verticalId',
          verticalId
        );

        history.push(path);
      }

      if (questionnaireType === 'claim') {
        const path = routes.birdhouse.preview.claim.path.replace(
          ':verticalId',
          verticalId
        );

        history.push(path);
      }
    }
  }, [json, verticalId, history]);

  if (loading === 'PROCESSING') {
    return <LoadingSpinner />;
  }

  if (!json && loading === 'COMPLETED') {
    return (
      <ErrorWithAction
        title="Questionnaire not found"
        description="Your questionnaire JSON wasn't found. Make sure your preview config is correct and try again."
      />
    );
  }

  return <></>;
};
