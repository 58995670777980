import { TFunction } from '@getpopsure/i18n-react';
import { Rule } from '@getpopsure/qnr-framework';
import dayjs from 'dayjs';
import type { ClaimQuestionnaire } from 'features/commonClaims/CommonClaims';

import { CommonClaimAnswers, PetHealthClaimType } from '../models';

export type PetHealthClaimAnswers = CommonClaimAnswers;

export const getTranslatedPetHealthQuestionnaire = (
  t: TFunction
): ClaimQuestionnaire => [
  {
    id: 'intro',
    type: 'PET_HEALTH_INTRO',
    groupId: 'questionnaire',
    props: {},
    screen: {
      layout: 'Standalone',
      noMaxWidth: true,
    },
  },
  {
    id: 'claimType',
    required: true,
    type: 'RADIO',
    props: {
      mapValue: petHealthClaimTypeMapping(t),
    },
    screen: {
      question: t(
        'claims.petHealth.claimType.title',
        'What is this claim for?'
      ),
    },
    groupId: 'questionnaire',
  },
  {
    id: 'details',
    required: true,
    type: 'TEXT',
    screen: {
      question: t('claims.petHealth.details.title', 'Describe what happened'),
      description: t(
        'claims.petHealth.details.description',
        'Explain how things happened, including relevant dates and who was involved'
      ),
      skipDetails: {
        title: t(
          'claims.petHealth.details.skipDetails.title',
          'Nothing to add'
        ),
        skipQuestion: { value: '' },
      },
    },
    props: {
      placeholder: t(
        'claims.petHealth.details.placeholder',
        'E.g. my dog ate something in the park yesterday, leading to diarrhea. We went to the emergency vet service for treatment.'
      ),
    },
    groupId: 'questionnaire',
  },
  {
    id: 'uploadDocuments',
    type: 'UPLOAD',
    required: true,
    props: {
      showLegalNotice: true,
    },
    screen: {
      question: t('claims.petHealth.uploadDocuments.title', 'Upload invoices'),
      additionalInfo: {
        title: t(
          'claims.petHealth.uploadDocuments.additionalInfo.title',
          'Make sure the invoice includes'
        ),
        description: t(
          'claims.petHealth.uploadDocuments.additionalInfo.description',
          "• Pet owner's name \n\n• Pet's name and microchip number\n\n• Treatment date\n\n• Diagnosis for vet treatments and medications\n\n• Breakdown of services and medications (with GOT details)\n\n For surgery coverage, it should specify if the procedure is surgery or surgery-related."
        ),
      },
    },
    groupId: 'questionnaire',
  },
  {
    id: 'dateOfEvent',
    groupId: 'questionnaire',
    required: true,
    type: 'DATE',
    props: {
      yearRange: {
        min: { op: 'subtract', type: 'years', value: 100 },
        max: { op: 'add', type: 'years', value: 10 },
      },
      displayCalendar: true,
    },
    screen: {
      question: t(
        'claims.petHealth.dateOfEvent.title',
        "What's the invoice date"
      ),
    },
    validations: [
      {
        op: 'Custom',
        fn: (answer) => {
          return !dayjs(answer as string).isAfter(dayjs(), 'day');
        },
        msg: () => ({
          type: 'Info',
          msg: t(
            'claims.petHealth.dateOfEvent.error',
            'The invoice date cannot be in the future.'
          ),
        }),
      },
    ],
  },
  {
    id: 'payoutDetails',
    required: true,
    type: 'PAYOUT_DETAILS',
    groupId: 'questionnaire',
    props: {},
    screen: {
      buttonText: t('claims.petHealth.payoutDetails.button', 'Continue'),
    },
  },
  {
    id: 'processing',
    required: true,
    type: 'CLAIM_PROCESSING',
    props: {},
    screen: {
      layout: 'Standalone',
    },
    groupId: 'questionnaire',
  },
  {
    id: 'submitted',
    required: true,
    type: 'SUBMISSION',
    props: {
      vertical: 'petHealth',
      region: 'de',
    },
    screen: {
      layout: 'Standalone',
    },
    groupId: 'questionnaire',
  },
];

export const getPetHealthRules = (): Rule<PetHealthClaimAnswers>[] => [];

export const petHealthClaimTypeMapping = (
  t: TFunction
): Partial<
  Record<PetHealthClaimType, string | { title: string; description: string }>
> => ({
  SURGERY: {
    title: t('claims.petHealth.claimType.surgery.title', 'Surgery'),
    description: t(
      'claims.petHealth.claimType.surgery.description',
      'Including surgery-related diagnosis, treatment, and recovery at the vet clinic'
    ),
  },
  VETERINARY_VISITS: t(
    'claims.petHealth.claimType.veterinaryVisits.title',
    'Regular vet visits'
  ),
  MEDICATION: {
    title: t(
      'claims.petHealth.claimType.medication.title',
      'Vet treatments and medication'
    ),
    description: t(
      'claims.petHealth.claimType.medication.description',
      'E.g. overnight vet stays, antibiotics, physiotherapy'
    ),
  },
  PREVENTATIVE_TREATMENT: {
    title: t(
      'claims.petHealth.claimType.preventativeTreatment.title',
      'Preventative treatment'
    ),
    description: t(
      'claims.petHealth.claimType.preventativeTreatment.description',
      'E.g. vaccinations, dental cleaning, tick repellents'
    ),
  },
  OTHER: t('claims.petHealth.claimType.other.title', 'Other'),
});
