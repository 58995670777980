import { email } from '@getpopsure/private-constants';
import GenericReview from 'components/review';
import { useSubmitLegalClaim } from 'features/legalClaims/hooks/useSubmitLegalClaim';
import { LegalClaimInfo } from 'models/claims';
import { CustomComponentProps } from 'models/questionnaireFramework';
import { FormEvent } from 'react';
import { useParams } from 'react-router';
import { useSafeTranslation } from 'shared/i18n';
import { prepareReviewValues } from 'SignupQuestionnaire/components/Review/Review';
import { ZodError } from 'zod';

export const Review = ({
  onSubmitValue,
  reviewValues,
  confirmationText,
  questionnaireAnswers,
  ctaText,
}: CustomComponentProps<LegalClaimInfo>) => {
  const { t } = useSafeTranslation();
  const { policyId } = useParams<{ policyId: string }>();

  const {
    mutate: submitLegalClaim,
    error,
    isError,
    isLoading,
  } = useSubmitLegalClaim();

  const questionReviewValues = prepareReviewValues(
    questionnaireAnswers,
    reviewValues
  );

  const getErrorMessage = () => {
    if (!isError) return;

    if (error instanceof ZodError) {
      return t(
        'claims.legal.review.validationError.message',
        'Some information needed to submit your claim are missing or invalid. Please try again or contact us at {{supportEmail}}.',
        { supportEmail: email.help }
      );
    }

    return t(
      'claims.legal.review.error.message',
      'An error occurred while submitting your claim. Please try again or contact us at {{supportEmail}}.',
      { supportEmail: email.help }
    );
  };

  const onSubmitClaim = async (e: FormEvent) => {
    e.preventDefault();

    submitLegalClaim(
      { questionnaire: questionnaireAnswers, policyId },
      {
        onSuccess: () => onSubmitValue(true),
      }
    );
  };

  return (
    <GenericReview
      questionReviewValues={questionReviewValues}
      loading={isLoading}
      error={getErrorMessage()}
      handleSubmit={onSubmitClaim}
      confirmationText={confirmationText}
      ctaText={ctaText}
      insuranceType="LEGAL_V2"
    />
  );
};
