import { createQuote } from 'actions/liability';
import View from 'components/review';
import { createLabels } from 'features/liability/utils';
import { useRequestStatus } from 'hooks/useRequestStatus';
import {
  Answers,
  getInclusiveMembersMapping,
  getPastClaimsMapping,
  QuestionnaireLabels,
  reviewQuestions,
  ZLiabilityAnswersRequiredForForQuoteSchema,
} from 'models/liability';
import { FormEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getGenericQuestionnaire } from 'selectors/genericQuestionnaire';
import { getQuestionReviewValues } from 'selectors/liability';
import { useSafeTranslation } from 'shared/i18n';

const LiabilityReview = () => {
  const { t } = useSafeTranslation();

  const [validationError, setValidationError] = useState<string | undefined>();

  const questionReviewValues = useSelector(getQuestionReviewValues(t));
  const questionnaire = useSelector(getGenericQuestionnaire).liability;

  const { loading, error } = useRequestStatus('CREATE_QUOTE');
  const dispatch = useDispatch();

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    const validationResult =
      ZLiabilityAnswersRequiredForForQuoteSchema.safeParse(questionnaire);

    if (!validationResult.success) {
      const { issues } = validationResult.error;

      issues.forEach((issue) => {
        if (issue.path.includes('startDate') && issue.code === 'too_small') {
          setValidationError(
            t(
              'page.liability.questionnaire.review.startDateNotInFuture.errorMessage',
              'Please choose a coverage start date in the future.'
            )
          );
        }
      });

      throw new Error(
        `[Liability - Review] Questionnaire answer validation errors:\n${validationResult.error.toString()}`
      );
    }

    const personalInfo = validationResult.data;

    const answers: Answers = {
      personalInfo,
    };

    const labels: QuestionnaireLabels = {
      labels: {
        title: {
          personalInfo: createLabels(t)(reviewQuestions),
        },
        answers: {
          personalInfo: {
            inclusiveMembers: getInclusiveMembersMapping(t),
            numberOfPreviousClaims: getPastClaimsMapping(t),
          },
        },
      },
      questionOrder: {
        personalInfo: reviewQuestions,
      },
    };

    dispatch(createQuote(answers, labels, t));
  };

  return (
    <View
      questionReviewValues={questionReviewValues}
      loading={loading}
      error={error?.message || validationError}
      handleSubmit={handleSubmit}
      insuranceType="LIABILITY"
    />
  );
};

export default LiabilityReview;
