import { QuestionnaireQuestions } from '@getpopsure/qnr-framework';
import { ErrorWithAction } from 'components/ErrorWithAction';
import TimedLoadSpinner from 'components/timedLoadSpinner';
import { InsuranceTypes } from 'models/insurances/types';
import { Policy } from 'models/policies';
import { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useParams } from 'react-router-dom';

import { interpolateData } from '../functions';
import { useFetchQuestionnaire, useFetchSharedConfig } from '../hooks';
import { QuestionnaireJSON } from '../model';
import { GeneralClaimsQuestionnaire } from './GeneralClaimsQuestionnaire';
import { useGeneralClaimsStorage } from './storage';

export const GeneralClaimsPreview = () => {
  const { verticalId } = useParams<{
    verticalId: string;
  }>();

  const [answers, setAnswers] = useState<QuestionnaireQuestions>({});

  const { removeQuestionnaireAnswers } = useGeneralClaimsStorage(verticalId);

  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const { sharedConfig, sharedConfigErrorMessage } =
    useFetchSharedConfig(verticalId);
  const { json, questionnaireErrorMessage } = useFetchQuestionnaire(verticalId);

  useEffect(() => {
    if (sharedConfigErrorMessage ?? questionnaireErrorMessage) {
      setErrorMessage(sharedConfigErrorMessage ?? questionnaireErrorMessage);
    }
  }, [sharedConfigErrorMessage, questionnaireErrorMessage]);

  if (json === undefined) {
    return <TimedLoadSpinner delayInMilliseconds={3_000} />;
  }

  if (errorMessage || !json) {
    return (
      <ErrorWithAction
        title="Questionnaire error"
        description={errorMessage ?? ''}
      />
    );
  }

  const interpolatedJSON = interpolateData<QuestionnaireJSON>(json, {
    answers,
    constants: {
      ...sharedConfig,
      ...json.meta.constants,
    },
  });

  const resetData = () => {
    removeQuestionnaireAnswers();
    window.location.reload();
  };

  const policy: Policy = {
    id: '123',
    type: json.meta.insuranceType as InsuranceTypes,
    applicationId: 'application_id_123',
    providerId: 'PROVIDER_ID',
    providerName: 'Provider',
    status: 'ACTIVE',
    userUploads: [],
    attributes: {
      policyNumber: 'policy_number_123',
      SVNR: 'svnr_123',
      insuredPerson: {
        name: { firstName: 'Seungha', lastName: 'Kong' },
      },
    },
    documents: [
      {
        title: 'Important document',
        downloadURL: 'https://cdn.getpopsure.com/document.pdf',
      },
      {
        title: 'My other document',
        downloadURL: 'https://cdn.getpopsure.com/document.pdf',
      },
    ],
    claims: [],
  };

  return (
    <>
      <ErrorBoundary
        fallbackRender={() => (
          <ErrorWithAction
            title="Questionnaire data error"
            description="The questionnaire answers do not match the questions. Some questions have changed"
            cta={{
              title: 'Reset data',
              onClick: resetData,
            }}
          />
        )}
      >
        <GeneralClaimsQuestionnaire
          data={interpolatedJSON}
          verticalId={verticalId}
          onQuestionnaireAnswersChanged={setAnswers}
          policy={policy}
          isPreview
        />
      </ErrorBoundary>
    </>
  );
};
