import { useCallback, useEffect, useState } from 'react';

import { QuestionnaireJSON } from '../model';

export type LoadStatus = 'IDLE' | 'PROCESSING' | 'COMPLETED';

const strapiHeaders = {
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_STRAPI_TOKEN}`,
  },
};

export const useFetchSharedConfig = (
  verticalId: string
): {
  sharedConfig?: Record<string, unknown>;
  sharedConfigErrorMessage?: string;
} => {
  const [sharedConfig, setSharedConfig] = useState<Record<string, unknown>>();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const fetchSharedConfig = useCallback(async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_STRAPI_URL}/shared-config?populate=deep`,
        strapiHeaders
      );

      if (!response.ok) {
        const erroredResponse = await response.json();
        throw new Error(
          erroredResponse.error.message ||
            'Failed to fetch shared config from Strapi'
        );
      }

      const sharedConfigData = await response.json();

      if (!sharedConfigData) {
        setSharedConfig({});
        throw new Error('Failed to get shared config from Strapi');
      }

      setSharedConfig(sharedConfigData);
    } catch (error) {
      if (error instanceof Error) {
        setErrorMessage(error.message);
      }
      setSharedConfig({});
    }
  }, []);

  useEffect(() => {
    fetchSharedConfig();
  }, [verticalId, fetchSharedConfig]);

  return {
    sharedConfig,
    sharedConfigErrorMessage: errorMessage,
  };
};

export const useFetchQuestionnaire = (
  verticalId: string
): {
  json?: QuestionnaireJSON | false;
  questionnaireErrorMessage?: string;
  loading: LoadStatus;
} => {
  const [json, setJson] = useState<QuestionnaireJSON | false>();
  const [loading, setLoading] = useState<LoadStatus>('IDLE');
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const fetchQuestionnaire = useCallback(async () => {
    setLoading('PROCESSING');

    try {
      const response = await fetch(
        `${process.env.REACT_APP_STRAPI_URL}/questionnaires/${verticalId}?populate=deep`,
        strapiHeaders
      );

      if (!response.ok) {
        const erroredResponse = await response.json();
        throw new Error(
          erroredResponse.error.message ||
            'Failed to fetch questionnaire from Strapi'
        );
      }

      const questionnaire = await response.json();

      if (!questionnaire.meta || !questionnaire.questions) {
        setJson(false);
        throw new Error(
          'Fetched Strapi questionnaire is missing "meta" or "questions" field'
        );
      }

      setJson({
        ...questionnaire,
        removeAnswersLogic: {},
      });
    } catch (error) {
      if (error instanceof Error) {
        setErrorMessage(error.message);
      }
      setJson(false);
    }

    setLoading('COMPLETED');
  }, [verticalId]);

  useEffect(() => {
    fetchQuestionnaire();
  }, [verticalId, fetchQuestionnaire]);

  return {
    json,
    questionnaireErrorMessage: errorMessage,
    loading,
  };
};

export const useRubiksDebugger = () => {
  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log(
      `
     ____  _   _ ____ ___ _  ______
    |  _ \\| | | | __ )_ _| |/ / ___|
    | |_) | | | |  _ \\| || ' /\\___ \\
    |  _ <| |_| | |_) | || . \\ ___) |
    |_| \\_ \\___/|____/___|_|\\_\\____/
    
     Move fast and debug things
          `
    );
  }, []);
};
