import {
  AddOn,
  Tariff,
  tariffLongNameMapping,
} from '@getpopsure/private-health-insurance-pricing-engine';
import { capitalizeName } from '@getpopsure/public-utility';
import routes from 'constants/routes';
import dayjs from 'dayjs';
import { getPolicyStatus } from 'features/policiesDashboard/utils/getPolicyStatus';
import { DigitalWalletsButton } from 'features/policySingle/components/buttons/DigitalWalletsButton';
import { AddToWalletModal } from 'features/policySingle/components/modals/AddToWalletModal';
import {
  IbanPaymentMethod,
  IbanPaymentMethods,
} from 'features/taskEngine/modules/PrivateHealthIbanUpdate/constants';
import {
  bannerColorMapping,
  getTitleMapping,
} from 'models/insurances/types/mapping';
import { generatePath } from 'react-router-dom';

import { CancelPolicyButton, ContactUsButton } from '../../components/buttons';
import {
  DelinquencyBlockerCancelPolicyModal,
  DelinquencyBlockerCreateClaimModal,
  PolicySingleModal,
} from '../../components/modals';
import { DATE_FORMAT } from '../../constants';
import {
  BillingType,
  GetPolicyConfigArgs,
  PolicyConfig,
  PolicySingleSection,
} from '../../PolicySingle.models';
import { getPolicyDocuments } from '../../sections/PolicyDocuments/utils/getPolicyDocuments';
import { PrivateHealthAddDependentsButton } from './buttons/PrivateHealthAddDependentsButton';
import { PrivateHealthChangePaymentMethodButton } from './buttons/PrivateHealthChangePaymentMethodButton';
import { getCoverageEntries } from './getCoverageItems';
import { getCoverageOverviewURL } from './getCoverageOverviewURL';
import { PrivateHealthAddDependentsModal } from './modals/PrivateHealthAddDependentsModal';
import { PrivateHealthCancelPolicyModal } from './modals/PrivateHealthCancelPolicyModal';
import { PrivateHealthChangePaymentMethodModal } from './modals/PrivateHealthChangePaymentMethodModal';
import { VideoDoctorAppointment } from './sections/VideoDoctorAppointment';

export const isIbanPaymentMethod = (
  value: unknown
): value is IbanPaymentMethod => {
  return IbanPaymentMethods.includes(value as IbanPaymentMethod);
};

export const getPrivateHealthConfig = (
  args: GetPolicyConfigArgs
): PolicyConfig => {
  const [policy, t] = args;
  const {
    insuredPerson,
    price,
    billingType,
    policyNumber,
    startDate,
    planName,
  } = policy.attributes;

  const title = getTitleMapping(t).PRIVATE_HEALTH;
  const capitalizedName = insuredPerson?.name
    ? capitalizeName(insuredPerson.name)
    : '';
  const subtitle = capitalizedName || '';
  const policyStatus = getPolicyStatus(policy, true);

  const header: PolicyConfig['header'] = {
    title,
    subtitle,
    backgroundColor: bannerColorMapping.PRIVATE_HEALTH,
    insuranceType: policy.type,
    policyStatus,
  };

  if (price) {
    header.price = {
      amount: parseFloat(price),
      billingType: billingType as BillingType,
    };
  }

  const showWalletButton = policy.status === 'ACTIVE' && policyNumber;

  // Remove provider check if logic gets complex
  const isProviderHallesche = policy.providerId === 'HALLESCHE';

  /**
   * Do not show the IBAN field if it's one of the labels
   * used by the PrivateHealthIbanUpdate task
   */
  const iban = isIbanPaymentMethod(policy.attributes.iban)
    ? null
    : policy.attributes.iban;

  const claimsSection: PolicySingleSection[] = [
    {
      id: 'POLICY_CLAIMS',
      props: {
        claims: policy.claims,
        route: generatePath(routes.claims.privateHealth.path, {
          policyId: policy.id,
        }),
        insuranceType: 'PRIVATE_HEALTH',
        activeUntil:
          policy.status === 'ACTIVE'
            ? undefined
            : policy.attributes.activeUntil,
        policyStatus: policy.status,
        screen: {
          button: {
            dataCy: 'me-policies-detail-make-a-claim',
            title: t(
              'myPolicies.policyDetails.privateHealth.claimsButton.caption',
              'New claim or cost plan'
            ),
          },
        },
      },
    },
  ];

  const claimAssessmentsSection: PolicySingleSection[] = [
    {
      id: 'POLICY_APVS',
      props: {
        claims: policy.claims,
      },
    },
  ];

  const sections: PolicyConfig['sections'] = [
    {
      id: 'POLICY_DETAILS',
      props: {
        policyType: policy.type,
        info: [
          {
            title: t('myPolicies.policyDetails.policyNumber', 'Policy number'),
            value: policyNumber,
          },
          {
            title: t(
              'myPolicies.policyDetails.insuredPerson',
              'Insured person'
            ),
            value: insuredPerson?.name && capitalizeName(insuredPerson.name),
          },
          {
            title: t('myPolicies.policyDetails.startDate', 'Start date'),
            value: startDate && dayjs(startDate).format(DATE_FORMAT),
          },
          {
            title: t('myPolicies.policyDetails.private.plan.title', 'Plan'),
            value: planName && tariffLongNameMapping[planName as Tariff],
          },
          {
            title: t('myPolicies.policyDetails.iban', 'IBAN'),
            value: iban,
          },
        ],
        buttons: [
          ...(showWalletButton && isProviderHallesche
            ? [
                {
                  id: 'DIGITAL_WALLETS',
                  component: DigitalWalletsButton,
                },
              ]
            : []),
          {
            id: 'CONTACT_US',
            component: ContactUsButton,
          },
          ...(policy.status === 'ACTIVE' && isProviderHallesche
            ? [
                {
                  id: 'ADD_DEPENDENTS',
                  component: PrivateHealthAddDependentsButton,
                },
                {
                  id: 'CHANGE_PAYMENT_METHOD',
                  component: PrivateHealthChangePaymentMethodButton,
                },
              ]
            : []),
          ...(policy.status !== 'CANCELED' && isProviderHallesche
            ? [
                {
                  id: 'CANCEL_POLICY',
                  component: CancelPolicyButton,
                },
              ]
            : []),
        ],
      },
    },
    ...(isProviderHallesche && policy.attributes.policyNumber
      ? [
          {
            id: 'POLICY_GENERIC_SECTION' as const,
            component: VideoDoctorAppointment,
          },
        ]
      : []),
    {
      id: 'POLICY_COVERAGE',
      props: {
        url: getCoverageOverviewURL(
          planName as Tariff,
          policy.attributes.addons as AddOn[]
        ),
        entries: getCoverageEntries(
          t,
          planName,
          policy.attributes.addons as AddOn[]
        ),
      },
    },
    ...(isProviderHallesche ? claimsSection : []),
    ...(isProviderHallesche ? claimAssessmentsSection : []),
    {
      id: 'POLICY_DOCUMENTS',
      props: {
        documents: getPolicyDocuments(policy),
      },
    },
  ];

  const modals: PolicySingleModal[] = [
    {
      id: 'DELINQUENT_BLOCKER_CANCEL_POLICY',
      component: DelinquencyBlockerCancelPolicyModal,
    },
    {
      id: 'DELINQUENT_BLOCKER_CREATE_CLAIM',
      component: DelinquencyBlockerCreateClaimModal,
    },
    {
      id: 'CANCEL_POLICY',
      component: PrivateHealthCancelPolicyModal,
    },
    {
      id: 'PRIVATE_HEALTH_ADD_DEPENDENTS',
      component: PrivateHealthAddDependentsModal,
    },
    {
      id: 'PRIVATE_HEALTH_CHANGE_PAYMENT_METHOD',
      component: PrivateHealthChangePaymentMethodModal,
    },
    {
      id: 'ADD_TO_WALLET',
      component: AddToWalletModal,
    },
  ];

  return {
    header,
    sections,
    modals,
  };
};
