import * as Sentry from '@sentry/react';
import { useMutation } from '@tanstack/react-query';
import { flushPrivateHealthClaimsAnswers } from 'features/privateHealthClaims/actions';
import {
  PrivateHealthClaims,
  PrivateHealthClaimsDispatch,
  PrivateHealthClaimsSubmitInfo,
  ZPrivateHealthClaimsSubmissionSchema,
} from 'features/privateHealthClaims/models';
import { getPrivateHealthClaims } from 'features/privateHealthClaims/selectors';
import { CustomComponentProps } from 'models/questionnaireFramework';
import { FormEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import endpoint from 'shared/api';
import { useSafeTranslation } from 'shared/i18n';

import { triggerPushNotifications } from '../../../../shared/pushNotifications';
import { privateHealthClaimsRoutes } from '../../privateHealthClaims.routes';
import { PrivateHealthClaimsAmountView } from './Amount.view';

const MIN_AMOUNT = 0.01;

export const PrivateHealthClaimsAmount = ({
  value,
  metaData: { policyId },
}: CustomComponentProps<PrivateHealthClaims>) => {
  const { t } = useSafeTranslation();
  const history = useHistory();

  const [amount, setAmount] = useState<number | undefined>(
    (value as number) ?? undefined
  );

  const dispatch = useDispatch<PrivateHealthClaimsDispatch>();

  const questionnaire = useSelector(getPrivateHealthClaims);

  const {
    mutate: submitClaim,
    isLoading,
    error,
  } = useMutation({
    mutationFn: (answers: PrivateHealthClaimsSubmitInfo) =>
      endpoint.submitClaim(answers),
  });

  const isSubmissionValid =
    Boolean(amount) && Number(amount) >= MIN_AMOUNT && !isLoading;

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    const validation = ZPrivateHealthClaimsSubmissionSchema.safeParse({
      ...questionnaire,
      amount,
    });

    if (!validation.success) {
      throw new Error(
        `[Private Health Claims] Validation error for policy number: ${policyId} - ${String(
          validation.error
        )}`
      );
    }

    const {
      claimTypes,
      uploadedDocumentTokens,
      amount: validatedClaimAmount,
      year,
    } = validation.data;

    const submittableClaimTypes = claimTypes.map((type) => ({
      claimType: type,
    }));
    const tokens = uploadedDocumentTokens.flatMap(({ token }) =>
      token !== undefined ? [token] : []
    );

    const submittableInfo: PrivateHealthClaimsSubmitInfo = {
      type: 'PRIVATE_HEALTH',
      userPolicyId: policyId,
      uploadedDocumentTokens: tokens,
      claimTypes: submittableClaimTypes,
      amount: validatedClaimAmount,
      year,
    };

    submitClaim(submittableInfo, {
      onSuccess: () => {
        triggerPushNotifications('PRIVATE_HEALTH');
        history.replace(
          generatePath(privateHealthClaimsRoutes.submitted.path, { policyId })
        );
        dispatch(flushPrivateHealthClaimsAnswers());
      },
      onError: (err) => {
        Sentry.captureException(err, {
          tags: {
            feature: 'CLAIMS',
            vertical: 'PRIVATE_HEALTH',
          },
          extra: {
            policyId,
            questionnaire,
            description:
              'Error happened while submitting Private Health claims',
          },
        });
      },
    });
  };

  return (
    <PrivateHealthClaimsAmountView
      handleSubmit={handleSubmit}
      t={t}
      loading={isLoading}
      error={error}
      amount={amount}
      setAmount={setAmount}
      validForSubmission={isSubmissionValid}
      minAmount={MIN_AMOUNT}
    />
  );
};
