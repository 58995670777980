import { toast } from '@popsure/dirty-swan';
import { useQuery } from '@tanstack/react-query';
import { flushGenericQuestionnaire } from 'actions/genericQuestionnaire';
import LoadingSpinner from 'components/loadingSpinner';
import routes from 'constants/routes';
import { getQuestionnaire } from 'features/paymentScreen/components/PolicyDetails/PolicyDetails';
import { getCheckoutInfo } from 'features/paymentScreen/paymentScreen.api';
import { Checkout } from 'features/paymentScreen/paymentScreen.models';
import { getReferralCode } from 'features/paymentScreen/paymentScreen.selectors';
import { CheckoutDispatch } from 'features/paymentScreen/paymentScreen.thunks';
import { redirectSuccessfulCheckout } from 'features/paymentScreen/utils/paymentMethod.utils';
import { mapVerticalId } from 'features/paymentScreen/utils/verticalId.utils';
import { clearReferrerCode } from 'features/referralEngine/actions';
import { useQueryParamValue } from 'hooks/useQueryParamValue';
import lz from 'lz-string';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory, useParams } from 'react-router';
import api from 'shared/api';
import { useSafeTranslation } from 'shared/i18n';
import { trackConversions } from 'shared/trackers';
/**
 * The customer lands here after confirming a new payment on
 * a payment provider's page.
 */

export const getCheckout = async (
  checkoutId?: string
): Promise<Checkout | undefined> => {
  if (!checkoutId) return undefined;
  const { data } = await getCheckoutInfo(api.network, checkoutId);
  return data;
};

const ConfirmPaymentRedirectGateway = () => {
  const { t } = useSafeTranslation();

  const { checkoutId, localeId }: { checkoutId: string; localeId: string } =
    useParams();
  const redirectStatus = useQueryParamValue('redirect_status');
  const paymentMethodId = useQueryParamValue('paymentMethodId');
  const referralCode = useSelector(getReferralCode);

  const dispatch = useDispatch<CheckoutDispatch>();

  const history = useHistory();
  const isPaymentSuccessful = redirectStatus === 'succeeded';

  const configurationParam = useQueryParamValue('c');
  const decompressedConfiguration = configurationParam
    ? lz.decompressFromEncodedURIComponent(configurationParam)
    : undefined;
  const configuration = decompressedConfiguration
    ? JSON.parse(decompressedConfiguration)
    : undefined;

  if (!configuration) {
    throw Error('Missing payment screen configuration');
  }

  const { data: checkoutInfo } = useQuery({
    queryKey: ['genericCheckoutInfo'],
    queryFn: () => getCheckout(checkoutId),
    enabled: !!checkoutId,
    refetchOnWindowFocus: false,
  });

  const { data: questionnaire } = useQuery({
    queryKey: ['genericCheckoutQuestionnaire'],
    queryFn: () => getQuestionnaire(checkoutInfo?.policyInfo.questionnaireId),
    enabled: !!checkoutInfo?.policyInfo.questionnaireId,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    const processPostConfirmPayment = async () => {
      if (!paymentMethodId) {
        throw Error('[Generic checkout] Missing payment method id');
      }

      if (!isPaymentSuccessful) {
        toast(
          t(
            'paymentScreen.errors.errorConfirmingCheckout',
            'The checkout was not successful'
          ),
          {
            type: 'error',
            description: t(
              'paymentScreen.errors.errorConfirmingCheckoutDescription',
              'Please, try again with a different payment method or contact customer support.'
            ),
            duration: 5000,
          }
        );

        const baseUrl = localeId
          ? generatePath(routes.paymentScreenRegionalised.pay.path, {
              checkoutId,
              localeId,
            })
          : generatePath(routes.paymentScreen.pay.path, {
              checkoutId,
            });

        history.replace(
          `${baseUrl}?paymentMethodId=${paymentMethodId}&c=${configurationParam}`
        );
      }

      if (isPaymentSuccessful && checkoutInfo) {
        const { policyDetails, id } = checkoutInfo.policies[0];
        const { regionOfPurchase } = checkoutInfo.policyInfo;

        trackConversions({
          verticalId: policyDetails.type,
          policyId: id,
          regionOfPurchase: regionOfPurchase ?? 'de',
        });

        if (checkoutInfo.policyInfo.questionnaireId && !questionnaire) return;

        const redirectPolicyId = configuration.redirectAddDependent
          ? id
          : undefined;

        const verticalStateId = mapVerticalId[policyDetails.type];

        await dispatch(clearReferrerCode());

        if (verticalStateId) {
          dispatch(flushGenericQuestionnaire(verticalStateId));
        }
        redirectSuccessfulCheckout(
          verticalStateId ?? undefined,
          redirectPolicyId,
          regionOfPurchase
        );
      }
    };
    processPostConfirmPayment();
  }, [
    checkoutId,
    dispatch,
    history,
    localeId,
    paymentMethodId,
    redirectStatus,
    referralCode,
    isPaymentSuccessful,
    t,
    questionnaire,
    checkoutInfo,
    configuration,
    configurationParam,
  ]);

  return <LoadingSpinner />;
};

export default ConfirmPaymentRedirectGateway;
