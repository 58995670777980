export const storeLocalStorageValue = ({
  key,
  value,
}: {
  key: string;
  value: unknown;
}) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const flushLocalStorageValue = ({ key }: { key: string }) => {
  localStorage.removeItem(key);
};

export const fetchLocalStorageValue = ({ key }: { key: string }) => {
  try {
    const value = localStorage.getItem(key);

    if (value) {
      return JSON.parse(value as string);
    }
    return {};
  } catch (err) {
    // eslint-disable-next-line no-console
    console.warn(err);
    return {};
  }
};
