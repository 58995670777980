/* eslint-disable import/no-cycle */
import { setRequestErrored, setRequestInProcess } from 'actions/request';
import { RootReducerAction } from 'constants/actions';
import { shutdownIntercom } from 'features/intercomLauncher/utils/shutdownIntercom';
import { APIResponseError } from 'models/error';
import { AppDispatch, AppState } from 'reducers';
import endpoint from 'shared/api';
import history from 'shared/browserHistory';

export function clearReducerState(
  preserving: Array<keyof AppState>
): RootReducerAction {
  return {
    type: 'CLEAR_REDUCER_STATE',
    preserving,
  };
}

export const signOut = () => async (dispatch: AppDispatch) => {
  const requestType = 'SIGN_OUT';
  dispatch(setRequestInProcess(true, requestType));
  try {
    await endpoint.signOutUser();
    dispatch(clearReducerState([]));
    shutdownIntercom();
    history.push('/account/signin');
    dispatch(setRequestInProcess(false, requestType));
  } catch (error) {
    dispatch(setRequestErrored(error as APIResponseError, requestType));
  }
};
