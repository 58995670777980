import { calculateLegalPricing } from '@getpopsure/legal-insurance-pricing-engine';
import {
  getRoutes,
  QuestionnaireQuestions,
  stateManagerHelper,
} from '@getpopsure/qnr-framework';
import { useFlag } from '@unleash/proxy-client-react';
import {
  removeGenericQuestionnaireAnswer,
  storeGenericQuestionnaireAnswer,
} from 'actions/genericQuestionnaire';
import routes from 'constants/routes';
import { useCheckoutStarted } from 'hooks/useCheckoutStarted';
import { UserWithBrokerMandate } from 'models/user';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useHistory } from 'react-router-dom';
import { AppState } from 'reducers';
import { getAccountInfo } from 'selectors/user';
import { useSafeTranslation } from 'shared/i18n';
import { isUnder18 } from 'shared/util/isUnder18';
import { SignupQuestionnaire } from 'SignupQuestionnaire';

import { Legal } from './models';
import { getTranslatedQuestionnaire, LegalComponents } from './questionnaire';
import { getRules, removeAnswersLogic } from './questionnaire/rules';

const prefillName = <
  Questionnaire extends QuestionnaireQuestions,
  Key extends keyof Questionnaire
>(
  questionnaireAnswers: Partial<Questionnaire>,
  questionId: Key,
  answer: Questionnaire[Key],
  account?: UserWithBrokerMandate
) => {
  if (
    questionId === 'email' &&
    account &&
    account.email === answer &&
    questionnaireAnswers.email !== answer
  ) {
    let toUpdate: Partial<Legal> = {};
    const { firstName, lastName } = account;

    if (firstName && lastName) {
      toUpdate = { ...toUpdate, name: { firstName, lastName } };
    }
    return toUpdate;
  }

  return null;
};

export const SignupPage = () => {
  const questionnaireAnswers =
    useSelector((state: AppState) => state.genericQuestionnaire.legal) || {};
  const dispatch = useDispatch();
  const account = useSelector(getAccountInfo);
  const { t } = useSafeTranslation();
  const history = useHistory();
  const useNewCheckout = useFlag('app_checkout_revamp_legal_v2');

  const onAnswer = <QuestionId extends keyof Legal>(
    questionId: QuestionId,
    answer: Legal[QuestionId],
    additionalData?: Partial<Legal>
  ) => {
    dispatch(
      storeGenericQuestionnaireAnswer('legal', { [questionId]: answer })
    );

    const answersToRemove = stateManagerHelper(
      removeAnswersLogic,
      questionnaire.components,
      questionnaireAnswers,
      questionnaire.rules
    ).getAnswersToRemove(questionId, answer);

    dispatch(removeGenericQuestionnaireAnswer('legal', answersToRemove));

    const toUpdate = prefillName(
      questionnaireAnswers,
      questionId,
      answer,
      account
    );

    if (['quote', 'review'].includes(questionId) && additionalData) {
      dispatch(storeGenericQuestionnaireAnswer('legal', additionalData));
    }

    if (toUpdate) {
      dispatch(storeGenericQuestionnaireAnswer('legal', toUpdate));
    }
  };

  const resetAddon = () => {
    dispatch(
      storeGenericQuestionnaireAnswer('legal', {
        quote: {
          ...questionnaireAnswers.quote,
          addons: questionnaireAnswers.quote?.addons?.filter(
            (addon) => addon !== 'CRIMINAL'
          ),
        },
        hasCriminalConvictions: false,
        hasPendingCriminalInvestigation: false,
        hasCriminalConvictionsBlocker: true,
        hasPendingCriminalInvestigationBlocker: true,
      })
    );
    history.push(legalRoutes.email.path);
  };

  const { totalPremium } = isUnder18(questionnaireAnswers.dateOfBirth)
    ? { totalPremium: 0 }
    : calculateLegalPricing({
        dateOfBirth: questionnaireAnswers.dateOfBirth ?? '',
        familyStatus: questionnaireAnswers?.includeSpouse ? 'FAMILY' : 'SINGLE',
        plan: questionnaireAnswers?.quote?.plan ?? 'BASIC',
        postcode: questionnaireAnswers.postcode ?? '',
        profession:
          questionnaireAnswers.employmentStatus ?? 'UNKNOWN_PROFESSION',
        addons:
          questionnaireAnswers?.quote?.addons?.filter(
            (addon) => addon !== 'CRIMINAL'
          ) ?? [],
      });

  const questions = getTranslatedQuestionnaire(
    t,
    resetAddon,
    totalPremium,
    useNewCheckout
  );

  const legalRoutes = getRoutes(questions, routes.policies.legal.path);

  const rules = getRules();

  const questionnaire = {
    components: questions,
    routes: legalRoutes,
    rules,
  };

  useCheckoutStarted('LEGAL_V2', questionnaireAnswers.email);

  return (
    <Switch>
      <Route path={routes.policies.legal.questionnaire.path}>
        <SignupQuestionnaire
          questionnaireAnswers={questionnaireAnswers}
          questionnaire={questionnaire}
          onAnswer={onAnswer}
          configuration={{
            components: LegalComponents,
          }}
          basePath={routes.policies.legal.path}
          questionId="intro"
          featureName="Legal"
          share={{
            verticalId: 'legal',
          }}
        />
      </Route>
    </Switch>
  );
};
