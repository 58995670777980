import { useMutation } from '@tanstack/react-query';
import endpoint from 'shared/api';

import { healthCardMutationErrorHandler } from '../utils/healthCardMutationErrorHandler';

export const useMarkCardAsReceived = () => {
  return useMutation({
    mutationFn: (policyId: string) => {
      return endpoint.markHealthCardAsReceived(policyId);
    },
    onError: (error, policyId) =>
      healthCardMutationErrorHandler(error, { policyId }),
  });
};
