import { language } from '@getpopsure/i18n-react';
import { website } from '@getpopsure/private-constants';
import Session from '@getpopsure/session';
import classNames from 'classnames';
import LanguageSwitcher from 'components/LanguageSwitcher';
import routes from 'constants/routes';
import { selectedCategoryForPath } from 'features/navigationBar/utlis/selectedCategoryForPath';
import { isSignupPath } from 'features/navigationBar/utlis/signup';
import { LocationState } from 'history';
import { useRegion } from 'hooks/useRegion';
import { useLocation } from 'react-router-dom';
import { getFeatureByRegion } from 'routeLocales';

import NavigationBarButton from '../button';
import logo from './img/logo-desktop.svg';
import styles from './style.module.scss';

const DesktopNavigationBar = ({ showLoggedIn }: { showLoggedIn?: boolean }) => {
  const location = useLocation<LocationState>();
  const selectedCategory = selectedCategoryForPath(location.pathname);
  const { isAuthenticated } = Session;
  const { region } = useRegion();
  const locale = language();

  const hasAccessToFeature = getFeatureByRegion(region);

  const isSignup = isSignupPath(location.pathname);
  const isCheckout = location.pathname.startsWith(routes.paymentScreen.path);
  const showLoggedInView =
    showLoggedIn || (isAuthenticated && !isSignup && !isCheckout);

  return (
    <div className={styles.container}>
      <div
        className={classNames(styles.content, {
          [styles.contentLoggedIn]: !!showLoggedInView,
          [styles.contentMaxWidth]: !isCheckout,
        })}
      >
        <a href={`${website.base}/${locale}-${region}`} className={styles.logo}>
          <img src={logo} className="d-block" alt="Home" />
        </a>
        {showLoggedInView ? (
          <div className={styles['button-container']}>
            {hasAccessToFeature('policies') && (
              <NavigationBarButton
                title="Policies"
                category="policies"
                link={routes.me.policies.path}
                selected={selectedCategory === 'policies'}
                data-cy="navigationbar-policies"
              />
            )}
            {hasAccessToFeature('recommendationTool') && (
              <NavigationBarButton
                title="Advice"
                category="advice"
                link={routes.insuranceTool.path}
                selected={selectedCategory === 'advice'}
                data-cy="navigationbar-recommendations"
              />
            )}
            {hasAccessToFeature('claims') && (
              <NavigationBarButton
                title="Claims"
                category="claim"
                link={routes.claims.path}
                selected={selectedCategory === 'claim'}
                data-cy="navigationbar-claim"
              />
            )}
            {hasAccessToFeature('account') && (
              <NavigationBarButton
                title="Account"
                category="account"
                link={routes.account.path}
                selected={selectedCategory === 'account'}
              />
            )}
          </div>
        ) : (
          <>
            {isSignup || isCheckout ? (
              <LanguageSwitcher type="dropdown" />
            ) : (
              <NavigationBarButton
                title="Log in"
                category="login"
                link={routes.account.signIn.path}
                selected={selectedCategory === 'login'}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default DesktopNavigationBar;
