import { Button, ChevronUpIcon } from '@popsure/dirty-swan';
import * as Sentry from '@sentry/react';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { getClaimTypeMapping } from 'features/claimsV2/utils';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { Claim } from 'models/claims';
import { type FormEvent, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { TFunction } from 'shared/i18n';
import { isValidIban } from 'shared/util/isValidIban';

import styles from './ClaimDetail.module.scss';
import {
  PayoutDetailsSchema,
  useUpdateClaimPayout,
} from './hooks/useUpdateClaimPayout';
import checkedIcon from './icons/checked.svg';
import { ClaimAmountUpdateModal } from './modals/ClaimAmountUpdate';
import { ClaimIbanUpdateModal } from './modals/ClaimIbanUpdate';

export const ClaimDescription = ({
  claim,
  t,
  isClaimSubmittedOpen,
  setIsClaimSubmittedOpen,
}: {
  claim: Claim;
  t: TFunction;
  isClaimSubmittedOpen: boolean;
  setIsClaimSubmittedOpen: (open: boolean) => void;
}) => {
  const [isAmountUpdateModalOpen, setIsAmountUpdateModalOpen] = useState(false);
  const [isIbanUpdateModalOpen, setIsIbanUpdateModalOpen] = useState(false);
  const [isIbanError, setIsIbanError] = useState(false);
  const isTabletOrDesktop = useMediaQuery('ABOVE_TABLET');

  const { claimTypes, insuranceType, details, iban, dateOfEvent, thirdParty } =
    claim;
  const hasClaimTypes = claimTypes && claimTypes.length > 0;

  const claimDisplayType = hasClaimTypes
    ? claimTypes
        .map(({ claimType }) =>
          getClaimTypeMapping(t, { insuranceType, claimType })
        )
        .join(', ')
    : null;
  const claimDisplayAmount = claim.amount;
  const claimAmountExistAndIsGreaterThanZero =
    claimDisplayAmount && claimDisplayAmount > 0;

  const { mutate: updateClaimPayout, isLoading } = useUpdateClaimPayout();

  const onSubmitPayoutDetails = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formEntries = new FormData(e.currentTarget).entries();

    const data = {
      claimId: claim.id,
      payload: {
        ...Object.fromEntries(formEntries),
      },
    };

    const parsedClaimUpdate = PayoutDetailsSchema.safeParse(data);

    if (!parsedClaimUpdate.success) {
      Sentry.captureMessage(
        'Claim descriptions: Failed to parse claim update details payload',
        {
          level: 'error',
          extra: {
            parsedClaimUpdate,
            validationErrors: parsedClaimUpdate.error,
          },
        }
      );
      return;
    }

    const { payload, claimId } = parsedClaimUpdate.data;

    if (payload.iban && !isValidIban(payload.iban)) {
      setIsIbanError(true);
      return;
    }

    updateClaimPayout({ claimId, payload });

    setIsAmountUpdateModalOpen(false);
    setIsIbanUpdateModalOpen(false);
  };

  const isPayoutUpdateButtonVisible =
    ['INCOMING', 'DENTAL', 'BIKE'].includes(insuranceType) &&
    ['SUBMITTED', 'PROCESSING', 'ACTION_NEEDED', 'INFO_RECEIVED'].includes(
      claim.status
    );

  return (
    <div className="ds-card ds-card--no-dropshadow mt24 p24 gap24">
      <button
        type="button"
        className="bg-white c-pointer d-flex df-row ai-start w100"
        onClick={() => setIsClaimSubmittedOpen(!isClaimSubmittedOpen)}
      >
        <span className={classNames('ai-center mr16', styles.row)}>
          <img src={checkedIcon} alt="Checked" />
        </span>
        <span className={classNames('p-h3 w100 jc-between', styles.row)}>
          {t('claims.detail.claimsSubmitted.title', 'Claims submitted')}

          <ChevronUpIcon
            className={
              isClaimSubmittedOpen ? styles.chevronOpen : styles.chevronClosed
            }
            color="primary-500"
            size={24}
            noMargin
          />
        </span>
      </button>
      <div className="d-flex fd-column w100">
        <AnimateHeight
          duration={300}
          height={isClaimSubmittedOpen ? 'auto' : 0}
        >
          {claimDisplayType && (
            <div className="d-flex d-row mt24 ai-center">
              <div className="w30 mr24 p-p tc-grey-500">
                {t('claims.detail.claimType', 'Claim type')}
              </div>
              <div className="w70 p-p">{claimDisplayType}</div>
            </div>
          )}
          {dateOfEvent && (
            <div className="d-flex d-row mt24 ai-center">
              <div className="w30 mr24 p-p tc-grey-500">
                {t('claims.detail.eventDate', 'Event date')}
              </div>
              <div className="w70 p-p">
                {dayjs(dateOfEvent).format('DD MMM YYYY')}
              </div>
            </div>
          )}
          {!['INCOMING', 'DENTAL'].includes(insuranceType) && details && (
            <div className="d-flex d-row mt24 ai-center">
              <div className="w30 mr24 p-p tc-grey-500">
                {t('claims.detail.eventDescription', 'Event description')}
              </div>
              <div className="w70 p-p">{details}</div>
            </div>
          )}
          {(claimAmountExistAndIsGreaterThanZero || iban || thirdParty) && (
            <div
              className={classNames(
                'bg-grey-200 my32',
                styles['vertical-line']
              )}
            />
          )}
          {claimAmountExistAndIsGreaterThanZero && (
            <div
              className={classNames(
                'd-flex d-row mt24',
                isPayoutUpdateButtonVisible
                  ? styles.claimPayoutSection
                  : 'd-flex fd-row gap24 jc-center'
              )}
            >
              <div
                className={classNames(
                  'p-p tc-grey-500',
                  isPayoutUpdateButtonVisible && styles.claimPayoutHeader
                )}
              >
                <span>
                  {t('claims.detail.claimedAmount', 'Claimed amount')}
                </span>
                {!isTabletOrDesktop && isPayoutUpdateButtonVisible && (
                  <Button
                    type="button"
                    variant="textColor"
                    onClick={() => setIsAmountUpdateModalOpen(true)}
                  >
                    {t('claims.detail.buttonChange', 'Change')}
                  </Button>
                )}
              </div>
              <div className="w70 p-p">
                <span>€{claimDisplayAmount}</span>
                {isTabletOrDesktop && isPayoutUpdateButtonVisible && (
                  <Button
                    type="button"
                    variant="textColor"
                    className="ml8"
                    onClick={() => setIsAmountUpdateModalOpen(true)}
                  >
                    {t('claims.detail.buttonChange', 'Change')}
                  </Button>
                )}
              </div>

              <ClaimAmountUpdateModal
                isOpen={isAmountUpdateModalOpen}
                payoutAmount={claimDisplayAmount}
                isLoading={isLoading}
                onSubmit={onSubmitPayoutDetails}
                onClose={() => setIsAmountUpdateModalOpen(false)}
              />
            </div>
          )}
          {iban && (
            <div
              className={classNames(
                'd-flex d-row mt24',
                isPayoutUpdateButtonVisible
                  ? styles.claimPayoutSection
                  : 'd-flex fd-row gap24 jc-center'
              )}
            >
              <div
                className={classNames(
                  'p-p tc-grey-500',
                  isPayoutUpdateButtonVisible && styles.claimPayoutHeader
                )}
              >
                <span>
                  {t('claims.detail.ibanForPayout', 'IBAN for payout')}
                </span>
                {!isTabletOrDesktop && isPayoutUpdateButtonVisible && (
                  <Button
                    type="button"
                    variant="textColor"
                    onClick={() => setIsIbanUpdateModalOpen(true)}
                  >
                    {t('claims.detail.buttonChange', 'Change')}
                  </Button>
                )}
              </div>
              <div className="w70 p-p">
                <span>{iban}</span>
                {isTabletOrDesktop && isPayoutUpdateButtonVisible && (
                  <Button
                    type="button"
                    variant="textColor"
                    className="ml8"
                    onClick={() => setIsIbanUpdateModalOpen(true)}
                  >
                    {t('claims.detail.buttonChange', 'Change')}
                  </Button>
                )}
              </div>

              <ClaimIbanUpdateModal
                isOpen={isIbanUpdateModalOpen}
                payoutIban={iban}
                isLoading={isLoading}
                onSubmit={onSubmitPayoutDetails}
                isIbanError={isIbanError}
                onClose={() => setIsIbanUpdateModalOpen(false)}
              />
            </div>
          )}
          {thirdParty && (
            <>
              {thirdParty.name && (
                <div className="d-flex d-row mt24">
                  <div className="w30 mr24 p-p tc-grey-500">
                    {t(
                      'claims.detail.affectedParty.name',
                      "Affected party's name"
                    )}
                  </div>
                  <div className="w70 p-p">{thirdParty.name}</div>
                </div>
              )}
              {thirdParty.email && (
                <div className="d-flex d-row mt24">
                  <div className="w30 mr24 p-p tc-grey-500">
                    {t(
                      'claims.detail.affectedParty.email',
                      "Affected party's email"
                    )}
                  </div>
                  <div className="w70 p-p">{thirdParty.email}</div>
                </div>
              )}
              {thirdParty.address && (
                <div className="d-flex d-row mt24">
                  <div className="w30 mr24 p-p tc-grey-500">
                    {t(
                      'claims.detail.affectedParty.address',
                      "Affected party's address"
                    )}
                  </div>
                  <div className="w70 p-p">{`${thirdParty.address.street} ${thirdParty.address.houseNumber} ${thirdParty.address.postcode} ${thirdParty.address.city}`}</div>
                </div>
              )}
            </>
          )}
        </AnimateHeight>
      </div>
    </div>
  );
};
