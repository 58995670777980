import { QuestionnaireQuestions } from '@getpopsure/qnr-framework';
import { useEffect, useState } from 'react';
import {
  fetchLocalStorageValue,
  flushLocalStorageValue,
  storeLocalStorageValue,
} from 'shared/util/storage';

import { GeneralClaimAnswers } from './models';

export const useGeneralClaimsStorage = (
  verticalId: string
): {
  questionnaireAnswers: Partial<GeneralClaimAnswers>;
  setQuestionnaireAnswers: (answers: QuestionnaireQuestions) => void;
  removeQuestionnaireAnswers: () => void;
} => {
  const generalClaimKey = `${verticalId}_claim`;

  const [questionnaireAnswers, setQuestionnaireAnswers] = useState(() =>
    fetchLocalStorageValue({ key: generalClaimKey })
  );

  useEffect(() => {
    setQuestionnaireAnswers(fetchLocalStorageValue({ key: generalClaimKey }));
  }, [generalClaimKey]);

  const updateQuestionnaireAnswers = (answers: QuestionnaireQuestions) => {
    storeLocalStorageValue({ key: generalClaimKey, value: answers });
  };

  const removeQuestionnaireAnswers = () => {
    flushLocalStorageValue({ key: generalClaimKey });
  };

  useEffect(() => {
    updateQuestionnaireAnswers(questionnaireAnswers);

    return removeQuestionnaireAnswers;
    // eslint-disable-next-line
  }, [questionnaireAnswers]);

  return {
    questionnaireAnswers,
    setQuestionnaireAnswers,
    removeQuestionnaireAnswers,
  };
};
