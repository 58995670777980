import { Region, regions } from '@getpopsure/public-models';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { PolicyDetailsProps } from 'features/paymentScreen/components/PolicyDetailsNew/PolicyDetails';
import { PriceBreakdownProps } from 'features/paymentScreen/components/PriceBreakdown/PriceBreakdown';
import { CheckoutInfo } from 'features/paymentScreen/paymentScreen.models';
import { regionToNameMapping } from 'features/signIn/models';
import { TFunction } from 'shared/i18n';
import { capitalizeSingleWord } from 'shared/util/capitalizeSingleWord';
import { emailAddressValidator } from 'shared/util/emailValidator';
import englishFormattedEuroCurrency from 'shared/util/priceFormatter';
import { isName } from 'shared/validators';

dayjs.extend(customParseFormat);

const DATE_FORMAT = 'DD MMM YYYY';
type DataType =
  | 'CURRENCY'
  | 'YEARS'
  | 'ADDONS'
  | 'NEGATIVE_CURRENCY'
  | 'REGION';

export type PriceBreakdownConfiguration = {
  basePriceQuestionId?: string;
  priceBreakdown?: {
    id: string;
    label: string;
    questionId: string;
    discount?: boolean;
  }[];
};

type Detail = {
  label: string;
  questionId: string;
  dataType?: DataType;
  id: string;
};

export type PolicyDetailsConfiguration = {
  productCardDetails: Detail[];
  policyDetails: Detail[];
  additionalPolicyDetails?: {
    id: string;
    title: string;
    details: Detail[];
  }[];
};

const isDate = (answer: unknown): answer is Date => {
  return (
    typeof answer === 'string' && dayjs(answer, 'YYYY-MM-DD', true).isValid()
  );
};

const isAddonsObject = (answer: unknown): answer is Record<string, number> => {
  if (!answer) return false;
  if (typeof answer !== 'object') return false;
  for (const value of Object.values(answer)) {
    if (!value) return false;
    if (typeof value !== 'number') return false;
  }
  return true;
};

export const processPolicyDetailsQuestionValue = (
  answer: unknown,
  dataType?: DataType
) => {
  if (!answer) return '';

  if (isName(answer)) {
    return `${answer.firstName} ${answer.lastName}`;
  }

  if (typeof answer === 'string' && emailAddressValidator(answer)) {
    return answer;
  }

  if (dataType === 'ADDONS' && isAddonsObject(answer)) {
    const addons = Object.keys(answer).map((key) => {
      return `${capitalizeSingleWord(key)}: ${englishFormattedEuroCurrency(
        answer[key]
      )}`;
    });

    return addons.join(', ');
  }

  if (isDate(answer)) {
    return dayjs(answer).format(DATE_FORMAT);
  }

  if (Array.isArray(answer) && answer.length !== 0) {
    return answer.map(capitalizeSingleWord).join(', ');
  }

  if (typeof answer === 'number' && dataType === 'CURRENCY') {
    return englishFormattedEuroCurrency(answer, 0);
  }

  if (typeof answer === 'number' && dataType === 'NEGATIVE_CURRENCY') {
    return `-${englishFormattedEuroCurrency(answer, 0)}`;
  }

  if (typeof answer === 'string' && dataType === 'CURRENCY') {
    return englishFormattedEuroCurrency(Number(answer), 0);
  }

  if (typeof answer === 'number' && dataType === 'YEARS') {
    return `${answer} years`;
  }

  if (
    typeof answer === 'string' &&
    dataType === 'REGION' &&
    regions.includes(answer.toLowerCase() as Region)
  ) {
    return regionToNameMapping[answer.toLowerCase() as Region];
  }

  if (typeof answer === 'string') {
    return capitalizeSingleWord(answer);
  }

  if (typeof answer === 'boolean') {
    return answer ? 'Yes' : 'No';
  }

  return answer ? String(answer) : '';
};

export const processPolicyDetailsData = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  questionnaireAnswers: Record<string, any>,
  policyDetailsConfiguration: PolicyDetailsConfiguration,
  productName: string,
  planName?: string
): PolicyDetailsProps => {
  return {
    productCard: {
      productName,
      planName,
      details: policyDetailsConfiguration.productCardDetails.map((config) => ({
        ...config,
        value: processPolicyDetailsQuestionValue(
          questionnaireAnswers[config.questionId] ??
            questionnaireAnswers.quote?.[config.questionId],
          config.dataType
        ),
      })),
    },
    policyDetails: policyDetailsConfiguration.policyDetails.map((config) => ({
      ...config,
      value: processPolicyDetailsQuestionValue(
        questionnaireAnswers[config.questionId] ??
          questionnaireAnswers.quote?.[config.questionId],
        config.dataType
      ),
    })),
    additionalPolicyDetails:
      policyDetailsConfiguration.additionalPolicyDetails?.map((config) => ({
        ...config,
        details: config.details.map((rowConfig) => ({
          ...rowConfig,
          value: processPolicyDetailsQuestionValue(
            questionnaireAnswers[rowConfig.questionId] ??
              questionnaireAnswers.quote?.[rowConfig.questionId],
            rowConfig.dataType
          ),
        })),
      })),
  };
};

type ProcessPriceBreakdownArgs = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  questionnaireAnswers: Record<string, any>;
  checkout: CheckoutInfo;
  t: TFunction;
  productName: string;
  priceBreakdownConfiguration?: PriceBreakdownConfiguration;
  planName?: string;
};

export const processPriceBreakdown = (
  args: ProcessPriceBreakdownArgs
): Omit<PriceBreakdownProps, 'payComponent' | 'infoBox'> => {
  const {
    questionnaireAnswers,
    checkout,
    t,
    priceBreakdownConfiguration,
    planName,
    productName,
  } = args;

  const basePrice = priceBreakdownConfiguration?.basePriceQuestionId
    ? questionnaireAnswers.quote[
        priceBreakdownConfiguration?.basePriceQuestionId
      ] ??
      questionnaireAnswers[priceBreakdownConfiguration?.basePriceQuestionId]
    : undefined;

  return {
    productName,
    planName,
    basePrice: basePrice || checkout.totalMonthlyPrice,
    priceBreakdown: priceBreakdownConfiguration?.priceBreakdown?.map(
      (config) => {
        const priceBreakdownValue = questionnaireAnswers.quote[
          config.questionId
        ]
          ? questionnaireAnswers.quote[config.questionId]
          : questionnaireAnswers[config.questionId];

        return {
          id: config.id,
          label: config.label,
          value: config.discount ? -priceBreakdownValue : priceBreakdownValue,
        };
      }
    ),
    subTotalPrice: checkout.totalMonthlyPrice,
    finalPrice: {
      primaryLabel: t('checkout.priceBreakdown.nextMonth', 'Next month'),
      primaryDescription: t(
        'checkout.priceBreakdown.primaryDescription.text',
        'Billed on the 1st'
      ),
      primaryValue: checkout.totalMonthlyPrice,
      secondaryLabel: isCurrentMonth(checkout.mainPolicy.proratedPeriod.start)
        ? t('checkout.priceBreakdown.secondaryLabel.thisMonth', 'This month')
        : t('checkout.priceBreakdown.secondaryLabel.firstMonth', 'First month'),
      secondaryValue: checkout.totalProratedPrice,
      secondaryDescription: `${secondaryDescriptionFormat(
        checkout.mainPolicy.proratedPeriod.start
      )} - ${secondaryDescriptionFormat(
        checkout.mainPolicy.proratedPeriod.end
      )}`,
    },
  };
};

const secondaryDescriptionFormat = (value: string | Date) =>
  dayjs(value).format('DD MMM');

export const isCurrentMonth = (date: string | Date): boolean => {
  const currentDate = dayjs();
  const checkDate = dayjs(date);

  return (
    currentDate.month() === checkDate.month() &&
    currentDate.year() === checkDate.year()
  );
};

export const getStartDate = (questionnaireAnswers: Record<string, unknown>) => {
  if (questionnaireAnswers && questionnaireAnswers.startDate) {
    return String(questionnaireAnswers.startDate);
  }

  if (questionnaireAnswers && questionnaireAnswers.quote) {
    const quote = Object(questionnaireAnswers.quote);
    if (quote.termStartDate) {
      return String(quote.termStartDate);
    }
  }
};
