import classNames from 'classnames';
import { useWindowSize } from 'hooks/useWindowSize';
import { Helmet } from 'react-helmet';
import { isMobileApp } from 'shared/util/isMobileApp';

import styles from './styles.module.scss';

const LifeFrance = () => {
  const size = useWindowSize();

  return (
    <>
      <Helmet title="Life">
        <script async src="https://tally.so/widgets/embed.js" />
      </Helmet>

      <iframe
        width={size.width ?? '100%'}
        height={size.height ?? '100%'}
        className={classNames(styles.iframe, {
          pt32: isMobileApp,
        })}
        data-tally-src="https://tally.so/r/mZ11b0?transparentBackground=1"
        frameBorder={0}
        marginHeight={0}
        marginWidth={0}
        title="Feather Life Insurance"
      />
    </>
  );
};

export default LifeFrance;
