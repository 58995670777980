import { TFunction } from '@getpopsure/i18n-react';
import { Region } from '@getpopsure/public-models';
import { ImprovementBox } from 'components/ImprovementBox/ImprovementBox';
import routes from 'constants/routes';
import { ValidVerticalUrlParams } from 'features/paymentScreen/utils/verticalId.utils';
import { Link } from 'react-router-dom';

import styles from './ClaimSubmitted.module.scss';
import checkCircleIcon from './icons/check-circle.svg';

interface Props {
  t: TFunction;
  children?: React.ReactNode;
  vertical: ValidVerticalUrlParams | 'private';
  region: Region;
}

export const ClaimSubmittedView = ({
  t,
  children,
  vertical,
  region,
}: Props) => (
  <div className={`d-flex p-body ai-center jc-center ${styles.container}`}>
    <div className="p-body d-flex fd-column jc-center mt80 mb80 ai-center">
      <img
        src={checkCircleIcon}
        alt={t('claims.generic.claimsubmitted.imgalt', 'check circle')}
      />
      <h1 className="p-h2 mt32">
        {t('claims.generic.claimsubmitted.title', 'Claim submitted')}
      </h1>
      <p className="p-h3 fw-normal mt16 tc-grey-600 ta-center wmx8">
        {t(
          'claims.generic.claimsubmitted.description',
          'We will get in touch with you once your claim has been processed. You can view the status of your claim in your claims dashboard.'
        )}
      </p>
      {children}
      <Link
        className={`p-btn--primary wmn4 mt24 ${styles.button}`}
        to={routes.claims.path}
        data-cy="view-claims-button"
      >
        {t('claims.generic.claimsubmitted.cta', 'View all claims')}
      </Link>
      <ImprovementBox vertical={vertical} region={region} />
    </div>
  </div>
);
