import { Address } from '@getpopsure/public-models';
import type { Liability as Questionnaire } from 'models/genericQuestionnaire';
import { Name } from 'models/user';
import { TFunction } from 'shared/i18n';
import {
  ZAddressSchema,
  ZDateStringSchema,
  ZEmailSchema,
  ZFutureDateString,
  ZGenderSchema,
  ZNameSchema,
} from 'shared/models/validations';
import { z } from 'zod';

export const MIN_ELIGIBLE_AGE = 18;

export const INCLUSIVE_MEMBERS = ['PARTNER', 'KIDS', 'PARENTS'] as const;
export type InclusiveMember = typeof INCLUSIVE_MEMBERS[number];

export const PAST_CLAIMS = ['NONE', 'ONE', 'TWO_OR_MORE'] as const;
export type PastClaims = typeof PAST_CLAIMS[number];

export const isInclusiveMembers = (
  answer: unknown
): answer is InclusiveMember[] => {
  return (
    Array.isArray(answer) &&
    answer.every(
      (item) =>
        typeof item === 'string' &&
        INCLUSIVE_MEMBERS.includes(item as InclusiveMember)
    )
  );
};

export const isPastClaims = (answer: unknown): answer is PastClaims => {
  return (
    typeof answer === 'string' && PAST_CLAIMS.includes(answer as PastClaims)
  );
};

export const isAddress = (answer: unknown): answer is Address => {
  return (
    typeof answer === 'object' &&
    answer !== null &&
    'street' in answer &&
    'houseNumber' in answer &&
    'postcode' in answer &&
    'city' in answer &&
    'country' in answer
  );
};

export const isName = (answer: unknown): answer is Name => {
  return (
    typeof answer === 'object' &&
    answer !== null &&
    'firstName' in answer &&
    'lastName' in answer
  );
};

export type QuestionId = keyof Questionnaire;
type QuestionnaireTypeObject<K extends QuestionId> = {
  questionId: K;
  value: Questionnaire[K];
};
export type QuestionnaireValueTypeMapping = {
  [K in QuestionId]: QuestionnaireTypeObject<K>;
};

export type PageId =
  | QuestionId
  | 'account'
  | 'blocker'
  | 'previousCancelationBlocker';

export interface CardInfo {
  title: string;
  description: string | React.ReactNode;
  icon: { src: string; alt: string };
}

export interface Answers {
  personalInfo: {
    name: {
      lastName: string;
      firstName: string;
    };
    email: string;
    address?: Address;
    startDate: string;
    dateOfBirth: string;
    relocationDate?: string;
    inclusiveMembers: InclusiveMember[];
    numberOfPreviousClaims: PastClaims;
    currentlyLivingInGermany: boolean;
  };
}

export interface QuestionnaireLabels {
  labels: {
    title: {
      personalInfo: {
        name: string;
        email: string;
        address: string;
        startDate: string;
        dateOfBirth: string;
        relocationDate: string;
        inclusiveMembers: string;
        numberOfPreviousClaims: string;
        currentlyLivingInGermany: string;
      };
    };
    answers: {
      personalInfo: {
        inclusiveMembers: { [K in InclusiveMember]: string };
        numberOfPreviousClaims: { [K in PastClaims]: string };
      };
    };
  };
  questionOrder: {
    personalInfo: QuestionId[];
  };
}

export interface Quote {
  questionnaireId: string;
  quoteId: string;
  price: number;
}

export interface PolicyDetails {
  inclusiveMembers: InclusiveMember[];
}

export const ZLiabilityAnswersRequiredForForQuoteSchema = z.object({
  address: ZAddressSchema.optional(),
  name: ZNameSchema,
  dateOfBirth: ZDateStringSchema,
  gender: ZGenderSchema.optional(),
  email: ZEmailSchema,
  startDate: ZFutureDateString,
  numberOfPreviousClaims: z.enum(PAST_CLAIMS),
  canceledByAnotherProvider: z.boolean(),
  currentlyLivingInGermany: z.boolean(),
  inclusiveMembers: z.array(z.enum(INCLUSIVE_MEMBERS)),
  relocationDate: ZDateStringSchema.optional(),
});

export const getPastClaimsMapping: (
  t: TFunction
) => Record<PastClaims, string> = (t) => ({
  NONE: t('page.liability.qnr.numberofpreviousclaims.none', 'None'),
  ONE: t('page.liability.qnr.numberofpreviousclaims.1', 'Only one'),
  TWO_OR_MORE: t('page.liability.qnr.numberofpreviousclaims.2+', 'Two or more'),
});

export const getInclusiveMembersMapping: (
  t: TFunction
) => Record<InclusiveMember, string> = (t) => ({
  PARTNER: t('page.liability.qnr.inclusiveMembers.partner', 'Partner'),
  KIDS: t('page.liability.qnr.inclusiveMembers.kids', 'Kid(s)'),
  PARENTS: t('page.liability.qnr.inclusiveMembers.parents', 'Parents'),
});

export const getQuestionsMapping: (
  t: TFunction
) => Record<QuestionId, string> = (t) => ({
  account: '',
  quote: '',
  numberOfPreviousClaims: t(
    'page.liability.qnr.numberofpreviousclaims.question',
    'How many liability insurance claims did you make in the past 3 years?'
  ),
  canceledByAnotherProvider: t(
    'page.liability.qnr.canceledByAnotherProvider.question',
    'Has an insurance company canceled your policy before?'
  ),
  email: t('page.liability.qnr.email.question', "What's your email address?"),
  name: t('page.liability.qnr.name.question', 'What is your name?'),
  dateOfBirth: t('page.liability.qnr.dob.question', 'When were you born?'),
  gender: t('page.liability.qnr.gender.question', 'What is your gender?'),
  currentlyLivingInGermany: t(
    'page.liability.qnr.currentlylivingingermany.question',
    'Are you currently living in Germany?'
  ),
  relocationDate: t(
    'page.liability.qnr.relocationdate.question',
    'When will you move to Germany?'
  ),
  address: t(
    'page.liability.qnr.address.question',
    'What is your address in Germany?'
  ),
  startDate: t(
    'page.liability.qnr.startdate.question',
    'When would you like your coverage to start?'
  ),
  inclusiveMembers: t(
    'page.liability.qnr.inclusivemembers.question',
    'Who are the household member(s)?'
  ),
  checkoutInfo: '',
});

export const getQuestionReviewLabelMapping: (
  t: TFunction
) => Partial<Record<QuestionId, string>> = (t) => ({
  email: t('page.liability.review.email', 'Email address'),
  name: t('page.liability.review.name', 'Policy holder'),
  dateOfBirth: t('page.liability.review.dob', 'Date of birth'),
  gender: t('page.liability.review.gender', 'Gender'),
  numberOfPreviousClaims: t(
    'page.liability.review.numberofpreviousclaims',
    'Liability claims - Past 3 years'
  ),
  inclusiveMembers: t(
    'page.liability.review.inclusivemembers',
    'Household members included?'
  ),
  currentlyLivingInGermany: t(
    'page.liability.review.currentlylivingingermany',
    'Currently living in Germany'
  ),
  address: t('page.liability.review.address', 'Address in Germany'),
  relocationDate: t('page.liability.review.relocationdate', 'Relocation date'),
  startDate: t('page.liability.review.startdate', 'Coverage start date'),
});

export const reviewQuestions: QuestionId[] = [
  'email',
  'name',
  'dateOfBirth',
  'numberOfPreviousClaims',
  'inclusiveMembers',
  'currentlyLivingInGermany',
  'address',
  'relocationDate',
  'startDate',
];

export const questionOrderWithAddress: PageId[] = [
  'inclusiveMembers',
  'numberOfPreviousClaims',
  'canceledByAnotherProvider',
  'blocker',
  'account',
  'email',
  'name',
  'dateOfBirth',
  'currentlyLivingInGermany',
  'address',
  'startDate',
  'quote',
  'checkoutInfo',
];

export const questionOrderWithRelocationDate: PageId[] = [
  'inclusiveMembers',
  'numberOfPreviousClaims',
  'canceledByAnotherProvider',
  'blocker',
  'account',
  'email',
  'name',
  'dateOfBirth',
  'currentlyLivingInGermany',
  'relocationDate',
  'startDate',
  'quote',
  'checkoutInfo',
];

export const skippablePageId: PageId[] = ['blocker', 'account', 'address'];
