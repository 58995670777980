import { illustrations, MultiDropzone, Spinner } from '@popsure/dirty-swan';
import classNames from 'classnames';
import routes from 'constants/routes';
import { featherIconSilhouette } from 'icons';
import { HealthCardPhoto } from 'models/healthCard';
import { QRCode } from 'react-qrcode-logo';

import PhotoLoader from '../PhotoLoader';
import PhotoPreview from '../photoPreview';
import ModalWrapper from './ModalWrapper';
import style from './style.module.scss';

export interface PhotoUploadModalProps {
  currentPhoto: HealthCardPhoto | null;
  approvedPhoto: HealthCardPhoto | null;
  onSelectPhoto: (files: File[]) => void;
  onDeletePhoto: () => void;
  onClose: () => void;
  onSubmit: () => void;
  toggleDisplayQR: () => void;
  qrToken: string | null;
  error: string | null;
  cardDetailsLoading: boolean;
  fetchTokenLoading: boolean;
  updateLoading: boolean;
}

const PhotoUploadModal = (props: PhotoUploadModalProps) => {
  const {
    currentPhoto,
    approvedPhoto,
    onSelectPhoto,
    onDeletePhoto,
    onClose,
    onSubmit,
    toggleDisplayQR,
    qrToken,
    error,
    cardDetailsLoading,
    fetchTokenLoading,
    updateLoading,
  } = props;

  if (cardDetailsLoading)
    return (
      <ModalWrapper
        onClose={onClose}
        error={error}
        onSubmit={onSubmit}
        loading={cardDetailsLoading}
        disabled
      >
        <PhotoLoader />
      </ModalWrapper>
    );

  return (
    <ModalWrapper
      onClose={onClose}
      error={error}
      onSubmit={onSubmit}
      loading={updateLoading}
      disabled={!currentPhoto || currentPhoto.id === approvedPhoto?.id}
    >
      {currentPhoto ? (
        <PhotoPreview photo={currentPhoto} onDelete={onDeletePhoto} />
      ) : (
        /* 
          TODO: [KONG] Update with new component with transition animation
        */
        <MultiDropzone
          onFileSelect={onSelectPhoto}
          uploading={false}
          /**
           * We are taking care of removing the file
           * so there is no need to pass these prop
           */
          onRemoveFile={() => {}}
          uploadedFiles={[]}
          isCondensed={Boolean(qrToken)}
          maxFiles={1}
        />
      )}
      {qrToken && !currentPhoto && (
        <div className="d-flex mt16 p16 bg-primary-25">
          <div className={`d-flex ${style['qr-container']}`}>
            <QRCode
              size={196}
              value={`${window.location.origin}${routes.healthCardPhoto.path}?hctoken=${qrToken}`}
              logoImage={featherIconSilhouette}
              logoHeight={32}
              logoWidth={32}
              fgColor="#5754bb"
              bgColor="#fcfcff"
              qrStyle="dots"
              eyeRadius={4}
            />
            <div className="d-flex fd-column jc-center ml16">
              <p className="p-h3 fw-bold">Scan QR code</p>
              <p className="p-p">to continue on your phone</p>
            </div>
          </div>
        </div>
      )}
      {!currentPhoto && !qrToken && (
        <button
          className={`mt16 p24 w100 ds-card ds-card--actionable p-p ta-left ai-center ${style.button}`}
          onClick={toggleDisplayQR}
          disabled={fetchTokenLoading}
          type="button"
        >
          <img
            src={illustrations.phonePhoto}
            alt=""
            className={classNames(style.phonePhoto, 'mr8')}
          />
          Take photo with your phone
          {fetchTokenLoading && (
            <div className={style['loader-container']}>
              <Spinner size="m" />
            </div>
          )}
        </button>
      )}
    </ModalWrapper>
  );
};

export default PhotoUploadModal;
