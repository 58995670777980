import { useFlag } from '@unleash/proxy-client-react';
import routes from 'constants/routes';
import { useSelector } from 'react-redux';
import { getGenericQuestionnaire } from 'selectors/genericQuestionnaire';
import { useSafeTranslation } from 'shared/i18n';
import { GenericCheckout } from 'SignupQuestionnaire/components/GenericCheckout';
import { GenericCheckoutV2 } from 'SignupQuestionnaire/components/GenericCheckoutV2/GenericCheckout';

const LiabilityCheckout = () => {
  const { t } = useSafeTranslation();
  const questionnaireAnswers = useSelector(getGenericQuestionnaire).liability;
  const useNewCheckout = useFlag('app_checkout_revamp_liability');
  const questionnaireAnswersWithIds = {
    ...questionnaireAnswers,
    questionnaireId: questionnaireAnswers?.quote?.questionnaireId,
    quoteId: questionnaireAnswers?.quote?.quoteId,
  };

  if (useNewCheckout) {
    return (
      <GenericCheckoutV2
        onSubmitValue={() => {}}
        setValidForSubmission={() => {}}
        featureName="questionnaire"
        question="reviewCheckout"
        questionnaireAnswers={questionnaireAnswersWithIds}
        type="LIABILITY"
        region="de"
        policyInfoKind="LiabilityPolicy"
        hasCheckoutDocuments={true}
        changeStartDatePath={
          routes.policies.liability.questionnaire.startDate.path
        }
        productName={t('page.liability.qnr.checkout.liability', 'Liability')}
        policyDetailsConfiguration={{
          productCardDetails: [
            {
              id: 'startDate',
              label: t(
                'page.liability.qnr.checkout.policyDetails.startDate',
                'Start date'
              ),
              questionId: 'startDate',
            },
          ],
          policyDetails: [
            {
              id: 'name',
              label: t(
                'page.liability.qnr.checkout.policyDetails.name',
                'Name'
              ),
              questionId: 'name',
            },
            {
              id: 'dateOfBirth',
              label: t(
                'page.liability.qnr.checkout.policyDetails.dateOfBirth',
                'Date of birth'
              ),
              questionId: 'dateOfBirth',
            },
            {
              id: 'email',
              label: t(
                'page.liability.qnr.checkout.policyDetails.email',
                'Account email'
              ),
              questionId: 'email',
            },
          ],
        }}
      />
    );
  }

  return (
    <GenericCheckout
      onSubmitValue={() => {}}
      setValidForSubmission={() => {}}
      featureName="questionnaire"
      question="reviewCheckout"
      questionnaireAnswers={questionnaireAnswersWithIds}
      type="LIABILITY"
      region="de"
      policyInfoKind="LiabilityPolicy"
      hasCheckoutDocuments={true}
      changeStartDatePath={
        routes.policies.liability.questionnaire.startDate.path
      }
      policyDetailsConfiguration={{
        policyDetails: [
          {
            title: t(
              'page.liability.qnr.checkout.policydetails.requestedStartDate',
              'Requested start date'
            ),
            questionId: 'startDate',
            additionalInfo: {
              title: t(
                'page.liability.qnr.checkout.policydetails.requestedStartDate.additionalInfo.title',
                'Requested start date'
              ),
              description: t(
                'page.liability.qnr.checkout.policydetails.requestedStartDate.additionalInfo.descriptionNoValue',
                'Your coverage should begin on the requested date, but only after you have received the official confirmation.'
              ),
            },
          },
          {
            title: t(
              'page.liability.qnr.checkout.policydetails.inclusivemembers',
              'Included household members'
            ),
            questionId: 'inclusiveMembers',
          },
        ],
      }}
    />
  );
};

export default LiabilityCheckout;
