import { language, Locale, TFunction } from '@getpopsure/i18n-react';
import Session from '@getpopsure/session';
import { createAccountV2 } from 'actions/account';
import { storeGenericQuestionnaireAnswer } from 'actions/genericQuestionnaire';
import { setRequestErrored, setRequestInProcess } from 'actions/request';
import { RequestAction } from 'constants/actions';
import { generateCheckoutDocuments } from 'features/checkoutDocuments/actions';
import { retrieveNextPagePath } from 'features/liability/utils';
import { APIResponseError } from 'models/error';
import {
  Answers,
  QuestionId,
  QuestionnaireLabels,
  QuestionnaireValueTypeMapping,
} from 'models/liability';
import { UserNewWithRequiredInfo } from 'models/user';
import { AppState } from 'reducers';
import { GenericQuestionnaireAction } from 'reducers/genericQuestionnaire';
import { ThunkDispatch } from 'redux-thunk';
import endpoint from 'shared/api';
import browserHistory from 'shared/browserHistory';

const history = browserHistory;

export type LiabilitySubmissionDispatch = ThunkDispatch<
  AppState,
  unknown,
  GenericQuestionnaireAction<'liability'>
>;

export const submitQuestionnaireAnswer =
  (answer: QuestionnaireValueTypeMapping[QuestionId]) =>
  async (dispatch: LiabilitySubmissionDispatch) => {
    const nextPagePath = retrieveNextPagePath(answer);

    dispatch(
      storeGenericQuestionnaireAnswer('liability', {
        [answer.questionId]: answer.value,
      })
    );

    dispatch(resetConditionalAnswers(answer));

    if (nextPagePath) {
      history.push(nextPagePath);
    }
  };

export const resetConditionalAnswers =
  (answer: QuestionnaireValueTypeMapping[QuestionId]) =>
  async (dispatch: LiabilitySubmissionDispatch) => {
    if (answer.questionId === 'currentlyLivingInGermany') {
      if (answer.value === true) {
        dispatch(
          storeGenericQuestionnaireAnswer('liability', {
            relocationDate: undefined,
          })
        );
      } else {
        dispatch(
          storeGenericQuestionnaireAnswer('liability', {
            address: undefined,
          })
        );
      }
    }

    if (answer.questionId === 'relocationDate') {
      dispatch(
        storeGenericQuestionnaireAnswer('liability', {
          startDate: undefined,
        })
      );
    }
  };

export const createQuote =
  (answers: Answers, labels: QuestionnaireLabels, t: TFunction) =>
  async (dispatch: ThunkDispatch<AppState, unknown, RequestAction>) => {
    const { isAuthenticated } = Session;
    const requestType = 'CREATE_QUOTE';
    dispatch(setRequestInProcess(true, requestType));

    try {
      if (!isAuthenticated) {
        const {
          personalInfo: { email, name, dateOfBirth },
        } = answers;
        const user: UserNewWithRequiredInfo = {
          email,
          ...name,
          dateOfBirth,
          language: language() as Locale,
        };

        await dispatch(createAccountV2(user));
      }

      const {
        data: { id: questionnaireId },
      } = await endpoint.createLiabilityQuestionnaire(answers, labels);

      const {
        data: { id: quoteId, price },
      } = await endpoint.liabilityCreateQuote(
        answers.personalInfo.inclusiveMembers
      );

      await generateCheckoutDocuments(questionnaireId, quoteId, 'LIABILITY', t);

      dispatch(setRequestInProcess(false, requestType));
      dispatch(
        submitQuestionnaireAnswer({
          questionId: 'quote',
          value: {
            questionnaireId,
            price,
            quoteId,
          },
        })
      );
    } catch (error) {
      dispatch(setRequestErrored(error as APIResponseError, requestType));
    }
  };
