import { email } from '@getpopsure/private-constants';
import * as Sentry from '@sentry/react';
import { useMutation } from '@tanstack/react-query';
import endpoint from 'shared/api';
import { useSafeTranslation } from 'shared/i18n';

import { triggerPushNotifications } from '../../../shared/pushNotifications';
import { LiabilityClaims, SubmitInfo } from '../models/LiabilityClaims.model';

export const useSubmitLiabilityClaim = () => {
  const { t } = useSafeTranslation();

  const onSubmitClaim = ({
    answers,
    policyId,
  }: {
    answers: Partial<LiabilityClaims>;
    policyId: string;
  }) => {
    if (
      !answers.dateOfEvent ||
      !answers.claimType ||
      !answers.description ||
      !answers.uploadedDocumentTokens ||
      (answers.claimType === 'KEY_LOST' &&
        (!answers.payoutDetails || !answers.payoutDetails.iban))
    ) {
      throw new Error(
        `[Liability Claim] Answer(s) are missing for submission for policy id: ${policyId}`
      );
    }

    const isThirdPartyInfoRequired = answers.claimType !== 'KEY_LOST';
    const includeThirdParty =
      isThirdPartyInfoRequired &&
      answers.thirdPartyName &&
      answers.thirdPartyEmail &&
      answers.thirdPartyAddress;

    const tokens = answers.uploadedDocumentTokens.flatMap(({ token }) =>
      token !== undefined ? [token] : []
    );

    const submittableInfo: SubmitInfo = {
      type: 'LIABILITY',
      userPolicyId: policyId,
      dateOfEvent: answers.dateOfEvent,
      description: answers.description,
      claimType: answers.claimType,
      uploadedDocumentTokens: tokens,
      ...(includeThirdParty
        ? {
            thirdParty: {
              name: answers.thirdPartyName,
              email: answers.thirdPartyEmail,
              address: answers.thirdPartyAddress,
            },
          }
        : {}),
      ...(answers.claimType === 'KEY_LOST' && answers.payoutDetails
        ? answers.payoutDetails
        : {}),
    };

    return endpoint.submitClaim(submittableInfo);
  };

  const mutation = useMutation({
    mutationFn: onSubmitClaim,
    onError: (error, variables) => {
      const { policyId, answers } = variables;
      Sentry.captureException(error, {
        tags: {
          feature: 'CLAIMS',
          vertical: 'LIABILITY',
        },
        extra: {
          policyId,
          answers,
          description: 'Error happened while submitting Liability claim',
        },
      });
    },
    onSuccess: () => {
      triggerPushNotifications('LIABILITY');
    },
  });

  const getErrorMessage = () => {
    if (!mutation.isError) return undefined;

    return t(
      'claims.liability.review.error.message',
      'An error occurred while submitting your claim. Please try again or contact us at {{supportEmail}}.',
      { supportEmail: email.help }
    );
  };

  return { mutation, getErrorMessage };
};
