import { BottomOrRegularModal } from '@popsure/dirty-swan';
import { Trans, useSafeTranslation } from 'shared/i18n';

import styles from '../style.module.scss';
import { ViewProps } from '../types';

export const CancelManuallyModal = ({
  activeUntil,
  onCloseModal,
  isOpen,
  policyNumber,
}: Pick<
  ViewProps,
  'onCloseModal' | 'isOpen' | 'activeUntil' | 'policyNumber'
>) => {
  const { t } = useSafeTranslation();

  const cancellationDateStr = activeUntil || '**ENTER CANCELLATION DATE HERE**';
  const policyNumberStr = policyNumber || '**ENTER POLICY NUMBER HERE**';
  const contactLinkHref = `mailto:hello@feather-insurance.com?subject=Policy cancellation request&body=Dear Feather, please cancel my policy ${policyNumberStr} on ${cancellationDateStr}.`;

  return (
    <BottomOrRegularModal
      title={t(
        'myPolicies.cancelModal.manualCancellation.title',
        'Cancel policy'
      )}
      isOpen={isOpen}
      onClose={onCloseModal}
    >
      <div
        className={`mt24 ${styles.container}`}
        data-testid="cancel-modal-manual"
      >
        <p className="p-p mb16">
          {t(
            'myPolicies.cancelModal.manualCancellation.description',
            "We're still working on automating this process. In the meantime you can request the cancellation below."
          )}
        </p>
        <Trans i18nKey="myPolicies.cancelModal.manualCancellation.issuesCTA">
          <p className="p-p--small mb24">
            Having issues? Reach out to us at{' '}
            <a
              className="p-a"
              href="mailto:hello@feather-insurance.com"
              target="_blank"
              rel="noreferrer"
            >
              hello@feather-insurance.com
            </a>
            .
          </p>
        </Trans>
        <div className={`d-flex f-wrap ${styles['button-container']}`}>
          <a
            className="p-btn--primary wmn2"
            href={contactLinkHref}
            target="_blank"
            rel="noreferrer"
          >
            {t(
              'myPolicies.cancelModal.manualCancellation.requestCancellationButton.caption',
              'Request Cancellation'
            )}
          </a>
        </div>
      </div>
    </BottomOrRegularModal>
  );
};
