import { calendly, insurance } from '@getpopsure/private-constants';
import { getPriceBreakdown } from '@getpopsure/private-health-insurance-pricing-engine';
import { useFlag } from '@unleash/proxy-client-react';
import { EmailAddressCollectionModal } from 'components/EmailAddressCollectionModal';
import Markdown from 'components/markdown';
import { TallyModal } from 'components/TallyModal';
import {
  persistPrivatePreSignupQuestionnaire,
  PrivatePreSignupDispatch,
} from 'features/privateHealthPreSignup/actions';
import {
  DependentType,
  PrivatePreSignup,
} from 'features/privateHealthPreSignup/models';
import { APIResponseError } from 'models/error';
import { CustomComponentProps } from 'models/questionnaireFramework';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { formatErrorMessageFromError } from 'selectors/requests';
import { useSafeTranslation } from 'shared/i18n';

import familySvg from './family.svg';
import styles from './FamilyCoverageBlocker.module.scss';

const dependentTypesToSelectionIndex = (dependentTypes: DependentType[]) => {
  const dependentTypeIndexLookup = {
    SPOUSE: '1',
    CHILDREN: '2',
  };

  return dependentTypes
    .map((d) => dependentTypeIndexLookup[d])
    .sort()
    .join(',');
};

export const FamilyCoverageBlocker = ({
  questionnaireAnswers,
}: CustomComponentProps<PrivatePreSignup>) => {
  const shouldShowCalculatorLink = useFlag('app_private_calculator_link');

  const { t } = useSafeTranslation();

  const dispatch = useDispatch<PrivatePreSignupDispatch>();

  const [bookACallModalVisible, setBookACallModalVisible] = useState(false);
  const [tallyModalVisible, setTallyModalVisible] = useState(false);

  const {
    birthYear = 1900,
    income = 0,
    dependentTypes,
    spouseHasOwnIncome,
    employmentStatus,
  } = questionnaireAnswers;

  const isEligibleForShortTerm = questionnaireAnswers.eligibleForShortTerm;

  const qnrShortTermPrice = isEligibleForShortTerm
    ? getPriceBreakdown({
        tariff: 'HiMedicalPlus',
        birthYear,
        income,
        employmentStatus,
      }).contributions.own
    : undefined;

  const qnrBasicPrice = getPriceBreakdown({
    tariff: 'NKSelectS',
    birthYear,
    income,
    employmentStatus,
  }).contributions.own;

  const qnrPlusPrice = getPriceBreakdown({
    tariff: 'NKSelectL',
    birthYear,
    income,
    employmentStatus,
  }).contributions.own;

  const qnrPremiumPrice = getPriceBreakdown({
    tariff: 'NKSelectXL',
    birthYear,
    income,
    employmentStatus,
  }).contributions.own;

  return (
    <>
      <EmailAddressCollectionModal
        isOpen={bookACallModalVisible}
        onClose={() => setBookACallModalVisible(false)}
        onSubmit={async ({ setSubmitting, setError, name, email }) => {
          setError('');
          setSubmitting(true);
          const { status, error, questionnaireId } = await dispatch(
            persistPrivatePreSignupQuestionnaire({
              answers: questionnaireAnswers,
              shortTermPrice: qnrShortTermPrice,
              basicPrice: qnrBasicPrice,
              plusPrice: qnrPlusPrice,
              premiumPrice: qnrPremiumPrice,
              email,
              name,
              hashOfLastSubmittedQuestionnaire:
                questionnaireAnswers.hashOfQuestionnaire,
            })
          );
          if (status === 'SUCCESS') {
            setBookACallModalVisible(false);
            window.Calendly.initPopupWidget({
              url: calendly.healthInsuranceAdviceFamily,
              prefill: {
                name,
                email,
                customAnswers: {
                  a1: dependentTypes
                    ? dependentTypesToSelectionIndex(dependentTypes)
                    : '',
                  a2: '',
                  a4: income,
                  a5: spouseHasOwnIncome ? 'Yes, over 520€' : 'No',
                  a7: questionnaireId,
                },
              },
            });
          } else {
            setError(formatErrorMessageFromError(error as APIResponseError));
          }
          setSubmitting(false);
        }}
      />
      <div
        className={`p-container d-flex fd-column ai-center jc-center ${styles.container}`}
      >
        <img src={familySvg} alt="family" />
        <h1 className="p-h1 mt16 mb8 ta-center">
          {t(
            'private.qnr.dependentSignupBlocker.title',
            'Great, let’s cover your family'
          )}
        </h1>
        <p className="p-p mt24 ta-center">
          {t(
            'private.qnr.dependentSignupBlocker.description.start',
            'We’d like to jump on a call to go through the next steps. Family coverage can be a bit complicated and we want to make sure we do things right.'
          )}
        </p>
        {shouldShowCalculatorLink && (
          <Markdown paragraphClassName="p-p ta-center">
            {t(
              'private.qnr.dependentSignupBlocker.description.calculatorLink',
              {
                defaultValue:
                  'If you didn’t get a family price yet, you can use our [calculator]({{calculatorUrl}}).',
                calculatorUrl: `${insurance.privateHealth.landingPage}#calculator`,
              }
            )}
          </Markdown>
        )}
        <p className="p-p mt24 ta-center">
          {t(
            'private.qnr.dependentSignupBlocker.description.end',
            'You can schedule a call with our experts at a time that suits you.'
          )}
        </p>
        <div className="d-flex fd-column gap16 mt24 ai-center jc-center">
          <button
            className="p-btn--primary wmn4"
            onClick={() => setBookACallModalVisible(true)}
            type="button"
          >
            {t(
              'private.qnr.dependentSignupBlocker.button.1.caption',
              'Book a call'
            )}
          </button>
          <button
            className="p-btn--secondary bg-white wmn4"
            onClick={() => setTallyModalVisible(true)}
            type="button"
          >
            {t(
              'private.qnr.dependentSignupBlocker.button.2.caption',
              'I already had a call'
            )}
          </button>
        </div>
      </div>
      {tallyModalVisible && (
        <TallyModal
          isVisible={tallyModalVisible}
          tallyId="nWEMMe"
          onClose={() => setTallyModalVisible(false)}
          title="Private health dependent sign up"
          URLparams={{}}
        />
      )}
    </>
  );
};
