import dayjs from 'dayjs';
import { Cell } from 'features/paymentScreen/components/Cell/Cell.view';
import { useSelector } from 'react-redux';
import { getGenericQuestionnaire } from 'selectors/genericQuestionnaire';
import { useSafeTranslation } from 'shared/i18n';
import { capitalizeName } from 'shared/util/capitalizeName';
import { formatCurrencyWithSpace } from 'shared/util/formatCurrencyWithSpace';

import styles from './PolicyDetails.module.scss';

const DATE_FORMAT = 'DD MMM YYYY';

export const PolicyDetails = () => {
  const { t } = useSafeTranslation();
  const questionnaire = useSelector(getGenericQuestionnaire).pension;

  if (!questionnaire) {
    throw new Error('[PENSION]: Questionnaire information is missing');
  }

  const { quote, name, dateOfBirth, email } = questionnaire;

  if (!quote) {
    throw new Error('[PENSION]: Quote information is missing');
  }
  const { monthlyPayment, initialAmount } = quote;
  const startDate = dayjs()
    .add(1, 'month')
    .startOf('month')
    .format(DATE_FORMAT);

  return (
    <div className={`bg-white ${styles.container}`}>
      <div className="p-h2 mb16">
        {t('pension.qnr.policyDetails .title', 'Policy details')}
      </div>
      <Cell
        title={t('pension.qnr.policyDetails.startDate', 'Start date')}
        value={startDate}
      />
      <Cell
        title={t('pension.qnr.policyDetails.oneOffPayment', 'One-off payment')}
        value={formatCurrencyWithSpace(initialAmount)}
      />
      <Cell
        title={t(
          'pension.qnr.policyDetails.monthlyContribution',
          'Monthly contribution'
        )}
        value={formatCurrencyWithSpace(monthlyPayment)}
      />

      {/* Policy holder */}
      <div className="p-h4 mt24">
        {t('pension.qnr.policyDetails.policyHolder.title', 'Policy holder')}
      </div>
      {name && (
        <Cell
          title={t('pension.qnr.policyDetails.policyholder.name', 'Name')}
          value={capitalizeName(name)}
        />
      )}

      {dateOfBirth && (
        <Cell
          title={t(
            'pension.qnr.policyDetails.policyholder.dateofbirth',
            'Date of birth'
          )}
          value={dayjs(dateOfBirth).format(DATE_FORMAT)}
        />
      )}
      {email && (
        <Cell
          title={t('pension.qnr.policyDetails.policyholder.email', 'Email')}
          value={email}
        />
      )}
    </div>
  );
};
