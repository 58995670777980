import { Address, isPartialAddressValid } from '@getpopsure/public-models';
import {
  AutocompleteAddress,
  BottomOrRegularModal,
  Button,
} from '@popsure/dirty-swan';
import { FormEvent, useState } from 'react';
import AnimateHeight from 'react-animate-height';

import styles from './style.module.scss';

interface AddressModalProps {
  toggleModal: () => void;
  userAddress?: Address;
  onSave: (address: Address) => void;
  error?: string;
  loading?: boolean;
}

const AddressModal = (props: AddressModalProps) => {
  const { toggleModal, userAddress, onSave, error, loading } = props;
  const [address, setAddress] = useState<Partial<Address> | undefined>(
    userAddress
  );
  const isValidAddress = address && isPartialAddressValid(address);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (isValidAddress) {
      onSave(address as Address);
    }
  };

  return (
    <BottomOrRegularModal
      title="Confirm your address"
      isOpen
      onClose={toggleModal}
    >
      <form onSubmit={handleSubmit} className={styles.container}>
        <AutocompleteAddress
          address={address}
          onAddressChange={setAddress}
          apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? ''}
        />
        <p className="mt16 p-notice--info">
          Make sure to fill the C/O field if your name is not on the front door
          and mailbox.
        </p>
        <Button
          type="submit"
          className="mt16 w100 wmx3"
          disabled={!isValidAddress}
          loading={loading}
          data-cy="confirm-address"
        >
          Confirm address
        </Button>
        <AnimateHeight duration={300} height={error ? 'auto' : 0}>
          <div className="p-notice p-notice--danger mt16 wmx8">{error}</div>
        </AnimateHeight>
      </form>
    </BottomOrRegularModal>
  );
};

export default AddressModal;
