import { capitalizeName } from '@getpopsure/public-utility';
import { getTrackingObject } from '@getpopsure/tracker';
import LoadingSpinner from 'components/loadingSpinner';
import routes from 'constants/routes';
import dayjs from 'dayjs';
import { mergeCheckoutInfo } from 'features/paymentScreen/actions';
import { linkToStripe, startCheckout } from 'features/paymentScreen/api';
import { PolicyDetailsProps } from 'features/paymentScreen/components/PolicyDetailsNew/PolicyDetails';
import { PriceBreakdownProps } from 'features/paymentScreen/components/PriceBreakdown/PriceBreakdown';
import { PaymentScreenThunkDispatch } from 'features/paymentScreen/paymentScreen.thunks';
import {
  useFormIdFromQuery,
  useInsuranceTypeFromQuery,
  useMonthlyPriceFromQuery,
  usePlanIdFromQuery,
  useStartDateFromQuery,
} from 'features/paymentScreen/utils/queryParams.utils';
import { useCheckoutRevampFeatureToggle } from 'hooks/useCheckoutRevampFeatureToggle';
import { capitalize, startCase } from 'lodash';
import lz from 'lz-string';
import { InsuranceTypes } from 'models/insurances/types';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { generatePath, useHistory, useParams } from 'react-router';
import api from 'shared/api';
import { getLocaleAndRegionFromLocaleId } from 'shared/i18n';
import { paramsSetUrl } from 'shared/util/paramsSetUrl';

const getPolicyHolder = async () => {
  const { data } = await api.getAccountInfo();
  return data;
};

const UrlGateway = () => {
  const { localeId } = useParams<{ localeId?: string }>();

  const startDate = useStartDateFromQuery('startDate');
  const insuranceType = useInsuranceTypeFromQuery('insuranceType');
  const planId = usePlanIdFromQuery('planId');
  const formId = useFormIdFromQuery('formId');
  const monthlyPriceInEuros = useMonthlyPriceFromQuery('monthlyPrice');
  const source = getTrackingObject();

  const history = useHistory();
  const dispatch = useDispatch<PaymentScreenThunkDispatch>();

  const displayNewPaymentScreen = useCheckoutRevampFeatureToggle(
    insuranceType as InsuranceTypes
  );

  const callInitializeCheckout = async () => {
    await linkToStripe(api.network);
    let region;

    if (localeId) {
      region = getLocaleAndRegionFromLocaleId(localeId).region;
    }

    const user = await getPolicyHolder();

    const { data: checkout } = await startCheckout(api.network, {
      startDate,
      insuranceType,
      monthlyPriceInEuros,
      formId,
      policyDetails: {
        type: insuranceType,
        planId,
      },
      policyInfo: {
        regionOfPurchase: region,
        source,
      },
    });

    const productName = capitalize(startCase(insuranceType));
    const planName = planId ? capitalize(startCase(planId)) : '';

    const policyDetailsConfiguration: PolicyDetailsProps = {
      productCard: {
        productName,
        planName,
        details: [
          {
            id: 'startDate',
            label: 'Start date',
            value: dayjs(startDate).format('DD MMM YYYY'),
          },
        ],
      },
      policyDetails: [
        {
          id: 'name',
          label: 'Name',
          value:
            capitalizeName({
              firstName: user.firstName ?? '',
              lastName: user.lastName ?? '',
            }) ?? '',
        },
        {
          id: 'email',
          label: 'Email',
          value: user.email,
        },
      ],
    };
    const priceBreakdownConfiguration: Omit<
      PriceBreakdownProps,
      'payComponent'
    > = {
      productName,
      planName,
      basePrice: monthlyPriceInEuros,
      subTotalPrice: monthlyPriceInEuros,
      finalPrice: {
        primaryLabel: 'Next month',
        primaryValue: monthlyPriceInEuros,
        primaryDescription: 'Billed on the 1st ',
        secondaryLabel: 'This month',
        secondaryValue: checkout.mainPolicy.proratedPrice,
        secondaryDescription: `${dayjs(
          checkout.mainPolicy.proratedPeriod.start
        ).format('DD MMM')} - ${dayjs(
          checkout.mainPolicy.proratedPeriod.end
        ).format('DD MMM')}`,
      },
    };

    const configuration = displayNewPaymentScreen.hasCheckoutRevampFlag
      ? {
          hasCheckoutDocuments: false,
          priceBreakdown: priceBreakdownConfiguration,
          policyDetails: policyDetailsConfiguration,
        }
      : {};

    if (region) {
      dispatch(
        mergeCheckoutInfo({
          ...checkout,
          region,
        })
      );

      const checkoutUrl = paramsSetUrl(
        generatePath(routes.paymentScreenRegionalised.pay.path, {
          checkoutId: checkout.id,
          localeId,
        }),
        [
          {
            key: 'c',
            value: lz.compressToEncodedURIComponent(
              JSON.stringify(configuration)
            ),
          },
        ]
      );

      history.replace(checkoutUrl);
    } else {
      dispatch(mergeCheckoutInfo(checkout));

      const checkoutUrl = paramsSetUrl(
        generatePath(routes.paymentScreen.pay.path, {
          checkoutId: checkout.id,
        }),
        [
          {
            key: 'c',
            value: lz.compressToEncodedURIComponent(
              JSON.stringify(configuration)
            ),
          },
        ]
      );

      history.replace(checkoutUrl);
    }
  };

  useEffect(() => {
    callInitializeCheckout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <LoadingSpinner />;
};

export default UrlGateway;
