import {
  getRoutes,
  QuestionnaireQuestions,
  stateManagerHelper,
} from '@getpopsure/qnr-framework';
import { interpolateData } from 'features/generalQuestionnaire/functions';
import { QuestionnaireJSON } from 'features/generalQuestionnaire/model';
import { Policy } from 'models/policies';
import { UserWithBrokerMandate } from 'models/user';
import { ReactNode, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { getAccountInfo } from 'selectors/user';
import { SignupQuestionnaire as QNRSignupQuestionnaire } from 'SignupQuestionnaire';
import { sharedConfig } from 'syncedData/sharedConfig';

import { useStateManagement } from '../../useStateManagement';

const prefillNameAndBirthDate = <
  Questionnaire extends QuestionnaireQuestions,
  Key extends keyof Questionnaire
>(
  questionnaireAnswers: Partial<Questionnaire>,
  questionId: Key,
  answer: Questionnaire[Key],
  account?: UserWithBrokerMandate
) => {
  if (
    questionId === 'email' &&
    account &&
    account.email === answer &&
    questionnaireAnswers.email !== answer
  ) {
    let toUpdate: Partial<QuestionnaireQuestions> = {};
    const { firstName, lastName } = account;

    if (firstName && lastName) {
      toUpdate = { ...toUpdate, name: { firstName, lastName } };
    }
    return toUpdate;
  }

  return null;
};

interface SignupQuestionnaireProps {
  basePath: string;
  components: Record<string, ReactNode>;
  questionnairePath: string;
  data: QuestionnaireJSON;
  regionId?: string;
  verticalId: string;
  mainPolicy?: Policy;
  onQuestionnaireAnswersChanged?: (answers: QuestionnaireQuestions) => void;
  debug?: boolean;
}

export const SignupQuestionnaire = ({
  basePath: basePathRoute,
  components,
  questionnairePath: questionnairePathRoute,
  data,
  regionId,
  verticalId,
  mainPolicy,
  onQuestionnaireAnswersChanged,
  debug = false,
}: SignupQuestionnaireProps) => {
  const {
    meta,
    questions,
    rules: preRules,
    removeAnswersLogic = {},
    calculatedFields = [],
  } = data;
  const account = useSelector(getAccountInfo);
  const {
    updateQuestionnaireAnswers,
    removeQuestionnaireAnswers,
    questionnaireAnswers,
  } = useStateManagement(meta.insuranceType);

  const rules = interpolateData<QuestionnaireJSON['rules']>(preRules, {
    answers: questionnaireAnswers,
    constants: {
      ...sharedConfig,
      ...meta.constants,
    },
    mainPolicy: meta.isDependent ? mainPolicy : undefined,
  });

  useEffect(() => {
    if (
      regionId &&
      (!questionnaireAnswers.region || questionnaireAnswers.region !== regionId)
    ) {
      updateQuestionnaireAnswers({ region: regionId });
    }
    if (mainPolicy && !questionnaireAnswers.mainPolicyId) {
      updateQuestionnaireAnswers({ mainPolicyId: mainPolicy.id });
    }
  }, [
    questionnaireAnswers.region,
    regionId,
    mainPolicy,
    updateQuestionnaireAnswers,
    questionnaireAnswers.mainPolicyId,
  ]);

  useEffect(() => {
    if (typeof onQuestionnaireAnswersChanged !== 'undefined') {
      onQuestionnaireAnswersChanged?.(questionnaireAnswers);
    }
  }, [onQuestionnaireAnswersChanged, questionnaireAnswers]);

  const onAnswer = (
    questionId: string,
    answer: unknown,
    additionalData: Partial<Record<string, unknown>> = {},
    calculatedFieldsAnswers: Record<string, number> = {}
  ) => {
    updateQuestionnaireAnswers({
      ...additionalData,
      ...calculatedFieldsAnswers,
      [questionId]: answer,
    });

    const answersToRemove = stateManagerHelper(
      removeAnswersLogic,
      questionnaire.components,
      questionnaireAnswers,
      questionnaire.rules
    ).getAnswersToRemove(questionId, answer);

    removeQuestionnaireAnswers(answersToRemove);

    const toUpdate = prefillNameAndBirthDate(
      questionnaireAnswers,
      questionId,
      answer,
      account
    );
    if (toUpdate) {
      updateQuestionnaireAnswers(toUpdate);
    }
  };

  const basePath = basePathRoute
    .replace(':verticalId', verticalId)
    .replace(':regionId', String(regionId));

  const questionnairePath = questionnairePathRoute
    .replace(':verticalId', verticalId)
    .replace(':regionId', String(regionId));

  const questionnaire = {
    components: questions,
    routes: getRoutes(questions, basePath),
    rules,
    calculatedFields,
  };

  return (
    <Switch>
      <Route path={questionnairePath}>
        <QNRSignupQuestionnaire
          questionnaireAnswers={questionnaireAnswers}
          questionnaire={questionnaire}
          onAnswer={onAnswer}
          configuration={{
            components,
            questions,
            debug,
          }}
          basePath={basePath}
          questionId="intro"
          featureName={meta.insuranceType}
          share={{
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            verticalId,
          }}
        />
      </Route>
    </Switch>
  );
};
