import * as Sentry from '@sentry/react';
import { useMutation } from '@tanstack/react-query';
import endpoint from 'shared/api';

export const useRequestLoginCode = () => {
  const onRequestLoginCode = ({ email }: { email: string }) => {
    return endpoint.sendSignInEmail(email);
  };

  const mutation = useMutation({
    mutationFn: onRequestLoginCode,
    onError: (error, variables) => {
      const { email } = variables;
      Sentry.captureException(error, {
        tags: {
          feature: 'ACCOUNT',
        },
        extra: {
          email,
          description: 'Error happened while sending sign in code via email',
        },
      });
    },
  });

  return mutation;
};
