import { InsuranceTypes } from '../../models/insurances/types';
import { isMobileApp } from '../util/isMobileApp';

export const triggerPushNotifications = (insuranceType: InsuranceTypes) => {
  if (isMobileApp) {
    window?.ReactNativeWebView?.postMessage(
      JSON.stringify({
        pushNotificationTrigger: {
          type: insuranceType,
        },
      })
    );
  }
};
