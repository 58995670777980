import { getBikeConfig } from './pages/bike/getBikeConfig';
import { getBikeSpainConfig } from './pages/bikeSpain/getBikeSpainConfig';
import { getCompanyHealthConfig } from './pages/companyHealth/getCompanyHealthConfig';
import { getCompanyLifeConfig } from './pages/companyLife/getCompanyLifeConfig';
import { getDentalConfig } from './pages/dental/getDentalConfig';
import { getDentalSpainConfig } from './pages/dentalSpain/getDentalSpainConfig';
import { getDisabilityConfig } from './pages/disability/getDisabilityConfig';
import { getDogLiabilityConfig } from './pages/dogLiability/getDogLiabilityConfig';
import { getDogLiabilitySpainConfig } from './pages/dogLiabilitySpain/getDogLiabilitySpainConfig';
import { getExpatEuHealthConfig } from './pages/expatEu/getExpatEuHealthConfig';
import { getExpatHealthConfig } from './pages/expatHealth/getExpatHealthConfig';
import { getExpatLongTermHealthConfig } from './pages/expatLongTerm/getExpatLongTermConfig';
import { getExpatSpainConfig } from './pages/expatSpain/getExpatSpainConfig';
import { getBaseConfig } from './pages/generic/getBaseConfig';
import { getHouseholdConfig } from './pages/household/getHouseholdConfig';
import { getLegalConfig } from './pages/legal/getLegalConfig';
import { getLiabilityConfig } from './pages/liability/getLiabilityConfig';
import { getLifeConfig } from './pages/life/getLifeConfig';
import { getLifeEuConfig } from './pages/lifeEu/getLifeEuConfig';
import { getPensionConfig } from './pages/pension/getPensionConfig';
import { getPetHealthConfig } from './pages/petHealth/getPetHealthConfig';
import { getPrivateHealthConfig } from './pages/privateHealth/getPrivateHealthConfig';
import { getPublicHealthConfig } from './pages/publicHealth/getPublicHealthConfig';
import { getTravelFranceConfig } from './pages/travelHealthFrance/getTravelFranceConfig';
import { GetPolicyConfigArgs, PolicyConfig } from './PolicySingle.models';
import { getTravelHealthConfig } from './travelHealth/getTravelHealthConfig';

export const getPolicyConfig = (...args: GetPolicyConfigArgs): PolicyConfig => {
  const [policy] = args;

  switch (policy.type) {
    case 'BIKE':
      return getBikeConfig(args);
    case 'LEGAL':
      return getLegalConfig(args);
    case 'DENTAL':
      return getDentalConfig(args);
    case 'COMPANY_HEALTH':
      return getCompanyHealthConfig(args);
    case 'INCOMING':
    case 'EXPAT_V2':
      return getExpatHealthConfig(args);
    case 'HOUSEHOLD':
      return getHouseholdConfig(args);
    case 'DOG_LIABILITY':
      return getDogLiabilityConfig(args);
    case 'PET_HEALTH':
      return getPetHealthConfig(args);
    case 'PUBLIC_HEALTH':
      return getPublicHealthConfig(args);
    case 'LIABILITY':
      return getLiabilityConfig(args);
    case 'LIFE':
    case 'LIFE_V2':
      return getLifeConfig(args);
    case 'PRIVATE_HEALTH':
      return getPrivateHealthConfig(args);
    case 'COMPANY_LIFE':
      return getCompanyLifeConfig(args);
    case 'TRAVEL':
      return getTravelHealthConfig(args);
    case 'PENSION':
      return getPensionConfig(args);
    case 'INCOMING_ES':
      return getExpatSpainConfig(args);
    case 'DISABILITY':
      return getDisabilityConfig(args);
    case 'TRAVEL_FR':
      return getTravelFranceConfig(args);
    case 'INCOMING_EU':
      return getExpatEuHealthConfig(args);
    case 'INCOMING_LT':
      return getExpatLongTermHealthConfig(args);
    case 'DOG_LIABILITY_ES':
      return getDogLiabilitySpainConfig(args);
    case 'BIKE_ES':
      return getBikeSpainConfig(args);
    case 'DENTAL_ES':
      return getDentalSpainConfig(args);
    case 'LIFE_EU':
      return getLifeEuConfig(args);
    default:
      return getBaseConfig(args);
  }
};
