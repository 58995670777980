import { notion } from '@getpopsure/private-constants';
import {
  AddOn,
  Tariff,
} from '@getpopsure/private-health-insurance-pricing-engine';

export const coverageOverviewUrls: Partial<Record<Tariff, string>> = {
  NK: notion.privatehHealthCoverage.NK,
  NKSelectS: notion.privatehHealthCoverage.NKSelectS,
  NKSelectL: notion.privatehHealthCoverage.NKSelectL,
  NKSelectXL: notion.privatehHealthCoverage.NKSelectXL,
  Primo: notion.privatehHealthCoverage.Primo,
  HiMedical: notion.privatehHealthCoverage.HiMedical,
  HiMedicalPlus: notion.privatehHealthCoverage.HiMedicalPlus,
};

export const coverageOverviewWithTravelUrls: Partial<Record<Tariff, string>> = {
  NK: notion.privatehHealthCoverage.NKWithTravel,
  NKSelectS: notion.privatehHealthCoverage.NKSelectSWithTravel,
  NKSelectL: notion.privatehHealthCoverage.NKSelectLWithTravel,
  NKSelectXL: notion.privatehHealthCoverage.NKSelectXLWithTravel,
  Primo: notion.privatehHealthCoverage.PrimoWithTravel,
};

export const shortTermCoverageUrls: Partial<
  Record<
    Tariff,
    Record<
      'dental' | 'dentalAndTravel' | 'dentalPremium' | 'dentalPremiumAndTravel',
      string
    >
  >
> = {
  HiMedical: {
    dental: notion.privatehHealthCoverage.hiMedicalDental,
    dentalAndTravel: notion.privatehHealthCoverage.hiMedicalDentalAndTravel,
    dentalPremium: notion.privatehHealthCoverage.hiMedicalDentalPremium,
    dentalPremiumAndTravel:
      notion.privatehHealthCoverage.hiMedicalDentalPremiumAndTravel,
  },
  HiMedicalPlus: {
    dental: notion.privatehHealthCoverage.hiMedicalPlusDental,
    dentalAndTravel: notion.privatehHealthCoverage.hiMedicalPlusDentalAndTravel,
    dentalPremium: notion.privatehHealthCoverage.hiMedicalPlusDentalPremium,
    dentalPremiumAndTravel:
      notion.privatehHealthCoverage.hiMedicalPlusDentalPremiumAndTravel,
  },
};

export const getCoverageOverviewURL = (
  planName: Tariff,
  addons: AddOn[] = []
): string => {
  const hasTravelCoverage = addons.includes('URZ');
  const hasDentalBenefits = addons.includes('HD');
  const hasPremiumDentalBenefits = addons.includes('HDL');
  const coverageUrlWithTravel = coverageOverviewWithTravelUrls[planName] || '';

  if (planName === 'NLTN.') {
    return notion.privatehHealthCoverage.NLTN;
  }

  if (hasTravelCoverage && coverageUrlWithTravel) {
    return coverageUrlWithTravel;
  }

  if (['HiMedical', 'HiMedicalPlus'].includes(planName)) {
    if (hasDentalBenefits && hasTravelCoverage) {
      return shortTermCoverageUrls[planName]?.dentalAndTravel || '';
    }
    if (hasDentalBenefits) {
      return shortTermCoverageUrls[planName]?.dental || '';
    }
    if (hasPremiumDentalBenefits && hasTravelCoverage) {
      return shortTermCoverageUrls[planName]?.dentalPremiumAndTravel || '';
    }
    if (hasPremiumDentalBenefits) {
      return shortTermCoverageUrls[planName]?.dentalPremium || '';
    }
  }

  return coverageOverviewUrls[planName] || '';
};
