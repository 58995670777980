import { setRequestErrored, setRequestInProcess } from 'actions/request';
import { HealthCardActions } from 'constants/actions';
import { APIResponseError } from 'models/error';
import {
  HealthCard,
  HealthCardDispatch,
  UpdateHealthCardAddress,
} from 'models/healthCard';
import endpoint from 'shared/api';

export const mergeHealthCardDetails = (
  id: string,
  details: HealthCard
): HealthCardActions => ({
  type: 'MERGE_HEALTH_CARD_DETAILS',
  payload: {
    id,
    details,
  },
});

export const fetchHealthCard =
  (healthCardId: string) => async (dispatch: HealthCardDispatch) => {
    const REQUEST_TYPE = 'FETCH_HEALTH_CARD_DETAILS';
    dispatch(setRequestInProcess(true, REQUEST_TYPE));

    try {
      const { data } = await endpoint.fetchHealthCardDetails(healthCardId);

      dispatch(mergeHealthCardDetails(healthCardId, data));
      dispatch(setRequestInProcess(false, REQUEST_TYPE));
      return data;
    } catch (error) {
      dispatch(setRequestErrored(error as APIResponseError, REQUEST_TYPE));
    }
  };

export const updateHealthCardAddress = (payload: UpdateHealthCardAddress) => {
  return async (dispatch: HealthCardDispatch) => {
    const { id } = payload;
    const REQUEST_TYPE = 'UPDATE_HEALTH_CARD_DETAILS';
    dispatch(setRequestInProcess(true, REQUEST_TYPE));

    try {
      await endpoint.updateHealthCardAddress(payload);

      const { data } = await endpoint.fetchHealthCardDetails(id);

      await dispatch(mergeHealthCardDetails(id, data));

      dispatch(setRequestInProcess(false, REQUEST_TYPE));
    } catch (error) {
      dispatch(setRequestErrored(error as APIResponseError, REQUEST_TYPE));
    }
  };
};
