import { Button } from '@popsure/dirty-swan';
import classNames from 'classnames';
import routes from 'constants/routes';
import { Link } from 'react-router-dom';
import { Trans, useSafeTranslation } from 'shared/i18n';

import slide1 from '../../assets/slide-1.png';
import slide2 from '../../assets/slide-2.png';
import slide3 from '../../assets/slide-3.png';
import { Carousel } from '../Carousel';
import styles from './style.module.scss';

const MobileAppView = () => {
  const { t } = useSafeTranslation();

  return (
    <div className="bg-purple-100 w100">
      <div
        className={classNames(
          'wmx6 mx-auto d-flex fd-column jc-between pt56 pb40',
          styles.wrapper
        )}
      >
        <div>
          <Carousel
            slides={[
              <>
                <h2 className="p-h1 p--serif tc-grey-900 ta-center mt64 mb24">
                  <Trans i18nKey="main.mobile.slide.title1">
                    All your insurances <br />
                    in one place
                  </Trans>
                </h2>

                <img src={slide1} className="w100" alt="" />
              </>,
              <>
                <h2 className="p-h1 p--serif tc-grey-900 ta-center mt64 mb24">
                  <Trans i18nKey="main.mobile.slide.title2">
                    Everything
                    <br />
                    in English
                  </Trans>
                </h2>

                <img src={slide2} className="w100" alt="" />
              </>,
              <>
                <h2 className="p-h1 p--serif tc-grey-900 ta-center mt64 mb24">
                  <Trans i18nKey="main.mobile.slide.title3">
                    Top-rated
                    <br />
                    customer support
                  </Trans>
                </h2>

                <img src={slide3} className="w100" alt="" />
              </>,
            ]}
          />
        </div>

        <div
          className={classNames(
            'd-flex fd-column w100 gap8 px24',
            styles.buttonsContainer
          )}
        >
          <Button
            as={Link}
            type="button"
            to={routes.me.policies.list.path}
            data-cy="main-show-policies"
          >
            {t('main.mobile.seePoliciesButton.caption', 'Get covered')}
          </Button>

          <Button
            as={Link}
            to={routes.account.signIn.path}
            data-cy="main-login"
            variant="textColor"
          >
            {t('main.mobile.logInButton.caption', 'Log in')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MobileAppView;
