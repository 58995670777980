import * as Sentry from '@sentry/react';

export const healthCardMutationErrorHandler = (
  error: unknown,
  variables: Record<string, unknown>
) => {
  Sentry.captureException(error, {
    tags: {
      feature: 'HEALTH_CARD_CHECKLIST',
      vertical: 'PUBLIC_HEALTH',
    },
    extra: {
      ...variables,
      description: 'Error happened during health card checklist mutation',
    },
  });
};
