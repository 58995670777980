import * as Sentry from '@sentry/react';
import { useMutation } from '@tanstack/react-query';
import endpoint from 'shared/api';
import { PayoutDetails } from 'shared/models/types';

import { triggerPushNotifications } from '../../../shared/pushNotifications';
import {
  HouseholdClaims,
  HouseholdClaimsSubmitInfo,
  ZHouseholdClaimsSubmissionSchema,
} from '../models';

export const useSubmitHouseholdClaim = () => {
  const onSubmitClaim = ({
    questionnaire,
    policyId,
    payoutDetails,
  }: {
    questionnaire: Partial<HouseholdClaims> | undefined;
    policyId: string;
    payoutDetails: PayoutDetails;
  }) => {
    const validation = ZHouseholdClaimsSubmissionSchema.parse({
      ...questionnaire,
      payoutDetails: {
        ...payoutDetails,
        amount: Number(payoutDetails.amount),
      },
    });

    const { claimType, uploadedDocumentTokens, details, dateOfEvent } =
      validation;

    const tokens = uploadedDocumentTokens.flatMap(({ token }) =>
      token !== undefined ? [token] : []
    );

    const submittableInfo: HouseholdClaimsSubmitInfo = {
      type: 'HOUSEHOLD',
      userPolicyId: policyId,
      uploadedDocumentTokens: tokens,
      claimType,
      details,
      iban: payoutDetails.iban,
      amount: Number(payoutDetails.amount),
      dateOfEvent,
    };

    return endpoint.submitClaim(submittableInfo);
  };

  const mutation = useMutation({
    mutationFn: onSubmitClaim,
    onError: (error, variables) => {
      const { policyId, questionnaire } = variables;
      Sentry.captureException(error, {
        tags: {
          feature: 'CLAIMS',
          vertical: 'HOUSEHOLD',
        },
        extra: {
          policyId,
          questionnaire,
          description: 'Error happened while submitting Household claims',
        },
      });
    },
    onSuccess: () => {
      triggerPushNotifications('HOUSEHOLD');
    },
  });

  return mutation;
};
