import LoadingSpinner from 'components/loadingSpinner';
import { isValidPhoto } from 'features/healthCard/util/isValidPhoto';
import { useQueryParamValue } from 'hooks/useQueryParamValue';
import { featherIcon } from 'icons';
import { FileExtension } from 'models/healthCard';
import { ChangeEvent, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { useSafeTranslation } from 'shared/i18n';
import { getFileType } from 'shared/util/getFileType';

import { useUploadHealthCardPhoto } from '../hooks/useUploadHealthCardPhoto';
import CompletedView from './completed';
import ErrorView from './error';
import photoIcon from './img/photo-icon.svg';
import style from './style.module.scss';

const getPhotoFromEvent = (event: ChangeEvent<HTMLInputElement>): File | null =>
  event.target.files && event.target.files.length > 0
    ? event.target.files[0]
    : null;

const HealthCardPhoto = () => {
  const { t } = useSafeTranslation();
  const token = useQueryParamValue('hctoken');
  const [photoUrl, setPhotoUrl] = useState<string>('');
  const [completed, setCompleted] = useState<boolean>(false);
  const [validationError, setValidationError] = useState<string>('');
  const {
    mutate: uploadHealthCardPhoto,
    isLoading: uploadLoading,
    isError: uploadError,
  } = useUploadHealthCardPhoto();

  if (!token) {
    throw new Error(
      '[HEALTH_CARD_CHECKLIST] trying to upload photo without a valid token'
    );
  }
  if (uploadError) return <ErrorView />;

  const handleInputChange = async (event: ChangeEvent<HTMLInputElement>) => {
    setValidationError('');
    const photo = getPhotoFromEvent(event);
    const {
      sub: { healthCardId },
      // TODO: [KONG] Define token type
      // eslint-disable-next-line
    } = token as any;

    if (!photo) return;

    const isValid = await isValidPhoto(photo);

    if (isValid) {
      uploadHealthCardPhoto(
        {
          token,
          id: healthCardId,
          file: photo,
          fileExtension: getFileType(photo.name) as FileExtension,
        },
        {
          onSuccess: (downloadUrl) => {
            setPhotoUrl(downloadUrl ?? '');
            setCompleted(true);
          },
        }
      );
    } else {
      setValidationError(
        'Error: the photo should be at least 300px x 400px, and less than 15MB'
      );
    }
  };

  if (completed && photoUrl) return <CompletedView photo={photoUrl} />;

  return (
    <div className="d-flex fd-column ai-center w100 wmx4 m-auto ta-center py24 px16">
      <img src={featherIcon} alt="Feather logo" className="mb32" />
      <h1 className="p-h1 mb8">
        {t('publichealth.policydetails.photoupload.title', 'Upload a photo')}
      </h1>
      <p className="p-p mb16">
        {t(
          'publichealth.policydetails.photoupload.description',
          'Use a neutral background and don’t forget to smile 😄'
        )}
      </p>
      {uploadLoading ? (
        <div
          className={`tc-primary-500 bg-primary-100 ${style['spinner-container']}`}
        >
          <LoadingSpinner />
        </div>
      ) : (
        <img width="132" height="178" src={photoIcon} alt="Portrait icon" />
      )}
      <AnimateHeight duration={100} height={validationError ? 'auto' : 0}>
        <div className="p-notice p-notice--danger mt16 p-p">
          {validationError}
        </div>
      </AnimateHeight>
      {!photoUrl && (
        <label
          className={`${
            photoUrl ? 'p-btn--secondary mt8' : 'p-btn--primary mt24'
          } w100`}
          htmlFor="health-card-photo-file-input"
        >
          {t('publichealth.policydetails.photoupload.cta', 'Choose file')}
          <input
            id="health-card-photo-file-input"
            type="file"
            accept="image/*"
            className={style.hidden}
            onChange={handleInputChange}
          />
        </label>
      )}
    </div>
  );
};

export default HealthCardPhoto;
