import {
  BellIcon,
  Button,
  EyeVisionIcon,
  FileTextIcon,
  HeartIcon,
  IconWrapperProps,
  LogOutIcon,
  MailIcon,
  MessageCircleIcon,
  toast,
  UserIcon,
} from '@popsure/dirty-swan';
import LanguageSwitcher from 'components/LanguageSwitcher';
import { TallyModal } from 'components/TallyModal';
import routes from 'constants/routes';
import {
  getShowReferralGenerator,
  ReferralCodeGenerator,
} from 'features/referralEngine';
import { useRegion } from 'hooks/useRegion';
import Cookies from 'js-cookie';
import { Policy } from 'models/policies';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getAccountInfo } from 'selectors/user';
import { Locale, useSafeTranslation } from 'shared/i18n';
import { isMobileApp } from 'shared/util/isMobileApp';

import { IntercomLauncher } from '../../../intercomLauncher';
import { AccountHeader } from '../../components/AccountHeader';
import { ActionListItem, ActionsList } from '../../components/ActionsList';
import styles from './Account.module.scss';

interface AccountViewProps {
  policies?: Policy[];
  onSignOut: () => void;
  showLanguageSwitcher?: boolean;
  updateUserLanguage: (locale: Locale, successCb?: () => void) => void;
  userId?: string;
}

export const AccountView = ({
  onSignOut,
  updateUserLanguage,
  showLanguageSwitcher = true,
  policies,
  userId,
}: AccountViewProps) => {
  const appVersion = Cookies.get('MOBILE_APP_VERSION');
  const { t } = useSafeTranslation();
  const { region } = useRegion();
  const accountInfo = useSelector(getAccountInfo);
  const [isLeaveFeedbackModalOpen, setIsLeaveFeedbackModalOpen] =
    useState(false);

  const iconProps: IconWrapperProps = {
    size: 24,
    noMargin: true,
  };

  const actionsSettings: ActionListItem[] = [
    {
      key: 'account.actions.referFriend',
      customRender: (cardProps) => (
        <div data-testid="referral-button">
          <ReferralCodeGenerator
            cardProps={{
              ...cardProps,
              icon: <HeartIcon {...iconProps} />,
            }}
            medium="account"
          />
        </div>
      ),
      shouldRender: getShowReferralGenerator(policies),
    },
    {
      title: t('account.actions.leaveFeedback.title', 'Leave feedback'),
      icon: <MessageCircleIcon {...iconProps} />,
      onClick: () => setIsLeaveFeedbackModalOpen(true),
      key: 'account.actions.leaveFeedback',
    },
    {
      customRender: (cardProps) => (
        <IntercomLauncher
          icon={<MailIcon {...iconProps} />}
          cardProps={cardProps}
        />
      ),
      key: 'account.actions.intercom',
    },
  ];

  const personalSettings: ActionListItem[] = [
    {
      title: t('account.settings.accountSettings.title', 'Account settings'),
      icon: <UserIcon {...iconProps} />,
      key: 'account.settings.accountSettings',
      as: Link,
      to: routes.me.accountSettings.path,
    },
    {
      title: t(
        'account.settings.billingAndPayment.title',
        'Billing and payment'
      ),
      icon: <FileTextIcon {...iconProps} />,
      key: 'account.settings.billingAndPayment',
      as: Link,
      to: routes.account.billingAndPayment.path,
    },
    {
      title: t('account.settings.communication.title', 'Communication'),
      icon: <BellIcon {...iconProps} />,
      key: 'account.settings.communicationPreferences',
      as: Link,
      to: routes.me.communicationPreferences.path,
    },
    {
      title: t(
        'account.settings.privacyAndTerms.title',
        'Privacy, terms and security'
      ),
      icon: <EyeVisionIcon {...iconProps} />,
      key: 'account.settings.privacyAndTerms',
      as: Link,
      to: routes.account.privacyAndTerms.path,
    },
  ];

  const onLanguageUpdate = (locale: Locale) => {
    updateUserLanguage(locale, () => {
      toast(
        t(
          'account.settings.language.modal.success',
          'Your language preference was successfully updated'
        ),
        {
          type: 'success',
        }
      );
    });
  };

  return (
    <>
      <div className="p-body">
        {accountInfo && (
          <AccountHeader
            onSignOut={onSignOut}
            accountInfo={accountInfo}
            showLanguageSwitcher={false}
            onLanguageUpdate={onLanguageUpdate}
          />
        )}

        <div className={`d-flex w100 mt16 ${styles.actionsWrapper}`}>
          <div className={styles.actionsColumn}>
            <ActionsList
              title={t('account.settings.title', 'Personal settings')}
              items={personalSettings}
            />
          </div>

          <div className={styles.actionsColumn}>
            <ActionsList
              title={t('account.actions.title', 'Actions')}
              items={actionsSettings}
            />
          </div>
        </div>

        <div className="w100 ta-center">
          <Button
            className={`w100 my32 ${styles.signOutButton}`}
            leftIcon={<LogOutIcon />}
            onClick={onSignOut}
            variant="filledWhite"
          >
            {t('account.mainPage.signOut', 'Sign out')}
          </Button>

          {showLanguageSwitcher && (
            <div className="my32">
              <LanguageSwitcher onUpdate={onLanguageUpdate} />
            </div>
          )}

          {Boolean(isMobileApp && appVersion) && (
            <p className="p-p--small fw-normal tc-grey-500">{appVersion}</p>
          )}
        </div>
      </div>

      {isLeaveFeedbackModalOpen && (
        <TallyModal
          tallyId="wgMp6M"
          isVisible
          onClose={() => setIsLeaveFeedbackModalOpen(false)}
          URLparams={{
            source: isMobileApp ? 'app' : 'web',
            ID: userId,
            country: region,
          }}
        />
      )}
    </>
  );
};
