import {
  PageNotFound,
  QuestionnaireQuestions,
} from '@getpopsure/qnr-framework';
import * as Sentry from '@sentry/react';
import { ErrorWithAction } from 'components/ErrorWithAction';
import LoadingSpinner from 'components/loadingSpinner';
import routes from 'constants/routes';
import { useLoadQuestionnaire } from 'features/generalQuestionnaire/functions';
import { useGetPolicy } from 'features/policySingle/hooks/useGetPolicy';
import { useState } from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { useSafeTranslation } from 'shared/i18n';
import { isStagingOrDev } from 'shared/util/isStagingOrDev';

import { GeneralClaimsQuestionnaire } from './GeneralClaimsQuestionnaire';

export const GeneralClaims = () => {
  const { policyId, verticalId } = useParams<{
    policyId: string;
    verticalId: string;
  }>();
  const { t } = useSafeTranslation();

  const { isLoading: policyLoading, policy, error } = useGetPolicy(policyId);
  const policyNumber = policy?.attributes.policyNumber;

  const history = useHistory();
  const [answers, setAnswers] = useState<QuestionnaireQuestions>({});
  const json = useLoadQuestionnaire(verticalId ?? '', false, true);

  if (verticalId === undefined || json === undefined) {
    return <LoadingSpinner />;
  }

  if (!json) {
    return <PageNotFound />;
  }

  const data = json(t, { answers });

  if (!data || (!isStagingOrDev && !data.meta.published)) {
    return <PageNotFound />;
  }

  const handlePolicyError = () => {
    const policyDetailsPage = generatePath(routes.me.policies.path, {
      policyId,
    });
    history.push(policyDetailsPage);
  };

  if (policyLoading || !policy) {
    return <LoadingSpinner />;
  }

  if (!policyNumber) {
    return (
      <ErrorWithAction
        title={t(
          'claims.general.policyNumberError.title',
          'Policy number not found'
        )}
        description={t(
          'claims.general.policyNumberError.description',
          "Sorry, your policy is currently missing the policy number but it's required to submit the claim."
        )}
        cta={{
          title: t(
            'claims.general.policyNumberError.cta',
            'Go back to your policies'
          ),
          onClick: handlePolicyError,
        }}
      />
    );
  }

  if (error) {
    Sentry.captureException(error, {
      tags: {
        feature: 'GENERAL_CLAIMS',
        vertical: verticalId,
      },
      extra: {
        policyId,
      },
    });

    return (
      <ErrorWithAction
        title={t(
          'claims.general.startClaimError.title',
          'Something went wrong'
        )}
        description={t(
          'claims.general.startClaimError.description',
          'Sorry, something didn’t work as it should. Some information needed to submit the claim are missing.'
        )}
        cta={{
          title: t(
            'claims.general.startClaimError.cta',
            'Go back to your policies'
          ),
          onClick: handlePolicyError,
        }}
      />
    );
  }

  return (
    <GeneralClaimsQuestionnaire
      data={data}
      onQuestionnaireAnswersChanged={setAnswers}
      policy={policy}
      verticalId={verticalId}
    />
  );
};
