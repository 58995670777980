import { PaymentMethodSelector } from 'features/paymentMethodSelector/containers';
import { PaymentOption } from 'features/paymentMethodSelector/paymentMethodSelector.models';
import { PriceInfo } from 'features/paymentScreen/paymentScreen.models';
import { formatProratedPeriod } from 'features/paymentScreen/utils/paymentMethod.utils';
import { useSafeTranslation } from 'shared/i18n';
import englishFormattedEuroCurrency from 'shared/util/priceFormatter';

export type PaymentMethodProps = {
  priceInfo: PriceInfo;
  setPaymentOption: (paymentOption: PaymentOption) => void;
  onStripePaymentMethodTypeChange: (type: string) => void;
  showPaymentSelector?: boolean;
};

export const PaymentMethod = ({
  priceInfo: { totalMonthlyPrice, totalProratedPrice, proratedPeriod },
  setPaymentOption,
  onStripePaymentMethodTypeChange,
  showPaymentSelector = true,
}: PaymentMethodProps) => {
  const { t } = useSafeTranslation();

  const isProratedPriceSameAsMonthly = totalProratedPrice === totalMonthlyPrice;

  const partialStr = isProratedPriceSameAsMonthly
    ? ''
    : `${t('paymentScreen.paymentMethod.firstPayment.partial', 'partial')} `;
  return (
    <div className="p24 br8 bg-white bs-xs">
      <div className="p-h2 mb16">
        {t('paymentScreen.paymentMethod.title', 'Payment method')}
      </div>

      <div className="mt16 p-p">
        {t(
          'paymentScreen.paymentMethod.description',
          'Please select the account that you wish to pay with. This will be charged on the 1st day of each month.'
        )}
      </div>

      <div className="mt16 p-p tc-grey-600">
        {t('paymentScreen.paymentMethod.firstPayment.description', {
          defaultValue:
            'Your first {{partial}} payment {{proratedPeriod}} of {{price}} will be taken today.',
          partial: partialStr,
          proratedPeriod: proratedPeriod
            ? `(${formatProratedPeriod(proratedPeriod)})`
            : '',
          price: totalProratedPrice
            ? englishFormattedEuroCurrency(totalProratedPrice)
            : englishFormattedEuroCurrency(totalMonthlyPrice || 0),
        })}
      </div>

      {showPaymentSelector && (
        <div className="mt16">
          <PaymentMethodSelector
            handlePaymentOptionUpdate={setPaymentOption}
            onStripePaymentMethodTypeChange={onStripePaymentMethodTypeChange}
          />
        </div>
      )}
    </div>
  );
};
