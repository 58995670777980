import {
  trackCheckoutScreenReached,
  trackPaymentMethodAdded,
} from '@getpopsure/analytics';
import { toast } from '@popsure/dirty-swan';
import { captureException } from '@sentry/browser';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeError } from '@stripe/stripe-js';
import { useFlag, useFlagsStatus } from '@unleash/proxy-client-react';
import { flushRequestError } from 'actions/request';
import { fetchAccountInfo } from 'actions/user';
import axios, { AxiosError } from 'axios';
import classNames from 'classnames';
import { ErrorWithAction } from 'components/ErrorWithAction';
import LoadingSpinner from 'components/loadingSpinner';
import TimedLoadSpinner from 'components/timedLoadSpinner';
import routes from 'constants/routes';
import { areDocumentsValid } from 'features/checkoutDocuments/actions';
import { getDocuments } from 'features/checkoutDocuments/checkoutDocuments.selectors';
import { usePollCheckoutDocuments } from 'features/checkoutDocuments/hooks';
import { prepareForPayment } from 'features/paymentMethodSelector/paymentMethodSelector.thunks';
import {
  isCustomerFacingError,
  isReadyForPayment,
} from 'features/paymentMethodSelector/paymentMethodSelector.utils';
import CheckoutDocuments from 'features/paymentScreen/components/CheckoutDocuments/CheckoutDocuments';
import { PolicyDetails } from 'features/paymentScreen/components/PolicyDetailsNew/PolicyDetails';
import { PriceBreakdown } from 'features/paymentScreen/components/PriceBreakdown/PriceBreakdown';
import { ReferralCodeForm } from 'features/paymentScreen/components/ReferralCode/ReferralCode';
import {
  getCheckoutInfo,
  getReferralCode,
} from 'features/paymentScreen/paymentScreen.selectors';
import {
  onSuccessfulPayment,
  pay,
  PaymentScreenThunkDispatch,
} from 'features/paymentScreen/paymentScreen.thunks';
import { isValidStartDate } from 'features/paymentScreen/utils/changeStartDate.utils';
import { checkHasCheckoutDocuments } from 'features/paymentScreen/utils/hasCheckoutDocuments.utils';
import { isStripeError } from 'features/paymentScreen/utils/paymentMethod.utils';
import { CheckoutContext } from 'features/paymentScreenV2/checkout.context';
import { PayComponent } from 'features/paymentScreenV2/components/PayComponent/PayComponent';
import {
  clearReferrerCode,
  flushReferrerCodeError,
  getIsValidReferrerCode,
  mergeUserReferralInfo,
} from 'features/referralEngine/actions';
import { REFERRAL_ENGINE_REQUEST_TYPE } from 'features/referralEngine/constants';
import {
  getReferrerCodeError,
  getValidReferrerCode,
} from 'features/referralEngine/selectors';
import { useQueryParamValue } from 'hooks/useQueryParamValue';
import { useRequestStatus } from 'hooks/useRequestStatus';
import lz from 'lz-string';
import { isValidInsuranceType } from 'models/insurances/types';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory, useParams } from 'react-router';
import { getAccountInfo, getUserId } from 'selectors/user';
import { useSafeTranslation } from 'shared/i18n';
import { isMobileApp } from 'shared/util/isMobileApp';
import { paramsSetUrl } from 'shared/util/paramsSetUrl';

import styles from './styles.module.scss';

const PaymentScreenV2 = () => {
  const { checkoutId }: { checkoutId: string } = useParams();
  const {
    paymentOption,
    setAddNewPaymentMethod,
    setPaymentOption,
    currentUserPaymentMethods: paymentOptions,
    setCurrentSelectedPaymentMethod,
    currentSelectedPaymentMethod,
    setIsSubmittingNewPaymentMethod,
    isProcessingPayment,
    setIsProcessingPayment,
    setAddress,
  } = useContext(CheckoutContext);

  const { t } = useSafeTranslation();
  const [checkboxValues, setCheckboxValues] = useState<string[]>([]);

  const dispatch = useDispatch<PaymentScreenThunkDispatch>();
  const history = useHistory();
  const referralCode = useSelector(getReferralCode);
  const checkoutInfo = useSelector(getCheckoutInfo(checkoutId));
  const userId = useSelector(getUserId);
  const redirectStatus = useQueryParamValue('redirect_status');

  const account = useSelector(getAccountInfo);
  const { localeId }: { localeId: string } = useParams();
  const { flagsReady } = useFlagsStatus();
  const isPayPalAvailable = useFlag('app_payment_method_paypal');

  const configurationParam = useQueryParamValue('c');
  const decompressedConfiguration = configurationParam
    ? lz.decompressFromEncodedURIComponent(configurationParam)
    : undefined;
  const configuration = decompressedConfiguration
    ? JSON.parse(decompressedConfiguration)
    : undefined;

  const documents = useSelector(
    getDocuments(checkoutInfo?.metaData?.questionnaireId)
  );
  const checkoutInsuranceType = checkoutInfo?.mainPolicy.policyDetails.type;
  isValidInsuranceType(checkoutInsuranceType);

  const hasCheckoutDocuments = checkHasCheckoutDocuments(
    configuration,
    checkoutInsuranceType
  );

  const { startPollingDocuments, documentsError, documentsLoading } =
    usePollCheckoutDocuments(
      checkoutInsuranceType ?? 'GENERIC',
      checkoutInfo?.metaData?.questionnaireId
    );

  useEffect(() => {
    if (hasCheckoutDocuments) {
      startPollingDocuments();
    }
  }, [hasCheckoutDocuments, startPollingDocuments]);

  if (!checkoutInfo) {
    throw Error(
      'The checkout info object is missing, TODO: implement fetchCheckoutInfo'
    );
  }

  if (!configuration) {
    throw Error('Missing payment screen configuration');
  }

  const {
    mainPolicy: {
      policyDetails: { type: insuranceType },
    },
  } = checkoutInfo;

  const { priceBreakdown, policyDetails, address } = configuration;

  const validReferrerCode = useSelector(getValidReferrerCode);
  const { loading: referralCodeLoading, error: referralEngineError } =
    useRequestStatus(REFERRAL_ENGINE_REQUEST_TYPE);
  const referrerCodeError = useSelector(getReferrerCodeError);

  const onSubmitReferralCode = async (newCode: string) => {
    const upperCaseCode = newCode.toUpperCase();
    dispatch(getIsValidReferrerCode(upperCaseCode));

    if (referralEngineError || referrerCodeError) {
      dispatch(flushReferrerCodeError());
      dispatch(flushRequestError(REFERRAL_ENGINE_REQUEST_TYPE));
    }
  };

  const onRemoveReferralCode = () => {
    dispatch(mergeUserReferralInfo({ validReferrerCode: undefined }));
  };

  const hasPayPal = paymentOptions.find((method) => method.type === 'PAYPAL');

  const stripeClient = useStripe();
  const elements = useElements();

  if (!isMobileApp && (isPayPalAvailable || hasPayPal)) {
    elements?.update({
      paymentMethodTypes: ['sepa_debit', 'card', 'paypal'],
    });
  }

  useEffect(() => {
    setAddress(address);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLoading =
    !stripeClient ||
    !elements ||
    isProcessingPayment ||
    (hasCheckoutDocuments && documentsLoading);

  const isSubmitButtonEnabled =
    !isLoading &&
    isReadyForPayment(paymentOption) &&
    ((hasCheckoutDocuments &&
      checkboxValues.includes('hasReviewedDocuments')) ||
      !hasCheckoutDocuments);

  useEffect(() => {
    dispatch(fetchAccountInfo());
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    if (account?.isDelinquent) {
      history.push(routes.policies.delinquency.path);
    }
  }, [account?.isDelinquent, history]);

  useEffect(() => {
    if (flagsReady && userId) {
      trackCheckoutScreenReached({
        variant: 'stripe',
        insurance_type: insuranceType,
        user_id: userId ?? null,
        medium: 'generic_checkout_screen',
      });
    }
    // eslint-disable-next-line
  }, [flagsReady, userId]);

  if (redirectStatus || account?.isDelinquent) {
    return <LoadingSpinner />;
  }

  const showNetworkError = (error: AxiosError) => {
    let message = t(
      'paymentScreen.errors.genericError',
      'We could not process your payment because of an error on our side, please contact support.'
    );

    if (error.response?.status === 402) {
      if (
        typeof error.response.data === 'object' &&
        error.response.data !== null
      ) {
        const data = error.response.data as Record<string, unknown>;
        if ('message' in data && typeof data.message === 'string') {
          message = data.message;
        }
      }
    }

    toast(t('paymentScreen.errors.title', 'Payment error'), {
      duration: 1000000000,
      description: message,
      type: 'error',
    });
  };

  const showStripeError = (_error: StripeError) => {
    toast(t('paymentScreen.errors.title', 'Payment error'), {
      duration: 1000000000,
      description: t(
        'paymentScreen.errors.genericStripeError',
        'There was an issue with your payment method, please contact support.'
      ),
      type: 'error',
    });
  };

  const updatePaymentMethods = async () => {
    setIsSubmittingNewPaymentMethod(true);
    try {
      if (!isReadyForPayment) {
        throw Error(
          '[Generic checkout] Attempting to pay while the component is not ready for payment'
        );
      }

      if (!stripeClient) {
        throw Error(
          "[Generic checkout] The stripe client hasn't been initialized when the checkout was attempted"
        );
      }

      if (!elements) {
        throw Error(
          '[Generic checkout] The Stripe elements object was not ready in time for payment'
        );
      }

      const basePath = localeId
        ? generatePath(routes.paymentScreenRegionalised.confirmSetup.path, {
            checkoutId,
            localeId,
          })
        : generatePath(routes.paymentScreen.confirmSetup.path, { checkoutId });
      const returnUrl = `${window.location.origin}${basePath}`;

      const paymentMethod = await dispatch(
        prepareForPayment({
          paymentOption,
          stripeClient,
          elements,
          returnUrl: paramsSetUrl(returnUrl, [
            { key: 'c', value: configurationParam ?? '' },
          ]),
        })
      );

      trackPaymentMethodAdded({
        variant: 'stripe',
        insurance_type: insuranceType,
        user_id: userId ?? null,
        medium: 'generic_checkout_screen',
        payment_type: paymentMethod.type,
        is_default_payment: paymentMethod.isDefault,
      });

      setPaymentOption({
        type: 'EXISTING_PAYMENT_METHOD',
        paymentMethod,
      });

      setCurrentSelectedPaymentMethod(paymentMethod);

      setAddNewPaymentMethod(false);
      setIsSubmittingNewPaymentMethod(false);
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        showNetworkError(error);
      } else if (isStripeError(error)) {
        showStripeError(error);

        if (!isCustomerFacingError(error)) {
          // This error should be reported to Sentry very rarely. If this shows up as
          // a false-positive, please update the list of reported error types in isCustomerFacingError,
          // or remove this captureException entirely
          captureException(error);
        }

        return;
      }
      throw error;
    }
  };

  const purchasePolicy = async () => {
    setIsProcessingPayment(true);

    if (!elements) {
      throw Error(
        '[Generic checkout] The Stripe elements object was not ready in time for payment'
      );
    }
    if (!stripeClient) {
      throw Error(
        "[Generic checkout] The stripe client hasn't been initialized when the checkout was attempted"
      );
    }

    if (!currentSelectedPaymentMethod) {
      throw Error('[Generic checkout] No payment method selected');
    }

    try {
      const { isDuplicatePaymentAttempt } = await dispatch(
        pay({
          checkoutInfo,
          paymentMethod: currentSelectedPaymentMethod,
          stripeClient,
          region: checkoutInfo.metaData?.regionOfPurchase ?? 'de',
          referralCode,
          configurationQueryParam: configurationParam ?? undefined,
        })
      );

      await dispatch(
        onSuccessfulPayment({
          t,
          history,
          verticalId: checkoutInfo.mainPolicy.policyDetails.type,
          policyId: checkoutInfo.mainPolicy.id,
          isDuplicatePaymentAttempt,
          region: checkoutInfo.metaData?.regionOfPurchase ?? 'de',
          redirectAddDependent: configuration.redirectAddDependent,
          genericQuestionnaireKey: configuration.genericQuestionnaireKey,
        })
      );

      dispatch(clearReferrerCode());
    } catch (error: unknown) {
      setIsProcessingPayment(false);

      if (axios.isAxiosError(error)) {
        showNetworkError(error);
      } else if (isStripeError(error)) {
        showStripeError(error);
        return;
      }

      throw error;
    }
  };

  if (hasCheckoutDocuments && documentsError) {
    return (
      <ErrorWithAction
        title={t('checkout.documents.error.title', 'Something went wrong')}
        description={t(
          'checkout.documents.error.description',
          "Some of the required documents couldn't be generated.\n\nGoing back to the previous page and trying again should fix the issue."
        )}
        cta={{
          title: t('checkout.documentsError.cta', 'Go back'),
          onClick: () => history.goBack(),
        }}
      />
    );
  }

  if (hasCheckoutDocuments && documentsLoading) {
    return (
      <TimedLoadSpinner
        title={t('genericCheckout.loading.title', 'Preparing your policy')}
        description={t(
          'genericCheckout.loading.description',
          "We're gathering your documents and policy details so you can review them before purchasing."
        )}
        delayInMilliseconds={0}
      />
    );
  }

  if (documentsError) {
    return (
      <ErrorWithAction
        title={t(
          'genericCheckout.documents.error.title',
          'Something went wrong'
        )}
        description={t(
          'genericCheckout.documents.error.description',
          "Some of the required documents couldn't be generated.\n\nGoing back to the previous page and trying again should fix the issue."
        )}
        cta={{
          title: t('genericCheckout.documentsError.cta', 'Go back'),
          onClick: () => history.goBack(),
        }}
      />
    );
  }

  return (
    <div className={styles.container} data-cy="checkout-container">
      <section
        className={classNames('bg-white w100', styles['child-container'])}
      >
        <h1 className="p-h1 pb48 wmx7 w100">
          {t('paymentScreen.heading.title', 'Checkout')}
        </h1>
        <PolicyDetails {...policyDetails} />
        <ReferralCodeForm
          onSubmitCode={onSubmitReferralCode}
          onRemoveCode={onRemoveReferralCode}
          loading={referralCodeLoading}
          error={referralEngineError?.message || referrerCodeError}
          validReferrerCode={validReferrerCode}
          startDate={checkoutInfo.mainPolicy.startDate}
        />
        {hasCheckoutDocuments && documents && areDocumentsValid(documents) && (
          <CheckoutDocuments documents={documents} />
        )}
      </section>
      <section
        className={classNames('bg-purple-50', styles['child-container'])}
      >
        <PriceBreakdown
          {...priceBreakdown}
          payComponent={
            <PayComponent
              checkboxValues={checkboxValues}
              setCheckboxValues={setCheckboxValues}
              isSubmitButtonEnabled={isSubmitButtonEnabled}
              startDateError={!isValidStartDate(checkoutInfo)}
              changeStartDatePath={configuration.changeStartDatePath}
              purchasePolicy={purchasePolicy}
              updatePaymentMethods={updatePaymentMethods}
              hasCheckoutDocuments={hasCheckoutDocuments}
            />
          }
        />
      </section>
    </div>
  );
};

export default PaymentScreenV2;
