import { LifeBeneficiariesActions } from 'constants/actions';

import { EditBeneficiaries } from '../models';

export const storeAddBeneficiariesAnswers = (
  answer: Partial<EditBeneficiaries>
): LifeBeneficiariesActions => ({
  type: 'STORE_LIFE_BENEFICIARIES',
  questionnaire: answer,
});

export const flushBeneficiariesAnswers = (): LifeBeneficiariesActions => ({
  type: 'FLUSH_LIFE_BENEFICIARIES',
});
