import 'swiper/css';

import classNames from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperClass, SwiperProps, SwiperSlide } from 'swiper/react';

import styles from './style.module.scss';

type CarouselProps = {
  slides: JSX.Element[];
  options?: SwiperProps;
};

const AUTOPLAY_INTERVAL = 5000;

const Carousel = (props: CarouselProps) => {
  const { slides, options = {} } = props;
  const [currentIndex, setCurrentIndex] = useState(0);

  const progressContents = useRef<HTMLSpanElement[]>([]);
  const animationFrameRef = useRef<number | null>(null);

  const onAutoplayTimeLeft = useCallback(
    (swiper: SwiperClass, _time: unknown, progress: number) => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }

      animationFrameRef.current = requestAnimationFrame(() => {
        progressContents.current.forEach((content, index) => {
          if (!content) {
            return;
          }

          const transitionProps = 'transform 0.05s ease-out';

          if (
            swiper.swipeDirection === 'next' &&
            content.style.transition !== transitionProps
          ) {
            content.style.transition = transitionProps;
          } else if (
            swiper.swipeDirection !== 'next' &&
            content.style.transition !== ''
          ) {
            content.style.transition = '';
          }

          if (index === currentIndex) {
            content.style.transform = `translate3d(${-progress * 105}%, 0, 0)`;
          } else {
            content.style.transform = `translate3d(${
              index < currentIndex ? 0 : -101
            }%, 0, 0)`;
          }
        });
      });
    },
    [currentIndex]
  );

  const handleSlideChange = useCallback(
    ({ realIndex }) => setCurrentIndex(realIndex),
    []
  );

  useEffect(() => {
    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, []);

  return (
    <>
      <div className="d-flex jc-between gap8 px24">
        {slides.map((_, index) => (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            className={classNames(
              styles.progressItem,
              'bg-white d-block br8 w100',
              {}
            )}
          >
            <span
              ref={(el) => {
                if (el) {
                  progressContents.current[index] = el;
                }
              }}
              className={classNames(styles.progressItemInner, 'd-block')}
            />
          </div>
        ))}
      </div>

      <Swiper
        autoplay={{
          delay: AUTOPLAY_INTERVAL,
          disableOnInteraction: false,
          waitForTransition: false,
          pauseOnMouseEnter: false,
          ...(typeof options.autoplay === 'object' ? options.autoplay : {}),
        }}
        speed={600}
        className={styles.carouselWrapper}
        {...options}
        modules={[Autoplay]}
        onAutoplayTimeLeft={onAutoplayTimeLeft}
        onSlideChange={handleSlideChange}
      >
        {slides.map((content, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <SwiperSlide key={index}>{content}</SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export { Carousel };
