import { trackConversion } from '@getpopsure/analytics';
import { Region } from '@getpopsure/public-models';
import { flushTrackingObject } from '@getpopsure/tracker';
import { captureException } from '@sentry/react';
import { InsuranceTypes } from 'models/insurances/types';
import endpoint from 'shared/api';
import {
  getFinanceAdsCategory,
  trackFinanceAdsConversion,
} from 'shared/trackers/financeAds';

import { mobileAppLogEvent } from './mobileAppLogEvent';

export const mapVerticalIdToSnowplowVerticalId: Partial<
  Record<InsuranceTypes, InsuranceTypes>
> = {
  DENTAL_V2: 'DENTAL',
  LEGAL_V2: 'LEGAL',
  EXPAT_V2: 'INCOMING',
  LIFE_V2: 'LIFE',
  BIKE_V2: 'BIKE',
};

type CommissionGroupId = '1' | '2' | '3';

const commissionsGroupDictionary: Partial<
  Record<InsuranceTypes, CommissionGroupId>
> = {
  PRIVATE_HEALTH: '3',
  DISABILITY: '2',
  DENTAL: '2',
  INCOMING: '2',
  HOUSEHOLD: '2',
  LEGAL: '2',
};

const getCommissionGroupFromVertical = (vertical?: InsuranceTypes) => {
  /**
   * We use group 1 as the default commission group.
   * If the vertical is not defined or not in the dictionary
   * we'll fall back to group 1
   */

  if (!vertical) {
    return '1';
  }

  return commissionsGroupDictionary[vertical] || '1';
};

export const trackConversions = async ({
  verticalId,
  policyId,
  regionOfPurchase,
}: {
  verticalId: InsuranceTypes;
  policyId: string;
  regionOfPurchase: Region;
}) => {
  try {
    const vertical = mapVerticalIdToSnowplowVerticalId[verticalId]
      ? mapVerticalIdToSnowplowVerticalId[verticalId]
      : verticalId;

    trackConversion({
      vertical,
      policy_id: policyId,
      region_of_purchase: regionOfPurchase,
      commissions_group: getCommissionGroupFromVertical(vertical),
    });

    mobileAppLogEvent('conversion', {
      // we should make sure policy_id is not defined
      // this is necessary to avoid passing non-anonymized data to the mobile app
      vertical,
      region_of_purchase: regionOfPurchase,
      commissions_group: getCommissionGroupFromVertical(vertical),
    });

    // financeAds
    const financeAdsCategory = getFinanceAdsCategory(verticalId);
    if (financeAdsCategory) {
      await trackFinanceAdsConversion(endpoint.network, {
        policyId,
        financeAdsCategory,
      });
    }

    flushTrackingObject();
  } catch (err) {
    captureException(err);
  }
};
