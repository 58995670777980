import { useFlag } from '@unleash/proxy-client-react';
import routes from 'constants/routes';
import { CreateClaimButton } from 'features/policySingle/components/buttons/CreateClaimButton';
import { PolicyDetailsButtonProps } from 'features/policySingle/sections/PolicyDetails/PolicyDetails.models';
import { generatePath } from 'react-router';
import { useSafeTranslation } from 'shared/i18n';

export const DogLiabilityCreateClaimButton = ({
  policyData,
  onOpenModal,
}: PolicyDetailsButtonProps) => {
  const { t } = useSafeTranslation();

  const isDogLiabilityBirdhouse = useFlag('birdhouse_dog_liability_claim');

  const birdhousePath = generatePath(routes.claims.general.path, {
    verticalId: 'dog-liability',
    policyId: policyData.id,
  });

  const claimPath = isDogLiabilityBirdhouse
    ? birdhousePath
    : routes.claims.dogLiability.path;

  return (
    <CreateClaimButton
      policyData={policyData}
      onOpenModal={onOpenModal}
      route={claimPath}
      title={t(
        'myPolicies.policyDetails.dogLiabilityClaims.caption',
        'Create claim'
      )}
    />
  );
};
