import { flushRequestError } from 'actions/request';
import { useRequestLoginCode } from 'features/accountV2/hooks/useRequestLoginCode';
import { useValidateAccount } from 'features/accountV2/hooks/useValidateAccount';
import { signInAndCreatePersona } from 'features/recommendationTool/actions';
import { RecommendationToolDispatch } from 'features/recommendationTool/models';
import { Account } from 'features/recommendationTool/models/account';
import { useRequestStatus } from 'hooks/useRequestStatus';
import { CustomComponentProps } from 'models/questionnaireFramework';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { EmailForm } from './components/EmailForm';
import { LoginModal } from './components/LoginModal';

const LoginContainer = ({ onSubmitValue }: CustomComponentProps<Account>) => {
  const [email, setEmail] = useState('');
  const [requiresLoginCode, setRequiresLoginCode] = useState(false);
  const dispatch = useDispatch<RecommendationToolDispatch>();
  const { loading: signInLoading, error: signinError } = useRequestStatus(
    'SIGN_IN_WITH_LOGIN_CODE'
  );
  const {
    mutate: requestLoginCode,
    isLoading: codeRequestLoading,
    error: codeRequestError,
    reset: resetCodeRequestMutation,
  } = useRequestLoginCode();

  const {
    mutate: validateAccount,
    isLoading: validateAccountLoading,
    error: validateAccountError,
  } = useValidateAccount();

  const resetErrors = () => {
    dispatch(flushRequestError('SIGN_IN_WITH_LOGIN_CODE'));
    resetCodeRequestMutation();
  };

  const handleClose = () => {
    resetErrors();
    setRequiresLoginCode(false);
  };

  const handleSendNewCode = () => {
    resetErrors();
    requestLoginCode({ email });
  };

  const handleSignin = async (code: string) => {
    resetErrors();
    const signInStatus = await dispatch(
      signInAndCreatePersona({ email, code })
    );
    if (signInStatus === 'SUCCESS') onSubmitValue(email);
  };

  const handleValidateAccount = () => {
    validateAccount(email, {
      onSuccess: ({ userExists }) => {
        if (userExists) {
          setRequiresLoginCode(true);
          requestLoginCode({ email });
        } else {
          onSubmitValue(email);
        }
      },
    });
  };

  return (
    <>
      <LoginModal
        isOpen={requiresLoginCode}
        email={email}
        handleClose={handleClose}
        handleSendNewCode={handleSendNewCode}
        handleSignin={handleSignin}
        loading={signInLoading || codeRequestLoading}
        inputError={signinError}
        codeRequestError={codeRequestError}
      />
      <EmailForm
        email={email}
        onInputChange={setEmail}
        onSubmit={handleValidateAccount}
        loading={validateAccountLoading}
        error={validateAccountError}
      />
    </>
  );
};

export default LoginContainer;
