import { StripeError } from '@stripe/stripe-js';
import routes from 'constants/routes';
import dayjs from 'dayjs';
import { ValidVerticalUrlParams } from 'features/paymentScreen/utils/verticalId.utils';
import {
  CollectableBillingAddress,
  StripeBillingAddress,
  ZStripeBillingAddress,
} from 'models/paymentMethods';
import { GenericQuestionnaireState } from 'reducers/genericQuestionnaire';
import browserHistory from 'shared/browserHistory';
import { paramsSetUrl } from 'shared/util/paramsSetUrl';

const DATE_FORMAT = 'DD MMM YYYY';
const history = browserHistory;

const isValidStripeBillingAddress = (
  value: Partial<StripeBillingAddress>
): value is StripeBillingAddress => {
  return ZStripeBillingAddress.safeParse(value).success;
};

export const toStripeBillingAddress = (
  collectableAddress: Partial<CollectableBillingAddress>
): StripeBillingAddress | undefined => {
  const stripeBillingAddress: Partial<StripeBillingAddress> = {
    ...collectableAddress,
    country: collectableAddress.country?.code,
  };

  if (isValidStripeBillingAddress(stripeBillingAddress)) {
    return stripeBillingAddress;
  }

  return undefined;
};

export const isStripeError = (error: unknown): error is StripeError => {
  return (
    error !== null &&
    typeof error === 'object' &&
    ['type', 'charge', 'payment_intent', 'payment_method', 'setup_intent'].some(
      (property: string) =>
        Object.prototype.hasOwnProperty.call(error, property)
    )
  );
};

export const formatProratedPeriod = (period: {
  start: string;
  end: string;
}): string => {
  const startDate = dayjs(period?.start).format(DATE_FORMAT);
  const endDate = dayjs(period?.end).format(DATE_FORMAT);

  if (startDate === endDate) {
    return `${startDate}`;
  }

  return `${startDate} - ${endDate}`;
};

export const redirectSuccessfulCheckout = (
  verticalStateId?: keyof GenericQuestionnaireState,
  mainPolicyId?: string,
  region?: string
) => {
  window.scrollTo(0, 0);
  history.push(
    paramsSetUrl<
      'signupSuccess' | 'mainPolicyId' | 'region',
      ValidVerticalUrlParams | string
    >(routes.me.policies.path, [
      { key: 'signupSuccess', value: verticalStateId ?? '' },
      { key: 'mainPolicyId', value: mainPolicyId ?? '' },
      { key: 'region', value: region ?? '' },
    ])
  );
};
