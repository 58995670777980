import { website } from '@getpopsure/private-constants';
import { ErrorWithAction } from 'components/ErrorWithAction';
import TimedLoadSpinner from 'components/timedLoadSpinner';
import { useParams } from 'react-router';
import { useSafeTranslation } from 'shared/i18n';

import { ClaimAssessmentDetailsView } from './ClaimAssessmentDetails.view';
import { useGetClaimAssessment } from './hooks/useGetClaimAssessment';

export const ClaimAssessmentDetails = () => {
  const { assessmentId } = useParams<{ assessmentId: string }>();
  const { t } = useSafeTranslation();
  const { assessment, isLoading, isError } =
    useGetClaimAssessment(assessmentId);

  if (isLoading || (!assessment && !isError)) {
    return <TimedLoadSpinner />;
  }

  if (isError) {
    return (
      <ErrorWithAction
        title={t(
          'claimAssessments.details.error.title',
          'Something went wrong'
        )}
        description={t(
          'claimAssessments.detail.error.description',
          'Sorry, there was a problem loading your claim assessment. If the problem persists, please contact support.'
        )}
        cta={{
          title: t(
            'claimAssessments.details.error.button.title',
            'Contact support'
          ),
          onClick: () => {
            window.location.href = website.support;
            return null;
          },
        }}
      />
    );
  }

  return <ClaimAssessmentDetailsView assessment={assessment} t={t} />;
};
