import { Appearance } from '@stripe/stripe-js';

export const stripeElementsAppearance: Appearance = {
  theme: 'flat',
  labels: 'floating',
  variables: {
    colorPrimary: '#EBEBFF',
    colorPrimaryText: '#8E8CEE',
    colorTextSecondary: '#696970',
    colorBackground: '#FFFFFF',
    colorText: '#26262E',
    colorDangerText: '#e55454',
    spacingGridRow: '16px',
  },
  rules: {
    '.Input': {
      border: '1px solid #D2D2D8',
      padding: '8px 16px',
    },
    '.Input:focus': {
      border: '1px solid #8E8CEE',
    },
    '.Tab': {
      border: '1px solid #D2D2D8',
      padding: '20px 16px',
    },
    '.Tab:focus': {
      border: '1px solid #8E8CEE',
    },
    '.Tab--selected': {
      border: '2px solid #8E8CEE',
      backgroundColor: '#FFFFFF',
    },
    '.Label': {
      color: '#91919D',
    },
    '.Label--resting': {
      fontSize: '14px',
    },
    '.Label--floating': {
      fontSize: '12px',
    },
  },
};
