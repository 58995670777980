import { BottomOrRegularModal, Button, Input } from '@popsure/dirty-swan';
import { fetchAllClaims } from 'actions/claims';
import dayjs from 'dayjs';
import { ExpatLongTermCancellationReasonsId } from 'features/expatLongTerm/models';
import { Fragment, useEffect, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { useDispatch, useSelector } from 'react-redux';
import { getClaims } from 'selectors/claims';
import { TFunction, Trans, useSafeTranslation } from 'shared/i18n';

import styles from '../style.module.scss';
import { ViewProps } from '../types';
import { isInRightOfWithdrawalPeriod } from '../utils';

type CancellationReason = {
  id: ExpatLongTermCancellationReasonsId;
  title: string;
};

const getExpatLongTermCancellationReasons = (
  t: TFunction,
  showRightOfWithdrawlReason?: boolean
): CancellationReason[] => {
  const reasons: CancellationReason[] = [
    {
      id: 'FOUND_A_JOB',
      title: t(
        'myPolicies.cancelModal.expatLongTerm.reason.foundAJob.title',
        'I found a job'
      ),
    },
    {
      id: 'FOUND_ANOTHER_INSURANCE',
      title: t(
        'myPolicies.cancelModal.expatLongTerm.reason.foundAnotherInsurance.title',
        'I got another health insurance'
      ),
    },
    {
      id: 'LEFT_COUNTRY',
      title: t(
        'myPolicies.cancelModal.expatLongTerm.reason.leftCountry.title',
        'I will leave the country'
      ),
    },
    {
      id: 'OTHER',
      title: t(
        'myPolicies.cancelModal.expatLongTerm.reason.other.title',
        'Other'
      ),
    },
  ];

  if (showRightOfWithdrawlReason) {
    return [
      {
        id: 'RIGHT_OF_WITHDRAWAL',
        title: t(
          'myPolicies.cancelModal.expatLongTerm.reason.rightOfWithdrawal.title',
          'I changed my mind and need a full refund'
        ),
      },
      ...reasons,
    ];
  }

  return reasons;
};

export const canCancel = (
  cancelationReason?: ExpatLongTermCancellationReasonsId,
  additionalInfo?: string
): boolean => {
  if (cancelationReason === 'FOUND_ANOTHER_INSURANCE') {
    return additionalInfo !== undefined && additionalInfo.length > 0;
  }

  return cancelationReason !== undefined;
};

export const CancelExpatLongTermModal = ({
  activeUntil,
  onCloseModal,
  onCancelPolicy,
  isCancelling,
  cancellingError,
  isOpen,
  id: policyId,
  startDate,
}: ViewProps) => {
  const { t } = useSafeTranslation();
  const dispatch = useDispatch();

  const [customCancellationReason, setCustomCancellationReason] = useState('');
  const [
    selectedExpatLongTermCancellationReason,
    setExpatLongTermCancellationReason,
  ] = useState<ExpatLongTermCancellationReasonsId | undefined>();
  const [newInsuranceProvider, setNewInsuranceProvider] = useState<
    string | undefined
  >();

  const claims = useSelector(getClaims) ?? null;

  const policyHasClaims = claims?.some(
    (claim) => claim.userPolicyId === policyId
  );

  const nowDate = Date.now();

  const showRightOfWithdrawlReason =
    isInRightOfWithdrawalPeriod({
      nowDate,
      startDateOfRoWPeriod: startDate,
    }) && !policyHasClaims;

  const getAdditionalInfo = (): string | undefined => {
    if (selectedExpatLongTermCancellationReason === 'FOUND_ANOTHER_INSURANCE') {
      return newInsuranceProvider;
    }

    if (selectedExpatLongTermCancellationReason === 'OTHER') {
      return customCancellationReason;
    }

    return undefined;
  };

  const additionalInfo = getAdditionalInfo();

  const valid = canCancel(
    selectedExpatLongTermCancellationReason,
    additionalInfo
  );

  const handleCancelPolicy = () => {
    const successMessage = t(
      'myPolicies.cancelModal.expatLongTerm.reason.successMessage',
      'Your policy was successfully cancelled'
    );

    onCancelPolicy(
      selectedExpatLongTermCancellationReason,
      additionalInfo,
      successMessage
    );
  };

  useEffect(() => {
    if (isOpen) {
      dispatch(fetchAllClaims());
    }
  }, [dispatch, isOpen]);

  return (
    <BottomOrRegularModal
      title={t('myPolicies.cancelModal.expatLongTerm.title', 'Cancel policy')}
      isOpen={isOpen}
      onClose={onCloseModal}
    >
      <div
        className={`mt24 ${styles.container}`}
        data-testid="cancel-modal-expat-lt"
      >
        <form className="mt16" onSubmit={handleCancelPolicy}>
          {getExpatLongTermCancellationReasons(
            t,
            showRightOfWithdrawlReason
          ).map(({ id, title: reasonTitle }) => (
            <Fragment key={id}>
              <label className="p-p mt8 d-block" htmlFor={id}>
                <input
                  id={id}
                  value={id}
                  type="radio"
                  name="cancellationReason"
                  onChange={() => {
                    setExpatLongTermCancellationReason(id);
                  }}
                  checked={id === selectedExpatLongTermCancellationReason}
                />
                &nbsp;{reasonTitle}
              </label>
              {id === 'FOUND_ANOTHER_INSURANCE' && (
                <AnimateHeight
                  duration={300}
                  height={
                    selectedExpatLongTermCancellationReason ===
                    'FOUND_ANOTHER_INSURANCE'
                      ? 'auto'
                      : 0
                  }
                >
                  <Input
                    className="mt8 wmx5"
                    placeholder={t(
                      'myPolicies.cancelModal.expatLongTerm.reason.foundAnotherInsurance.input.placeholder',
                      'Insurance provider'
                    )}
                    value={newInsuranceProvider ?? ''}
                    onChange={(e) => {
                      setNewInsuranceProvider(e.target.value);
                    }}
                  />
                </AnimateHeight>
              )}
              {id === 'OTHER' && (
                <AnimateHeight
                  duration={300}
                  height={
                    selectedExpatLongTermCancellationReason === 'OTHER'
                      ? 'auto'
                      : 0
                  }
                >
                  <Input
                    className="mt8 wmx5"
                    placeholder={t(
                      'myPolicies.cancelModal.expatLongTerm.reason.other.input.placeholder',
                      'Please type the reason for cancellation'
                    )}
                    value={customCancellationReason ?? ''}
                    onChange={(e) => {
                      setCustomCancellationReason(e.target.value);
                    }}
                  />
                </AnimateHeight>
              )}
            </Fragment>
          ))}
          {activeUntil && !showRightOfWithdrawlReason && (
            <Trans i18nKey="myPolicies.cancelModal.expatLongTerm.activeUntil.description">
              <p className="p-notice--warning mt24 p-p">
                The cancellation will be effective at the end of your next
                billing period on{' '}
                <b className="fw-bold">
                  {{ endDate: dayjs(activeUntil).format('DD MMM YYYY') }}
                </b>
                .
              </p>
            </Trans>
          )}
          {showRightOfWithdrawlReason && (
            <Trans i18nKey="myPolicies.cancelModal.expatLongTerm.activeUntil.rightOfWithdrawal.description">
              <p className="p-notice--warning mt24 p-p">
                Your policy will be canceled immediately.
              </p>
            </Trans>
          )}
          <div className={`d-flex f-wrap mt24 ${styles['button-container']}`}>
            <Button
              className="wmn3"
              disabled={!valid || isCancelling}
              loading={isCancelling}
              type="submit"
            >
              {t(
                'myPolicies.cancelModal.expatLongTerm.confirmButton.caption',
                'Cancel policy'
              )}
            </Button>
            <Button
              className="wmn2"
              variant="filledGray"
              onClick={() => onCloseModal()}
              type="button"
            >
              {t(
                'myPolicies.cancelModal.expatLongTerm.closeButton.caption',
                'Close'
              )}
            </Button>
          </div>
          {cancellingError && (
            <p className="p-notice--danger p-p mt24">{cancellingError}</p>
          )}
        </form>
      </div>
    </BottomOrRegularModal>
  );
};
