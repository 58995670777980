import { trackInsuranceSubscribed } from '@getpopsure/analytics/dist/events';
import { Trans } from '@getpopsure/i18n-react';
import { email } from '@getpopsure/private-constants';
import { capitalizeName } from '@getpopsure/public-utility';
import { QuestionnaireFormProps } from '@getpopsure/qnr-framework';
import {
  BottomOrRegularModal,
  Button,
  Checkbox,
  InformationBox,
  Input,
  toast,
} from '@popsure/dirty-swan';
import { useFlag } from '@unleash/proxy-client-react';
import { flushGenericQuestionnaire } from 'actions/genericQuestionnaire';
import { fetchAccountInfo } from 'actions/user';
import classNames from 'classnames';
import { ErrorWithAction } from 'components/ErrorWithAction';
import TimedLoadSpinner from 'components/timedLoadSpinner';
import routes from 'constants/routes';
import dayjs from 'dayjs';
import { areDocumentsValid } from 'features/checkoutDocuments/actions';
import { getDocuments } from 'features/checkoutDocuments/checkoutDocuments.selectors';
import { usePollCheckoutDocuments } from 'features/checkoutDocuments/hooks';
import CheckoutDocuments from 'features/paymentScreen/components/CheckoutDocuments/CheckoutDocuments';
import { PolicyDetails as PolicyDetailsNew } from 'features/paymentScreen/components/PolicyDetailsNew/PolicyDetails';
import { PriceBreakdown } from 'features/paymentScreen/components/PriceBreakdown/PriceBreakdown';
import { redirectSuccessfulCheckout } from 'features/paymentScreen/utils/paymentMethod.utils';
import {
  submitApplicationAction,
  SubmitApplicationDispatch,
} from 'features/pension/actions';
import { PolicyDetails } from 'features/pension/components/PolicyDetails/PolicyDetails';
import { Pension } from 'features/pension/models';
import { useRequestStatus } from 'hooks/useRequestStatus';
import { imageTypeMapping } from 'models/insurances/types/mapping';
import { FormEvent, useEffect, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router';
import { getGenericQuestionnaire } from 'selectors/genericQuestionnaire';
import { useSafeTranslation } from 'shared/i18n';

import { PensionDocumentsReview } from '../PensionDocumentsReview/PensionDocumentsReview';
import { SepaMandate } from '../SepaMandate/SepaMandate';
import styles from './SubmitApplication.module.scss';

export const SubmitApplication = ({
  questionnaireAnswers,
}: QuestionnaireFormProps<Pension>) => {
  const dispatch = useDispatch<SubmitApplicationDispatch>();
  const history = useHistory();
  const [checkboxValues, setCheckboxValues] = useState<string[]>([]);
  const { t } = useSafeTranslation();
  const { loading, error } = useRequestStatus('PENSION_SUBMIT_APPLICATION');
  const displayNewCheckoutUI = useFlag('app_checkout_revamp_pension');
  const [openModal, setOpenModal] = useState(false);

  const questionnaire = useSelector(getGenericQuestionnaire).pension;
  const iban = questionnaireAnswers.sepa?.iban;
  const accountHolder = questionnaireAnswers.sepa?.accountHolder;
  const initialAmount = questionnaire?.quote?.initialAmount ?? 0;
  const monthlyPayment = questionnaire?.quote?.monthlyPayment ?? 0;

  const proratedPeriodStart = dayjs().format('DD MMM');
  const proratedPeriodEnd = dayjs().endOf('month').format('DD MMM');

  if (!questionnaire) {
    throw new Error('[Pension]: Questionnaire not found');
  }

  if (!iban) {
    throw new Error('[Pension]: IBAN is missing from the questionnaire info');
  }

  if (!accountHolder) {
    throw new Error(
      '[Pension]: Account holder is missing from the questionnaire info'
    );
  }

  const { startPollingDocuments, documentsError, documentsLoading } =
    usePollCheckoutDocuments('PENSION', questionnaire.questionnaireId);
  const documents = useSelector(getDocuments(questionnaire.questionnaireId));

  useEffect(() => {
    dispatch(fetchAccountInfo());
  }, [dispatch]);

  useEffect(() => {
    startPollingDocuments();
  }, [startPollingDocuments]);

  const isValidForSubmission =
    checkboxValues.includes('sepaMandate') &&
    checkboxValues.includes('hasReviewedDocuments');

  const handleSubmitApplication = async (e: FormEvent) => {
    e.preventDefault();
    const { status } = await dispatch(submitApplicationAction());

    if (status === 'SUCCESS') {
      redirectSuccessfulCheckout('pension');
      dispatch(flushGenericQuestionnaire('pension'));
      trackInsuranceSubscribed({
        vertical: 'pension',
      });
    }

    if (status === 'ERROR') {
      toast(
        t(
          'pension.qnr.reviewCheckout.submit.error.title',
          'Error submitting your application'
        ),
        {
          type: 'error',
          duration: 10000000000,
          description: t(
            'pension.qnr.reviewCheckout.submit.error.message',
            'We could not submit your pension application. Please try again or contact us at {{supportEmail}}.',
            { supportEmail: email.help }
          ),
        }
      );
    }
  };

  if (documentsError) {
    return (
      <ErrorWithAction
        title={t(
          'pension.qnr.submitApplication.documentsError.title',
          'Something went wrong'
        )}
        description={t(
          'pension.qnr.submitApplication.documentsError.description',
          "Some of the required documents couldn't be generated.\n\nGoing back to the previous page and trying again should fix the issue."
        )}
        cta={{
          title: t('checkout.documentsError.cta', 'Go back'),
          onClick: () => history.goBack(),
        }}
      />
    );
  }

  if (documentsLoading) {
    return (
      <TimedLoadSpinner
        title={t(
          'pension.qnr.submitApplication.loading.title',
          'Preparing your application'
        )}
        description={t(
          'pension.qnr.submitApplication.loading.description1',
          "We're gathering your documents and policy details so you can review them before submitting your application."
        )}
        delayedAnimatedText={t(
          'pension.qnr.submitApplication.loading.description2',
          "This takes a little longer than usual - it's quite the stack 🥞"
        )}
        delayInMilliseconds={0}
      />
    );
  }

  if (displayNewCheckoutUI) {
    return (
      <div className={styles.submitPage}>
        <div className={`${styles.submitSection} bg-white`}>
          <div className="wmx7 w100">
            <h1 className="p-h1 mb48  ta-start">
              {t('pension.qnr.submitApplication.title', 'Checkout')}
            </h1>
            <PolicyDetailsNew
              productCard={{
                productName: t(
                  'pension.qnr.policyDetails.pension',
                  'Private pension'
                ),
                details: [
                  {
                    id: 'startDate',
                    label: t(
                      'pension.qnr.policyDetails.startDate',
                      'Start date'
                    ),
                    value: dayjs()
                      .add(1, 'month')
                      .startOf('month')
                      .format('DD MMM YYYY'),
                  },
                  {
                    id: 'annualFee',
                    label: t(
                      'pension.qnr.policyDetails.annualFee',
                      'Annual fee'
                    ),
                    value: '0.72%',
                  },
                ],
              }}
              policyDetails={[
                {
                  id: 'name',
                  label: t(
                    'pension.qnr.policyDetails.policyHolder.name',
                    'Name'
                  ),
                  value: questionnaire.name
                    ? capitalizeName(questionnaire.name) ?? ''
                    : '',
                },
                {
                  id: 'dateOfBirth',
                  label: t(
                    'pension.qnr.policyDetails.policyholder.dateOfBirth',
                    'Date of birth'
                  ),
                  value: dayjs(questionnaire.dateOfBirth).format('DD MMM YYYY'),
                },
                {
                  id: 'email',
                  label: t(
                    'pension.qnr.policyDetails.policyHolder.email',
                    'Email'
                  ),
                  value: questionnaire.email ?? '',
                },
              ]}
            />
            <div className="wmx7 w100 ta-start mt48 d-flex jc-between ai-center">
              <h2 className="p-h2 ">
                {t('pension.qnr.paymentMethod.title', 'Payment method')}
              </h2>
              <a
                href={generatePath(routes.policies.pension.questionnaire.path, {
                  groupId: 'signup',
                  questionId: 'sepa',
                })}
                className="p-p"
                style={{ textDecoration: 'underline' }}
              >
                {t('pension.qnr.paymentMethod.edit.action', 'Edit')}
              </a>
            </div>
            <div className="wmx7 w100">
              <Input
                className="mt16"
                type="text"
                defaultValue={iban}
                disabled
              />
              <Input
                className="mt16"
                type="text"
                defaultValue={accountHolder}
                disabled
              />
              <Checkbox
                bordered={false}
                classNames={{ container: 'mt16' }}
                onChange={setCheckboxValues}
                wide
                options={{
                  sepaMandate: (
                    <p className="p-p">
                      {t(
                        'pension.qnr.paymentMethod.checkbox.text',
                        'I agree to set up direct debit by SEPA mandate and confirm to have full access to the provided account.'
                      )}
                      <button
                        className="ml8 p-p bg-transparent"
                        style={{
                          textDecoration: 'underline',
                        }}
                        onClick={() => setOpenModal(true)}
                        type="button"
                      >
                        {t(
                          'pension.qnr.paymentMethod.seeAgreement.label',
                          'See agreement'
                        )}
                      </button>
                    </p>
                  ),
                }}
                value={checkboxValues}
              />
            </div>
            {documents && areDocumentsValid(documents) && (
              <CheckoutDocuments documents={documents} />
            )}
          </div>
        </div>
        <div className={styles.submitSection}>
          <form onSubmit={handleSubmitApplication} className="w100 wmx7">
            <PriceBreakdown
              productName={t(
                'pension.qnr.policyDetails.pension',
                'Private pension'
              )}
              basePrice={monthlyPayment}
              priceBreakdown={[
                {
                  id: 'initialAmount',
                  label: t(
                    'pension.qnr.priceBreakdown.oneOffPayment',
                    'One-off payment'
                  ),
                  value: initialAmount,
                },
              ]}
              subTotalPrice={monthlyPayment + initialAmount}
              finalPrice={{
                primaryLabel: t(
                  'pension.qnr.priceBreakdown.nextMonth',
                  'Next month'
                ),
                primaryValue: monthlyPayment,
                primaryDescription: t(
                  'pension.qnr.priceBreakdown.billedOnTheFirst',
                  'Billed on the 1st'
                ),
                secondaryLabel: t(
                  'pension.qnr.priceBreakdown.thisMonth',
                  'This month'
                ),
                secondaryValue: initialAmount + monthlyPayment,
                secondaryDescription: `${proratedPeriodStart} - ${proratedPeriodEnd}`,
              }}
              payComponent={
                <>
                  <Checkbox
                    bordered={false}
                    value={checkboxValues}
                    onChange={setCheckboxValues}
                    wide
                    options={{
                      hasReviewedDocuments: (
                        <p className="p-p">
                          {t(
                            'pension.qnr.submitApplication.documentsReview.label',
                            'I reviewed the insurance conditions/IPID, advice record, and licensing information.'
                          )}
                        </p>
                      ),
                    }}
                  />
                  <Button
                    loading={loading}
                    disabled={!isValidForSubmission}
                    className="w100 mt24"
                    data-cy="button-submit-application"
                    type="submit"
                  >
                    {t(
                      'pension.qnr.reviewCheckout.submitApplication.caption',
                      'Buy policy'
                    )}
                  </Button>
                  <p className="p-p--small tc-grey-500 mt24 ta-center">
                    {t(
                      'pension.qnr.submitApplication.description',
                      'First payments are charged on the policy start date. Coverage is valid only after a confirmation email is received and the start date has passed.'
                    )}
                  </p>
                </>
              }
            />
          </form>
        </div>
        <BottomOrRegularModal
          isOpen={openModal}
          onClose={() => setOpenModal(false)}
          title={t('pension.qnr.sepaMandate.modal.title', 'SEPA mandate')}
        >
          <Trans i18nKey="pension.qnr.sepaMandate.modal.text">
            <div className="p-p px24 pb24">
              <p>
                By setting up direct debit, I authorise Squarelife Insurance AG
                to collect payments from my account by direct debit or any other
                similar direct debit system in use in my country. At the same
                time, I instruct my credit institution to honour the direct
                debits drawn on my account by the group company. I agree that I
                will be notified of the SEPA direct debit collection at least 5
                calendar days in advance.
              </p>
              <p>
                <b className="fw-bold">Note:</b> I may request a refund of the
                debited amount within 8 weeks, beginning with the debit date.
                The conditions agreed with my credit institution apply.
              </p>
              <p className="pt24">
                <b className="fw-bold">Payment mode:</b> Monthly
              </p>
              <p>
                <b className="fw-bold">Premium:</b> as agreed above
              </p>
              <p>
                <b className="fw-bold">Company:</b> Squarelife Insurance AG
              </p>
              <p>
                <b className="fw-bold">Payment method:</b>{' '}
                SEPA-Lastschrift:DE50120300001081442533
              </p>
              <p>
                <b className="fw-bold">Creditor-Identification-No:</b>{' '}
                DE65ZZZ00001990209
              </p>
            </div>
          </Trans>
        </BottomOrRegularModal>
      </div>
    );
  }

  return (
    <div className="p-body">
      <form
        className="d-flex fd-column ai-center mt80 mb80"
        onSubmit={handleSubmitApplication}
      >
        <img
          className={`${styles.icon} br8`}
          src={imageTypeMapping.PENSION}
          alt="A palm tree and a sunbed"
        />
        <h1 className="p-h1 py24">
          {t('pension.qnr.reviewCheckout.title', 'Submit application')}
        </h1>
        <div className="mt16 d-flex f-wrap ai-start gap24">
          <div className={styles.columnItem}>
            <PolicyDetails />
          </div>
          <div className={styles.columnItem}>
            <SepaMandate
              accountHolder={accountHolder}
              iban={iban}
              checkboxValues={checkboxValues}
              setCheckboxValues={setCheckboxValues}
            />
          </div>
        </div>
        <div
          className={`d-flex ai-center fd-column mt32 w100 ${styles.buttonContainer}`}
        >
          <div
            className={classNames(
              'd-flex fd-column gap32',
              styles.buttonDocsContainer
            )}
          >
            {documents && areDocumentsValid(documents) && (
              <PensionDocumentsReview
                documents={documents}
                checkboxValue={checkboxValues}
                setCheckboxValues={setCheckboxValues}
              />
            )}
            <Button
              loading={loading}
              disabled={!isValidForSubmission}
              className="wmn3"
              data-cy="button-submit-application"
              type="submit"
            >
              {t(
                'pension.qnr.reviewCheckout.submit.caption',
                'Submit application'
              )}
            </Button>
            <AnimateHeight duration={300} height={error ? 'auto' : 0}>
              <InformationBox variant="warning" className="mt24 wmx6">
                {t(
                  'pension.qnr.reviewCheckout.submit.error.message',
                  'We could not submit your pension application. Please try again or contact us at {{supportEmail}}.',
                  { supportEmail: email.help }
                )}
              </InformationBox>
            </AnimateHeight>
          </div>
        </div>
      </form>
    </div>
  );
};
