import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { ClaimAssessment } from 'models/claims';
import endpoint from 'shared/api';

export const getClaimAssessment = async (
  assessmentId: string
): Promise<ClaimAssessment> => {
  const { data } = await endpoint.getClaimAssessment(assessmentId);
  return data;
};

export const useGetClaimAssessment = (assessmentId: string) => {
  const {
    isLoading,
    data: assessment,
    isError,
  } = useQuery<ClaimAssessment, AxiosError>({
    queryKey: ['getClaimAssessment'],
    queryFn: () => getClaimAssessment(assessmentId),
    enabled: true,
  });

  return {
    isError,
    isLoading,
    assessment,
  };
};
