import LoadingSpinner from 'components/loadingSpinner';
import { useLoadPolicyDetail } from 'features/claimsProcess/hooks';
import {
  flushHouseholdClaimsAnswers,
  storeHouseholdClaimsAnswers,
} from 'features/householdClaims/actions';
import { HouseholdClaimsView } from 'features/householdClaims/HouseholdClaims.view';
import type {
  HouseholdClaims as HouseholdClaimsModel,
  HouseholdClaimsDispatch,
} from 'features/householdClaims/models';
import { translatedQuestionnaire } from 'features/householdClaims/questionnaire';
import { getHouseholdClaims } from 'features/householdClaims/selectors';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useSafeTranslation } from 'shared/i18n';

export const HouseholdClaims = () => {
  const { t } = useSafeTranslation();
  const dispatch = useDispatch<HouseholdClaimsDispatch>();
  const { policyId }: { policyId: string } = useParams();

  const answers = useSelector(getHouseholdClaims) ?? {};
  const { policy, loading } = useLoadPolicyDetail(policyId);

  const flushAnswers = useCallback(() => {
    dispatch(flushHouseholdClaimsAnswers());
  }, [dispatch]);

  useEffect(() => {
    return flushAnswers;
  }, [policyId, dispatch, flushAnswers]);

  const handleSubmitAnswer = (answer: Partial<HouseholdClaimsModel>) => {
    dispatch(storeHouseholdClaimsAnswers(answer));
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  if (!policy) {
    throw new Error('[Household claims] Missing selected policy information');
  }

  return (
    <HouseholdClaimsView
      questionnaireAnswers={answers}
      questionnaire={translatedQuestionnaire(t, policy)}
      handleAnswerQuestion={handleSubmitAnswer}
    />
  );
};
