import * as Sentry from '@sentry/react';
import { useMutation } from '@tanstack/react-query';
import { ExistingAccountResponse } from 'models/session';
import endpoint from 'shared/api';

export const useValidateAccount = () => {
  const mutation = useMutation({
    mutationFn: async (email: string): Promise<ExistingAccountResponse> => {
      const { data } = await endpoint.validateAccount(email);
      return data;
    },
    onError: (error, email) => {
      Sentry.captureException(error, {
        tags: {
          feature: 'ACCOUNT',
        },
        extra: {
          email,
          description: 'Error happened while validating email address',
        },
      });
    },
  });

  return mutation;
};
