import { Button } from '@popsure/dirty-swan';
import { fetchPolicyDetail, mergePolicyAttr } from 'actions/policies';
import { HealthCardDispatch } from 'models/healthCard';
import { useState } from 'react';
import { createPortal } from 'react-dom';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { ConfirmReceivedHealthCardModal } from '../ConfirmReceivedHealthCardModal';
import { useMarkCardAsReceived } from '../hooks/useMarkCardAsReceived';
import styles from './MarkHealthCardAsReceived.module.scss';

export const MarkHealthCardAsReceived = () => {
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const { policyId } = useParams<{ policyId: string }>();
  const dispatch = useDispatch<HealthCardDispatch>();
  const {
    mutate: markHealthCardAsReceived,
    isLoading,
    error,
  } = useMarkCardAsReceived();

  const openConfirmationModal = () => setShowConfirmModal(true);

  const handleCancel = () => setShowConfirmModal(false);

  const handleMarkAsReceived = () => {
    markHealthCardAsReceived(policyId, {
      onSuccess: async () => {
        await dispatch(fetchPolicyDetail(policyId));
        dispatch(
          mergePolicyAttr(policyId, { showHealthCardConfirmation: true })
        );
      },
    });
  };

  return (
    <>
      <div className={styles.container}>
        <Button
          className="w100 wmx3"
          variant="filledGray"
          onClick={openConfirmationModal}
        >
          I received it
        </Button>
      </div>
      {showConfirmModal &&
        createPortal(
          <ConfirmReceivedHealthCardModal
            loading={isLoading}
            onMarkAsReceived={handleMarkAsReceived}
            onCancel={handleCancel}
            error={error}
          />,
          document.body
        )}
    </>
  );
};
