import { TFunction } from '@getpopsure/i18n-react';
import { calendly, faq, website } from '@getpopsure/private-constants';
import { breedsSpain } from '@getpopsure/public-models';
import { illustrations } from '@popsure/dirty-swan';
import dayjs from 'dayjs';
import { imageTypeMapping } from 'models/insurances/types/mapping';
import { SignupQuestionnaireType } from 'SignupQuestionnaire';

import { createPostQuote } from './actions';
import {
  DogLiabilitySpain,
  DogLiabilitySpainGroupIds,
  genderMapping,
  IdentificationDocument,
  identificationDocumentMapping,
  identificationNumberMapping,
} from './models';

export const DogLiabilitySpainComponents = {} as const;

export type DogLiabilitySpainQuestionnaire = SignupQuestionnaireType<
  DogLiabilitySpain,
  DogLiabilitySpainGroupIds,
  typeof DogLiabilitySpainComponents
>;

export const getTranslatedQuestionnaire = (
  t: TFunction,
  isDangerousBreed = false,
  identificationDocument: IdentificationDocument = 'PASSPORT',
  useNewCheckout?: boolean
): DogLiabilitySpainQuestionnaire => [
  {
    id: 'intro',
    required: true,
    type: 'INTRO',
    props: {
      title: t(
        'dogLiabilitySpain.qnr.preQuote.intro.title',
        "Hey! Let's get you a quote!"
      ),

      subTitle: t(
        'dogLiabilitySpain.qnr.preQuote.intro.subTitle',
        'It’s just 2 questions, it takes less than a minute'
      ),
    },
    screen: {
      continueButtonText: t(
        'dogLiabilitySpain.qnr.preQuote.intro.continueButtonText',
        'Get started'
      ),
    },
    groupId: 'preQuote',
  },
  {
    id: 'isMixedBreed',
    required: true,
    type: 'RADIO',
    props: {
      mapValue: {
        YES: t(
          'dogLiabilitySpain.qnr.preQuote.isMixedBread.mixedBreed',
          'Mixed breed'
        ),
        NO: t(
          'dogLiabilitySpain.qnr.preQuote.isMixedBread.purebred',
          'Purebred'
        ),
      },
    },
    screen: {
      question: t(
        'dogLiabilitySpain.qnr.preQuote.isMixedBread.title',
        'Is your dog mixed or purebred?'
      ),
    },
    groupId: 'signup',
  },
  {
    id: 'breed',
    type: 'AUTOSUGGEST',
    props: {
      options: [...breedsSpain],
    },
    screen: {
      question: t(
        'dogLiabilitySpain.qnr.preQuote.breed.title',
        'What breed is your dog?'
      ),
      skipDetails: {
        title: t(
          'dogLiabilitySpain.qnr.preQuote.breed.notListed',
          'Unknown or not listed'
        ),
        skipQuestion: { value: 'Unknown/not listed' },
        path: '',
      },
      noMaxWidth: true,
    },
    groupId: 'preQuote',
  },
  {
    id: 'mixedBreed',
    type: 'AUTOSUGGEST_MULTI',
    props: {
      options: [...breedsSpain],
    },
    screen: {
      question: t(
        'dogLiabilitySpain.qnr.preQuote.mixedBreed.title',
        'What mix of breeds is your dog?'
      ),
      description: t(
        'dogLiabilitySpain.qnr.preQuote.mixedBreed.description',
        'If known, add the breeds of the parents'
      ),
      skipDetails: {
        title: t(
          'dogLiabilitySpain.qnr.preQuote.mixedBreed.notListed',
          'Unknown or not listed'
        ),
        skipQuestion: { value: ['Unknown/not listed'] },
        path: '',
      },
      noMaxWidth: true,
    },
    groupId: 'preQuote',
  },
  {
    id: 'quote',
    required: true,
    type: 'QUOTEPAGE',
    groupId: 'signup',
    props: {
      config: {
        title: t('dogLiabilitySpain.qnr.quote.title', 'Here is your quote'),
        headerImage: {
          src: imageTypeMapping.DOG_LIABILITY_ES,
          alt: 'dog',
        },
        reviewBadge: true,
        coveredSection: {
          layout: {
            cards: true,
          },
          title: t(
            'dogLiabilitySpain.qnr.quote.covered.title',
            "What's covered?"
          ),
          items: [
            {
              title: t(
                'dogLiabilitySpain.qnr.quote.covered.injuries.title',
                'Medical injuries to other people'
              ),
              description: t(
                'dogLiabilitySpain.qnr.quote.covered.injuries.description',
                'If your dog gets excited and runs into someone, causing them to fall and hurt themselves, your policy will cover the expenses.'
              ),
              icon: {
                src: illustrations.aids,
                alt: 'Aid',
              },
            },
            {
              title: t(
                'dogLiabilitySpain.qnr.quote.covered.propertyDamage.title',
                'Property damage'
              ),
              description: t(
                'dogLiabilitySpain.qnr.quote.covered.propertyDamage.description',
                "You'll be covered if your dog damages someone's belongings (E.g. your friend's carpet or laptop)."
              ),
              icon: {
                src: illustrations.damagedLaptop,
                alt: 'Damaged laptop',
              },
            },
            {
              title: t(
                'dogLiabilitySpain.qnr.quote.covered.injuriesToOtherPets.title',
                'Injuries to other pets'
              ),
              description: t(
                'dogLiabilitySpain.qnr.quote.covered.injuriesToOtherPets.description',
                "Did your dog run away after the neighbour's cat? Rest assured other animals' vet bills are covered too."
              ),
              icon: {
                src: illustrations.petsCat,
                alt: 'Cat',
              },
            },
            {
              title: t(
                'dogLiabilitySpain.qnr.quote.covered.financialLoss.title',
                'Financial loss'
              ),
              description: t(
                'dogLiabilitySpain.qnr.quote.covered.financialLoss.description',
                "If your dog bites the mailperson and they can't work after that, dog liability insurance will cover you."
              ),
              icon: {
                src: illustrations.moneyIncome,
                alt: 'Money Income',
              },
            },
          ],
        },
        priceHeader: {
          title: '{{value}}',
          subTitle: t('dogLiabilitySpain.qnr.quote.perMonth', 'per month'),
        },
        actions: {
          items: [
            {
              type: 'SubHeader',
              text: t(
                'dogLiabilitySpain.qnr.quote.areaOfCoverage.title',
                'Choose your area of coverage'
              ),
            },
            {
              type: 'Description',
              text: t(
                'dogLiabilitySpain.qnr.quote.areaOfCoverage.description',
                'We recommend extending your coverage to EU-wide if you frequently travel or stay for long periods outside of Spain.'
              ),
            },
            {
              type: 'Toggle',
              mapValues: {
                0: t('dogLiabilitySpain.qnr.quote.toggle.spain', 'Only Spain'),
                1: t('dogLiabilitySpain.qnr.quote.toggle.eu', 'EU-wide'),
              },
            },
          ],
          valuesMap: {
            0: isDangerousBreed ? '8.82' : '4.41',
            1: isDangerousBreed ? '13.23' : '6.62',
          },
          submitMap: [
            {
              id: '1',
              price: isDangerousBreed ? 8.82 : 4.41,
              coveredArea: 'SPAIN',
              isDangerousBreed,
              plan: 'BASIC',
            },
            {
              id: '2',
              price: isDangerousBreed ? 13.23 : 6.62,
              coveredArea: 'EU',
              isDangerousBreed,
              plan: 'BASIC',
            },
          ],
          button: {
            text: t(
              'dogLiabilitySpain.qnr.quote.continue.title',
              'Get started'
            ),
          },
        },
        notCoveredSection: {
          title: t(
            'dogLiabilitySpain.qnr.quote.notCovered.title',
            "What's not covered?"
          ),
          items: [
            {
              title: t(
                'dogLiabilitySpain.qnr.quote.notCovered.damage.title',
                'Damage to own property'
              ),
              description: t(
                'dogLiabilitySpain.qnr.quote.notCovered.damage.description',
                'Replacement or repair of things you or the dog sitter own is not covered.'
              ),
              icon: {
                src: illustrations.brokenGlass,
                alt: '',
              },
            },
            {
              title: t(
                'dogLiabilitySpain.qnr.quote.notCovered.injuries.title',
                'Injuries to you or the dog sitter'
              ),
              description: t(
                'dogLiabilitySpain.qnr.quote.notCovered.injuries.description',
                'While we offer health insurance separately, dog liability insurance will not cover your medical bills if your dog bites you.'
              ),
              icon: {
                src: illustrations.shieldCross,
                alt: '',
              },
            },
            {
              title: t(
                'dogLiabilitySpain.qnr.quote.notCovered.penalities.title',
                'Penalties and fines'
              ),
              description: t(
                'dogLiabilitySpain.qnr.quote.notCovered.penalities.description',
                'Shall you walk your dog without a leash and get fined, the sanction isn’t covered.'
              ),
              icon: {
                src: illustrations.visual,
                alt: '',
              },
            },
            {
              title: t(
                'dogLiabilitySpain.qnr.quote.notCovered.diseases.title',
                'Contagion of diseases'
              ),
              description: t(
                'dogLiabilitySpain.qnr.quote.notCovered.diseases.description',
                'If your dog is sick and infects another person or animal, they will not be covered.'
              ),
              icon: {
                src: illustrations.bigDog,
                alt: '',
              },
            },
          ],
        },
        ctaBox: {
          header: t(
            'dogLiabilitySpain.qnr.quote.ctaBox.title',
            'Dog liability insurance'
          ),
          title: '{{value}}',
          subTitle: t(
            'dogLiabilitySpain.qnr.quote.ctaBox.subTitle',
            'per month'
          ),
          buttonText: t(
            'dogLiabilitySpain.qnr.quote.ctaBox.buttonText',
            'Get started'
          ),
        },
        faq: {
          title: t(
            'dogLiabilitySpain.qnr.quote.faq.title',
            'Frequently asked questions'
          ),
          data: [
            {
              questionId: 1,
              question: t(
                'dogLiabilitySpain.qnr.quote.faq.one.question',
                'Do I need dog liability insurance if I already have personal liability insurance?'
              ),
              answer: t(
                'dogLiabilitySpain.qnr.quote.faq.one.answer',
                'Personal liability insurance covers small animals like cats or birds but dogs usually aren’t covered. This is why we recommend getting dog liability insurance in addition to personal liability insurance.'
              ),
            },
            {
              questionId: 2,
              question: t(
                'dogLiabilitySpain.qnr.quote.faq.two.question',
                'Is dog liability insurance mandatory?'
              ),
              answer: t(
                'dogLiabilitySpain.qnr.quote.faq.two.answer',
                'In Spain it is mandatory to have a dog liability insurance for potentially dangerous breeds. After the passing of new Spanish regulation, it will soon be legally required for the rest of breeds too. It is highly recommended to have dog liability insurance as whatever damage your dog does, you would have to pay.'
              ),
            },
            {
              questionId: 3,
              question: t(
                'dogLiabilitySpain.qnr.quote.faq.three.question',
                'My dog is considered to be a “dangerous” breed. Can I still be covered?'
              ),
              answer: t(
                'dogLiabilitySpain.qnr.quote.faq.three.answer',
                'Yes. The only difference is potentially dangerous dogs are charged 4.41€ more per month.'
              ),
            },
            {
              questionId: 4,
              question: t(
                'dogLiabilitySpain.qnr.quote.faq.four.question',
                'Can I get dog insurance for a mixed-breed dog?'
              ),
              answer: t(
                'dogLiabilitySpain.qnr.quote.faq.four.answer',
                'Yes! All dogs can be covered regardless of the breed.'
              ),
            },
            {
              questionId: 5,
              question: t(
                'dogLiabilitySpain.qnr.quote.faq.five.question',
                'Do you differentiate across age, weight or size?'
              ),
              answer: t(
                'dogLiabilitySpain.qnr.quote.faq.five.answer',
                'No, we ensure your beloved one will get covered regardless of how old, young, big or small it is.'
              ),
            },
            {
              questionId: 6,
              question: t(
                'dogLiabilitySpain.qnr.quote.faq.six.question',
                "Who's covered under dog liability insurance?"
              ),
              answer: t(
                'dogLiabilitySpain.qnr.quote.faq.six.answer',
                'Feather’s dog liability insurance covers both the owner and the person in charge of the dog at any given moment, shall it be a relative, neighbour, friend or anyone looking after your dog.'
              ),
            },
            {
              questionId: 7,
              question: t(
                'dogLiabilitySpain.qnr.quote.faq.seven.question',
                'How do claims work?'
              ),
              answer: t(
                'dogLiabilitySpain.qnr.quote.faq.seven.answer',
                'You will simply need to submit a claim within your Feather’s account. We will do the rest.'
              ),
            },
            {
              questionId: 8,
              question: t(
                'dogLiabilitySpain.qnr.quote.faq.eight.question',
                'How can I cancel?'
              ),
              answer: t(
                'dogLiabilitySpain.qnr.quote.faq.eight.answer',
                'You will need to let us know 1 month in advance before your policy gets renewed 1 year after its start date.'
              ),
            },
          ],
          footer: t('dogLiabilitySpain.qnr.quote.faq.footer', {
            defaultValue: `See our [FAQ page]({{link}}) for more answers.`,
            link: faq.baseEs,
          }),
        },
        moreQuestionsSection: {
          bookACallGACategory: 'Dog',
          title: t(
            'dogLiabilitySpain.qnr.quote.moreQuestions.title',
            'Have more questions?'
          ),
          bookACallUrl: calendly.dogLiabilityInsurance,
          secondaryActionLink: faq.baseEs,
          secondaryAction: t(
            'dogLiabilitySpain.qnr.quote.moreQuestions.secondary',
            'Visit our FAQ'
          ),
        },
      },
    },
    screen: {
      layout: 'Standalone',
    },
  },
  {
    id: 'signupIntro',
    required: true,
    type: 'INTRO',
    props: {
      title: t(
        'dogLiabilitySpain.qnr.signup.signupIntro.title',
        "Great! Let's sign you up"
      ),
      subTitle: t(
        'dogLiabilitySpain.qnr.signup.signupIntro.subTitle',
        'Just 12 questions, it takes about 1-2 minutes.'
      ),
    },
    screen: {
      continueButtonText: t(
        'dogLiabilitySpain.qnr.signup.signupIntro.continueButtonText',
        'Get started'
      ),
    },
    groupId: 'signup',
  },
  {
    id: 'email',
    required: true,
    type: 'EMAIL',
    props: {
      verticalId: 'dogLiabilitySpain',
    },
    screen: {
      layout: 'Standalone',
      question: t(
        'dogLiabilitySpain.qnr.signup.postalCode.email.title',
        "What's your email address?"
      ),
      description: t(
        'dogLiabilitySpain.qnr.signup.postalCode.email.description',
        'This policy will be accessible in your Feather account.'
      ),
    },
    groupId: 'signup',
  },
  {
    id: 'dogName',
    required: true,
    type: 'INPUT',
    props: {
      placeholder: t(
        'dogLiabilitySpain.qnr.signup.dogName.placeholder',
        "Dog's name"
      ),
    },
    screen: {
      question: t(
        'dogLiabilitySpain.qnr.signup.dogName.title',
        "What's your dog's name?"
      ),
    },
    groupId: 'signup',
  },
  {
    id: 'microchipNumber',
    required: true,
    type: 'INPUT',
    props: {
      placeholder: t(
        'dogLiabilitySpain.qnr.signup.microchipNumber.placeholder',
        "Dog's microchip number"
      ),
    },
    screen: {
      question: t(
        'dogLiabilitySpain.qnr.signup.microchipNumber.title',
        "What's your dog's microchip number?"
      ),
      additionalInfo: {
        title: t(
          'dogLiabilitySpain.qnr.signup.microchipNumber.additionalInfo.title',
          'Where to find the microchip number'
        ),
        description: t(
          'dogLiabilitySpain.qnr.signup.microchipNumber.additionalInfo.description',
          'The microchip, or alphanumeric transponder code, is a unique ID number for your dog. It can be found on the dog’s health card or passport.\n\nIf you’re unsure whether your dog has one, your vet can scan for the microchip, and if present, provide you with the number.'
        ),
      },
    },
    groupId: 'signup',
  },
  {
    id: 'name',
    required: true,
    type: 'NAME',
    props: {},
    screen: {
      question: t(
        'dogLiabilitySpain.qnr.signup.name.title',
        "What's your name?"
      ),
      description: t(
        'dogLiabilitySpain.qnr.signup.name.description',
        'Please include your second last name if you have one.'
      ),
    },
    groupId: 'signup',
  },
  {
    id: 'dateOfBirth',
    type: 'DATE',
    required: true,
    props: {
      yearRange: {
        min: { op: 'subtract', type: 'years', value: 100 },
        max: { op: 'subtract', type: 'years', value: 0 },
      },
      placeholders: {
        day: t('dogLiabilitySpain.qnr.preQuote.day', 'Day'),
        month: t('dogLiabilitySpain.qnr.preQuote.month', 'Month'),
        year: t('dogLiabilitySpain.qnr.preQuote.year', 'Year'),
      },
    },
    screen: {
      question: t(
        'dogLiabilitySpain.qnr.preQuote.dateOfBirth.title',
        'When were you born?'
      ),
    },
    validations: [
      {
        op: 'dateDiff',
        variable: {
          type: 'day',
          value: 1,
        },
        msg: {
          type: 'Info',
          value: t(
            'dogLiabilitySpain.qnr.preQuote.dateOfBirth.errorMessage',
            'You can’t select a birth date in the future. Please make sure the date of birth is correct.'
          ),
        },
      },
    ],
    groupId: 'signup',
  },
  {
    id: 'under18Blocker',
    type: 'BLOCKER',
    props: {
      title: t(
        'dogLiabilitySpain.qnr.signup.under18Blocker.title',
        'We can’t sign you up...'
      ),
      iconType: 'SHIELD',
      description: t(
        'dogLiability.qnr.signup.under18Blocker.description',
        'We currently only cover people who are over 18 years old.\n\nYou can speak to one of our insurance experts if you have any questions'
      ),
      buttonProps: [
        {
          type: 'href',
          href: `${website.base}/en-es/support`,
          caption: t(
            'dogLiability.qnr.signup.under18Blocker.button',
            'Speak to us'
          ),
        },
      ],
    },
    screen: {
      layout: 'Standalone',
    },
    groupId: 'signup',
  },
  {
    id: 'gender',
    required: true,
    type: 'RADIO',
    props: {
      mapValue: genderMapping(t),
    },
    screen: {
      question: t(
        'dogLiabilitySpain.qnr.signup.gender.title',
        "What's your gender?"
      ),
    },
    groupId: 'signup',
  },
  {
    id: 'address',
    required: true,
    type: 'ADDRESS',
    props: {
      countryCode: 'ES',
      placeholders: {
        street: t('dogLiabilitySpain.qnr.signup.address.street', 'Street'),
        houseNumber: t(
          'dogLiabilitySpain.qnr.signup.address.houseNumber',
          'House number'
        ),
        additionalInformation: t(
          'dogLiabilitySpain.qnr.signup.address.additionalInformation',
          'Additional information (C/O, apartment, …)'
        ),
        postcode: t(
          'dogLiabilitySpain.qnr.signup.address.postcode',
          'Postcode'
        ),
        city: t('dogLiabilitySpain.qnr.signup.address.city', 'City'),
        manualAddressEntry: t(
          'dogLiabilitySpain.qnr.signup.address.searchInput',
          'Search for address'
        ),
      },
      manualAddressEntryTexts: {
        preText: t(
          'dogLiabilitySpain.qnr.signup.address.manual.preText',
          'Or '
        ),
        cta: t(
          'dogLiabilitySpain.qnr.signup.address.manual.cta',
          'enter address manually'
        ),
      },
    },
    screen: {
      question: t(
        'dogLiabilitySpain.qnr.signup.address.title',
        "What's your address in Spain?"
      ),
      noMaxWidth: true,
    },
    groupId: 'signup',
  },
  {
    id: 'identificationDocument',
    required: true,
    type: 'RADIO',
    props: {
      mapValue: identificationDocumentMapping(t),
    },
    screen: {
      question: t(
        'dogLiabilitySpain.qnr.signup.identificationDocument.title',
        'Provide an identification document'
      ),
    },
    groupId: 'signup',
  },
  {
    id: 'identificationNumber',
    required: true,
    type: 'INPUT',
    props: {
      placeholder:
        identificationNumberMapping(t)[identificationDocument].placeholder,
    },
    screen: {
      question: identificationNumberMapping(t)[identificationDocument].title,
    },
    groupId: 'signup',
  },
  {
    id: 'citizenship',
    type: 'COUNTRY_MULTI',
    props: {},
    screen: {
      question: t(
        'dogLiabilitySpain.qnr.signup.citizenship.title',
        'Which country do you hold a passport from?'
      ),
    },
    groupId: 'signup',
  },
  {
    id: 'startDate',
    required: true,
    type: 'DATE',
    props: {
      yearRange: {
        min: { op: 'subtract', type: 'years', value: 0 },
        max: { op: 'add', type: 'years', value: 5 },
      },
      placeholders: {
        day: t('dogLiabilitySpain.qnr.preQuote.day', 'Day'),
        month: t('dogLiabilitySpain.qnr.preQuote.month', 'Month'),
        year: t('dogLiabilitySpain.qnr.preQuote.year', 'Year'),
      },
    },
    screen: {
      question: t(
        'dogLiabilitySpain.qnr.signup.startDate.title',
        'When would you like your coverage to start?'
      ),
    },
    validations: [
      {
        op: 'DateIsInFuture',
        msg: {
          type: 'Info',
          value: t(
            'dogLiabilitySpain.qnr.signup.DateIsInFuture.earliestStartDate.error',
            'Earliest start date is {{value}}. Please select another date.'
          ),
        },
      },
      {
        op: 'Custom',
        fn: (answer) => {
          return dayjs(answer as string).isBefore(dayjs().add(3, 'month'));
        },
        msg: () => ({
          type: 'Info',
          msg: t(
            'dogLiabilitySpain.qnr.signup.DateIsInFuture.moreThanThreeMonthsInTheFuture.error',
            {
              defaultValue:
                'Coverage start date should not be more than 90 days in the future. Please adjust the start date.',
              date: dayjs().add(3, 'month').format('DD MMM YYYY'),
            }
          ),
        }),
      },
    ],
    groupId: 'signup',
  },
  {
    id: 'review',
    required: true,
    type: 'REVIEW',
    props: {
      title: t(
        'dogLiabilitySpain.qnr.signup.review.title',
        'Review your details'
      ),
      isDescriptionVisible: false,
      confirmationText: t(
        'dogLiabilitySpain.qnr.signup.review.confirmationText',
        'By selecting "Continue", I confirm to have answered all questions truthfully. Knowingly omitting any relevant details entitles the insurer to cancel the contract—either retroactively or from the date the omission is discovered.'
      ),
      requestType: 'REVIEW_SUBMIT_INFO',
      reviewValues: [
        {
          title: t('dogLiabilitySpain.qnr.signup.review.dogName', "Dog's Name"),
          value: { type: 'String', key: 'dogName' },
          path: 'dogName',
        },
        {
          title: t(
            'dogLiabilitySpain.qnr.signup.review.microchipNumber',
            'Microchip number'
          ),
          value: {
            type: 'String',
            key: 'microchipNumber',
          },
          path: 'microchipNumber',
        },
        {
          title: t('dogLiabilitySpain.qnr.signup.review.pureBreed', 'Breed'),
          value: { type: 'String', key: 'breed' },
          path: 'breed',
        },
        {
          title: t('dogLiabilitySpain.qnr.signup.review.mixedBreed', 'Breed'),
          value: { type: 'String', key: 'mixedBreed' },
          path: 'mixedBreed',
        },
        {
          title: t('dogLiabilitySpain.qnr.signup.review.email', 'Email'),
          value: { type: 'String', key: 'email' },
          path: 'email',
        },
        {
          title: t(
            'dogLiabilitySpain.qnr.signup.review.policyHolderName',
            "Policy holder's name"
          ),
          value: {
            type: 'Strings',
            key: 'name',
            keys: ['firstName', 'lastName'],
          },
          path: 'name',
        },
        {
          title: t(
            'dogLiabilitySpain.qnr.signup.review.dateOfBirth',
            'Date of birth'
          ),
          value: { type: 'Date', format: 'DD MMM YYYY', key: 'dateOfBirth' },
          path: 'dateOfBirth',
        },
        {
          title: t(
            'dogLiabilitySpain.qnr.signup.review.gender.title',
            'Gender'
          ),
          value: {
            type: 'String',
            valueMap: genderMapping(t),
            key: 'gender',
          },
          path: 'gender',
        },
        {
          title: t(
            'dogLiabilitySpain.qnr.signup.review.address',
            'Address in Spain'
          ),
          value: {
            type: 'Strings',
            key: 'address',
            keys: ['street', 'houseNumber', 'postcode', 'city'],
          },
          path: 'address',
        },
        {
          title: t(
            'dogLiabilitySpain.qnr.signup.review.gender.identificationDocument',
            'ID type'
          ),
          value: {
            type: 'String',
            valueMap: identificationDocumentMapping(t),
            key: 'identificationDocument',
          },
          path: 'identificationDocument',
        },
        {
          title: t(
            'dogLiabilitySpain.qnr.signup.review.gender.identificationNumber',
            'ID number'
          ),
          value: {
            type: 'String',
            key: 'identificationNumber',
          },
          path: 'identificationNumber',
        },
        {
          title: t(
            'dogLiabilitySpain.qnr.signup.review.citizenship',
            'Nationality'
          ),
          value: { type: 'Strings', keys: ['name'], key: 'citizenship' },
          path: 'citizenship',
        },
        {
          title: t(
            'dogLiabilitySpain.qnr.signup.review.coverageStartDate',
            'Coverage start date'
          ),
          value: { type: 'Date', format: 'DD MMM YYYY', key: 'startDate' },
          path: 'startDate',
        },
      ],
      verticalId: 'dogLiabilitySpain',
      insuranceType: 'DOG_LIABILITY_ES',
      createPostQuote: () => createPostQuote(t),
    },
    screen: {
      layout: 'Standalone',
    },
    groupId: 'signup',
  },
  ...(!useNewCheckout
    ? [
        {
          id: 'reviewCheckout' as const,
          required: true,
          type: 'GENERIC_CHECKOUT' as const,
          props: {
            type: 'DOG_LIABILITY_ES' as const,
            icon: imageTypeMapping.DOG_LIABILITY,
            region: 'es' as const,
            hasCheckoutDocuments: true,
            policyInfoKind: 'DogLiabilitySpainPolicy' as const,
            policyDetailsConfiguration: {
              policyDetails: [
                {
                  title: t(
                    'dogLiabilitySpain.qnr.checkout.policyDetails.startDate',
                    'Requested start date'
                  ),
                  questionId: 'startDate',
                  additionalInfo: {
                    title: t(
                      'dogLiabilitySpain.qnr.checkout.policyDetails.requestedStartDate.additionalInfo.title',
                      'Requested start date'
                    ),
                    description: t(
                      'dogLiabilitySpain.qnr.checkout.policyDetails.requestedStartDate.additionalInfo.descriptionNoValue',
                      'Your coverage should begin on the requested date, but only after you have received the official confirmation.'
                    ),
                  },
                },
                {
                  title: t(
                    'dogLiabilitySpain.qnr.checkout.policyDetails.coveredArea',
                    'Area of coverage'
                  ),
                  questionId: 'coveredArea',
                },
                {
                  title: t(
                    'dogLiabilitySpain.qnr.checkout.policyDetails.dogName',
                    "Dog's name"
                  ),
                  questionId: 'dogName',
                },
                {
                  title: t(
                    'dogLiabilitySpain.qnr.checkout.policyDetails.microchipNumber',
                    'Microchip number'
                  ),
                  questionId: 'microchipNumber',
                },
              ],
            },
          },
          screen: {
            layout: 'Standalone' as const,
          },
          groupId: 'signup' as const,
        },
      ]
    : [
        {
          id: 'reviewCheckout' as const,
          required: true,
          type: 'GENERIC_CHECKOUT_V2' as const,
          props: {
            type: 'DOG_LIABILITY_ES' as const,
            region: 'es' as const,
            hasCheckoutDocuments: true,
            policyInfoKind: 'DogLiabilitySpainPolicy' as const,
            productName: t(
              'dogLiabilitySpain.qnr.checkout.productName',
              'Dog liability'
            ),
            policyDetailsConfiguration: {
              productCardDetails: [
                {
                  id: 'startDate',
                  label: t(
                    'dogLiabilitySpain.qnr.checkout.productCardDetails.startDate',
                    'Start date'
                  ),
                  questionId: 'startDate',
                },
                {
                  id: 'areaOfCoverage',
                  label: t(
                    'dogLiabilitySpain.qnr.checkout.productCardDetails.coveredArea',
                    'Area of coverage'
                  ),
                  questionId: 'coveredArea',
                  dataType: 'REGION' as const,
                },
              ],
              policyDetails: [
                {
                  id: 'name',
                  label: t(
                    'dogLiabilitySpain.qnr.checkout.policyDetails.name',
                    'Name'
                  ),
                  questionId: 'name',
                },
                {
                  id: 'email',
                  label: t(
                    'dogLiabilitySpain.qnr.checkout.policyDetails.email',
                    'Account email'
                  ),
                  questionId: 'email',
                },
              ],
              additionalPolicyDetails: [
                {
                  id: 'petInformation',
                  title: t(
                    'dogLiabilitySpain.qnr.checkout.petInformation.title',
                    'Pet information'
                  ),
                  details: [
                    {
                      id: 'dogName',
                      label: t(
                        'dogLiabilitySpain.qnr.checkout.petInformation.dogName',
                        'Name'
                      ),
                      questionId: 'dogName',
                    },
                    {
                      id: 'microchipNumber',
                      label: t(
                        'dogLiabilitySpain.qnr.checkout.petInformation.microchipNumber',
                        'Microchip number'
                      ),
                      questionId: 'microchipNumber',
                    },
                  ],
                },
              ],
            },
          },
          screen: {
            layout: 'Standalone' as const,
          },
          groupId: 'signup' as const,
        },
      ]),
];
