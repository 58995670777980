import { BottomOrRegularModal, Button, toast } from '@popsure/dirty-swan';
import { useMarkPolicyDroppedOut } from 'features/policySingle/hooks/useMarkPolicyDroppedOut';
import AnimateHeight from 'react-animate-height';
import { useHistory } from 'react-router';
import { useSafeTranslation } from 'shared/i18n';

interface CancelApplicationModalProps {
  policyId: string;
  isOpen: boolean;
  onClose: () => void;
}

const CancelApplicationModal = (props: CancelApplicationModalProps) => {
  const { t } = useSafeTranslation();
  const history = useHistory();

  const { isOpen, onClose, policyId } = props;

  const { markPolicyDroppedOut, isLoading, errorMessage } =
    useMarkPolicyDroppedOut(t);

  const handleCancelApplication = () => {
    markPolicyDroppedOut(
      { policyId },
      {
        onSuccess: () => {
          history.goBack();
          toast(
            t(
              'myPolicies.cancelApplicationModal.successMessage',
              'Your application was successfully canceled'
            ),
            {
              type: 'success',
            }
          );
        },
      }
    );
  };

  return (
    <BottomOrRegularModal
      isOpen={isOpen}
      title="Cancel application"
      onClose={onClose}
    >
      <div className="pt16 pb24 px24">
        <p className="p-p">
          Are you sure you want to cancel your application for <br /> Health
          Insurance?
        </p>
        <Button
          className="w100 mt16"
          onClick={handleCancelApplication}
          loading={isLoading}
        >
          Cancel my application
        </Button>
        <AnimateHeight duration={300} height={errorMessage ? 'auto' : 0}>
          <div className="p-notice p-notice--warning mt16 p-p wmx5">
            {errorMessage}
          </div>
        </AnimateHeight>
      </div>
    </BottomOrRegularModal>
  );
};

export default CancelApplicationModal;
