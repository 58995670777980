import { capitalizeName } from '@getpopsure/public-utility';
import { Button, PlusIcon } from '@popsure/dirty-swan';
import { useQuery } from '@tanstack/react-query';
import { useFlag } from '@unleash/proxy-client-react';
import { storeClaims } from 'actions/claims';
import { AxiosError } from 'axios';
import classNames from 'classnames';
import DelinquencyBlocker from 'components/DelinquencyBlocker/DelinquencyBlocker';
import ErrorView from 'components/error';
import LoadingSpinner from 'components/loadingSpinner';
import routes from 'constants/routes';
import dayjs from 'dayjs';
import noClaimsIcon from 'features/claimsV2/icons/no-claims.svg';
import { useGetPolicies } from 'hooks/useGetPolicies';
import {
  Claim,
  CLAIM_ASSESSMENT_STATUSES_PROCESSED,
  ClaimAssessmentStatusProcessed,
  ClaimStatusWithAPV,
} from 'models/claims';
import { InsuranceTypes } from 'models/insurances/types';
import { getTitleMapping } from 'models/insurances/types/mapping';
import { Name } from 'models/user';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, Link, useHistory } from 'react-router-dom';
import { getAccountInfo, isDelinquentUser } from 'selectors/user';
import { useSafeTranslation } from 'shared/i18n';

import { getClaims } from './api';
import { ClaimCard } from './components/claimCard';
import styles from './style.module.scss';
import { insuranceWithClaimRoute } from './utils';

const ITEM_LIMIT = 10;

const ClaimsDashboard = () => {
  const { t } = useSafeTranslation();
  const [itemsLimit, setItemsLimit] = useState(ITEM_LIMIT);
  const { policies } = useGetPolicies();
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(getAccountInfo);

  const isDelinquent = useSelector(isDelinquentUser);
  const [delinquencyBlockerOpen, setDelinquencyBlockerOpen] = useState(false);

  const isCommonClaims = useFlag('app_common_claims');

  const hasPrivateHealthPolicy = policies?.some(
    (policy) => policy.type === 'PRIVATE_HEALTH'
  );

  const hasActiveAPV = (claim: Claim) =>
    claim.claimAssessment &&
    !CLAIM_ASSESSMENT_STATUSES_PROCESSED.includes(
      claim.claimAssessment.status as ClaimAssessmentStatusProcessed
    );

  // TODO: ideally we can refactor into a reusable hook
  const {
    isLoading,
    data: claims,
    error,
  } = useQuery<Claim[], AxiosError>({
    queryKey: ['getClaims'],
    queryFn: getClaims,
    enabled: true,
    retry: 3,
    retryDelay: 1_000 * 60,
    retryOnMount: true,
    refetchOnReconnect: true,
    refetchOnMount: true,
  });

  useEffect(() => {
    if (claims?.length) {
      /**
       * Stores claims into Redux store for Expat health cancellations
       * This can be removed when we start using cached data
       */
      dispatch(storeClaims(claims));
    }
  }, [dispatch, claims]);

  const duplicateInsuranceTypeLookup = useMemo(() => {
    const lookUp = {} as Record<InsuranceTypes, number>;
    if (claims === null) {
      return lookUp;
    }
    return claims?.reduce((acc, claim) => {
      if (acc[claim.insuranceType]) {
        acc[claim.insuranceType] += 1;
      } else {
        acc[claim.insuranceType] = 1;
      }
      return acc;
    }, lookUp);
  }, [claims]);

  const isDuplicateInsuranceType = (type: InsuranceTypes) => {
    return duplicateInsuranceTypeLookup?.[type]
      ? duplicateInsuranceTypeLookup[type] > 1
      : false;
  };

  const isPolicyHolderMatch = (insuredPerson: Name) => {
    return (
      user?.firstName === insuredPerson?.firstName &&
      user?.lastName === insuredPerson?.lastName
    );
  };

  const getSubTitle = (claim: Claim) => {
    const policy = policies?.find((pol) => pol.id === claim.userPolicyId);

    if (policy && policy.type === 'BIKE') {
      return `${policy.attributes?.model} ${policy.attributes?.brand}`.trim();
    }

    if (policy && policy.type === 'HOUSEHOLD') {
      const { street, houseNumber } = policy.attributes?.householdAttributes
        ?.personalInfo?.address ?? { street: '', houseNumber: '' };
      return `${street} ${houseNumber}`.trim();
    }

    const policyHolder = claim.insuredPerson;

    if (!policyHolder) {
      return '';
    }
    if (
      isDuplicateInsuranceType(claim.insuranceType) ||
      !isPolicyHolderMatch(policyHolder)
    ) {
      return capitalizeName(policyHolder) ?? '';
    }
    return '';
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <ErrorView error={error.message} />;
  }

  const showMore = () => {
    setItemsLimit((limit) => Math.min(limit + ITEM_LIMIT, claims?.length ?? 0));
  };

  const onSelect = (claimId: string) => {
    history.push(
      generatePath(routes.claims.detail.path, {
        claimId,
      })
    );
  };

  const sortedClaims = claims
    ?.sort((a, b) => {
      if (a.status === 'ACTION_NEEDED' || hasActiveAPV(a)) {
        return -1;
      }
      if (b.status === 'ACTION_NEEDED' || hasActiveAPV(b)) {
        return 1;
      }
      return dayjs(b.claimDate).diff(dayjs(a.claimDate));
    })
    .slice(0, itemsLimit);

  const hasPolicies =
    policies &&
    policies.filter((policy) => {
      const filteredInsuranceRoutes = !isCommonClaims
        ? insuranceWithClaimRoute.filter((type) => type !== 'PET_HEALTH')
        : insuranceWithClaimRoute;
      return filteredInsuranceRoutes.includes(policy.type);
    }).length > 0;

  type APVWarningSectionData = {
    claimsCount?: number;
    apvIds?: string[];
    href?: string;
  };

  const apvWarningSectionDataMap: Map<InsuranceTypes, APVWarningSectionData> =
    new Map();

  if (claims && claims.length > 0) {
    for (const claim of claims) {
      if (
        claim.claimAssessment &&
        !CLAIM_ASSESSMENT_STATUSES_PROCESSED.includes(
          claim.claimAssessment.status as ClaimAssessmentStatusProcessed
        )
      ) {
        const { insuranceType } = claim.claimAssessment;

        const oldSectionData =
          apvWarningSectionDataMap.get(insuranceType) ?? {};

        const { claimsCount = 0, apvIds = [] } = oldSectionData;

        const newApvIds = Array.from(
          new Set([...apvIds, claim.claimAssessment.id])
        );

        const href =
          newApvIds.length === 1
            ? generatePath(routes.claims.assessments.path, {
                assessmentId: claim.claimAssessment.id,
              })
            : `${generatePath(routes.me.policies.detail.path, {
                policyId: claim.userPolicyId,
              })}#claim-assessments`;

        apvWarningSectionDataMap.set(insuranceType, {
          claimsCount: claimsCount + 1,
          apvIds: newApvIds,
          href,
        });
      }
    }
  }

  const apvWarningSectionDataArray = Array.from(
    apvWarningSectionDataMap.entries()
  );

  const showAPVWarning = apvWarningSectionDataArray.length > 0;

  return (
    <div className="p-body">
      <DelinquencyBlocker
        onClose={() => setDelinquencyBlockerOpen(false)}
        isOpen={delinquencyBlockerOpen}
        description={t(
          'account.delinquencyBlocker.claims.description',
          'You can submit claims once the outstanding insurance premiums are successfully collected.'
        )}
      />
      <div className={classNames('wmx12 ml-auto mr-auto', styles.container)}>
        <div
          className={classNames(
            'mb24 bg-green d-flex fd-row jc-between ai-center',
            styles.header
          )}
        >
          <div className="p-h1">{t('claims.title', 'All claims')}</div>
          {hasPolicies && (
            <Button
              leftIcon={<PlusIcon />}
              variant="filledWhite"
              onClick={() => {
                if (isDelinquent && !hasPrivateHealthPolicy) {
                  setDelinquencyBlockerOpen(true);
                } else {
                  history.push(routes.claims.create.path);
                }
              }}
            >
              {t('claims.createLinkButton.title', 'New claim')}
            </Button>
          )}
        </div>
        {showAPVWarning &&
          apvWarningSectionDataArray.map(
            ([insuranceType, sectionData], index) => {
              const insuranceTypeTitle =
                getTitleMapping(t)[insuranceType as InsuranceTypes];

              const hasOnlyOneAPV = sectionData.apvIds?.length === 1;

              return (
                <div
                  className={`bg-yellow-100 br8 mt24 p24 border ${styles.yellowBorder}`}
                  key={String(`APV_WARNING_${insuranceType}_${index}`)}
                >
                  <p className="p-p--small">{insuranceTypeTitle}</p>
                  <p className="p-p fw-bold mb8">
                    {t('claims.apvWarningBox.text', {
                      defaultValue:
                        'You have {{numberOfClaims}} claim(s) under in-depth assessment',
                      numberOfClaims: sectionData.claimsCount,
                    })}
                  </p>
                  <Link to={sectionData.href ?? ''}>
                    {hasOnlyOneAPV
                      ? t(
                          'claims.apvWarningBox.linkText.single',
                          'See assessment'
                        )
                      : t(
                          'claims.apvWarningBox.linkText.multiple',
                          'See assessments'
                        )}
                  </Link>
                </div>
              );
            }
          )}
        {sortedClaims && sortedClaims.length > 0 ? (
          <>
            <div className="mt40 mb40">
              {sortedClaims.map((item) => {
                const { insuranceType, claimDate, status, claimTypes, id } =
                  item;
                const displayStatus: ClaimStatusWithAPV = hasActiveAPV(item)
                  ? 'APV'
                  : status;
                return (
                  <ClaimCard
                    key={id}
                    className="mt16"
                    date={claimDate}
                    status={displayStatus}
                    subTitle={getSubTitle(item)}
                    insuranceType={insuranceType}
                    claimTypes={claimTypes}
                    onClick={() => onSelect(id)}
                  />
                );
              })}
            </div>
            {itemsLimit < (claims?.length || 0) && (
              <div className="d-flex ai-center jc-center mb32">
                <button
                  className="pp tc-grey-500 fw-700 ds-interactive-component ds-card--no-dropshadow"
                  onClick={showMore}
                  type="button"
                >
                  {t('claims.showmorebutton.title', 'Show more')}
                </button>
              </div>
            )}
          </>
        ) : (
          <div
            className={`w100 d-flex fd-column ai-center jc-center bg-primary-25 br8 ${styles['empty-container']}`}
          >
            <img src={noClaimsIcon} alt="feathers" />
            <div className="p-p tc-grey-600 mt32">
              {t('claims.noClaimsSubmitted.message', 'No claims submitted.')}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ClaimsDashboard;
