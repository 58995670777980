import { ClaimsAction } from 'constants/actions';
import { DentalClaims } from 'features/dentalClaims/models';

export const storeDentalClaimsAnswers = (
  answer: Partial<DentalClaims>
): ClaimsAction => ({
  type: 'STORE_DENTAL_CLAIM',
  dental: answer,
});

export const flushDentalClaimsAnswers = (): ClaimsAction => ({
  type: 'FLUSH_DENTAL_CLAIM',
});
