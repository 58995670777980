import { useFlag, useFlagsStatus } from '@unleash/proxy-client-react';
import { InsuranceTypes } from 'models/insurances/types';

export const useCheckoutRevampFeatureToggle = (
  insuranceType?: InsuranceTypes
) => {
  const { flagsReady } = useFlagsStatus();
  const lowerCaseInsuranceType = (insuranceType || '').toLowerCase();
  const hasCheckoutRevampFlag = useFlag(
    `app_checkout_revamp_${lowerCaseInsuranceType}`
  );

  if (!insuranceType)
    return {
      flagsReady,
      hasCheckoutRevampFlag: false,
    };

  return { flagsReady, hasCheckoutRevampFlag };
};
