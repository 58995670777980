import * as Sentry from '@sentry/react';
import { useMutation } from '@tanstack/react-query';
import { Beneficiary } from 'features/life/models';
import endpoint from 'shared/api';

import { ZCreateBeneficiaries } from '../models';

export const useCreateBeneficiaries = (
  currentPolicyId: string,
  currentBeneficiaries: Beneficiary[],
  currentToken: string
) => {
  const onCreateBeneficiaries = ({
    policyId,
    beneficiaries,
    policyHolderIdentificationToken,
  }: {
    policyId: string;
    beneficiaries: Beneficiary[];
    policyHolderIdentificationToken: string;
  }) => {
    if (!policyId) {
      throw new Error('Policy ID is missing to create beneficiaries');
    }

    const beneficiariesWithUploadTokens = beneficiaries.map((beneficiary) => ({
      ...beneficiary,
      uploadTokens:
        beneficiary.uploads?.map((upload) => upload.token ?? '') ?? [],
    }));

    const validatedData = ZCreateBeneficiaries.parse({
      beneficiariesWithUploadTokens,
      identificationDocumentToken: policyHolderIdentificationToken,
    });

    return endpoint.createLifeBeneficiaries(
      validatedData.beneficiariesWithUploadTokens,
      validatedData.identificationDocumentToken,
      policyId
    );
  };

  const { mutateAsync } = useMutation({
    mutationFn: onCreateBeneficiaries,
    onError: (error, variables) => {
      const { policyId, beneficiaries } = variables;
      Sentry.captureException(error, {
        tags: {
          feature: 'BENEFICIARIES',
          vertical: 'LIFE',
        },
        extra: {
          policyId,
          beneficiaries,
          description: 'Error happened while creating beneficiaries',
        },
      });
    },
  });

  const createBeneficiaries = () =>
    mutateAsync({
      policyId: currentPolicyId,
      beneficiaries: currentBeneficiaries,
      policyHolderIdentificationToken: currentToken,
    });

  return { createBeneficiaries };
};
