import dayjs from 'dayjs';

import { Quote, SubmittablePetHealthQuestionnaire } from '.';

export const dogPriceGroups = [
  {
    groupId: '1',
    ageGroups: {
      0: {
        plus: {
          deductible: 33.93,
          regular: 39.24,
        },
        surgery: {
          deductible: 11.73,
          regular: 13.8,
        },
      },
      1: {
        plus: {
          deductible: 33.93,
          regular: 39.24,
        },
        surgery: {
          deductible: 11.73,
          regular: 13.8,
        },
      },
      3: {
        plus: {
          deductible: 34.13,
          regular: 39.48,
        },
        surgery: {
          deductible: 13.42,
          regular: 15.99,
        },
      },
      5: {
        plus: {
          deductible: 39.18,
          regular: 45.49,
        },
        surgery: {
          deductible: 16.07,
          regular: 19.13,
        },
      },
      7: {
        plus: {
          deductible: 71.2,
          regular: 83.26,
        },
        surgery: {
          deductible: 31.51,
          regular: 37.51,
        },
      },
    },
  },
  {
    groupId: '2',
    ageGroups: {
      0: {
        plus: {
          deductible: 37.73,
          regular: 43.76,
        },
        surgery: {
          deductible: 11.73,
          regular: 13.8,
        },
      },
      1: {
        plus: {
          deductible: 37.73,
          regular: 43.76,
        },
        surgery: {
          deductible: 11.73,
          regular: 13.8,
        },
      },
      3: {
        plus: {
          deductible: 37.96,
          regular: 44.03,
        },
        surgery: {
          deductible: 13.42,
          regular: 15.99,
        },
      },
      5: {
        plus: {
          deductible: 43.69,
          regular: 50.85,
        },
        surgery: {
          deductible: 16.07,
          regular: 19.13,
        },
      },
      7: {
        plus: {
          deductible: 79.82,
          regular: 93.52,
        },
        surgery: {
          deductible: 31.51,
          regular: 37.51,
        },
      },
    },
  },
  {
    groupId: '3',
    ageGroups: {
      0: {
        plus: {
          deductible: 47.23,
          regular: 55.07,
        },
        surgery: {
          deductible: 14.08,
          regular: 16.56,
        },
      },
      1: {
        plus: {
          deductible: 47.23,
          regular: 55.07,
        },
        surgery: {
          deductible: 14.08,
          regular: 16.56,
        },
      },
      3: {
        plus: {
          deductible: 47.52,
          regular: 55.41,
        },
        surgery: {
          deductible: 15.99,
          regular: 19.03,
        },
      },
      5: {
        plus: {
          deductible: 54.97,
          regular: 64.29,
        },
        surgery: {
          deductible: 20.7,
          regular: 24.64,
        },
      },
      7: {
        plus: {
          deductible: 101.4,
          regular: 119.21,
        },
        surgery: {
          deductible: 40.98,
          regular: 48.77,
        },
      },
    },
  },
  {
    groupId: '4',
    ageGroups: {
      0: {
        plus: {
          deductible: 53.55,
          regular: 62.61,
        },
        surgery: {
          deductible: 17.57,
          regular: 20.7,
        },
      },
      1: {
        plus: {
          deductible: 53.55,
          regular: 62.61,
        },
        surgery: {
          deductible: 17.57,
          regular: 20.7,
        },
      },
      3: {
        plus: {
          deductible: 53.89,
          regular: 63.0,
        },
        surgery: {
          deductible: 20.93,
          regular: 24.93,
        },
      },
      5: {
        plus: {
          deductible: 62.49,
          regular: 73.24,
        },
        surgery: {
          deductible: 24.79,
          regular: 29.52,
        },
      },
      7: {
        plus: {
          deductible: 115.79,
          regular: 136.32,
        },
        surgery: {
          deductible: 47.29,
          regular: 56.28,
        },
      },
    },
  },
];

const catPriceGroup = {
  ageGroups: {
    0: {
      plus: {
        deductible: 25.37,
        regular: 29.05,
      },
      surgery: {
        deductible: 9.29,
        regular: 10.92,
      },
    },
    1: {
      plus: {
        deductible: 25.37,
        regular: 29.05,
      },
      surgery: {
        deductible: 9.29,
        regular: 10.92,
      },
    },
    3: {
      plus: {
        deductible: 29.41,
        regular: 33.87,
      },
      surgery: {
        deductible: 10.36,
        regular: 12.35,
      },
    },
    5: {
      plus: {
        deductible: 34.39,
        regular: 39.79,
      },
      surgery: {
        deductible: 13.57,
        regular: 16.17,
      },
    },
    7: {
      plus: {
        deductible: 53.55,
        regular: 62.26,
      },
      surgery: {
        deductible: 22.15,
        regular: 26.37,
      },
    },
  },
};

type Grouped = {
  plus: {
    deductible: number;
    regular: number;
  };
  surgery: {
    deductible: number;
    regular: number;
  };
};

const getGroupedValues = (group: Grouped, isDeductible = false) => {
  return [
    calculateFinalPrice(group.surgery[isDeductible ? 'deductible' : 'regular']),
    calculateFinalPrice(group.plus[isDeductible ? 'deductible' : 'regular']),
  ];
};

export const getPrices = (
  answers: SubmittablePetHealthQuestionnaire,
  isDeductible = false
): number[] => {
  let priceGroup;

  if (!answers.species) {
    throw new Error('[PET_HEALTH - getPrices]: Species is undefined');
  }

  if (answers.species === 'DOG') {
    priceGroup = dogPriceGroups.find(
      (group) => group.groupId === answers.raceGroup
    );
  }

  if (answers.species === 'CAT') {
    priceGroup = catPriceGroup;
  }

  const age = dayjs().diff(dayjs(answers.dateOfBirth), 'years');

  if (!priceGroup) {
    throw new Error('[PET_HEALTH - getPrices]: Price group is undefined');
  }

  if (age >= 9) {
    throw new Error('[PET_HEALTH - getPrices]: Age is out of range');
  }

  switch (age) {
    case 0:
      return getGroupedValues(priceGroup.ageGroups[0], isDeductible);
    case 1:
    case 2:
      return getGroupedValues(priceGroup.ageGroups[1], isDeductible);
    case 3:
    case 4:
      return getGroupedValues(priceGroup.ageGroups[3], isDeductible);
    case 5:
    case 6:
      return getGroupedValues(priceGroup.ageGroups[5], isDeductible);
    case 7:
    case 8:
      return getGroupedValues(priceGroup.ageGroups[7], isDeductible);
    default:
      throw new Error('Invalid age supplied');
  }
};

export const getPrice = (
  answers: SubmittablePetHealthQuestionnaire,
  plan: Quote['plan'],
  isDeductible = false
) => {
  const [surgeryPrice, plusPrice] = getPrices(answers, isDeductible);
  return plan === 'SURGERY' ? surgeryPrice : plusPrice;
};

const calculateFinalPrice = (basePrice: number) => {
  /**
   * Return base price + 32% commmission + 19% tax
   * and round to 2 decimal places
   */
  return Math.ceil(basePrice * 1.32 * 1.19 * 100) / 100;
};
