import {
  QuestionnaireFormProps,
  QuestionnaireQuestions,
} from '@getpopsure/qnr-framework';
import { useEffect, useRef } from 'react';
import { useSafeTranslation } from 'shared/i18n';
import { ProcessingView as ActionProcessingView } from 'shared/pages/Processing/Processing.view';

type ProcessingProps<Questionnaire extends QuestionnaireQuestions> = {
  textList?: string[];
} & QuestionnaireFormProps<Questionnaire>;

export const PreviewClaimProcessing = <
  Questionnaire extends QuestionnaireQuestions
>({
  onSubmitValue,
  textList,
  description,
}: ProcessingProps<Questionnaire> & {
  description?: string;
}) => {
  const { t } = useSafeTranslation();

  const refOnSubmit = useRef<(value: unknown) => void>(onSubmitValue);

  const TIMEOUT_TIME = 3_000;
  const elementIndex = 0;
  const fadeInAnimation = false;

  const list: string[] = textList
    ? [textList[0]]
    : [t('claims.general.processing.loadingText', 'Processing claim...')];

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      refOnSubmit.current(true);
    }, TIMEOUT_TIME);

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <ActionProcessingView
      elementIndex={elementIndex}
      fadeInAnimation={fadeInAnimation}
      text={list}
      description={description}
    />
  );
};
