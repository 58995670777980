import { Edit3Icon } from '@popsure/dirty-swan';
import { CategoryLookup } from 'features/recommendationTool/models/lookups';
import ReactMarkdown from 'react-markdown';
import { useSafeTranslation } from 'shared/i18n';

import styles from './HeaderCard.module.scss';

export const HeaderCard = ({
  onEditQuestionnaire,
  lookup,
}: {
  onEditQuestionnaire(): void;
  lookup: CategoryLookup;
}) => {
  const { t } = useSafeTranslation();

  return (
    <section
      className={`d-flex f-wrap mb32 ml8 mr8 wmx12 p40 ${styles.container}`}
    >
      <img
        className={styles.icon}
        src={lookup.icon}
        srcSet={lookup.iconSrcSet}
        alt={lookup.title}
      />
      <h2 className={`p-h2 ta-center mb16 w100 ${styles.title}`}>
        {lookup.title}
      </h2>
      <ReactMarkdown
        className={`p-p tc-grey-600 ${styles.markdown}`}
        source={lookup.description}
        renderers={{
          link: ({ href, children }) => (
            <a href={href} className="p-a" target="_blank" rel="noreferrer">
              {children}
            </a>
          ),
        }}
      />
      <div className={`d-flex w100 ${styles.buttonsWrapper}`}>
        <button
          type="button"
          className={`d-inline-flex p-a fw-bold ai-center jc-center c-pointer ${styles.button}`}
          onClick={onEditQuestionnaire}
          data-cy="button-edit-answers"
        >
          <Edit3Icon size={20} noMargin />
          {t(
            'recommendationtool.recommendations.editAnswers',
            'Edit my answers'
          )}
        </button>
      </div>
    </section>
  );
};
