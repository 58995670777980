import { TFunction } from '@getpopsure/i18n-react';
import { insurance, website } from '@getpopsure/private-constants';
import { Gender } from '@getpopsure/public-models';
import { illustrations, images } from '@popsure/dirty-swan';
import dayjs from 'dayjs';
import { imageTypeMapping } from 'models/insurances/types/mapping';
import { SignupQuestionnaireType } from 'SignupQuestionnaire';
import {
  arrivalDateOneYearInTheFutureValidator,
  coverageOneYearInTheFutureValidator,
} from 'SignupQuestionnaire/shared/customValidators';

import { createPostQuote } from '../actions';
import checkIcon from '../assets/icon-check.svg';
import checkUpIconGray from '../assets/icon-checkup-gray.svg';
import dentalIcon from '../assets/icon-dental-treatment.svg';
import medsIcon from '../assets/icon-meds.svg';
import medsIconGray from '../assets/icon-meds-gray.svg';
import mentalHealthIcon from '../assets/icon-mentalhealth-gray.svg';
import planeIcon from '../assets/icon-repatriation.svg';
import transportIcon from '../assets/icon-transport.svg';
import vaccinationIcon from '../assets/icon-vaccination-gray.svg';
import { AddDependents } from '../components/AddDependents';
import { Email } from '../components/Email';
import { Postcode } from '../components/Postcode/Postcode';
import { QuoteProcessing } from '../components/quoteProcessing';
import {
  ExpatSpain,
  ExpatSpainGroupIds,
  OccupationMapping,
  OtherOccupationMapping,
} from '../models';
import { getEarliestStartDate } from '../util/getEarliestStartDate';

export const ExpatSpainComponents = {
  QUOTE_PROCESSING: QuoteProcessing,
  DEPENDENTS: AddDependents,
  POST_CODE: Postcode,
  EMAIL_SIMPLE: Email,
} as const;

export type ExpatSpainQuestionnaire = SignupQuestionnaireType<
  ExpatSpain,
  ExpatSpainGroupIds,
  typeof ExpatSpainComponents
>;

const genderMapping = (t: TFunction): Record<Gender, string> => ({
  MALE: t('expatSpain.qnr.gender.optionMale', 'Male'),
  FEMALE: t('expatSpain.qnr.gender.optionFemale', 'Female'),
  OTHER: t('expatSpain.qnr.gender.optionOther', 'Gender non-conforming'),
});

const expatImg = imageTypeMapping.EXPAT_V2;

export const getTranslatedQuestionnaire = (
  t: TFunction,
  price: number | null,
  continueFromBetweenFourAndFiveYearsInSpainBlocker: () => void,
  useNewCheckout?: boolean
): ExpatSpainQuestionnaire => [
  {
    id: 'intro',
    required: true,
    type: 'INTRO',
    props: {
      title: t(
        'expatSpain.qnr.preQuote.intro.title',
        "Hey! Let's get you a quote in minutes"
      ),
      subTitle: t(
        'expatSpain.qnr.preQuote.intro.subTitle',
        'We need a few pieces of information about you and we can figure out the rest.'
      ),
    },
    screen: {
      continueButtonText: t(
        'expatSpain.qnr.preQuote.intro.continueButtonText',
        'Get started'
      ),
    },
    groupId: 'preQuote',
  },
  {
    id: 'occupation',
    required: true,
    type: 'RADIO',
    props: {
      mapValue: OccupationMapping(t),
    },
    screen: {
      question: t(
        'expatSpain.qnr.preQuote.occupation.title',
        'What is your occupation?'
      ),
    },
    groupId: 'preQuote',
  },
  {
    id: 'employedInSpain',
    type: 'RADIO',
    props: {
      mapValue: {
        YES: t(
          'expatSpain.qnr.preQuote.employedInSpain.yes.text',
          'Yes, in Spain'
        ),
        NO: t(
          'expatSpain.qnr.preQuote.employedInSpain.no.text',
          'No, elsewhere'
        ),
      },
    },
    screen: {
      question: t(
        'expatSpain.qnr.preQuote.employedInSpain.title',
        'Are you employed in Spain?'
      ),
      description: t(
        'expatSpain.qnr.preQuote.employedInSpain.description',
        'If the company you work for has an office in Spain and you have a Spanish employment contract, you are considered to be employed in Spain.'
      ),
    },
    groupId: 'preQuote',
  },
  {
    id: 'employedInSpainBlocker',
    type: 'CUSTOM_INTRO',
    props: {
      title: t(
        'expatSpain.qnr.preQuote.employedInSpainBlocker.title',
        "You can only use expat insurance if you don't already have Spanish public health insurance."
      ),
      subTitle: t(
        'expatSpain.qnr.preQuote.employedInSpainBlocker.description',
        "As an employee for a Spanish company or registered Freelancer (Autónomo), you are automatically enrolled in Spanish public health insurance.\n\nCheck with your company first in case they have already enrolled you in the Spanish public health insurance system. If this is the case, you don't need this insurance.  If this is not the case, you can proceed."
      ),
      icon: {
        src: illustrations.shieldWarning,
        alt: 'shield warning',
        width: '75',
      },
    },
    screen: {},
    groupId: 'preQuote',
  },
  {
    id: 'registeredFreelancer',
    type: 'BOOLEAN',
    props: {},
    screen: {
      question: t(
        'expatSpain.qnr.preQuote.registeredFreelancer.title',
        'Are you officially registered as a Freelancer ("Autónomo") in Spain?'
      ),
    },
    groupId: 'preQuote',
  },
  {
    id: 'registeredFreelancerOccupation',
    type: 'RADIO',
    props: {
      mapValue: OtherOccupationMapping(t),
    },
    screen: {
      question: t(
        'expatSpain.qnr.preQuote.registeredFreelancerOccupation.title',
        'Is your main occupation one of the options listed below?'
      ),
    },
    groupId: 'preQuote',
  },
  {
    id: 'otherOccupation',
    type: 'RADIO',
    props: {
      mapValue: OtherOccupationMapping(t),
    },
    screen: {
      question: t(
        'expatSpain.qnr.preQuote.otherOccupation.title',
        'Is your main occupation one of the options listed below?'
      ),
    },
    groupId: 'preQuote',
  },
  {
    id: 'dateOfBirth',
    required: true,
    type: 'DATE',
    props: {
      yearRange: {
        min: { op: 'subtract', type: 'years', value: 100 },
        max: { op: 'add', type: 'years', value: 0 },
      },
      placeholders: {
        day: t('expatSpain.qnr.preQuote.day', 'Day'),
        month: t('expatSpain.qnr.preQuote.month', 'Month'),
        year: t('expatSpain.qnr.preQuote.year', 'Year'),
      },
    },
    screen: {
      question: t(
        'expatSpain.qnr.preQuote.dateOfBirth.title',
        'What is your date of birth?'
      ),
    },
    groupId: 'preQuote',
  },
  {
    id: 'ageIs75AndAboveBlocker',
    type: 'BLOCKER',
    props: {
      title: t(
        'expatSpain.qnr.preQuote.ageIs75AndAboveBlocker.title',
        'Unfortunately...'
      ),
      iconType: 'SHIELD',
      description: t(
        'expatSpain.qnr.preQuote.ageIs75AndAboveBlocker.description',
        'Because you are over 74 years old, you aren’t eligible for expat health insurance.'
      ),
      buttonProps: [
        {
          type: 'href',
          href: `${website.base}/en-es`,
          caption: t(
            'legal.qnr.preQuote.ageIs75AndAboveBlocker.button',
            'Back to homepage'
          ),
        },
      ],
    },
    screen: {
      layout: 'Standalone',
    },
    groupId: 'preQuote',
  },
  {
    id: 'quoteProcessing',
    required: true,
    type: 'QUOTE_PROCESSING',
    props: {},
    screen: {
      layout: 'Standalone',
    },
    groupId: 'preQuote',
  },
  {
    id: 'quote',
    required: true,
    type: 'QUOTEPAGE',
    props: {
      config: {
        title: t('expatSpain.qnr.quote.title', 'Here is your quote'),
        headerImage: {
          src: expatImg,
          alt: t(
            'expatSpain.qnr.quote.header.image.alt',
            'An avocado and a medicine capsule'
          ),
        },
        priceHeader: {
          title: `${price}€`,
          subTitle: t(
            'expatSpain.qnr.quote.header.priceHeader.subtitle',
            'per month'
          ),
        },
        actions: {
          valuesMap: {
            0: `${price}`,
          },
          submitMap: [
            {
              id: '1',
              price,
              planId: 'BASIC',
            },
          ],
          items: [],
          button: {
            text: t(
              'expatSpain.qnr.quote.actions.button.continue.text',
              'Continue'
            ),
          },
        },
        coveredSection: {
          title: t('expatSpain.qnr.quote.covered.title', "What's covered?"),
          layout: {
            compact: true,
            cards: true,
          },
          items: [
            {
              title: t(
                'expatSpain.qnr.quote.covered.accidents.title',
                'Accidents and emergencies'
              ),
              description: t(
                'expatSpain.qnr.quote.covered.accidents.description',
                'Our plans cover unexpected medical treatments and procedures, as well as associated follow-up costs.'
              ),
              icon: {
                src: images.aid,
                alt: t(
                  'expatSpain.qnr.quote.covered.accidents.image.alt',
                  'First aid kit bag'
                ),
              },
            },
            {
              title: t(
                'expatSpain.qnr.quote.covered.medication.title',
                'Medication'
              ),
              description: t(
                'expatSpain.qnr.quote.covered.medication.description',
                'If an unforeseen illness requires you to take medication prescribed by a doctor, this is covered.'
              ),
              icon: {
                src: medsIcon,
                alt: t(
                  'expatSpain.qnr.quote.covered.medication.image.alt',
                  'Two pills'
                ),
              },
            },
            {
              title: t(
                'expatSpain.qnr.quote.covered.ambulance.title',
                'Ambulance transport'
              ),
              description: t(
                'expatSpain.qnr.quote.covered.ambulance.description',
                'If you are injured or have health problems that require you to be transported by an ambulance to a medical facility your costs will be covered.'
              ),
              icon: {
                src: transportIcon,
                alt: t(
                  'expatSpain.qnr.quote.covered.ambulance.image.alt',
                  'Ambulance transport'
                ),
              },
            },
            {
              title: t(
                'expatSpain.qnr.quote.covered.repatriation.title',
                'Repatriation'
              ),
              description: t(
                'expatSpain.qnr.quote.covered.repatriation.description',
                'In particularly serious cases, your insurance covers your return to your home country for treatment or in case of fatality, for burial.'
              ),
              icon: {
                src: planeIcon,
                alt: t(
                  'expatSpain.qnr.quote.covered.repatriation.image.alt',
                  'A plane'
                ),
              },
            },
            {
              title: t(
                'expatSpain.qnr.quote.covered.dental.title',
                'Dental treatment'
              ),
              description: t(
                'expatSpain.qnr.quote.covered.dental.description',
                'If you need to see a dentist, pain relief treatments are covered up to €500/year. Tooth replacement after an accident is also covered up to €500.'
              ),
              icon: {
                src: dentalIcon,
                alt: t(
                  'expatSpain.qnr.quote.covered.dental.image.alt',
                  'A tooth and a checkmark'
                ),
              },
            },
            {
              title: t(
                'expatSpain.qnr.quote.covered.euCoverage.title',
                'EU-wide coverage'
              ),
              description: t(
                'expatSpain.qnr.quote.covered.euCoverage.description',
                'With Expat insurance you can rest assured that your coverage extends to all EU countries, in case you decide to travel outside of Spain.'
              ),
              icon: {
                src: checkIcon,
                alt: t(
                  'expatSpain.qnr.quote.covered.euCoverage.image.alt',
                  'A shield with a checkmark on it'
                ),
              },
            },
          ],
        },
        notCoveredSection: {
          title: t(
            'expatSpain.qnr.quote.notCovered.title',
            "What's not covered?"
          ),
          items: [
            {
              title: t(
                'expatSpain.qnr.quote.notCovered.conditions.title',
                'Pre-existing conditions'
              ),
              description: t(
                'expatSpain.qnr.quote.notCovered.conditions.description',
                'Treatments related to pre-existing conditions are not covered.'
              ),
              icon: {
                src: medsIconGray,
                alt: t(
                  'expatSpain.qnr.quote.notCovered.conditions.img.alt',
                  'Two pills'
                ),
              },
            },
            {
              title: t(
                'expatSpain.qnr.quote.notCovered.checkups.title',
                'Routine check-ups'
              ),
              description: t(
                'expatSpain.qnr.quote.notCovered.checkups.description',
                'Medical visits without any symptoms or complaints and any conditions discovered during those are not covered.'
              ),
              icon: {
                src: checkUpIconGray,
                alt: t(
                  'expatSpain.qnr.quote.notCovered.checkups.img.alt',
                  'A magnifying glass on a document'
                ),
              },
            },
            {
              title: t(
                'expatSpain.qnr.quote.notCovered.vaccinations.title',
                'Regular vaccinations'
              ),
              description: t(
                'expatSpain.qnr.quote.notCovered.vaccinations.description',
                'Travel vaccinations are only covered in the advanced plan.'
              ),
              icon: {
                src: vaccinationIcon,
                alt: t(
                  'expatSpain.qnr.quote.notCovered.vaccinations.img.alt',
                  'A vaccination syringe'
                ),
              },
            },
            {
              title: t(
                'expatSpain.qnr.quote.notCovered.mentalHealth.title',
                'Mental health therapy'
              ),
              description: t(
                'expatSpain.qnr.quote.notCovered.mentalHealth.description',
                'If you need to get psychotherapy appointments or treatments, those cannot be covered by the policy.'
              ),
              icon: {
                src: mentalHealthIcon,
                alt: t(
                  'expatSpain.qnr.quote.notCovered.mentalHealth.img.alt',
                  'A human brain with a checkmark on it'
                ),
              },
            },
          ],
        },
        reviewBadge: false,
        ctaBox: {
          header: t(
            'expatSpain.qnr.quote.ctaBox.header',
            'Expat health insurance'
          ),
          title: `${price}€`,
          subTitle: t(
            'expatSpain.qnr.quote.notCovered.ctaBox.perMonth',
            'per month'
          ),
          buttonText: t(
            'expatSpain.qnr.quote.notCovered.ctaBox.button.label',
            'Get covered'
          ),
        },
        faq: {
          title: t(
            'expatSpain.qnr.quote.faq.title',
            'Frequently asked questions'
          ),
          data: [
            {
              questionId: 1,
              question: t(
                'expatSpain.qnr.quote.faq.visaRequirements.question',
                'What visas requirements is this insurance suitable for?'
              ),
              answer: t(
                'expatSpain.qnr.quote.faq.visaRequirements.answer',
                '- Digital nomad visa\n- Student & Au-pair visa\n- Investment visa\n- Non-lucrative visa\n- Tarjeta de identidad de extranjero (TIE card) and its renewal\n- Job seeker visa\n- Family reunification visa'
              ),
            },
            {
              questionId: 2,
              question: t(
                'expatSpain.qnr.quote.faq.tie.question',
                'Does this policy fulfill the requirements for my TIE card and visa?'
              ),
              answer: t(
                'expatSpain.qnr.quote.faq.tie.answer',
                'Yes, this policy meets the legal requirements for getting your visa or residence permit in Spain and qualifies as your proof of health insurance.'
              ),
            },
            {
              questionId: 3,
              question: t(
                'expatSpain.qnr.quote.faq.eligible.question',
                'Am I eligible for Expat health insurance for Spain?'
              ),
              answer: t(
                'expatSpain.qnr.quote.faq.eligible.answer',
                "Yes, if you are looking to move to Spain or if you’ve recently relocated to Spain and are looking for your first health insurance plan, you're eligible for expat health insurance."
              ),
            },
            {
              questionId: 4,
              question: t(
                'expatSpain.qnr.quote.faq.selfEmployed.question',
                'What if I am self-employed or a freelancer?'
              ),
              answer: t(
                'expatSpain.qnr.quote.faq.selfEmployed.answer',
                "Expat health insurance is an excellent option if you're a freelancer or digital nomad. Expat insurance provides emergency and accidental coverage in addition to any illness you might develop.\nIf you’ve been publicly insured in the EU within the last three months, you can get on public insurance after moving to Spain. You can speak to one of our experts to find the best option."
              ),
            },
            {
              questionId: 5,
              question: t(
                'expatSpain.qnr.quote.faq.goodOption.question',
                'Is this a good option for foreigners?'
              ),
              answer: t(
                'expatSpain.qnr.quote.faq.goodOption.answer',
                "Yes, Expat insurance is specifically meant for foreigners and is a good option for people who want to get insured quickly to get their first visa or temporary residence permit because it's affordable health insurance coverage that you can sign up for entirely in English.\nHowever, it's not a long-term solution. After staying in Spain for a few years we recommend switching to a local health insurance option. If you would like to switch to a local plan you can speak to one of our experts for a recommendation."
              ),
            },
            {
              questionId: 6,
              question: t(
                'expatSpain.qnr.quote.faq.documents.question',
                'When will I get my documents after getting the insurance plan?'
              ),
              answer: t(
                'expatSpain.qnr.quote.faq.documents.answer',
                'Almost immediately. We’ll make sure you can get covered as fast as possible usually within two working days. After you have been approved you will receive all insurance documents by email.'
              ),
            },
            {
              questionId: 7,
              question: t(
                'expatSpain.qnr.quote.faq.timeLimit.question',
                'What is the maximum amount of time that I can be covered?'
              ),
              answer: t(
                'expatSpain.qnr.quote.faq.timeLimit.answer',
                'Expat health insurance can be used for up to five years.'
              ),
            },
            {
              questionId: 8,
              question: t(
                'expatSpain.qnr.quote.faq.waitingPeriod.question',
                'Are there any waiting periods?'
              ),
              answer: t(
                'expatSpain.qnr.quote.faq.waitingPeriod.answer',
                'Generally there are no waiting periods. If you purchase expat health insurance after staying in Spain for more than a month a 31 day waiting period applies (unless there is an acute danger to your life). There are a few more exceptions for pregnancy treatment and existing conditions. Please refer to the full policy wording to learn more about those exceptions.'
              ),
            },
            {
              questionId: 9,
              question: t(
                'expatSpain.qnr.quote.faq.reimbursement.question',
                'Do I have to pay medical costs myself before getting reimbursed?'
              ),
              answer: t(
                'expatSpain.qnr.quote.faq.reimbursement.answer',
                "Yes, with expat health insurance you'll have to pay upfront and submit photos of all the bills or receipts to get reimbursed. We can help you get reimbursed quickly."
              ),
            },
            {
              questionId: 10,
              question: t(
                'expatSpain.qnr.quote.faq.dentalTreatments.question',
                'Are dental treatments covered?'
              ),
              answer: t(
                'expatSpain.qnr.quote.faq.dentalTreatments.answer',
                'Yes, expat insurance covers €500 for dental treatment to relief you from pain. Tooth replacement after an accident is also covered up to €500 per year. plans will cover some dental treatments for pain relief like simple fillings.'
              ),
            },
          ],
        },
        moreQuestionsSection: {
          bookACallGACategory: 'Spain Expat',
          title: t(
            'expatSpain.qnr.quote.moreQuestionsSection.title',
            'Have more questions?'
          ),
          description: t(
            'expatSpain.qnr.quote.moreQuestionsSection.description',
            'Reach out to us for more information.'
          ),
          bookACallUrl: 'https://tally.so/r/n0Ooej',
        },
      },
    },
    screen: { layout: 'Standalone' },
    groupId: 'signup',
  },
  {
    id: 'legalGuardianName',
    type: 'NAME',
    props: {},
    screen: {
      question: t(
        'expatSpain.qnr.signup.legalGuardianName.title',
        "What's the name of your parent or legal guardian?"
      ),
      description: t(
        'expatSpain.qnr.signup.legalGuardianName.description',
        "As you are under 18 years old, we need your parent / legal guardian's details to be able to sign you up."
      ),
    },
    groupId: 'signup',
  },
  {
    id: 'legalGuardianDateOfBirth',
    type: 'DATE',
    props: {
      yearRange: {
        min: { op: 'subtract', type: 'years', value: 100 },
        max: { op: 'subtract', type: 'years', value: 18 },
      },
    },
    screen: {
      question: t(
        'expatSpain.qnr.signup.legalGuardianDateOfBirth.title',
        'When were they born?'
      ),
    },
    validations: [
      {
        op: 'dateDiff',
        variable: {
          type: 'year',
          value: 18,
        },
        msg: {
          type: 'Info',
          value: t(
            'expatSpain.qnr.signup.legalGuardianDateOfBirth.errorMessage',
            'Legal guardian / parent should be over 18 years old.\n\nPlease make sure the birth date is correct.'
          ),
        },
      },
    ],
    groupId: 'signup',
  },
  {
    id: 'legalGuardianEmail',
    type: 'EMAIL_SIMPLE',
    props: {},
    screen: {
      question: t(
        'expatSpain.qnr.signup.legalGuardianEmail.email.title',
        "What's their email address?"
      ),
    },
    groupId: 'signup',
  },
  {
    id: 'addFamilyMembers',
    type: 'DEPENDENTS',
    props: {},
    screen: {
      layout: 'Standalone',
    },
    groupId: 'signup',
  },
  {
    id: 'arrivalDate',
    required: true,
    type: 'DATE',
    props: {
      yearRange: {
        min: { op: 'subtract', type: 'years', value: 100 },
        max: { op: 'add', type: 'years', value: 1 },
      },
      placeholders: {
        day: t('expatSpain.qnr.preQuote.day', 'Day'),
        month: t('expatSpain.qnr.preQuote.month', 'Month'),
        year: t('expatSpain.qnr.preQuote.year', 'Year'),
      },
    },
    screen: {
      question: t(
        'expatSpain.qnr.signup.arrivalDate.title',
        'When did you (or will you) move to Spain?'
      ),
    },
    validations: [arrivalDateOneYearInTheFutureValidator(t)],
    groupId: 'signup',
  },
  {
    id: 'betweenFourAndFiveYearsInSpainBlocker',
    type: 'BLOCKER',
    props: {
      title: t(
        'expatSpain.qnr.signup.betweenFourAndFiveYearsInSpainBlocker.title',
        'Expat health insurance might not be the best choice for you...'
      ),
      iconType: 'SHIELD',
      description: t(
        'expatSpain.qnr.signup.betweenFourAndFiveYearsInSpainBlocker.description',
        'Expat health insurance is only valid for the first 5 years of your stay in Spain. Soon, you will no longer be eligible for it.\n\n You can check out long-term expat health insurance as an alternative.'
      ),
      buttonProps: [
        {
          type: 'href',
          href: insurance.longTermExpatHealth.landingPage,
          caption: t(
            'expatSpain.qnr.signup.betweenFourAndFiveYearsInSpainBlocker.primaryButton.cta',
            'Explore long-term expat health'
          ),
        },
        {
          variant: 'SECONDARY',
          type: 'button',
          onClick: () => continueFromBetweenFourAndFiveYearsInSpainBlocker(),
          caption: t(
            'expatSpain.qnr.signup.betweenFourAndFiveYearsInSpainBlocker.secondaryButton.cta',
            'Continue application'
          ),
        },
      ],
    },
    screen: {
      layout: 'Standalone',
    },
    groupId: 'signup',
  },
  {
    id: 'moreThanFiveYearsInSpainBlocker',
    type: 'BLOCKER',
    props: {
      title: t(
        'expatSpain.qnr.signup.moreThanFiveYearsInSpainBlocker.title',
        'We have another option for you'
      ),
      iconType: 'SHIELD',
      description: t(
        'expatSpain.qnr.signup.moreThanFiveYearsInSpainBlocker.description',
        "Since you have been in Spain for longer than 5 years, you aren't eligible for expat health insurance.\n\nYou can explore long-term expat health insurance as an alternative."
      ),
      buttonProps: [
        {
          type: 'href',
          href: insurance.longTermExpatHealth.landingPage,
          caption: t(
            'expatSpain.qnr.signup.moreThanFiveYearsInGermany.exploreLongTermExpatButton.cta',
            'Explore long-term expat health'
          ),
        },
      ],
    },
    screen: {
      layout: 'Standalone',
    },
    groupId: 'signup',
  },
  {
    id: 'lastPermanentResidency',
    required: true,
    type: 'COUNTRY_SINGLE',
    props: {},
    screen: {
      question: t(
        'expatSpain.qnr.signup.lastPermanentResidency.title',
        'Where did you live permanently before moving to Spain?'
      ),
    },
    groupId: 'signup',
  },
  {
    id: 'startDate',
    required: true,
    type: 'DATE',
    props: {
      yearRange: {
        min: { op: 'subtract', type: 'years', value: 0 },
        max: { op: 'add', type: 'years', value: 1 },
      },
      placeholders: {
        day: t('expatSpain.qnr.preQuote.day', 'Day'),
        month: t('expatSpain.qnr.preQuote.month', 'Month'),
        year: t('expatSpain.qnr.preQuote.year', 'Year'),
      },
    },
    screen: {
      question: t(
        'expatSpain.qnr.signup.startDate.title',
        'When would you like your coverage to start?'
      ),
      description: t(
        'expatSpain.qnr.signup.startDate.description',
        "The soonest possible start date is tomorrow, if you're already in Spain. Otherwise, it's the day of your arrival."
      ),
    },
    validations: [
      {
        op: 'Custom',
        fn: (answer, { arrivalDate }: Partial<ExpatSpain>) => {
          const startDate = String(answer);
          return (
            dayjs().isBefore(startDate) &&
            (dayjs(arrivalDate).isSame(startDate) ||
              dayjs(arrivalDate).isBefore(startDate))
          );
        },
        msg: (_, answers) => {
          const { arrivalDate } = answers as Partial<ExpatSpain>;
          const earliestStartDate = getEarliestStartDate(arrivalDate);

          return {
            type: 'Info',
            msg: t(
              'expatSpain.qnr.signup.startDate.errorMessage',
              'Earliest start date is {{date}}.',
              {
                date: earliestStartDate,
              }
            ),
          };
        },
      },
      coverageOneYearInTheFutureValidator(t),
    ],
    groupId: 'signup',
  },
  {
    id: 'postalCode',
    required: true,
    type: 'POST_CODE',
    props: {},
    screen: {
      question: t(
        'expatSign.qnr.signup.postalCode.title',
        'What is the postal code for your Spain address?'
      ),
      skipDetails: {
        title: t(
          'expatSpain.qnr.signup.postalCode.skipDetails.title',
          'Provide later'
        ),
        skipQuestion: {
          value: '',
        },
      },
    },
    groupId: 'signup',
  },
  {
    id: 'email',
    required: true,
    type: 'EMAIL',
    props: {
      verticalId: 'expatSpain',
    },
    screen: {
      layout: 'Standalone',
      question: t(
        'expatSpain.qnr.signup.postalCode.email.title',
        "What's your email address?"
      ),
      description: t(
        'expatSpain.qnr.signup.postalCode.email.description',
        'This policy will be accessible in your Feather account.'
      ),
    },
    groupId: 'signup',
  },
  {
    id: 'name',
    required: true,
    type: 'NAME',
    props: {},
    screen: {
      question: t('expatSpain.qnr.signup.name.title', "What's your name?"),
    },
    groupId: 'signup',
  },
  {
    id: 'gender',
    required: true,
    type: 'RADIO',
    props: {
      mapValue: genderMapping(t),
    },
    screen: {
      question: t('expatSpain.qnr.signup.gender.title', 'What is your gender?'),
    },
    groupId: 'signup',
  },
  {
    id: 'review',
    required: true,
    type: 'REVIEW',
    props: {
      title: t(
        'expatSpain.qnr.signup.review.title',
        "Here's what we know about you:"
      ),
      requestType: 'CREATE_POST_QUOTE_SUBMIT_INFO',
      reviewValues: [
        {
          title: t('expatSpain.qnr.signup.review.email.title', 'Email'),
          value: { type: 'String', key: 'email' },
          path: 'email',
        },
        {
          title: t(
            'expatSpain.qnr.signup.review.name.title',
            "Policy holder's name"
          ),
          value: {
            type: 'Strings',
            key: 'name',
            keys: ['firstName', 'lastName'],
          },
          path: 'name',
        },
        {
          title: t(
            'expatSpain.qnr.signup.review.dateOfBirth.title',
            'Date of birth'
          ),
          value: { type: 'Date', format: 'DD MMM YYYY', key: 'dateOfBirth' },
          path: 'dateOfBirth',
        },
        {
          title: t('expatSpain.qnr.signup.review.gender.title', 'Gender'),
          value: {
            type: 'String',
            valueMap: genderMapping(t),
            key: 'gender',
          },
          path: 'gender',
        },
        {
          title: t(
            'expatSpain.qnr.signup.review.occupation.title',
            'Occupation'
          ),
          value: {
            type: 'String',
            valueMap: OccupationMapping(t),
            key: 'occupation',
          },
          path: 'occupation',
        },
        {
          title: t(
            'expatSpain.qnr.signup.review.addFamilyMembers.title',
            'Add family members'
          ),
          value: {
            type: 'Boolean',
            key: 'addFamilyMembers',
          },
          path: 'addFamilyMembers',
        },
        {
          title: t(
            'expatSpain.qnr.signup.review.arrivalDate.title',
            'Arrival date in Spain'
          ),
          value: { type: 'Date', format: 'DD MMM YYYY', key: 'arrivalDate' },
          path: 'arrivalDate',
        },
        {
          title: t(
            'expatSpain.qnr.signup.review.lastPermanentResidency.title',
            'Last permanent residency'
          ),
          value: {
            type: 'Strings',
            key: 'lastPermanentResidency',
            keys: ['name'],
          },
          path: 'lastPermanentResidency',
        },
        {
          title: t(
            'expatSpain.qnr.signup.review.postalCode.title',
            'Postal Code in Spain'
          ),
          value: {
            type: 'String',
            key: 'postalCode',
          },
          path: 'postalCode',
        },
        {
          title: t(
            'expatSpain.qnr.signup.review.startDate.title',
            'Coverage start date'
          ),
          value: { type: 'Date', format: 'DD MMM YYYY', key: 'startDate' },
          path: 'startDate',
        },
        {
          title: t(
            'expatSpain.qnr.signup.review.legalGuardianName',
            "Parent / legal guardian's name"
          ),
          value: {
            type: 'Strings',
            key: 'legalGuardianName',
            keys: ['firstName', 'lastName'],
          },
          path: 'legalGuardianName',
        },
        {
          title: t(
            'expatSpain.qnr.signup.review.legalGuardianDateOfBirth',
            "Parent / legal guardian's date of birth"
          ),
          value: {
            type: 'Date',
            key: 'legalGuardianDateOfBirth',
            format: 'DD MMM YYYY',
          },
          path: 'legalGuardianDateOfBirth',
        },
        {
          title: t(
            'expatSpain.qnr.signup.review.legalGuardianEmail',
            "Parent / legal guardian's email"
          ),
          value: { type: 'String', key: 'legalGuardianEmail' },
          path: 'legalGuardianEmail',
        },
      ],
      verticalId: 'expatSpain',
      insuranceType: 'INCOMING_ES',
      region: 'es',
      createPostQuote: () => createPostQuote(t),
      confirmationText: t(
        'expatSpain.qnr.signup.review.confirmation',
        'By selecting "Continue", I confirm to have answered all questions truthfully. Knowingly omitting any relevant details entitles the insurer to cancel the contract—either retroactively or from the date the omission is discovered.'
      ),
    },
    screen: {
      layout: 'Standalone',
    },
    groupId: 'signup',
  },
  ...(useNewCheckout
    ? [
        {
          id: 'reviewCheckout' as const,
          required: true,
          type: 'GENERIC_CHECKOUT_V2' as const,
          props: {
            type: 'INCOMING_ES' as const,
            productName: t(
              'expatSpain.qnr.checkoutV2.productName',
              'Expat Health'
            ),
            planQuestionId: 'planId',
            region: 'es' as const,
            policyInfoKind: 'ExpatSpainPolicy',
            redirectAddDependent: 'addFamilyMembers',
            hasCheckoutDocuments: true,
            policyDetailsConfiguration: {
              productCardDetails: [
                {
                  id: 'startDate',
                  label: t(
                    'expatSpain.qnr.checkoutV2.productCard.startDate',
                    'Start date'
                  ),
                  questionId: 'startDate',
                },
              ],
              policyDetails: [
                {
                  id: 'name',
                  label: t(
                    'expatSpain.qnr.checkoutV2.policyDetails.name',
                    'Name'
                  ),
                  questionId: 'name',
                },
                {
                  id: 'dateOfBirth',
                  label: t(
                    'expatSpain.qnr.checkoutV2.policyDetails.dateOfBirth',
                    'Date of Birth'
                  ),
                  questionId: 'dateOfBirth',
                },
                {
                  id: 'email',
                  label: t(
                    'expatSpain.qnr.checkoutV2.policyDetails.email',
                    'Account email'
                  ),
                  questionId: 'email',
                },
              ],
              additionalPolicyDetails: [
                {
                  id: 'legalGuardian',
                  title: t(
                    'expatSpain.qnr.checkoutV2.policyDetails.legalGuardian.subTitle',
                    'Legal guardian'
                  ),
                  details: [
                    {
                      id: 'legalGuardianName',
                      label: t(
                        'expatSpain.qnr.checkoutV2.policyDetails.legalGuardian.name',
                        'Name'
                      ),
                      questionId: 'legalGuardianName',
                    },
                    {
                      id: 'legalGuardianDateOfBirth',
                      label: t(
                        'expatSpain.qnr.checkoutV2.policyDetails.legalGuardian.dateOfBirth',
                        'Date of Birth'
                      ),
                      questionId: 'legalGuardianDateOfBirth',
                    },
                    {
                      id: 'legalGuardianEmail',
                      label: t(
                        'expatSpain.qnr.checkoutV2.policyDetails.legalGuardian.email',
                        'Email'
                      ),
                      questionId: 'legalGuardianEmail',
                    },
                  ],
                },
              ],
            },
          },
          screen: {
            layout: 'Standalone' as const,
          },
          groupId: 'signup' as const,
        },
      ]
    : [
        {
          id: 'reviewCheckout' as const,
          required: true,
          type: 'GENERIC_CHECKOUT' as const,
          props: {
            type: 'INCOMING_ES' as const,
            icon: expatImg,
            region: 'es' as const,
            policyInfoKind: 'ExpatSpainPolicy',
            redirectAddDependent: 'addFamilyMembers',
            hasCheckoutDocuments: true,
            policyDetailsConfiguration: {
              policyDetails: [
                {
                  title: t(
                    'expatSpain.qnr.checkout.policyDetails.name',
                    'Name'
                  ),
                  questionId: 'name',
                },
                {
                  title: t(
                    'expatSpain.qnr.checkout.policydetails.requestedStartDate',
                    'Requested start date'
                  ),
                  questionId: 'startDate',
                  additionalInfo: {
                    title: t(
                      'expatSpain.qnr.checkout.policydetails.requestedStartDate.additionalInfo.title',
                      'Requested start date'
                    ),
                    description: t(
                      'expatSpain.qnr.checkout.policydetails.requestedStartDate.additionalInfo.descriptionNoValue',
                      'Your coverage should begin on the requested start date, but only after you have received the official confirmation.'
                    ),
                  },
                },
                {
                  title: t(
                    'expatSpain.qnr.checkout.policyDetails.dateOfBirth',
                    'Date of Birth'
                  ),
                  questionId: 'dateOfBirth',
                },
                {
                  title: t(
                    'expatSpain.qnr.checkout.policyDetails.email',
                    'Email'
                  ),
                  questionId: 'email',
                },
              ],
              otherSections: [
                {
                  title: t(
                    'expatSpain.qnr.checkout.policyDetails.legalGuardian.subTitle',
                    'Parent / Legal guardian'
                  ),
                  rows: [
                    {
                      title: t(
                        'expatSpain.qnr.checkout.policyDetails.legalGuardian.name',
                        'Name'
                      ),
                      questionId: 'legalGuardianName',
                    },
                    {
                      title: t(
                        'expatSpain.qnr.checkout.policyDetails.legalGuardian.dateOfBirth',
                        'Date of Birth'
                      ),
                      questionId: 'legalGuardianDateOfBirth',
                    },
                    {
                      title: t(
                        'expatSpain.qnr.checkout.policyDetails.legalGuardian.email',
                        'Email'
                      ),
                      questionId: 'legalGuardianEmail',
                    },
                  ],
                },
              ],
            },
          },
          screen: {
            layout: 'Standalone' as const,
          },
          groupId: 'signup' as const,
        },
      ]),
];
