import { Trans } from 'shared/i18n';

export const PrivateDescriptionModal = () => {
  return (
    <div className="wmx6">
      <Trans i18nKey="private.qnr.quote.shortTermCard.modal.description.1">
        <p className="p-p mb16">
          Since you’re from outside the EU and have been in Germany for less
          than 4 years, our short-term coverage is available. It is valid up to
          5 years after entering Germany and fulfills all requirements for a
          visa. This tariff has similar benefits to our other plans but does not
          include <strong className="fw-bold">old age contributions</strong>,
          which makes it up to 50% cheaper.
        </p>
      </Trans>
      <Trans i18nKey="private.qnr.quote.shortTermCard.modal.description.2">
        <p className="p-p mb16">
          The old age savings reserves are meant to keep private insurance
          affordable as customers get older, but aren’t necessary for those who
          plan on leaving Germany.
        </p>
      </Trans>
      <Trans i18nKey="private.qnr.quote.shortTermCard.modal.description.3">
        <p className="p-p mb16">
          If you stay in Germany for longer than 5 years or get an unlimited
          residence permit, make sure to inform us in advance so we can help you
          transfer to our long-term tariffs. Keep in mind that the price can be
          higher since you’ll be a few years older when you start the new
          policy, and will have more old age contributions to catch up on.
        </p>
      </Trans>
    </div>
  );
};
