import {
  BottomOrRegularModal,
  Button,
  InformationBox,
  Input,
} from '@popsure/dirty-swan';
import { type FormEvent } from 'react';
import AnimateHeight from 'react-animate-height';
import { useSafeTranslation } from 'shared/i18n';

interface Props {
  payoutIban?: string;
  isOpen: boolean;
  onClose: () => void;
  isLoading?: boolean;
  onSubmit?(value: FormEvent<HTMLFormElement>): void;
  isIbanError?: boolean;
}

export const ClaimIbanUpdateModal = ({
  isOpen,
  onClose,
  payoutIban,
  isLoading,
  isIbanError,
  onSubmit,
}: Props) => {
  const { t } = useSafeTranslation();
  return (
    <BottomOrRegularModal
      isOpen={isOpen}
      onClose={onClose}
      title={t('claims.claimDetail.ibanUpdateModal.title', 'Edit IBAN')}
    >
      <form className="px24 pb24 p-p" onSubmit={onSubmit}>
        <p className="mb16">
          {t(
            'claims.claimDetail.ibanUpdateModal.description',
            "Please enter the IBAN of your account where you'll receive the payout if approved."
          )}
        </p>
        <Input
          name="iban"
          defaultValue={payoutIban}
          placeholder={t(
            'claims.claimDetail.ibanUpdateModal.input.placeholder',
            'IBAN'
          )}
        />
        <AnimateHeight duration={300} height={isIbanError ? 'auto' : 0}>
          {isIbanError && (
            <InformationBox variant="warning" className="my16">
              {t(
                'claims.claimDetail.ibanUpdateModal.ibanError',
                'Please provide a valid IBAN.'
              )}
            </InformationBox>
          )}
        </AnimateHeight>

        <Button type="submit" className="mt16" loading={isLoading}>
          {t('claims.claimDetail.ibanUpdateModal.button', 'Submit')}
        </Button>
      </form>
    </BottomOrRegularModal>
  );
};
