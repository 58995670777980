import {
  getRoutes,
  RemoveAnswerType,
  Rule,
  stateManagerHelper,
} from '@getpopsure/qnr-framework';
import { useFlag } from '@unleash/proxy-client-react';
import { flushGenericQuestionnaire } from 'actions/genericQuestionnaire';
import routes from 'constants/routes';
import {
  removeDependentGenericQuestionnaireAnswer,
  storeDependentGenericQuestionnaireAnswer,
} from 'features/expatSpain/actions';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from 'react-router';
import { AppState } from 'reducers';
import { useSafeTranslation } from 'shared/i18n';
import { SignupQuestionnaire } from 'SignupQuestionnaire';

import { ExpatSpainDependent } from '../../models';
import {
  ExpatSpainDependentComponents,
  getTranslatedQuestionnaire,
} from '../../questionnaire/Dependent';

const getRules = (): Rule<ExpatSpainDependent>[] => [
  {
    id: 'occupation',
    if: {
      op: 'equals',
      variable: { type: 'static', value: 'EMPLOYED' },
    },
    then: {
      goTo: 'employedInSpain',
    },
  },
  {
    id: 'occupation',
    if: {
      op: 'equals',
      variable: { type: 'static', value: 'SELF_EMPLOYED' },
    },
    then: {
      goTo: 'registeredFreelancer',
    },
  },
  {
    id: 'occupation',
    if: {
      op: 'equals',
      variable: { type: 'static', value: 'OTHER' },
    },
    then: {
      goTo: 'otherOccupation',
    },
  },
  {
    id: 'employedInSpain',
    if: {
      op: 'equals',
      variable: {
        type: 'static',
        value: 'YES',
      },
    },
    then: {
      goTo: 'employedInSpainBlocker',
    },
  },
  {
    id: 'registeredFreelancer',
    if: {
      op: 'equals',
      variable: {
        type: 'static',
        value: true,
      },
    },
    then: {
      goTo: 'registeredFreelancerOccupation',
    },
  },
  {
    id: 'dateOfBirth',
    if: {
      op: 'dateDiff',
      variable: {
        type: 'year',
        value: 75,
      },
    },
    then: {
      goTo: 'ageIs75AndAboveBlocker',
    },
  },
  {
    id: 'dateOfBirth',
    if: {
      op: 'dateDiff',
      variable: {
        type: 'year',
        value: 18,
      },
    },
    then: {
      goTo: 'arrivalDate',
    },
    else: {
      goTo: 'isMainPolicyTheLegalGuardian',
    },
  },
  {
    id: 'arrivalDate',
    if: {
      op: 'dateDiff',
      variable: {
        type: 'month',
        value: 58,
        dir: 'gt',
      },
    },
    then: {
      goTo: 'moreThanFiveYearsInSpainBlocker',
    },
  },
  {
    id: 'arrivalDate',
    if: [
      'AND',
      [
        {
          op: 'dateDiff',
          variable: {
            type: 'month',
            value: 47,
            dir: 'gt',
          },
        },
        {
          op: 'dateDiff',
          variable: {
            type: 'month',
            value: 59,
            dir: 'lt',
          },
        },
      ],
    ],
    then: {
      goTo: 'betweenFourAndFiveYearsInSpainBlocker',
    },
  },
  {
    id: 'isMainPolicyTheLegalGuardian',
    if: {
      op: 'equals',
      variable: {
        type: 'static',
        value: true,
      },
    },
    then: {
      goTo: 'arrivalDate',
    },
    else: {
      goTo: 'legalGuardianName',
    },
  },
  {
    id: 'legalGuardianName',
    if: {
      op: 'always',
    },
    then: {
      goTo: 'legalGuardianDateOfBirth',
    },
  },
  {
    id: 'legalGuardianDateOfBirth',
    if: {
      op: 'always',
    },
    then: {
      goTo: 'legalGuardianEmail',
    },
  },
];

const removeReview: RemoveAnswerType<ExpatSpainDependent> = {
  op: 'always',
  questions: ['review'],
};

const removeAnswersLogic: Partial<
  Record<keyof ExpatSpainDependent, RemoveAnswerType<ExpatSpainDependent>>
> = {
  postalCode: removeReview,
  quote: removeReview,
  name: removeReview,
  gender: removeReview,
  legalGuardianName: removeReview,
  legalGuardianDateOfBirth: removeReview,
  legalGuardianEmail: removeReview,
  dateOfBirth: {
    op: 'always',
    questions: [
      'isMainPolicyTheLegalGuardian',
      'legalGuardianName',
      'legalGuardianDateOfBirth',
      'legalGuardianEmail',
      'review',
    ],
  },
  isMainPolicyTheLegalGuardian: {
    op: 'always',
    questions: [
      'legalGuardianName',
      'legalGuardianDateOfBirth',
      'legalGuardianEmail',
      'review',
    ],
  },
};

const getQuotePrice = (answers: Partial<ExpatSpainDependent>) => {
  if (answers.quoteProcessing && answers.quoteProcessing.length > 0) {
    return answers.quoteProcessing[0].price;
  }

  return null;
};

export const DependentSignupPage = () => {
  const dispatch = useDispatch();
  const { t } = useSafeTranslation();
  const { url } = useRouteMatch();
  const history = useHistory();
  const { mainPolicyId } = useParams<{ mainPolicyId: string }>();
  const useNewCheckout = useFlag('app_checkout_revamp_incoming_es');

  const rules = getRules();

  const continueFromBetweenFourAndFiveYearsInSpainBlocker = () => {
    dispatch(
      storeDependentGenericQuestionnaireAnswer({
        betweenFourAndFiveYearsInSpainBlocker: true,
      })
    );
    history.push(expatSpainRoutes.lastPermanentResidency.path);
  };

  const questionnaireAnswers =
    useSelector(
      (state: AppState) => state.genericQuestionnaire.expatSpain?.dependent
    ) || {};

  useEffect(() => {
    return () => {
      dispatch(flushGenericQuestionnaire('expatSpain'));
    };
  }, [dispatch]);

  const onAnswer = <QuestionId extends keyof ExpatSpainDependent>(
    questionId: QuestionId,
    answer: ExpatSpainDependent[QuestionId]
  ) => {
    if (
      questionnaireAnswers.mainPolicyId ||
      questionnaireAnswers.isPolicyHolder
    ) {
      dispatch(
        storeDependentGenericQuestionnaireAnswer({
          [questionId]: answer,
        })
      );
    } else {
      dispatch(
        storeDependentGenericQuestionnaireAnswer({
          [questionId]: answer,
          mainPolicyId,
          isPolicyHolder: false,
        })
      );
    }

    const answersToRemove = stateManagerHelper(
      removeAnswersLogic,
      questionnaire.components,
      questionnaireAnswers,
      questionnaire.rules
    ).getAnswersToRemove(questionId, answer);

    dispatch(removeDependentGenericQuestionnaireAnswer(answersToRemove));
  };

  const quotePrice = getQuotePrice(questionnaireAnswers);

  const questions = getTranslatedQuestionnaire(
    t,
    quotePrice,
    mainPolicyId,
    continueFromBetweenFourAndFiveYearsInSpainBlocker,
    useNewCheckout
  );
  const expatSpainRoutes = getRoutes(questions, url);

  const questionnaire = {
    components: questions,
    routes: expatSpainRoutes,
    rules,
  };

  return (
    <Switch>
      <Route path={routes.policies.expatSpain.dependents.questionnaire.path}>
        <SignupQuestionnaire
          questionnaireAnswers={questionnaireAnswers}
          questionnaire={questionnaire}
          onAnswer={onAnswer}
          configuration={{
            components: ExpatSpainDependentComponents,
          }}
          basePath={url}
          questionId="intro"
          featureName="ExpatSpainDependent"
        />
      </Route>
    </Switch>
  );
};
