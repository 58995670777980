import Session from '@getpopsure/session';
import routes from 'constants/routes';
import { Redirect } from 'react-router-dom';
import { isMobileApp } from 'shared/util/isMobileApp';

import DesktopView from './components/DesktopView';
import MobileAppView from './components/MobileAppView';

const MainScreen = () => {
  const { isAuthenticated } = Session;

  if (isAuthenticated) {
    return <Redirect to={routes.me.policies.path} />;
  }

  if (isMobileApp) {
    return <MobileAppView />;
  }

  return <DesktopView />;
};

export default MainScreen;
