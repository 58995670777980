import { Link } from 'react-router-dom';

import styles from './LinkCell.module.scss';

export const LinkCell = ({
  href,
  children,
  isExternalLink = false,
}: {
  href: string;
  children: React.ReactNode;
  isExternalLink?: boolean;
}) => {
  return isExternalLink ? (
    <a
      href={href}
      className={`p16 w100 ${styles.linkCell}`}
      target="_blank"
      rel="noopener noreferrer"
      key={href}
    >
      {children}
    </a>
  ) : (
    <Link to={href} className={`p16 w100 ${styles.linkCell}`} key={href}>
      {children}
    </Link>
  );
};
