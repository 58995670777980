import { capitalizeName } from '@getpopsure/public-utility';
import { getPoliciesListAction } from 'features/policiesDashboard/utils/getPoliciesListAction';
import { getPolicyStatus } from 'features/policiesDashboard/utils/getPolicyStatus';
import {
  BillingType,
  GetPolicyConfigArgs,
  PolicyConfig,
  PolicySingleSection,
} from 'features/policySingle/PolicySingle.models';
import {
  bannerColorMapping,
  getTitleMapping,
} from 'models/insurances/types/mapping';

import type { PolicySingleModal } from '../../components/modals';
import {
  DelinquencyBlockerCancelPolicyModal,
  DelinquencyBlockerCreateClaimModal,
} from '../../components/modals';
import { getPolicyDocuments } from '../../sections/PolicyDocuments/utils/getPolicyDocuments';
import { getDentalSpainButtons } from './getDentalSpainButtons';
import { getDentalSpainInfo } from './getSpainDentalInfo';

export const getDentalSpainConfig = (
  args: GetPolicyConfigArgs
): PolicyConfig => {
  const [policy, t] = args;
  const { insuredPerson } = policy.attributes;

  const action = getPoliciesListAction(policy);
  const policyStatus =
    action && action !== 'DETAIL'
      ? 'ACTION_NEEDED'
      : getPolicyStatus(policy, true);
  const capitalizedName = insuredPerson?.name
    ? capitalizeName(insuredPerson.name)
    : '';
  const subtitle = capitalizedName || '';
  const title = getTitleMapping(t)[policy.type];

  const header: PolicyConfig['header'] = {
    title,
    backgroundColor: bannerColorMapping.BIKE_ES,
    insuranceType: policy.type,
    subtitle,
    policyStatus,
    startDate: policy.attributes.startDate,
  };

  if (policy.attributes.price) {
    header.price = {
      amount: parseFloat(policy.attributes.price),
      billingType: policy.attributes.billingType as BillingType,
    };
  }

  const sections: PolicySingleSection[] = [
    {
      id: 'POLICY_DETAILS',
      props: {
        policyType: policy.type,
        info: getDentalSpainInfo(policy, t),
        buttons: getDentalSpainButtons(policy),
      },
    },
    {
      id: 'POLICY_DOCUMENTS',
      props: {
        documents: getPolicyDocuments(policy),
      },
    },
  ];

  const modals: PolicySingleModal[] = [
    {
      id: 'DELINQUENT_BLOCKER_CREATE_CLAIM',
      component: DelinquencyBlockerCreateClaimModal,
    },
    {
      id: 'DELINQUENT_BLOCKER_CANCEL_POLICY',
      component: DelinquencyBlockerCancelPolicyModal,
    },
  ];

  return { header, modals, sections };
};
