import {
  getRoutes,
  QuestionnaireQuestions,
  RemoveAnswerType,
  Rule,
  stateManagerHelper,
} from '@getpopsure/qnr-framework';
import { useFlag } from '@unleash/proxy-client-react';
import {
  flushGenericQuestionnaire,
  removeGenericQuestionnaireAnswer,
  storeGenericQuestionnaireAnswer,
} from 'actions/genericQuestionnaire';
import routes from 'constants/routes';
import { UserWithBrokerMandate } from 'models/user';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useHistory } from 'react-router-dom';
import { AppState } from 'reducers';
import { getAccountInfo } from 'selectors/user';
import { useSafeTranslation } from 'shared/i18n';
import { SignupQuestionnaire } from 'SignupQuestionnaire';

import { ExpatSpain } from './models';
import {
  ExpatSpainComponents,
  getTranslatedQuestionnaire,
} from './questionnaire/PolicyHolder';

const getRules = (): Rule<ExpatSpain>[] => [
  {
    id: 'occupation',
    if: {
      op: 'equals',
      variable: { type: 'static', value: 'EMPLOYED' },
    },
    then: {
      goTo: 'employedInSpain',
    },
  },
  {
    id: 'occupation',
    if: {
      op: 'equals',
      variable: { type: 'static', value: 'SELF_EMPLOYED' },
    },
    then: {
      goTo: 'registeredFreelancer',
    },
  },
  {
    id: 'occupation',
    if: {
      op: 'equals',
      variable: { type: 'static', value: 'OTHER' },
    },
    then: {
      goTo: 'otherOccupation',
    },
  },
  {
    id: 'employedInSpain',
    if: {
      op: 'equals',
      variable: {
        type: 'static',
        value: 'YES',
      },
    },
    then: {
      goTo: 'employedInSpainBlocker',
    },
  },
  {
    id: 'registeredFreelancer',
    if: {
      op: 'equals',
      variable: {
        type: 'static',
        value: true,
      },
    },
    then: {
      goTo: 'registeredFreelancerOccupation',
    },
  },
  {
    id: 'dateOfBirth',
    if: {
      op: 'dateDiff',
      variable: {
        type: 'year',
        value: 75,
      },
    },
    then: {
      goTo: 'ageIs75AndAboveBlocker',
    },
  },
  {
    id: 'dateOfBirth',
    if: {
      op: 'dateDiff',
      variable: {
        type: 'year',
        value: 18,
      },
    },
    then: {
      goTo: 'addFamilyMembers',
    },
    else: {
      goTo: 'legalGuardianName',
    },
  },
  {
    id: 'arrivalDate',
    if: {
      op: 'dateDiff',
      variable: {
        type: 'month',
        value: 58,
        dir: 'gt',
      },
    },
    then: {
      goTo: 'moreThanFiveYearsInSpainBlocker',
    },
  },
  {
    id: 'arrivalDate',
    if: [
      'AND',
      [
        {
          op: 'dateDiff',
          variable: {
            type: 'month',
            value: 47,
            dir: 'gt',
          },
        },
        {
          op: 'dateDiff',
          variable: {
            type: 'month',
            value: 59,
            dir: 'lt',
          },
        },
      ],
    ],
    then: {
      goTo: 'betweenFourAndFiveYearsInSpainBlocker',
    },
  },
  {
    id: 'legalGuardianName',
    if: {
      op: 'always',
    },
    then: {
      goTo: 'legalGuardianDateOfBirth',
    },
  },
  {
    id: 'legalGuardianDateOfBirth',
    if: {
      op: 'always',
    },
    then: {
      goTo: 'legalGuardianEmail',
    },
  },
];

const removeReview: RemoveAnswerType<ExpatSpain> = {
  op: 'always',
  questions: ['review'],
};

const removeAnswersLogic: Partial<
  Record<keyof ExpatSpain, RemoveAnswerType<ExpatSpain>>
> = {
  postalCode: removeReview,
  quote: removeReview,
  name: removeReview,
  email: removeReview,
  gender: removeReview,
  addFamilyMembers: removeReview,
  legalGuardianName: removeReview,
  legalGuardianDateOfBirth: removeReview,
  legalGuardianEmail: removeReview,
  dateOfBirth: {
    op: 'always',
    questions: [
      'addFamilyMembers',
      'legalGuardianName',
      'legalGuardianDateOfBirth',
      'legalGuardianEmail',
      'review',
    ],
  },
};

const prefillNameAndBirthDate = <
  Questionnaire extends QuestionnaireQuestions,
  Key extends keyof Questionnaire
>(
  questionnaireAnswers: Partial<Questionnaire>,
  questionId: Key,
  answer: Questionnaire[Key],
  account?: UserWithBrokerMandate
) => {
  if (
    questionId === 'email' &&
    account &&
    account.email === answer &&
    questionnaireAnswers.email !== answer
  ) {
    let toUpdate: Partial<ExpatSpain> = {};
    const { firstName, lastName } = account;

    if (firstName && lastName) {
      toUpdate = { ...toUpdate, name: { firstName, lastName } };
    }
    return toUpdate;
  }

  return null;
};

const getQuotePrice = (answers: Partial<ExpatSpain>) => {
  if (answers.quoteProcessing && answers.quoteProcessing.length > 0) {
    return answers.quoteProcessing[0].price;
  }

  return null;
};

export const SignupPage = () => {
  const questionnaireAnswers =
    useSelector((state: AppState) => state.genericQuestionnaire.expatSpain) ||
    {};
  const dispatch = useDispatch();
  const account = useSelector(getAccountInfo);
  const { t } = useSafeTranslation();
  const history = useHistory();

  const useNewCheckout = useFlag('app_checkout_revamp_incoming_es');

  const rules = getRules();

  const continueFromBetweenFourAndFiveYearsInSpainBlocker = () => {
    dispatch(
      storeGenericQuestionnaireAnswer('expatSpain', {
        betweenFourAndFiveYearsInSpainBlocker: true,
      })
    );
    history.push(expatSpainRoutes.lastPermanentResidency.path);
  };

  useEffect(() => {
    return () => {
      dispatch(flushGenericQuestionnaire('expatSpain'));
    };
  }, [dispatch]);

  const onAnswer = <QuestionId extends keyof ExpatSpain>(
    questionId: QuestionId,
    answer: ExpatSpain[QuestionId]
  ) => {
    dispatch(
      storeGenericQuestionnaireAnswer('expatSpain', { [questionId]: answer })
    );

    const answersToRemove = stateManagerHelper(
      removeAnswersLogic,
      questionnaire.components,
      questionnaireAnswers,
      questionnaire.rules
    ).getAnswersToRemove(questionId, answer);

    dispatch(removeGenericQuestionnaireAnswer('expatSpain', answersToRemove));

    const toUpdate = prefillNameAndBirthDate(
      questionnaireAnswers,
      questionId,
      answer,
      account
    );
    if (toUpdate) {
      dispatch(storeGenericQuestionnaireAnswer('expatSpain', toUpdate));
    }
  };

  const quotePrice = getQuotePrice(questionnaireAnswers);

  const questions = getTranslatedQuestionnaire(
    t,
    quotePrice,
    continueFromBetweenFourAndFiveYearsInSpainBlocker,
    useNewCheckout
  );
  const expatSpainRoutes = getRoutes(
    questions,
    routes.policies.expatSpain.path
  );

  const questionnaire = {
    components: questions,
    routes: expatSpainRoutes,
    rules,
  };

  return (
    <Switch>
      <Route path={routes.policies.expatSpain.questionnaire.path}>
        <SignupQuestionnaire
          questionnaireAnswers={questionnaireAnswers}
          questionnaire={questionnaire}
          onAnswer={onAnswer}
          configuration={{
            components: ExpatSpainComponents,
          }}
          basePath={routes.policies.expatSpain.path}
          questionId="intro"
          featureName="ExpatSpain"
          share={{
            verticalId: 'expatSpain',
          }}
        />
      </Route>
    </Switch>
  );
};
