import classNames from 'classnames';
import routes from 'constants/routes';
import dayjs from 'dayjs';
import { StatusBadge } from 'features/claimsV2/components/statusBadge';
import {
  CLAIM_ASSESSMENT_STATUSES_ACTION_NEEDED,
  ClaimAssessment,
  ClaimAssessmentStatus,
  ClaimAssessmentStatusRequiringAction,
  getClaimAssessmentStatusDataMapping,
} from 'models/claims';
import { PolicyClaim } from 'models/policies';
import { useState } from 'react';
import { generatePath } from 'react-router';
import { Link } from 'react-router-dom';
import { useSafeTranslation } from 'shared/i18n';

import { PolicySingleCard } from '../../components/PolicySingleCard';
import { DATE_FORMAT } from '../../constants';
import styles from './PolicyAPVs.module.scss';

type ClaimAssessmentWithClaimsCount = ClaimAssessment & {
  claimsCount?: number;
};

const ITEM_LIMIT = 3;

const isActionNeeded = (status: ClaimAssessmentStatus) =>
  CLAIM_ASSESSMENT_STATUSES_ACTION_NEEDED.includes(
    status as ClaimAssessmentStatusRequiringAction
  );

export type PolicyAPVsProps = {
  claims?: PolicyClaim[];
};

export const PolicyAPVs = ({ claims }: PolicyAPVsProps) => {
  const { t } = useSafeTranslation();
  const [itemsLimit, setItemsLimit] = useState(ITEM_LIMIT);

  const apvsMap: Map<string, ClaimAssessmentWithClaimsCount> = new Map();

  if (!claims || claims.length === 0) {
    return null;
  }

  for (const claim of claims) {
    if (claim.claimAssessment?.id) {
      const claimsCount =
        apvsMap.get(claim.claimAssessment?.id)?.claimsCount ?? 0;
      apvsMap.set(claim.claimAssessment?.id, {
        ...claim.claimAssessment,
        claimsCount: claimsCount + 1,
      });
    }
  }

  const apvs = Array.from(apvsMap.values());

  const sortedAPVs = apvs
    .sort((a, b) => {
      if (isActionNeeded(a.status)) {
        return -1;
      }
      if (isActionNeeded(b.status)) {
        return 1;
      }
      return dayjs(b.createdAt).diff(dayjs(a.createdAt));
    })
    .slice(0, itemsLimit);

  const showMore = () => {
    setItemsLimit((limit) => Math.min(limit + ITEM_LIMIT, apvs.length));
  };

  if (apvs.length === 0) return null;

  return (
    <div className="p-container" id="claim-assessments">
      <PolicySingleCard
        title={t('myPolicies.policyAPVs.title', 'Claim assessments')}
      >
        <>
          {sortedAPVs.map(({ id, title, createdAt, status, claimsCount }) => {
            const statusBadgeData = getClaimAssessmentStatusDataMapping({
              t,
              status,
            });

            const countText =
              claimsCount === 1 ? '1 claim' : `${claimsCount} claims`;

            return (
              <APVItem
                key={id}
                path={generatePath(routes.claims.assessments.path, {
                  assessmentId: id,
                })}
              >
                <div className="w-100">
                  <div className="p-p fw-bold tc-grey-700">{title}</div>
                  <div className="d-flex ai-center gap16">
                    <div
                      className={classNames(
                        'p-p--small tc-grey-700 fw-bold mt8',
                        styles.mobileHidden
                      )}
                    >
                      {dayjs(createdAt).format(DATE_FORMAT)}
                    </div>
                    <div
                      className={classNames(
                        'p-p--small tc-grey-600 mt8',
                        styles.mobileHidden
                      )}
                    >
                      {countText}
                    </div>
                  </div>
                </div>
                <div
                  className={classNames(
                    'jc-between ai-center',
                    styles.mobileHidden
                  )}
                >
                  <StatusBadge {...statusBadgeData} />
                </div>
                <div className={classNames('f-nowrap', styles.mobileDisplay)}>
                  <div className="d-flex jc-between ai-center mt16">
                    <StatusBadge {...statusBadgeData} />
                    <div className="p-p--small tc-grey-700 fw-bold">
                      {dayjs(createdAt).format(DATE_FORMAT)}
                    </div>
                    <div className="p-p--small tc-grey-600">{countText}</div>
                  </div>
                </div>
              </APVItem>
            );
          })}
          {itemsLimit < (apvs?.length || 0) && (
            <div className="d-flex ai-center jc-center mt16 mb16">
              <button
                className="p-p tc-grey-500 fw-700 ds-interactive-component ds-card--no-dropshadow"
                onClick={showMore}
                type="button"
              >
                {t('myPolicies.policyAPVs.showMoreButton.title', 'Show more')}
              </button>
            </div>
          )}
        </>
      </PolicySingleCard>
    </div>
  );
};

const APVItem = ({
  children,
  path,
}: {
  children: React.ReactNode;
  path: string;
}) => {
  return (
    <Link
      className={classNames(
        'w-100 p24 mb16 d-flex ai-center f-nowrap jc-between br8 ds-card--actionable ds-card--no-dropshadow bs-xs',
        styles.apvItem
      )}
      to={{ pathname: path, state: { from: 'policy' } }}
    >
      {children}
    </Link>
  );
};
