import { InsuranceTypes } from 'models/insurances/types';
import { GenericQuestionnaireState } from 'reducers/genericQuestionnaire';

export type ValidVerticalUrlParams =
  | keyof GenericQuestionnaireState
  | 'publicHealth'
  | 'car'
  | 'job'
  | 'liability'
  | 'BIKE_ES';

export const mapVerticalId: {
  [K in InsuranceTypes]: keyof GenericQuestionnaireState | null;
} = {
  INCOMING: 'expat',
  EXPAT_V2: 'expat',
  LIFE: 'life',
  LIFE_V2: 'lifeV2',
  BIKE: 'bike',
  LIABILITY: 'liability',
  CAR: null,
  JOB: null,
  PUBLIC_HEALTH: null,
  PRIVATE_HEALTH: null,
  HOUSEHOLD: 'household',
  LEGAL: 'legal',
  LEGAL_V2: 'legal',
  DENTAL: 'dentalV2',
  DENTAL_V2: 'dentalV2',
  DOG_LIABILITY: 'dog',
  DISABILITY: 'disability',
  GENERIC: null,
  TRAVEL: 'travelHealthV1',
  PET_HEALTH: 'petHealth',
  COMPANY_LIFE: null,
  COMPANY_HEALTH: null,
  PENSION: null,
  BASIS_PENSION: null,
  INCOMING_ES: 'expatSpain',
  TRAVEL_HEALTH_V1: null,
  TRAVEL_FR: null,
  BIKE_V2: 'bike',
  INCOMING_EU: 'expatEu',
  INCOMING_LT: 'expatLongTerm',
  DISABILITY_V2: 'disabilityV2',
  COMPANY_PENSION: null,
  DOG_LIABILITY_ES: 'dogLiabilitySpain',
  BIKE_ES: null,
  LIFE_EU: null,
  DENTAL_ES: null,
};
