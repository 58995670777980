import { faq } from '@getpopsure/private-constants';
import {
  BottomOrRegularModal,
  Button,
  InformationBox,
} from '@popsure/dirty-swan';
import dayjs from 'dayjs';
import { IntercomLoaderWrapper } from 'features/intercomLauncher';
import { Trans, useSafeTranslation } from 'shared/i18n';

import styles from '../style.module.scss';
import { ViewProps } from '../types';

export const isCancellationAutomated = (startDate?: string) => {
  if (!startDate) {
    return false;
  }
  return dayjs().diff(dayjs(startDate), 'years') >= 1;
};

export const CancelDentalModal = (props: ViewProps) => {
  const { startDate } = props;
  if (isCancellationAutomated(startDate)) {
    return <CancelDentalAutomatedModal {...props} />;
  }
  return <CancelDentalManualModal {...props} />;
};

const CancelDentalManualModal = ({
  onCloseModal,
  onCancelPolicy,
  isCancelling,
  cancellingError,
  isOpen,
}: ViewProps) => {
  const { t } = useSafeTranslation();

  return (
    <BottomOrRegularModal
      title={t('dental.cancelModal.manualCancellation.title', 'Cancel policy')}
      isOpen={isOpen}
      onClose={onCloseModal}
    >
      <div
        className={`mt24 ${styles.container}`}
        data-testid="cancel-modal-dental-manual"
      >
        <p>
          {t(
            'dental.cancelModal.manualCancellation.description.title',
            'Are you sure you wish to cancel your dental Insurance policy?'
          )}
        </p>
        <div className="p-p mt24">
          <Trans key="dental.cancelModal.manualCancellation.description.body">
            <p className="fw-bold mt8 mb8">
              If you are upgrading your dental insurance, you do not need to put
              in a cancel request - we will take care of that for you!
            </p>
            Keep in mind that receiving a claim payout may change your{' '}
            <a
              href={faq.dentalClaimSubmit}
              className="p-a"
              target="_blank"
              rel="noreferrer"
            >
              cancellation options
            </a>
            .
          </Trans>
        </div>
        <div className={`d-flex f-wrap mt24 ${styles['button-container']}`}>
          <Button
            className="wmn3"
            loading={isCancelling}
            disabled={isCancelling}
            onClick={() =>
              onCancelPolicy(
                undefined,
                undefined,
                'We have received your cancellation request'
              )
            }
            type="button"
          >
            {t(
              'dental.cancelModal.manualCancellation.cancelButton.caption',
              'Request to cancel'
            )}
          </Button>
          <ContactUsButton />
        </div>
        {cancellingError && (
          <InformationBox variant="error">{cancellingError}</InformationBox>
        )}
      </div>
    </BottomOrRegularModal>
  );
};

const CancelDentalAutomatedModal = ({
  onCloseModal,
  onCancelPolicy,
  isCancelling,
  cancellingError,
  isOpen,
  activeUntil,
}: ViewProps) => {
  const { t } = useSafeTranslation();

  return (
    <BottomOrRegularModal
      title={t(
        'dental.cancelModal.automatedCancellation.title',
        'Cancel policy'
      )}
      isOpen={isOpen}
      onClose={onCloseModal}
    >
      <div
        className={`mt24 ${styles.container}`}
        data-testid="cancel-modal-dental-auto"
      >
        <p>
          {t(
            'dental.cancelModal.automatedCancellation.description.1',
            'Are you sure you wish to cancel your dental Insurance policy?'
          )}
        </p>
        <p className="p-p mt24">
          {t(
            'dental.cancelModal.automatedCancellation.description.2',
            'You can reach out to us if you have a preferred cancellation date.'
          )}
        </p>
        <div className="p-p mt24">
          <Trans i18nKey="dental.cancelModal.automatedCancellation.activeUntil.description">
            <InformationBox variant="warning">
              If you press cancel, your coverage will end on{' '}
              <b className="fw-bold">
                {{ endDate: dayjs(activeUntil).format('DD MMM YYYY') }}.
              </b>
            </InformationBox>
          </Trans>
        </div>
        <div className={`d-flex f-wrap mt24 ${styles['button-container']}`}>
          <Button
            className="wmn3"
            loading={isCancelling}
            disabled={isCancelling}
            onClick={() => onCancelPolicy()}
            type="button"
          >
            {t(
              'dental.cancelModal.automatedCancellation.cancelButton.caption',
              'Cancel policy'
            )}
          </Button>
          <ContactUsButton />
        </div>
        {cancellingError && (
          <InformationBox variant="error">{cancellingError}</InformationBox>
        )}
      </div>
    </BottomOrRegularModal>
  );
};

export const ContactUsButton = () => {
  const { t } = useSafeTranslation();
  return (
    <IntercomLoaderWrapper
      render={({ loading, handleClick }) => {
        if (loading) {
          return (
            <Button loading variant="filledGray" className="wmn2" type="button">
              Loading
            </Button>
          );
        }

        return (
          <Button
            className="wmn2"
            type="button"
            onClick={() => {
              handleClick();
              if (window.Intercom) {
                window.Intercom('show');
              }
            }}
            variant="textColor"
          >
            {t(
              'dental.cancelModal.automatedCancellation.contactUs.caption',
              'Contact us'
            )}
          </Button>
        );
      }}
    />
  );
};
