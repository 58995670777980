import { paramsSetUrl } from '@getpopsure/public-utility';
import { RefreshCwIcon } from '@popsure/dirty-swan';
import { captureException } from '@sentry/react';
import { useFlag } from '@unleash/proxy-client-react';
import { storeGenericQuestionnaireAnswer } from 'actions/genericQuestionnaire';
import ButtonCell from 'components/buttonCell';
import routes from 'constants/routes';
import dayjs from 'dayjs';
import { useLoadPolicyDetail } from 'features/claimsProcess/hooks';
import {
  ExpatProfileInfo,
  SubmittableQuestionnaire,
} from 'features/expat/models';
import { storeDependentGenericQuestionnaireAnswer } from 'features/expatLongTerm/actions';
import {
  ExpatLongTerm,
  ExpatLongTermDependent,
} from 'features/expatLongTerm/models';
import { PolicyDetailsButtonProps } from 'features/policySingle/sections/PolicyDetails/PolicyDetails.models';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import api from 'shared/api';
import { useSafeTranslation } from 'shared/i18n';

export const canUpdateToExpatLT = (arrivalDate?: string) => {
  if (!arrivalDate) {
    return false;
  }
  return dayjs(arrivalDate)
    .add(5, 'years')
    .subtract(2, 'months')
    .isBefore(dayjs());
};

export const ExpatUpgradeLongTermHealth = ({
  policyData,
  onOpenModal,
}: PolicyDetailsButtonProps) => {
  const isExpatLt = useFlag('app_expat_lt');
  const [questionnaireAnswers, setQuestionnaireAnswers] = useState<
    SubmittableQuestionnaire | undefined
  >();
  const history = useHistory();

  if (!isExpatLt) {
    return null;
  }

  const fetchQuote = async (questionnaireId?: string) => {
    if (!questionnaireId || questionnaireAnswers) {
      return;
    }

    try {
      const {
        data: { answers },
      } = await api.getQuestionnaire(questionnaireId);

      setQuestionnaireAnswers(answers);
    } catch (e) {
      captureException(e, {
        tags: {
          feature: 'UPGRADE_TO_LONG_TERM_HEALTH',
          vertical: 'EXPAT_V2',
        },
        extra: {
          description: 'Failed to load questionnaire',
        },
      });
    }
  };

  const navigateTo = (upgradePath: string) => {
    history.push(
      paramsSetUrl(upgradePath, [
        {
          key: 'switchFromExpat',
          value: '1',
        },
      ])
    );
  };

  if (policyData.mainPolicyId) {
    return (
      <ExpatDependentUpgradeLongTermHealth
        policyData={policyData}
        onOpenModal={onOpenModal}
        fetchQuote={fetchQuote}
        questionnaireAnswers={questionnaireAnswers}
        navigateTo={navigateTo}
      />
    );
  }

  return (
    <ExpatMainUpgradeLongTermHealth
      policyData={policyData}
      onOpenModal={onOpenModal}
      fetchQuote={fetchQuote}
      questionnaireAnswers={questionnaireAnswers}
      navigateTo={navigateTo}
    />
  );
};

type ExpatUpgradeButtonProps = PolicyDetailsButtonProps & {
  fetchQuote: (questionnaireId?: string) => void;
  questionnaireAnswers: SubmittableQuestionnaire | undefined;
  navigateTo: (path: string) => void;
};

export const ExpatMainUpgradeLongTermHealth = ({
  policyData,
  fetchQuote,
  questionnaireAnswers,
  navigateTo,
}: ExpatUpgradeButtonProps) => {
  const { t } = useSafeTranslation();

  const dispatch = useDispatch();

  const questionnaireId = policyData.attributes?.questionnaireId;

  useEffect(() => {
    fetchQuote(questionnaireId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const upgradeable = canUpdateToExpatLT(
    questionnaireAnswers?.tariffInfo?.arrivalDate
  );

  if (
    !upgradeable ||
    !questionnaireAnswers ||
    policyData.attributes?.upgradedToExpatLtId !== null
  ) {
    return <></>;
  }

  const handleButtonClick = () => {
    dispatch(
      storeGenericQuestionnaireAnswer(
        'expatLongTerm',
        convertExpatToLongTerm(policyData.id, questionnaireAnswers)
      )
    );

    const upgradePath = generatePath(routes.policies.expatLongTerm.path, {
      localeId: 'de',
    });

    navigateTo(upgradePath);
  };

  return (
    <ButtonCell
      key="UPGRADE_LONG_TERM_HEALTH"
      title={t(
        'myPolicies.policyDetails.upgradeButton.longTerm.caption',
        'Switch to long term health'
      )}
      icon={<RefreshCwIcon noMargin size={24} className="ml16" />}
      onClick={handleButtonClick}
    />
  );
};

export const ExpatDependentUpgradeLongTermHealth = ({
  policyData,
  fetchQuote,
  questionnaireAnswers,
  navigateTo,
}: ExpatUpgradeButtonProps) => {
  const { t } = useSafeTranslation();
  const dispatch = useDispatch();

  const { policy: mainPolicy } = useLoadPolicyDetail(
    policyData.mainPolicyId ?? ''
  );

  const questionnaireId = policyData.attributes?.questionnaireId;

  useEffect(() => {
    fetchQuote(questionnaireId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const upgradeable = canUpdateToExpatLT(
    questionnaireAnswers?.tariffInfo?.arrivalDate
  );

  const canNotUpgrade =
    !upgradeable ||
    !questionnaireAnswers ||
    policyData.attributes.upgradedToExpatLtId !== null ||
    !mainPolicy ||
    !mainPolicy.attributes?.upgradedToExpatLtId;

  if (canNotUpgrade) {
    return <></>;
  }

  const handleButtonClick = () => {
    dispatch(
      storeDependentGenericQuestionnaireAnswer(
        convertExpatToLongTerm(policyData.id, questionnaireAnswers)
      )
    );

    const upgradePath = generatePath(
      routes.policies.expatLongTerm.dependents.path,
      {
        localeId: 'de',
        mainPolicyId: mainPolicy.attributes.upgradedToExpatLtId ?? '',
      }
    );

    navigateTo(upgradePath);
  };

  return (
    <ButtonCell
      key="UPGRADE_LONG_TERM_HEALTH"
      title={t(
        'myPolicies.policyDetails.upgradeButton.longTerm.caption',
        'Switch to long term health'
      )}
      icon={<RefreshCwIcon noMargin size={24} className="ml16" />}
      onClick={handleButtonClick}
    />
  );
};

const convertExpatToLongTerm = (
  policyId: string,
  questionnaire: SubmittableQuestionnaire
): Partial<ExpatLongTerm | ExpatLongTermDependent> => {
  const { personalInfo, tariffInfo } = questionnaire;

  return {
    region: 'de',
    dateOfBirth: personalInfo.dateOfBirth,
    citizenship: personalInfo.citizenship,
    gender: personalInfo.gender,
    email: (personalInfo as ExpatProfileInfo).email ?? undefined,
    occupation:
      personalInfo.occupation === 'EMPLOYED' ||
      personalInfo.occupation === 'OTHER'
        ? personalInfo.occupation
        : undefined,
    name: personalInfo.name,
    startDate: dayjs(tariffInfo?.arrivalDate)
      .add(5, 'years')
      .format('YYYY-MM-DD'),
    lastPermanentResidency: personalInfo.lastPermanentResidency,

    addFamilyMembers: tariffInfo.addDependents,

    // legal guardian
    legalGuardianName: tariffInfo.legalGuardian?.name,
    legalGuardianEmail: tariffInfo.legalGuardian?.email,
    legalGuardianDateOfBirth: tariffInfo.legalGuardian?.dateOfBirth,
    updatedFromExpatId: policyId,
  };
};
