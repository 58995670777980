import { email } from '@getpopsure/private-constants';
import { CurrencyInput } from '@popsure/dirty-swan';
import { NavButton } from 'components/NavButton';
import QuestionnaireWrapper from 'components/questionnaireForms/QuestionnaireWrapper';
import { FormEvent } from 'react';
import { TFunction } from 'shared/i18n';
import { isMobileApp } from 'shared/util/isMobileApp';

interface Props {
  handleSubmit: (e: FormEvent) => void;
  t: TFunction;
  loading: boolean;
  error: unknown;
  validForSubmission: boolean;
  minAmount: number;
  amount?: number;
  setAmount: (amount: number) => void;
}

export const PrivateHealthClaimsAmountView = ({
  handleSubmit,
  t,
  loading,
  error,
  validForSubmission,
  amount,
  setAmount,
  minAmount,
}: Props) => {
  const getErrorMessage = () => {
    if (!error) return;

    return t(
      'claims.privateHealth.amount.error.message',
      'An error occurred while submitting your claim. Please try again or contact us at {{supportEmail}}.',
      { supportEmail: email.help }
    );
  };

  return (
    <>
      {isMobileApp && <NavButton title="Previous question" renderInPortal />}
      <QuestionnaireWrapper
        question={t('claims.privateHealth.amount.question', 'Claim amount')}
        description={t(
          'claims.privateHealth.amount.description',
          'If your claim is approved, the payout will be sent to the bank account you use to pay for your policy.'
        )}
        onSubmit={handleSubmit}
        validForSubmission={validForSubmission}
        loading={loading}
        error={getErrorMessage()}
        buttonText={t('claims.privateHealth.amount.button', 'Submit claim')}
      >
        <CurrencyInput
          className="mt8"
          value={amount}
          onChange={(value) => setAmount(value)}
          placeholder={t(
            'claims.privateHealth.amount.amount.placeholder',
            'Amount'
          )}
          min={minAmount}
          required
          data-cy="input-currency"
        />
      </QuestionnaireWrapper>
    </>
  );
};
