import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import dayjs from 'dayjs';
import { Policy } from 'models/policies';
import { TFunction } from 'shared/i18n';
import { capitalizeName } from 'shared/util/capitalizeName';

import { DATE_FORMAT } from '../../constants';
import { PolicyDetailsInfo } from '../../sections/PolicyDetails/PolicyDetails.models';

export const getBikeSpainInfo = (policy: Policy, t: TFunction) => {
  const {
    insuredPerson,
    policyNumber,
    startDate,
    price,
    brand,
    model,
    frameNumber,
    idType,
    idNumber,
  } = policy.attributes;
  const infoArray: Array<PolicyDetailsInfo> = [];

  if (policyNumber) {
    infoArray.push({
      id: 'policyNumber',
      title: t('myPolicies.policyDetails.policyNumber', 'Policy number'),
      value: policyNumber,
    });
  }

  if (insuredPerson) {
    infoArray.push({
      title: t('myPolicies.policyDetails.insuredPerson', 'Insured person'),
      value: capitalizeName(insuredPerson.name),
    });
  }

  if (startDate) {
    infoArray.push({
      id: 'startDate',
      title: t('myPolicies.policyDetails.startDate', 'Start date'),
      value: dayjs(startDate).format(DATE_FORMAT),
    });
  }

  infoArray.push({
    id: 'purchaseDate',
    title: t('myPolicies.policyDetails.purchaseDate', 'Purchase date'),
    value: dayjs(policy.createdAt).format(DATE_FORMAT),
  });

  if (price) {
    infoArray.push({
      id: 'monthlyPremium',
      title: t('myPolicies.policyDetails.monthlyPremium', 'Monthly premium'),
      value: englishFormattedEuroCurrency(Number(price)),
    });
  }

  if (brand) {
    infoArray.push({
      id: 'brand',
      title: t('myPolicies.policyDetails.bikeSpain.brand', 'Brand'),
      value: brand,
    });
  }

  if (model) {
    infoArray.push({
      id: 'model',
      title: t('myPolicies.policyDetails.bikeSpain.model', 'Model'),
      value: model,
    });
  }

  infoArray.push({
    id: 'frameNumber',
    title: t('myPolicies.policyDetails.bikeSpain.frameNumber', 'Frame number'),
    value: frameNumber,
  });

  infoArray.push({
    id: 'idDocument',
    title: t('myPolicies.policyDetails.bikeSpain.idDocument', 'ID document'),
    value: idType,
  });

  infoArray.push({
    id: 'idDocument',
    title: t('myPolicies.policyDetails.bikeSpain.idNumber', 'ID number'),
    value: idNumber,
  });

  return infoArray;
};
