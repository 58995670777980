import { trackPrivateHealthBookCallClicked } from '@getpopsure/analytics';
import { calendly } from '@getpopsure/private-constants';
import {
  getPriceBreakdown,
  getTariffLabel,
} from '@getpopsure/private-health-insurance-pricing-engine';
import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import { Button, CheckIcon } from '@popsure/dirty-swan';
import { EmailAddressCollectionModal } from 'components/EmailAddressCollectionModal';
import {
  persistPrivatePreSignupQuestionnaire,
  PrivatePreSignupDispatch,
} from 'features/privateHealthPreSignup/actions';
import { PrivatePreSignup } from 'features/privateHealthPreSignup/models';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { APIResponseError } from 'models/error';
import { CustomComponentProps } from 'models/questionnaireFramework';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { formatErrorMessageFromError } from 'selectors/requests';

import planIcon from './assets/plan.svg';
import styles from './PlanReview.module.scss';

export const PlanReview = ({
  questionnaireAnswers,
  onSubmitValue,
  routes: qnrRoutes,
}: CustomComponentProps<PrivatePreSignup>) => {
  const { birthYear = 0, employmentStatus, quote } = questionnaireAnswers;
  const [bookACallModal, setBookACallModal] = useState<boolean>(false);

  if (!quote?.tariff || !quote?.deductible || !quote?.sickDayPayout) {
    throw new Error(
      '[Private Pre-Signup] Questionnaire answers are missing for plan review'
    );
  }
  const dispatch = useDispatch<PrivatePreSignupDispatch>();

  const toggleBookACallModal = () => {
    trackPrivateHealthBookCallClicked({ tariff: quote.tariff });
    setBookACallModal((state) => !state);
  };

  const { sickDayPayout, tariff } = quote;

  const planName = getTariffLabel(tariff, true);

  const priceBreakdown = getPriceBreakdown({
    tariff,
    birthYear,
    sickDayPayout,
    employmentStatus,
  });

  const coveragePrice = priceBreakdown.tariff.totalPrice;

  const employerPaysBackAmount = priceBreakdown.contributions.employer;
  const monthlyTotal = priceBreakdown.contributions.own;

  const isDesktop = useMediaQuery('ABOVE_MOBILE');

  return (
    <div className={`d-flex fd-column ai-center jc-center ${styles.container}`}>
      <div className={`p-container w100 wmx8 ${styles.content}`}>
        <h1 className="p-h1 mb24">Here’s your plan</h1>
        <div className={`bg-white br8 ${styles.card}`}>
          <div className={`d-flex jc-between r-gap16 ${styles.columnOnMobile}`}>
            <div>
              <div className="d-flex ai-center gap8">
                <img src={planIcon} alt="" />
                <div className="d-flex ai-baseline gap8">
                  <h3 className="p-h3">{planName} plan</h3>
                </div>
              </div>

              <div className={`d-flex ai-center ${styles.incomeProtection}`}>
                {isDesktop && <CheckIcon size={20} color="purple-500" />}
                <p className="p-p">
                  Income protection:{' '}
                  <span className="fw-bold">
                    {englishFormattedEuroCurrency(sickDayPayout * 30, true)}/mo
                  </span>
                </p>
              </div>
            </div>
            <Link className="p-btn--secondary-grey" to={qnrRoutes.quote.path}>
              Adjust
            </Link>
          </div>

          <div className={`bg-grey-200 mt32 mb32 ${styles.divider}`} />
          {!!employerPaysBackAmount && (
            <>
              <div className="d-flex ai-center jc-between">
                <p className="p-p">Coverage price</p>
                <p className="p-p w100 wmx2 ta-right">
                  {englishFormattedEuroCurrency(coveragePrice, true)}
                </p>
              </div>
              <div className="d-flex ai-center jc-between mt16">
                <p className="p-p">Employer pays back</p>
                <p className="p-p w100 wmx2 ta-right">
                  - {englishFormattedEuroCurrency(employerPaysBackAmount, true)}
                </p>
              </div>
            </>
          )}
          <div className="d-flex ai-center jc-between mt16">
            <p className="p-h3">Your monthly total</p>
            <p className="p-h2 w100 wmx2 ta-right">
              {englishFormattedEuroCurrency(monthlyTotal, true)}
            </p>
          </div>
        </div>
        <div className={`d-flex jc-center gap8 mt32 ${styles.columnOnMobile}`}>
          <Button
            className="wmn3"
            variant="filledColor"
            onClick={() => onSubmitValue(true)}
            data-cy="button-continue"
          >
            Continue
          </Button>
          <Button
            className="wmn3"
            variant="filledGray"
            onClick={() => toggleBookACallModal()}
          >
            Talk to us
          </Button>
        </div>
      </div>
      <EmailAddressCollectionModal
        isOpen={bookACallModal}
        onClose={() => setBookACallModal(false)}
        onSubmit={async ({ setSubmitting, setError, name, email }) => {
          setError('');
          setSubmitting(true);

          const isEligibleForShortTerm =
            questionnaireAnswers.eligibleForShortTerm;

          const qnrShortTermPrice = isEligibleForShortTerm
            ? getPriceBreakdown({
                tariff: 'HiMedicalPlus',
                birthYear,
                sickDayPayout,
                employmentStatus,
              }).contributions.own
            : undefined;

          const qnrBasicPrice = getPriceBreakdown({
            tariff: 'NKSelectS',
            birthYear,
            sickDayPayout,
            employmentStatus,
          }).contributions.own;

          const qnrPlusPrice = getPriceBreakdown({
            tariff: 'NKSelectL',
            birthYear,
            sickDayPayout,
            employmentStatus,
          }).contributions.own;

          const qnrPremiumPrice = getPriceBreakdown({
            tariff: 'NKSelectXL',
            birthYear,
            sickDayPayout,
            employmentStatus,
          }).contributions.own;

          const { status, error, questionnaireId } = await dispatch(
            persistPrivatePreSignupQuestionnaire({
              answers: questionnaireAnswers,
              shortTermPrice: qnrShortTermPrice,
              basicPrice: qnrBasicPrice,
              plusPrice: qnrPlusPrice,
              premiumPrice: qnrPremiumPrice,
              email,
              name,
              hashOfLastSubmittedQuestionnaire:
                questionnaireAnswers.hashOfQuestionnaire,
            })
          );
          if (status === 'SUCCESS') {
            setBookACallModal(false);
            window.Calendly.initPopupWidget({
              url: calendly.healthInsuranceAdvice,
              prefill: {
                name,
                email,
                customAnswers: {
                  a4: questionnaireId,
                },
              },
            });
          } else {
            setError(formatErrorMessageFromError(error as APIResponseError));
          }
          setSubmitting(false);
        }}
      />
    </div>
  );
};
