import { BottomOrRegularModal } from '@popsure/dirty-swan';
import Markdown from 'components/markdown';
import {
  Modal,
  ModalWithTracking,
} from 'features/privateHealthPreSignup/models';

import styles from './TableModal.module.scss';

const getPlanNameFromModalData = (modal: Modal | ModalWithTracking) => {
  if (!('tariff' in modal)) {
    return '';
  }

  if (modal.tariff === 'header') {
    return '';
  }

  if (modal.tariff === 'Public') {
    return '(Public health insurance)';
  }

  if (modal.tariff === 'HiMedicalPlus') {
    return '(Short-term plan)';
  }

  if (modal.tariff === 'NKSelectS') {
    return '(Standard plan)';
  }

  if (modal.tariff === 'NKSelectL') {
    return '(Plus plan)';
  }

  if (modal.tariff === 'NKSelectXL') {
    return '(Premium plan)';
  }

  return '';
};

interface TableModalProps {
  modal: Modal | ModalWithTracking;
  setModal: (value: Modal | ModalWithTracking | null) => void;
}

export const TableModal = ({ modal, setModal }: TableModalProps) => {
  const handleClose = () => setModal(null);
  const subtitle = modal.subtitle || getPlanNameFromModalData(modal);

  return (
    <BottomOrRegularModal isOpen title={modal.title} onClose={handleClose}>
      <div className={`${modal.wide ? 'wmx8' : 'wmx6'} ${styles.container}`}>
        {subtitle && <p className="p-h3 tc-grey-500 mb16">{subtitle}</p>}
        {typeof modal.children === 'string' ? (
          <Markdown>{modal.children}</Markdown>
        ) : (
          modal.children
        )}
      </div>
    </BottomOrRegularModal>
  );
};
