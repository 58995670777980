import { getRoutes } from '@getpopsure/qnr-framework';
import { AxiosResponse } from 'axios';
import routes from 'constants/routes';
import dayjs from 'dayjs';
import { Beneficiary } from 'features/life/models';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useParams, useRouteMatch } from 'react-router';
import { AppState } from 'reducers';
import { UploadedFile } from 'shared/models/types';
import {
  SignupQuestionnaire,
  SignupQuestionnaireType,
} from 'SignupQuestionnaire';
import { v4 as uuidv4 } from 'uuid';

import {
  flushBeneficiariesAnswers,
  storeAddBeneficiariesAnswers,
} from './actions';
import Review from './components/Review';
import { Submitted } from './components/Submitted';
import { WelcomeScreen } from './components/WelcomeScreen';
import { useCreateBeneficiaries } from './hooks/useCreateBeneficiaries';
import { EditBeneficiaries } from './models';

export type BeneficiariesGroupIds = 'questionnaire';

export const BeneficiariesComponents = {
  REVIEW: Review,
  SUBMITTED: Submitted,
  WELCOME: WelcomeScreen,
} as const;

export type BeneficiariesQuestionnaire = SignupQuestionnaireType<
  EditBeneficiaries,
  BeneficiariesGroupIds,
  typeof BeneficiariesComponents
>;

export const addNewBeneficiary = (
  questionnaireAnswers: Partial<EditBeneficiaries>,
  currentAnswer: UploadedFile[]
): Beneficiary[] => {
  if (!questionnaireAnswers.name) {
    return questionnaireAnswers.beneficiaries ?? [];
  }

  const beneficiary = {
    id: uuidv4(),
    name: questionnaireAnswers.name,
    dateOfBirth: questionnaireAnswers.dateOfBirth ?? '',
    uploads: currentAnswer,
  };
  return (questionnaireAnswers.beneficiaries ?? []).concat(beneficiary);
};

export const editExistingBeneficiary = (
  questionnaireAnswers: Partial<EditBeneficiaries>,
  currentAnswer: UploadedFile[]
) => {
  return (
    questionnaireAnswers.beneficiaries?.map((beneficiary) => {
      if (beneficiary.id === questionnaireAnswers.currentBeneficiaryId) {
        return {
          ...beneficiary,
          name: questionnaireAnswers.name ?? {
            firstName: '',
            lastName: '',
          },
          dateOfBirth: questionnaireAnswers.dateOfBirth ?? '',
          uploads: currentAnswer,
        };
      }
      return beneficiary;
    }) ?? []
  );
};

export const getQuestionnaire = (
  questionnaireType: 'add' | 'edit',
  submitCreateBeneficiariesRequest: () => Promise<AxiosResponse<{ id: string }>>
): BeneficiariesQuestionnaire => {
  return [
    {
      id: 'intro',
      type: 'WELCOME',
      props: {
        questionnaireType,
      },
      required: true,
      screen: {
        layout: 'Standalone',
      },
      groupId: 'questionnaire',
    },
    {
      id: 'name',
      type: 'NAME',
      required: true,
      screen: {
        question: 'What is their name?',
      },
      props: {},
      groupId: 'questionnaire',
    },
    {
      id: 'dateOfBirth',
      type: 'DATE',
      required: true,
      screen: {
        question: 'When were they born?',
      },
      props: {
        yearRange: {
          min: dayjs().subtract(150, 'years').year(),
          max: dayjs().year(),
        },
      },
      groupId: 'questionnaire',
    },
    {
      id: 'uploads',
      type: 'UPLOAD',
      required: true,
      props: {
        showLegalNotice: false,
      },
      screen: {
        question: 'Upload a copy of their passport or identity card',
        description:
          'It is required to ensure they can claim the benefit without any complications.',
      },
      groupId: 'questionnaire',
    },
    {
      id: 'review',
      required: true,
      type: 'REVIEW',
      props: {},
      screen: {
        question: 'Your beneficiaries',
        description:
          'You can add multiple beneficiaries and split the payout sum between them as you wish. ',
        noMaxWidth: true,
        continueButtonText: 'Submit',
      },
      groupId: 'questionnaire',
    },
    {
      id: 'policyHolderIdUpload',
      type: 'UPLOAD',
      required: true,
      props: {
        showLegalNotice: false,
        maxFiles: 1,
      },
      screen: {
        question: 'Please upload a copy of your passport or identity card',
        description:
          'It is required to confirm your identity and add or update your beneficiaries.',
      },
      groupId: 'questionnaire',
    },
    {
      id: 'processing',
      required: true,
      type: 'PROCESSING',
      props: {
        textList: ['Processing request'],
        requestFn: submitCreateBeneficiariesRequest,
      },
      screen: {
        layout: 'Standalone',
      },
      groupId: 'questionnaire',
    },
    {
      id: 'submitted',
      required: true,
      type: 'SUBMITTED',
      props: {},
      screen: {
        layout: 'Standalone',
      },
      groupId: 'questionnaire',
    },
  ];
};

export const AddBeneficiaries = () => {
  const { url } = useRouteMatch();

  const questionnaireAnswers =
    useSelector((state: AppState) => state.lifeBeneficiaries.questionnaire) ||
    {};
  const dispatch = useDispatch();
  const { policyId } = useParams<{ policyId: string }>();

  const flushAnswers = useCallback(() => {
    dispatch(flushBeneficiariesAnswers());
  }, [dispatch]);

  useEffect(() => {
    return flushAnswers;
  }, [policyId, dispatch, flushAnswers]);

  const onAnswer = <QuestionId extends keyof EditBeneficiaries>(
    questionId: QuestionId,
    answer: EditBeneficiaries[QuestionId]
  ) => {
    if (questionId === 'uploads' && questionnaireAnswers.name) {
      if (!questionnaireAnswers.currentBeneficiaryId) {
        dispatch(
          storeAddBeneficiariesAnswers({
            ...questionnaireAnswers,
            [questionId]: answer,
            beneficiaries: addNewBeneficiary(
              questionnaireAnswers,
              answer as UploadedFile[]
            ),
          })
        );
      } else {
        dispatch(
          storeAddBeneficiariesAnswers({
            ...questionnaireAnswers,
            [questionId]: answer,
            beneficiaries: editExistingBeneficiary(
              questionnaireAnswers,
              answer as UploadedFile[]
            ),
          })
        );
      }
    } else {
      dispatch(
        storeAddBeneficiariesAnswers({
          ...questionnaireAnswers,
          [questionId]: answer,
        })
      );
    }
  };

  const { createBeneficiaries } = useCreateBeneficiaries(
    policyId,
    questionnaireAnswers.beneficiaries ?? [],
    questionnaireAnswers.policyHolderIdUpload?.[0].token ?? ''
  );

  const questions = getQuestionnaire(
    questionnaireAnswers.currentBeneficiaryId ? 'edit' : 'add',
    createBeneficiaries
  );

  const questionnaire = {
    components: questions,
    routes: getRoutes(questions, url),
    rules: [],
  };

  return (
    <Switch>
      <Route path={routes.me.policies.life.beneficiaries.questionnaire.path}>
        <SignupQuestionnaire
          questionnaireAnswers={questionnaireAnswers}
          questionnaire={questionnaire}
          onAnswer={onAnswer}
          configuration={{
            components: BeneficiariesComponents,
          }}
          basePath={url}
          questionId="intro"
          featureName="EditBeneficiaries"
        />
      </Route>
    </Switch>
  );
};
