import { EyeVisionIcon, FileIcon, XIcon } from '@popsure/dirty-swan';
import dayjs from 'dayjs';
import { HealthCardPhoto } from 'models/healthCard';

import style from './style.module.scss';

interface PhotoPreviewProps {
  photo: HealthCardPhoto;
  onDelete: () => void;
}

const PhotoPreview = (props: PhotoPreviewProps) => {
  const { photo, onDelete } = props;
  const date = dayjs(photo.markedUploadedAt);

  return (
    <div className={`d-flex ${style.container}`}>
      <FileIcon className="mr16" size={32} color="primary-500" />
      <p className={`p-p ${style.name}`}>
        Photo uploaded on {date.format('MMM DD, YYYY')} at{' '}
        {date.format('HH:mm')}
      </p>
      <a
        className={`p-link ml-auto d-flex ${style.button}`}
        rel="noopener noreferrer"
        target="_blank"
        href={photo.publicUrl}
        aria-label="view uploaded file"
      >
        <EyeVisionIcon size={24} color="primary-500" />
      </a>
      <button
        aria-label="Delete uploaded image"
        data-cy="photo-upload-delete-button"
        className={`d-flex c-pointer ${style.button}`}
        onClick={onDelete}
        type="button"
      >
        <XIcon size={24} color="primary-500" />
      </button>
    </div>
  );
};

export default PhotoPreview;
