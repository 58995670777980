import {
  blog,
  calendly,
  insurance,
  website,
} from '@getpopsure/private-constants';
import { FAQInfo } from 'components/faq';

export const faqContent = (eligibleForShortTerm?: boolean): FAQInfo[] => [
  ...longTermFAQContent,
  ...(eligibleForShortTerm ? shortTermFAQContent : []),
];

export const longTermFAQContent: FAQInfo[] = [
  {
    questionId: 1,
    question: 'How does Feather help me?',
    answer:
      'What you can expect from our English speaking brokerage team:\n* Fully digital sign-up\n* All necessary insurance documents for your visa, employer, and tax declaration\n* Support for changing your coverage or type of insurance\n* Fully managing your private insurance cover: changing the coverage, start date, address, bank details, cancellation & more\n* Step-by-step help for adding family members to your plan\n* Support during the claims process\n* Help with payment issues\n* Help with mail from provider\n*  Individual personal support through chat or video call for any issue related to your private insurance coverage',
  },
  {
    questionId: 2,
    question: 'Is there a cancellation period for private insurance?',
    answer:
      "You can cancel right away in the following cases:\n- You're moving within the EU, and you are now part of a mandatory insurance system\n- You're leaving the EU\n\nWant to cancel for a different reason? Under the short-term plan, there is a 15-day notice period toward the end of the month. Under the regular plans, you can cancel earliest after 24 months with a 3-month notice period.",
  },
  {
    questionId: 3,
    question: 'How does the monthly payment work?',
    answer:
      'The total monthly cost will be deducted from your bank account. Employers pay approximately 50% of your cost by transferring their monthly share to your bank account (usually as part of your salary).',
  },
  {
    questionId: 4,
    question: 'When will I know more about my application?',
    answer: `We are always checking each individual application to make sure that you’re going to be covered or not. We will keep you informed throughout the process. If you have any further questions, please feel free to [contact us](${website.support}).`,
  },
  {
    questionId: 5,
    question: 'Can private health insurance companies deny coverage?',
    answer:
      'Yes, you can be denied coverage by private health insurance companies. The most common reasons for a denial are the following pre-existing health conditions: ADHD, diabetes, arthritis, autoimmune diseases, asthma, cancer and mental health conditions. Other health conditions can lead to risk surcharges or exclusions of coverage.\n\nIf you cannot be covered or if your coverage needs to be modified, our team will reach out to you with step by step guidance, so you can take the decision that is right for you.',
  },
  {
    questionId: 6,
    question: 'How will the price change over time?',
    answer: `There is no annual price increase based on age. The price can only change, if the medical cost increases across Germany, if there are changes in interest rates or if statistics show that people are living longer than previously expected (and thus also need health insurance for longer). In short: you can expect a price increase comparable to general inflation. Good to know: the cost for public health insurance increases in the same way.\n\nCheck out [our article](${`${blog.base}private-premium-increase`}) to see how price increases work in more detail or [schedule a call with an expert](${
      calendly.healthInsuranceAdvicePriority
    }).`,
  },
  {
    questionId: 7,
    question: 'How can I switch between public and private health insurance?',
    answer: `To cancel public health insurance, you will go through 3 steps:\n\n 1. Hand in a [cancelation form](https://docs.google.com/document/d/1i8-vRkDsEMxPWmNvfDiFCTaX5Riq61bFzT5_-Uu2_wI) to your public provider. This will kick off the cancellation period, which is typically 2 months plus the ongoing month. _Example: You hand in the cancelation form on January 15th, so your private insurance can start earliest on April 1st._\n2. Apply for private health insurance.\n3. Forward the confirmation of private health coverage to your public provider. This confirmation document is uploaded automatically to your Feather account after your coverage is confirmed.\n\nCheck out our [article](${blog.base}switching-private-public-mythbusters) to see how you can switch from private to public insurance!`,
  },
  {
    questionId: 8,
    question: 'Can I insure my family with private insurance?',
    answer:
      'Every family is different, so we are happy to have a quick chat about your needs. Since every additional family member in private insurance means additional cost we want to help you figure out the best option. Keep in mind that pre-existing medical conditions might influence whether they can access private health insurance.\n\nYour parents can be co-insured if they have no income and meet all health requirements.\n\nYour spouse can be co-insured if they do not earn more than €470 per month and meet all health requirements. If you are not married to your partner, you cannot get them covered through your private insurance plan.\n\nYour kids can be co-insured. Tip: if you are also eligible for public insurance and have several kids, public insurance might be a better idea since you can insure them cost-free there.',
  },
];

export const shortTermFAQContent: Array<FAQInfo> = [
  {
    questionId: 9,
    question: 'How can I switch to long-term private coverage?',
    answer: `If you start out with the short-term coverage, you will only need to switch to the long-term coverage if you stay in Germany for longer than 5 years. Our team will then happily assist you with the switch and your pre-existing conditions will be covered.\n\nIf you want to apply to the long-term cover right away, you can look at it [here](${insurance.privateHealth.preSignup}/quote?light=eligible).`,
  },
  {
    questionId: 10,
    question:
      'What happens if I do end up staying in Germany for more than 5 years?',
    answer:
      'You can easily switch to our long-term private health coverage after you have spent 5 years in Germany.\n\nSo, what’s the difference between the two?\nEvery long-term private health coverage includes a component called “old-age savings”, but the short-term coverage does not - which is why the monthly premium is extremely affordable.\n\nWhat do old-age savings do? Typically, you need more medical assistance as you get older. But obviously, you do not want your private health to skyrocket. That is why the long-term health cover collects savings that ensure your private health cover stays affordable as you get older. That’s why, if you’re planning on staying in Germany only for a short period, you do not need this component. So, if you end up staying longer, you can simply upgrade.',
  },
  {
    questionId: 11,
    question:
      'Will my private short-term coverage fulfill the requirement for my blue card/residence permit application?',
    answer:
      'Private short-term coverage fulfills the requirements for blue card applications.',
  },
  {
    questionId: 12,
    question: 'How can I switch between public and private health insurance?',
    answer: `To cancel public health insurance, you will go through 3 steps:\n\n 1. Hand in a [cancelation form](https://docs.google.com/document/d/1i8-vRkDsEMxPWmNvfDiFCTaX5Riq61bFzT5_-Uu2_wI) to your public provider. This will kick off the cancellation period, which is typically 2 months plus the ongoing month. _Example: You hand in the cancelation form on January 15th, so your private insurance can start earliest on April 1st._\n2. Apply for private health insurance.\n3. Forward the confirmation of private health coverage to your public provider. This confirmation document is uploaded automatically to your Feather account after your coverage is confirmed.\n\nCheck out our [article](${blog.base}switching-private-public-mythbusters) to see how you can switch from private to public insurance!`,
  },
  {
    questionId: 13,
    question: 'Can private health insurance companies deny coverage?',
    answer:
      'Yes, you can be denied coverage by private health insurance companies. The most common reasons for a denial are the following pre-existing health conditions: ADHD, diabetes, arthritis, autoimmune diseases, asthma, cancer and mental health conditions. Other health conditions can lead to risk surcharges or exclusions of coverage.\n\nIf you cannot be covered or if your coverage needs to be modified, our team will reach out to you with step by step guidance, so you can take the decision that is right for you.',
  },
  {
    questionId: 14,
    question: 'How does the monthly payment work?',
    answer:
      'The total monthly cost will be deducted from your bank account. Employers pay approximately 50% of your cost by transferring their monthly share to your bank account.\n\nWhy is the payment not automatically done by your employer when you receive your salary? You are the legal owner of your own private insurance policy, so your bank account must be used.',
  },
  {
    questionId: 15,
    question: 'When will I know more about my application?',
    answer:
      'You will already hear back within a couple of days. In case the insurance provider asks for additional information, our team will assist you.\n\nIf you have not had comprehensive health insurance in the past 12 months, medical examinations might be needed. In this case, our team will automatically reach out and help you set up the appointments.',
  },
];
