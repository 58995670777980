import { email } from '@getpopsure/private-constants';
import { BottomOrRegularModal, Button } from '@popsure/dirty-swan';
import AnimateHeight from 'react-animate-height';

interface ConfirmReceivedHealthCardModalProps {
  onMarkAsReceived(): void;
  onCancel(): void;
  loading?: boolean;
  error?: unknown;
}

export const ConfirmReceivedHealthCardModal = (
  props: ConfirmReceivedHealthCardModalProps
) => {
  const { loading, onMarkAsReceived, onCancel, error } = props;
  return (
    <BottomOrRegularModal title="Are you sure" isOpen onClose={onCancel}>
      <div className="p24">
        <p className="p-p mb24">Confirm that you received your health card.</p>
        <Button
          loading={loading}
          onClick={onMarkAsReceived}
          className="mr8"
          data-cy="confirm-health-card-as-received"
        >
          Yes, I received it
        </Button>
        <Button
          variant="textColor"
          onClick={onCancel}
          data-cy="cancel-confirm-health-card-as-received"
        >
          Cancel
        </Button>
      </div>
      <AnimateHeight duration={150} height={error ? 'auto' : 0}>
        <div className="p-notice p-notice--danger mt8 p-p">
          {`An error occurred while confirming your health card was received. Please try
            again or contact us at ${email.help}`}
        </div>
      </AnimateHeight>
    </BottomOrRegularModal>
  );
};
