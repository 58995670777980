import * as Sentry from '@sentry/react';
import { useMutation } from '@tanstack/react-query';
import { ClaimSubmitInfo } from 'models/claims';
import endpoint from 'shared/api';

import { triggerPushNotifications } from '../../../shared/pushNotifications';
import { ExpatSpainClaim, ZSubmitClaimInfo } from '../models';

export const useSubmitExpatSpainClaim = (
  currentAnswer: Partial<ExpatSpainClaim>,
  currentPolicyId: string
) => {
  const onSubmitClaim = ({
    answers,
    policyId,
  }: {
    answers: Partial<ExpatSpainClaim>;
    policyId: string;
  }) => {
    if (!policyId) {
      throw new Error(
        '[Expat Spain Submit Claim] Policy ID is missing for submitting claim.'
      );
    }

    const validatedData = ZSubmitClaimInfo.parse({
      ...answers,
    });

    const isCostPlanClaim = validatedData.claimType === 'COST_PLAN';

    const claimInfo: ClaimSubmitInfo = {
      userPolicyId: policyId,
      claimTypes: [
        {
          claimType: validatedData.claimType,
          additionalInfo: validatedData.additionalInfo,
        },
      ],
      uploadTokens: validatedData.uploadDocuments.map((file) => file.token),
      amount: isCostPlanClaim ? undefined : validatedData.payoutDetails.amount,
      iban: isCostPlanClaim ? undefined : validatedData.payoutDetails.iban,
      details: validatedData.diagnosis,
    };

    return endpoint.submitClaim(claimInfo);
  };

  const { mutateAsync } = useMutation({
    mutationFn: onSubmitClaim,
    onError: (error, variables) => {
      const { policyId, answers } = variables;
      Sentry.captureException(error, {
        tags: {
          feature: 'CLAIMS',
          vertical: 'INCOMING_ES',
        },
        extra: {
          policyId,
          questionnaire: answers,
          description: 'Error happened while submitting Expat Spain claim',
        },
      });
    },
    onSuccess: () => {
      triggerPushNotifications('INCOMING_ES');
    },
  });

  const submitClaimRequest = () =>
    mutateAsync({
      answers: currentAnswer,
      policyId: currentPolicyId,
    });

  return { submitClaimRequest };
};
