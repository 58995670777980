import { BottomOrRegularModal, Button, Radio } from '@popsure/dirty-swan';
import { mapping } from 'components/cardBrand/icons';
import {
  makePaymentMethodDefault,
  PaymentMethodDispatch,
} from 'features/paymentMethods/paymentMethods.actions';
import { CheckoutContext } from 'features/paymentScreenV2/checkout.context';
import { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'reducers';
import { getRequestInProcess } from 'selectors/requests';
import { useSafeTranslation } from 'shared/i18n';
import {
  formatCardExpiryDate,
  formatCardLastDigits,
} from 'shared/util/cardFormatters';

import styles from './style.module.scss';

export const SetNewDefaultModal = () => {
  const { t } = useSafeTranslation();
  const {
    currentUserPaymentMethods: paymentOptions,
    newDefaultOption: setDefaultOption,
    setNewDefaultOption: setSetDefaultOption,
  } = useContext(CheckoutContext);

  const [selectedPaymentOption, setSelectedPaymentOption] = useState<
    string | undefined
  >();

  const isUpdatingDefaultPaymentMethod = useSelector((state: AppState) =>
    getRequestInProcess(state, 'MAKE_PAYMENT_METHOD_A_DEFAULT')
  );

  const dispatch = useDispatch<PaymentMethodDispatch>();

  const radioPaymentOptions = paymentOptions.reduce(
    (acc, radioPaymentOption) => {
      return {
        ...acc,
        [radioPaymentOption.id]: (
          <div className="d-flex ai-center jc-between w100">
            <div className="d-flex ai-center">
              <img
                alt={radioPaymentOption?.attributes.brand}
                className={`${styles.paymentMethodIcon}`}
                src={mapping[radioPaymentOption?.attributes.brand]}
              />
              <p
                className={`p-p tc-grey-900 ${styles.paymentMethodIdentifier}`}
              >
                {radioPaymentOption.type === 'PAYPAL'
                  ? radioPaymentOption?.attributes.identifier ?? 'PayPal'
                  : formatCardLastDigits(
                      radioPaymentOption?.attributes.identifier
                    )}
              </p>
            </div>
            {radioPaymentOption.attributes.expiry && (
              <p className={`p-p tc-grey-600 ml24 ${styles.expireDate}`}>
                {formatCardExpiryDate(radioPaymentOption.attributes.expiry)}
              </p>
            )}
          </div>
        ),
      };
    },
    {}
  );

  const handleMakeDefault = async (paymentMethodId?: string) => {
    if (!paymentMethodId) return;
    await dispatch(makePaymentMethodDefault(paymentMethodId));
    setSetDefaultOption(false);
    setSelectedPaymentOption(undefined);
  };

  return (
    <BottomOrRegularModal
      title={t(
        'paymentScreen.defaultPaymentModal.modal.title',
        'Set a new payment method'
      )}
      onClose={() => {
        setSetDefaultOption(false);
        setSelectedPaymentOption(undefined);
      }}
      dismissible={true}
      isOpen={setDefaultOption}
    >
      <div className="px24 py16">
        <p className="tc-grey-600 p-p mb24">
          {t(
            'paymentScreen.defaultPaymentModal.modal.description',
            'This method will be used for all current and future policies. You can update this anytime in account settings.'
          )}
        </p>
        <Radio
          wide
          bordered={false}
          options={radioPaymentOptions}
          onChange={setSelectedPaymentOption}
          value={selectedPaymentOption}
          classNames={{
            container: 'mb24',
            label: styles.label,
          }}
        />
        <Button
          className="w100"
          onClick={() => handleMakeDefault(selectedPaymentOption)}
          disabled={!selectedPaymentOption}
          loading={isUpdatingDefaultPaymentMethod}
          data-cy="button-continue"
        >
          {t(
            'paymentMethodSelector.defaultPaymentModal.button.title',
            'Continue'
          )}
        </Button>
      </div>
    </BottomOrRegularModal>
  );
};
