import { insurance } from '@getpopsure/private-constants';
import {
  getRoutes,
  QuestionnaireQuestions,
  RemoveAnswerType,
  Rule,
  stateManagerHelper,
} from '@getpopsure/qnr-framework';
import { useFlag } from '@unleash/proxy-client-react';
import {
  removeGenericQuestionnaireAnswer,
  storeGenericQuestionnaireAnswer,
} from 'actions/genericQuestionnaire';
import routes from 'constants/routes';
import dayjs from 'dayjs';
import { UserWithBrokerMandate } from 'models/user';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  generatePath,
  Route,
  Switch,
  useHistory,
  useParams,
} from 'react-router-dom';
import { AppState } from 'reducers';
import { getAccountInfo } from 'selectors/user';
import { useSafeTranslation } from 'shared/i18n';
import { SignupQuestionnaire } from 'SignupQuestionnaire';

import { RegionSelector } from './components/RegionSelector/RegionSelector';
import { ExpatEu, isValidRegion, regionToNameMapping } from './models';
import { ExpatEuComponents, getTranslatedQuestionnaire } from './questionnaire';

export const YEARLY_MIN_INCOME_THRESHOLD =
  12 * insurance.healthInsurance.miniJobThreshold;

const getRules = (isOver18: boolean): Rule<ExpatEu>[] => [
  {
    id: 'dateOfBirth',
    if: {
      op: 'dateDiff',
      variable: { type: 'years', value: 75 },
    },
    then: {
      goTo: 'ageIs75AndAboveBlocker',
    },
  },
  {
    id: 'occupation',
    if: () => {
      if (isOver18) {
        return true;
      }
      return false;
    },
    then: {
      goTo: 'addFamilyMembers',
    },
    else: {
      goTo: 'quoteOptions',
    },
  },
  {
    id: 'arrivalDate',
    if: {
      op: 'dateDiff',
      variable: {
        type: 'month',
        value: 58,
        dir: 'gt',
      },
    },
    then: {
      goTo: 'moreThanFiveYearsBlocker',
    },
  },
  {
    id: 'arrivalDate',
    if: [
      'AND',
      [
        {
          op: 'dateDiff',
          variable: {
            type: 'month',
            value: 47,
            dir: 'gt',
          },
        },
        {
          op: 'dateDiff',
          variable: {
            type: 'month',
            value: 59,
            dir: 'lt',
          },
        },
      ],
    ],
    then: {
      goTo: 'betweenFourAndFiveYearsBlocker',
    },
  },
  {
    id: 'occupation',
    if: {
      op: 'equals',
      variable: { type: 'static', value: 'EMPLOYED' },
    },
    then: {
      goTo: 'regionOfEmployment',
    },
    else: {
      goTo: 'quoteOptions',
    },
  },
  {
    id: 'regionOfEmployment',
    if: {
      op: 'equals',
      variable: { type: 'static', value: 'YES' },
    },
    then: {
      goTo: 'employedInRegionBlocker',
    },
    else: {
      goTo: isOver18 ? 'addFamilyMembers' : 'quoteOptions',
    },
  },
  {
    id: 'employedInRegionBlocker',
    if: {
      op: 'equals',
      variable: { type: 'static', value: true },
    },
    then: {
      goTo: isOver18 ? 'addFamilyMembers' : 'quoteOptions',
    },
  },
  {
    id: 'legalGuardianName',
    if: {
      op: 'always',
    },
    then: {
      goTo: 'legalGuardianDateOfBirth',
    },
  },
  {
    id: 'legalGuardianDateOfBirth',
    if: {
      op: 'always',
    },
    then: {
      goTo: 'legalGuardianEmail',
    },
  },
  {
    id: 'startDate',
    if: () => {
      if (isOver18) {
        return true;
      }
      return false;
    },
    then: {
      goTo: 'review',
    },
    else: {
      goTo: 'legalGuardianName',
    },
  },
  {
    id: 'legalGuardianName',
    if: {
      op: 'always',
    },
    then: {
      goTo: 'legalGuardianDateOfBirth',
    },
  },
  {
    id: 'legalGuardianDateOfBirth',
    if: {
      op: 'always',
    },
    then: {
      goTo: 'legalGuardianEmail',
    },
  },
];

const removeReview: RemoveAnswerType<ExpatEu> = {
  op: 'always',
  questions: ['review'],
};

const removeAnswersLogic: Partial<
  Record<keyof ExpatEu, RemoveAnswerType<ExpatEu>>
> = {
  dateOfBirth: {
    op: 'always',
    questions: [
      'quoteOptions',
      'quote',
      'legalGuardianName',
      'legalGuardianDateOfBirth',
      'legalGuardianEmail',
      'review',
    ],
  },
  occupation: {
    op: 'always',
    questions: ['quoteOptions', 'quote'],
  },
  quote: removeReview,
  name: removeReview,
  email: removeReview,
  review: {
    op: 'always',
    questions: ['hasDownloadedDocs'],
  },
};

const prefillNameAndBirthDate = <
  Questionnaire extends QuestionnaireQuestions,
  Key extends keyof Questionnaire
>(
  questionnaireAnswers: Partial<Questionnaire>,
  questionId: Key,
  answer: Questionnaire[Key],
  account?: UserWithBrokerMandate
) => {
  if (
    questionId === 'email' &&
    account &&
    account.email === answer &&
    questionnaireAnswers.email !== answer
  ) {
    let toUpdate: Partial<ExpatEu> = {};
    const { firstName, lastName } = account;

    if (firstName && lastName) {
      toUpdate = { ...toUpdate, name: { firstName, lastName } };
    }
    return toUpdate;
  }

  return null;
};

export const SignupPage = () => {
  const questionnaireAnswers =
    useSelector((state: AppState) => state.genericQuestionnaire.expatEu) || {};
  const dispatch = useDispatch();
  const account = useSelector(getAccountInfo);
  const { t } = useSafeTranslation();
  const history = useHistory();
  const useNewCheckout = useFlag('app_checkout_revamp_incoming_eu');

  const { localeId } = useParams<{ localeId?: string }>();

  useEffect(() => {
    dispatch(storeGenericQuestionnaireAnswer('expatEu', { region: localeId }));
  }, [localeId, dispatch]);

  if (!localeId || !isValidRegion(localeId)) {
    return <RegionSelector />;
  }

  const onAnswer = <QuestionId extends keyof ExpatEu>(
    questionId: QuestionId,
    answer: ExpatEu[QuestionId]
  ) => {
    dispatch(
      storeGenericQuestionnaireAnswer('expatEu', {
        [questionId]: answer,
        ...(questionId === 'intro' ? { isPolicyHolder: true } : {}),
      })
    );
    const answersToRemove = stateManagerHelper(
      removeAnswersLogic,
      questionnaire.components,
      questionnaireAnswers,
      questionnaire.rules
    ).getAnswersToRemove(questionId, answer);

    dispatch(removeGenericQuestionnaireAnswer('expatEu', answersToRemove));

    const toUpdate = prefillNameAndBirthDate(
      questionnaireAnswers,
      questionId,
      answer,
      account
    );
    if (toUpdate) {
      dispatch(storeGenericQuestionnaireAnswer('expatEu', toUpdate));
    }
  };

  const isOver18 = questionnaireAnswers.dateOfBirth
    ? dayjs().diff(questionnaireAnswers.dateOfBirth, 'years') >= 18
    : false;

  const continueFromBlocker = () => {
    dispatch(
      storeGenericQuestionnaireAnswer('expatEu', {
        employedInRegionBlocker: true,
      })
    );
    history.push(
      isOver18
        ? expatEuRoutes.addFamilyMembers.path
        : expatEuRoutes.quoteOptions.path
    );
  };

  const continueFromBetweenFourAndFiveYearsBlocker = () => {
    dispatch(
      storeGenericQuestionnaireAnswer('expatEu', {
        betweenFourAndFiveYearsBlocker: true,
      })
    );
    history.push(expatEuRoutes.occupation.path);
  };

  const rules = getRules(isOver18);

  // TODO: Move this country-handling logic where it's centralized, translatable and reusable (f.e. i18n library)
  const region =
    localeId === 'nl' ? 'the Netherlands' : regionToNameMapping[localeId];

  const questions = getTranslatedQuestionnaire(
    t,
    region,
    localeId,
    continueFromBlocker,
    continueFromBetweenFourAndFiveYearsBlocker,
    useNewCheckout
  );

  const basePath = generatePath(routes.policies.expatEu.path, {
    localeId: String(localeId),
  });

  const baseQuestionnairePath =
    routes.policies.expatEu.questionnaire.path.replace(
      ':localeId',
      String(localeId)
    );

  const expatEuRoutes = getRoutes(questions, basePath);

  const questionnaire = {
    components: questions,
    routes: expatEuRoutes,
    rules,
  };

  return (
    <Switch>
      <Route path={baseQuestionnairePath}>
        <SignupQuestionnaire
          questionnaireAnswers={questionnaireAnswers}
          questionnaire={questionnaire}
          onAnswer={onAnswer}
          configuration={{
            components: ExpatEuComponents,
          }}
          basePath={basePath}
          questionId="intro"
          featureName="ExpatEu"
          share={{
            verticalId: 'expatEu',
          }}
        />
      </Route>
    </Switch>
  );
};
