import {
  getRoutes,
  QuestionnaireQuestions,
  removeAnswers,
  stateManagerHelper,
} from '@getpopsure/qnr-framework';
import routes from 'constants/routes';
import { interpolateData } from 'features/generalQuestionnaire/functions';
import { QuestionnaireJSON } from 'features/generalQuestionnaire/model';
import { Policy } from 'models/policies';
import { useEffect } from 'react';
import { Route, Switch } from 'react-router';
import { SignupQuestionnaire } from 'SignupQuestionnaire';

import { GeneralReview } from '../generalPolicy/components/Review/Review';
import { ClaimProcessing } from './components/ClaimProcessing/ClaimProcessing';
import { PreviewClaimProcessing } from './components/ClaimProcessing/PreviewClaimProcessing';
import { GeneralClaimAnswers } from './models';
import { useGeneralClaimsStorage } from './storage';

export type ClaimGroupIds = 'questionnaire';

type CommonClaimProps = {
  data: QuestionnaireJSON;
  policy: Policy;
  onQuestionnaireAnswersChanged?: (answers: QuestionnaireQuestions) => void;
  verticalId: string;
  isPreview?: boolean;
};

export const GeneralClaimsQuestionnaire = ({
  /**
   * TODO: [KONG] Add components
   */
  data,
  policy,
  onQuestionnaireAnswersChanged,
  verticalId,
  isPreview = false,
}: CommonClaimProps) => {
  const { meta, questions, rules: preRules, removeAnswersLogic = {} } = data;

  // TODO: [KONG] Handle remove questionnaire
  const { questionnaireAnswers, setQuestionnaireAnswers } =
    useGeneralClaimsStorage(verticalId);

  useEffect(() => {
    if (typeof onQuestionnaireAnswersChanged !== 'undefined') {
      onQuestionnaireAnswersChanged?.(questionnaireAnswers);
    }
  }, [onQuestionnaireAnswersChanged, questionnaireAnswers]);

  const onAnswer = (
    questionId: string,
    answer: unknown,
    additionalData: Partial<Record<string, unknown>> = {}
  ) => {
    const answersToRemove = stateManagerHelper(
      removeAnswersLogic,
      questionnaire.components,
      questionnaireAnswers,
      questionnaire.rules
    ).getAnswersToRemove(questionId, answer) as (keyof GeneralClaimAnswers)[];

    const nextState = removeAnswers(
      {
        ...questionnaireAnswers,
        ...additionalData,
        [questionId]: answer,
      },
      answersToRemove
    );

    setQuestionnaireAnswers(nextState);
  };

  const rules = interpolateData<QuestionnaireJSON['rules']>(preRules, {
    answers: questionnaireAnswers,
    constants: {
      ...meta.constants,
    },
  });

  const basePath = isPreview
    ? routes.birdhouse.preview.claim.path
    : routes.claims.general.path;

  const populatedBasePath = basePath
    .replace(':verticalId', verticalId)
    .replace(':policyId', policy.id);

  const questionnairePath = isPreview
    ? routes.birdhouse.preview.claim.questionnaire.path
    : routes.claims.general.questionnaire.path;

  const populatedQuestionnairePath = questionnairePath
    .replace(':verticalId', verticalId)
    .replace(':policyId', policy.id);

  const questionnaire = {
    components: questions,
    routes: getRoutes(questions, populatedBasePath),
    rules,
  };

  return (
    <Switch>
      <Route path={populatedQuestionnairePath}>
        <SignupQuestionnaire
          /**
           * TODO: [KONG] Add components, debug
           */
          questionnaireAnswers={questionnaireAnswers}
          questionnaire={questionnaire}
          onAnswer={onAnswer}
          configuration={{
            policyId: policy.id,
            components: {
              REVIEW: GeneralReview,
              CLAIM_PROCESSING: isPreview
                ? PreviewClaimProcessing
                : ClaimProcessing,
              questions,
            },
            questions,
            debug: isPreview,
          }}
          basePath={populatedBasePath}
          questionId="intro"
          featureName={verticalId}
        />
      </Route>
    </Switch>
  );
};
