import { Region } from '@getpopsure/public-models';
import {
  QuestionnaireFormProps,
  QuestionnaireQuestions,
} from '@getpopsure/qnr-framework';
import { getTrackingObject } from '@getpopsure/tracker';
import LoadingSpinner from 'components/loadingSpinner';
import routes from 'constants/routes';
import { mergeCheckoutInfo } from 'features/paymentScreen/paymentScreen.actions';
import {
  linkToStripe,
  startCheckout,
} from 'features/paymentScreen/paymentScreen.api';
import { PaymentScreenThunkDispatch } from 'features/paymentScreen/paymentScreen.thunks';
import lz from 'lz-string';
import { InsuranceTypes, PolicyInfoKind } from 'models/insurances/types';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { generatePath, useHistory } from 'react-router';
import api from 'shared/api';
import { useSafeTranslation } from 'shared/i18n';
import { paramsSetUrl } from 'shared/util/paramsSetUrl';

import {
  getStartDate,
  PolicyDetailsConfiguration,
  PriceBreakdownConfiguration,
  processPolicyDetailsData,
  processPolicyDetailsQuestionValue,
  processPriceBreakdown,
} from './utils';

export const GenericCheckoutV2 = <
  Questionnaire extends QuestionnaireQuestions
>({
  type,
  questionnaireAnswers,
  region,
  policyInfoKind,
  hasCheckoutDocuments,
  changeStartDatePath,
  redirectAddDependent,
  policyDetailsConfiguration,
  priceBreakdownConfiguration,
  mainPolicyId,
  genericQuestionnaireKey,
  productName,
  planQuestionId,
}: QuestionnaireFormProps<Questionnaire> & {
  type: InsuranceTypes;
  region: Region;
  policyInfoKind?: PolicyInfoKind;
  hasCheckoutDocuments?: boolean;
  changeStartDatePath?: string;
  redirectAddDependent?: keyof Questionnaire | boolean;
  policyDetailsConfiguration?: PolicyDetailsConfiguration;
  priceBreakdownConfiguration?: PriceBreakdownConfiguration;
  mainPolicyId?: string;
  genericQuestionnaireKey?: string;
  productName: string;
  planQuestionId?: string;
}) => {
  const { t } = useSafeTranslation();
  const history = useHistory();
  const dispatch = useDispatch<PaymentScreenThunkDispatch>();
  const startDate = getStartDate(questionnaireAnswers);
  const address = questionnaireAnswers?.address;
  const monthlyPriceInEuros = Object(questionnaireAnswers?.quote).price;
  const questionnaireId = questionnaireAnswers?.questionnaireId
    ? String(questionnaireAnswers?.questionnaireId)
    : undefined;
  const quoteId = questionnaireAnswers?.quoteId
    ? String(questionnaireAnswers?.quoteId)
    : undefined;

  const source = getTrackingObject();

  if (!startDate || !monthlyPriceInEuros || !questionnaireId || !quoteId) {
    throw new Error('Missing start date and monthlyPrice data for checkout');
  }

  const callInitializeCheckout = async () => {
    await linkToStripe(api.network);

    const { data: checkout } = await startCheckout(api.network, {
      startDate,
      insuranceType: type,
      monthlyPriceInEuros,
      policyDetails: {
        type,
      },
      policyInfo: {
        questionnaireId,
        quoteId,
        source,
        regionOfPurchase: region,
        mainPolicyId,
      },
      ...(policyInfoKind && { policyInfoKind }),
    });

    if (!checkout) {
      throw new Error('Failed to initialize checkout');
    }

    const planName = planQuestionId
      ? processPolicyDetailsQuestionValue(
          questionnaireAnswers[planQuestionId] ??
            Object(questionnaireAnswers.quote)[planQuestionId]
        )
      : undefined;

    const checkoutConfiguration = {
      productName,
      planName,
      hasCheckoutDocuments,
      changeStartDatePath,
      genericQuestionnaireKey,
      redirectAddDependent:
        typeof redirectAddDependent === 'string'
          ? questionnaireAnswers[redirectAddDependent]
          : redirectAddDependent,
      priceBreakdown: processPriceBreakdown({
        questionnaireAnswers,
        checkout,
        t,
        productName,
        priceBreakdownConfiguration,
        planName,
      }),
      policyDetails: policyDetailsConfiguration
        ? processPolicyDetailsData(
            questionnaireAnswers,
            policyDetailsConfiguration,
            productName,
            planName
          )
        : undefined,
      address,
    };

    dispatch(mergeCheckoutInfo(checkout));

    const checkoutUrl = paramsSetUrl(
      generatePath(routes.paymentScreen.pay.path, { checkoutId: checkout.id }),
      [
        {
          key: 'c',
          value: lz.compressToEncodedURIComponent(
            JSON.stringify(checkoutConfiguration)
          ),
        },
      ]
    );

    history.replace(checkoutUrl);
  };

  useEffect(() => {
    callInitializeCheckout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <LoadingSpinner />;
};
