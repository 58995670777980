import { QuestionnaireQuestions } from '@getpopsure/qnr-framework';
import { GeneralQuestion } from 'features/generalQuestionnaire/model';

export const getReviewValue = <Questionnaire extends QuestionnaireQuestions>(
  questions: GeneralQuestion,
  questionId: keyof Questionnaire
) => {
  const question = questions.find((q) => q.id === questionId);
  if (!question) {
    return null;
  }

  switch (question.type) {
    case 'ADDRESS':
      return {
        type: 'Strings',
        key: 'address',
        keys: ['street', 'houseNumber', 'postcode', 'city'],
      };
    case 'NAME':
      return {
        type: 'Strings',
        key: 'name',
        keys: ['firstName', 'lastName'],
      };
    case 'DATE':
      return {
        type: 'Date',
        format: 'DD MMM YYYY',
        key: question.id,
      };

    case 'CHECKBOX':
    case 'RADIO':
      return {
        type: 'String',
        key: question.id,
        valueMap: question.props.mapValue?.title ?? question.props.mapValue,
      };

    case 'BOOLEAN':
      return {
        type: 'Boolean',
        key: question.id,
      };

    case 'COUNTRY_SINGLE':
      return {
        type: 'Strings',
        key: question.id,
        keys: ['name'],
      };

    case 'COUNTRY_MULTI':
      return { type: 'Array', key: question.id, pick: ['name'] };

    case 'QUOTEPAGE':
      return {
        type: 'Strings',
        key: question.id,
        keys: ['planId'],
      };
    default:
      return { type: 'String', key: questionId };
  }
};
