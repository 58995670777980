import { PaymentSelect } from 'features/paymentMethodSelector/components';
import { CheckoutContext } from 'features/paymentScreenV2/checkout.context';
import { useContext } from 'react';

import { AddNewPaymentOptionModal } from '../AddNewPaymentOptionModal/AddNewPaymentOptionModal';
import { SetNewDefaultModal } from '../SetNewDefaultModal/SetNewDefaultModal';

export const PaymentMethodSelector = ({
  hideDropdown = false,
  updatePaymentMethods,
}: {
  hideDropdown?: boolean;
  updatePaymentMethods: () => void;
}) => {
  const {
    setAddNewPaymentMethod,
    currentUserPaymentMethods: paymentOptions,
    currentSelectedPaymentMethod,
    setCurrentSelectedPaymentMethod,
    setNewDefaultOption: setSetDefaultOption,
  } = useContext(CheckoutContext);

  return (
    <div>
      <div className="mt16">
        {!hideDropdown && (
          <PaymentSelect
            selectedPaymentOption={currentSelectedPaymentMethod}
            onChange={setCurrentSelectedPaymentMethod}
            setAddNewPayment={setAddNewPaymentMethod}
            paymentOptions={paymentOptions}
            addSetDefaultOption={true}
            setSetDefaultOption={setSetDefaultOption}
          />
        )}

        <AddNewPaymentOptionModal updatePaymentMethods={updatePaymentMethods} />
        <SetNewDefaultModal />
      </div>
    </div>
  );
};
